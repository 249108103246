import { isNil } from "lodash";
import * as z from "zod";

export const US_ZIP_CODE_VALIDATOR = z
    .string()
    .optional()
    .refine((val) => isNil(val) || val === "" || /^\d{5}?$/.test(val), {
        message: "Invalid US ZIP code",
    });

export const POSITIVE_NUMBER_VALIDATOR = z.coerce
    .number({ message: "Please enter a number" })
    .refine((val) => val >= 0, { message: "Please enter a positive number" });

export const NUMBER_VALIDATOR = z.coerce
    .number({ message: "Please enter a number" })
    .optional();
