import clsx from "clsx";
import { forwardRef } from "react";
import { Typography } from "../typography/typography";

import "./checkbox.css";

interface CheckboxProps {
    checked?: boolean;
    onChange?: () => void;
    disabled?: boolean;
}
interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    id: string;
    label?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    ({ id, label, disabled, ...props }, ref) => {
        return (
            <label
                htmlFor={id}
                className={clsx(
                    "lui-flex lui-gap-2 lui-cursor-pointer lui-m-0 lui-items-center",
                    {
                        "lui-cursor-not-allowed": disabled,
                    },
                )}
            >
                <span
                    className={clsx(`lui-checkbox`, {
                        "lui-checkbox--checked": props.checked,
                        "lui-checkbox--disabled": disabled,
                    })}
                >
                    {props.checked && !disabled && (
                        <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_566_2455)">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.6889 1.02718C12.0538 1.32874 12.1052 1.86902 11.8036 2.23393L4.9122 10.5733L4.91013 10.5758C4.75337 10.764 4.55664 10.9149 4.33424 11.0175C4.11183 11.1202 3.86934 11.172 3.62441 11.1692C3.37547 11.1661 3.12969 11.1066 2.90702 10.9953C2.68509 10.8843 2.49108 10.7247 2.33946 10.5283C2.33895 10.5276 2.33844 10.527 2.33793 10.5263L0.180595 7.75261C-0.110037 7.37895 -0.0427217 6.84042 0.330947 6.54979C0.704617 6.25916 1.24314 6.32648 1.53377 6.70014L3.6454 9.41512L10.4821 1.1419C10.7837 0.776993 11.324 0.725632 11.6889 1.02718Z"
                                    fill="#E7F6ED"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_566_2455">
                                    <rect width="12" height="12" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                </span>
                <input
                    disabled={disabled ? true : false}
                    ref={ref}
                    id={id}
                    type="checkbox"
                    className="lui-checkbox__input"
                    {...props}
                />
                <Typography variant="span">{label}</Typography>
            </label>
        );
    },
);
