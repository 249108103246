import { useQueryClient } from "@tanstack/react-query";
import { Button } from "../../../land_ui/button/button";
import { Modal } from "../../../land_ui/modal/modal";
import { useSavedlistsDestroy } from "../../../orval/gen/api";
import { Icon } from "../../../land_ui/icon/icon";
import { ErrorMessageBox } from "../../../components/ErrorMessageBox";
import { Typography } from "@mui/material";

type DeleteSaveFilterModalProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    filterId: string;
};

export default function DeleteSaveFilterModal({
    isOpen,
    setIsOpen,
    filterId,
}: DeleteSaveFilterModalProps) {
    let queryClient = useQueryClient();
    let { mutate, isPending, error } = useSavedlistsDestroy({
        mutation: {
            onSuccess: () => {
                setIsOpen(false);
                queryClient.invalidateQueries({
                    queryKey: ["savedFilterList"],
                });
            },
        },
    });

    return (
        <div>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} isError>
                <Modal.Header>
                    <div className="lui-flex lui-gap-3 lui-items-center">
                        <Icon name="Trash" color="red-700" />
                        Delete
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <Typography className="lui-p-6">
                        Are you sure you want to delete this filter?
                    </Typography>
                    <ErrorMessageBox isShown={!!error}>
                        Something went wrong. Please try again.
                    </ErrorMessageBox>
                </Modal.Content>
                <Modal.Footer className="lui-flex lui-justify-end lui-gap-7">
                    <Button variant="base" onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        isLoading={isPending}
                        disabled={isPending}
                        variant="danger"
                        onClick={() => {
                            mutate({ id: filterId });
                        }}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
