import clsx from "clsx";
import { useLocalStorage } from "usehooks-ts";
import { Button } from "../../land_ui/button/button";
import { Icon } from "../../land_ui/icon/icon";
import { SatelliteIcon } from "../../land_ui/icon/icons/Satellite";
import { StreetIcon } from "../../land_ui/icon/icons/Street";
import { ControlPopover } from "./control_popover";

export const LayerOptions = {
    satellite: "mapbox://styles/mapbox/satellite-v9",
    street: "mapbox://styles/mapbox/outdoors-v12",
} as const;

interface MapLayerControlProps {
    setLayer: (layer: string) => void;
}

export function useMapLayer() {
    const [mapLayer, setMapLayer] = useLocalStorage<keyof typeof LayerOptions>(
        "landinsights-map-layer-setting",
        "satellite", // default value
    );

    return {
        mapLayer,
        setMapLayer,
        mapLayerOption: LayerOptions[mapLayer],
    };
}

export function MapLayerControl({ setLayer }: MapLayerControlProps) {
    const { mapLayer, setMapLayer } = useMapLayer();
    const mapLayerOptions = [
        {
            label: "Satellite",
            name: "satellite",
            icon: <SatelliteIcon />,
        },
        {
            label: "Street",
            name: "street",
            icon: <StreetIcon />,
        },
    ] as const;

    return (
        <ControlPopover
            icon="Globe"
            tooltipContent="Map Layers"
            title="Map Layers"
            placement="left"
        >
            {mapLayerOptions.map((layer) => (
                <div
                    key={layer.name}
                    className={clsx("lui-px-4 lui-py-2 ", {
                        "lui-bg-primary-50 hover:lui-bg-primary-50":
                            layer.name == mapLayer,
                        "hover:lui-bg-gray-100": layer.name != mapLayer,
                    })}
                >
                    <Button
                        variant="base"
                        onClick={() => {
                            setLayer(LayerOptions[layer.name]);
                            setMapLayer(layer.name);
                        }}
                        fullWidth
                        className="lui-p-0"
                    >
                        <span
                            className={clsx(
                                "lui-flex lui-justify-between lui-w-full lui-gap-2.5 lui-items-center",
                            )}
                        >
                            <span
                                className={clsx(
                                    "lui-flex lui-grow lui-gap-2.5 lui-items-center lui-no-underline lui-w-24",
                                )}
                            >
                                <span
                                    className={clsx(
                                        "lui-w-9 lui-border-2 lui-border-solid lui-rounded-lg",
                                        {
                                            "lui-border-primary-500":
                                                layer.name == mapLayer,
                                            "lui-border-gray-50":
                                                layer.name != mapLayer,
                                        },
                                    )}
                                >
                                    {layer.icon}
                                </span>
                                <span>{layer.label}</span>
                            </span>
                            <span>
                                {mapLayer == layer.name && (
                                    <Icon name="Check" size="md" color="primary-500" />
                                )}
                            </span>
                        </span>
                    </Button>
                </div>
            ))}
        </ControlPopover>
    );
}
