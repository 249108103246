import {
    arrow,
    autoUpdate,
    flip,
    FloatingArrow,
    FloatingPortal,
    hide,
    offset,
    Placement,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useMergeRefs,
    useRole,
} from "@floating-ui/react";
import clsx from "clsx";
import { cloneElement, forwardRef, isValidElement, useRef } from "react";
import { useControlledState } from "../../hooks/useControlledState";

const ARROW_WIDTH = 10;
const ARROW_HEIGHT = 7;
const GAP = 10;

export type PlacementOptionsType = Placement;

interface PopoverProps {
    children: React.ReactNode;
    trigger?: React.ReactNode;
    className?: string;
    placement?: PlacementOptionsType;
    showArrow?: boolean;
    dismissOnClick?: boolean;
    isOpen?: boolean;
    setIsOpen?: (isOpen: boolean) => void;
}
export const Popover = forwardRef<HTMLButtonElement, PopoverProps>(
    (
        {
            className,
            children,
            trigger,
            placement = "top",
            showArrow = false,
            dismissOnClick = false,
            isOpen: isControlledOpen,
            setIsOpen: setIsControlledOpen,
        }: PopoverProps,
        ref,
    ) => {
        const { state: isOpen, setState: setIsOpen } = useControlledState(
            "Popover",
            isControlledOpen,
            setIsControlledOpen,
        );

        const arrowRef = useRef(null);

        const { refs, floatingStyles, context } = useFloating({
            open: isOpen,
            onOpenChange: setIsOpen,
            placement: placement,
            whileElementsMounted: autoUpdate,
            middleware: [
                offset(showArrow ? ARROW_HEIGHT + GAP : GAP),
                flip(),
                arrow({
                    element: arrowRef,
                }),
                hide({
                    padding: 1,
                    altBoundary: true,
                    strategy: "referenceHidden", // 'referenceHidden' by default
                }),
            ],
        });

        const mergedRef = useMergeRefs([ref, refs.setReference]);
        const dismiss = useDismiss(context);
        const click = useClick(context, {});

        // Role props for screen readers
        const role = useRole(context, { role: "dialog" });

        // Merge all the interactions into prop getters
        const { getReferenceProps, getFloatingProps } = useInteractions([
            click,
            dismiss,
            role,
        ]);

        const triggerElement = isValidElement(trigger) ? (
            cloneElement(
                trigger,
                getReferenceProps({
                    ref: mergedRef,
                    ...trigger.props,
                }),
            )
        ) : (
            <button
                ref={mergedRef}
                className="lui-padding-0 lui-border-0 lui-bg-transparent"
                {...getReferenceProps()}
            >
                {trigger}
            </button>
        );

        return (
            <>
                {triggerElement}

                <FloatingPortal>
                    {isOpen && (
                        <div
                            ref={refs.setFloating}
                            className={clsx("lui-popover lui-z-50", className)}
                            style={floatingStyles}
                            {...getFloatingProps()}
                            onClick={() => {
                                if (dismissOnClick) {
                                    setIsOpen(false);
                                }
                            }}
                        >
                            {showArrow && (
                                <FloatingArrow
                                    ref={arrowRef}
                                    context={context}
                                    className="lui-fill-white"
                                    width={ARROW_WIDTH}
                                    height={ARROW_HEIGHT}
                                />
                            )}

                            {children}
                        </div>
                    )}
                </FloatingPortal>
            </>
        );
    },
);
