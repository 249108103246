import React from "react";

import MapIconButton from "../ui/map_button";
import ReactMapControl from "../ui/map_control";

export default function HelpControl() {
    return (
        <ReactMapControl position="top-right">
            <MapIconButton icon="fa-solid fa-question" title="Help" />
        </ReactMapControl>
    );
}
