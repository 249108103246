import clsx from "clsx";
import { Badge } from "../../land_ui/badge/badge";
import { Button } from "../../land_ui/button/button";
import { Typography } from "../../land_ui/typography/typography";

interface TagListProps {
    items: string[];
    onRemove: (item: string) => void;
    className?: string;
}

export function TagList({ items = [], onRemove, className }: TagListProps) {
    return (
        <>
            {items?.length > 0 && (
                <div
                    className={clsx(
                        "lui-flex lui-flex-wrap lui-gap-2 lui-mt-2",
                        className,
                    )}
                >
                    {items?.map((item) => (
                        <Badge
                            size="sm"
                            variant="info"
                            key={item}
                            className="lui-flex lui-gap-1 lui-items-center lui-rounded-xl"
                        >
                            <Typography weight="medium">{item}</Typography>
                            <Button
                                variant="base"
                                onClick={() => {
                                    onRemove(item);
                                }}
                                icon="Close"
                                iconSize="sm"
                            />
                        </Badge>
                    ))}
                </div>
            )}
        </>
    );
}
