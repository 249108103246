import { formatPercent, formatMoneyRounded } from "functions";

const METRIC_GROUP = {
    STR: "Sell Through Rates",
    OM: "On Market",
    SOLD: "Total Sold",
    DOM: "DOM",
    MP: "Median Price",
    AC: "Acreage",
    PPA: "PPA",
    GINI: "Gini",
};

const METRIC_TO_GROUP = {
    Active: METRIC_GROUP.OM,
    Pending: METRIC_GROUP.OM,

    "Sold: 1mo": METRIC_GROUP.SOLD,
    "Sold: 3mo": METRIC_GROUP.SOLD,
    "Sold: 6mo": METRIC_GROUP.SOLD,
    "Sold: 1yr": METRIC_GROUP.SOLD,

    "Pending STR": METRIC_GROUP.STR,
    "1mo STR": METRIC_GROUP.STR,
    "3mo STR": METRIC_GROUP.STR,
    "6mo STR": METRIC_GROUP.STR,
    "1yr STR": METRIC_GROUP.STR,

    "Avg DOM 1mo": METRIC_GROUP.DOM,
    "Avg DOM 3mo": METRIC_GROUP.DOM,
    "Avg DOM 6mo": METRIC_GROUP.DOM,
    "Avg DOM 1yr": METRIC_GROUP.DOM,

    "Median Price 1mo": METRIC_GROUP.MP,
    "Median Price 3mo": METRIC_GROUP.MP,
    "Median Price 6mo": METRIC_GROUP.MP,
    "Median Price 1yr": METRIC_GROUP.MP,
    "Median Active Price": METRIC_GROUP.MP,
    "Median Pending Price": METRIC_GROUP.MP,

    "Median Active Acreage": METRIC_GROUP.AC,
    "Median Pending Acreage": METRIC_GROUP.AC,
    "Median Sold Acreage": METRIC_GROUP.AC,

    "Median Active PPA": METRIC_GROUP.PPA,
    "Median Pending PPA": METRIC_GROUP.PPA,
    "Median Sold PPA": METRIC_GROUP.PPA,
    "Active PPA Min": METRIC_GROUP.PPA,
    "Active PPA Max": METRIC_GROUP.PPA,
    "Pending PPA Min": METRIC_GROUP.PPA,
    "Pending PPA Max": METRIC_GROUP.PPA,
    "PPA Range 1mo Min": METRIC_GROUP.PPA,
    "PPA Range 1mo Max": METRIC_GROUP.PPA,
    "PPA Range 3mo Min": METRIC_GROUP.PPA,
    "PPA Range 3mo Max": METRIC_GROUP.PPA,
    "PPA Range 6mo Min": METRIC_GROUP.PPA,
    "PPA Range 6mo Max": METRIC_GROUP.PPA,
    "PPA Range 1yr Min": METRIC_GROUP.PPA,
    "PPA Range 1yr Max": METRIC_GROUP.PPA,

    "Gini 1mo": METRIC_GROUP.GINI,
    "Gini 3mo": METRIC_GROUP.GINI,
    "Gini 6mo": METRIC_GROUP.GINI,
    "Gini 1yr": METRIC_GROUP.GINI,
};

const METRIC_LABEL = {
    // OM
    Active: "ACTIVE",
    Pending: "PENDING",

    // SOLD
    "Sold: 1mo": "1MO",
    "Sold: 3mo": "3MO",
    "Sold: 6mo": "6MO",
    "Sold: 1yr": "1YR",

    // STR
    "Pending STR": "PENDING",
    "1mo STR": "1MO",
    "3mo STR": "3MO",
    "6mo STR": "6MO",
    "1yr STR": "1YR",

    // DOM
    "Avg DOM 1mo": "1MO",
    "Avg DOM 3mo": "3MO",
    "Avg DOM 6mo": "6MO",
    "Avg DOM 1yr": "1YR",

    // MP
    "Median Price 1mo": "1MO",
    "Median Price 3mo": "3MO",
    "Median Price 6mo": "6MO",
    "Median Price 1yr": "1YR",
    "Median Active Price": "ACTIVE",
    "Median Pending Price": "PENDING",

    // AC
    "Median Active Acreage": "ACTIVE",
    "Median Pending Acreage": "PENDING",
    "Median Sold Acreage": "SOLD",

    // PPA
    "Median Active PPA": "MEDIAN ACTIVE",
    "Median Pending PPA": "MEDIAN PENDING",
    "Median Sold PPA": "MEDIAN SOLD",
    "Active PPA Min": "ACTIVE MIN",
    "Active PPA Max": "ACTIVE MAX",
    "Pending PPA Min": "PENDING MIN",
    "Pending PPA Max": "PENDING MAX",
    "PPA Range 1mo Min": "1MO MIN",
    "PPA Range 1mo Max": "1MO MAX",
    "PPA Range 3mo Min": "3MO MIN",
    "PPA Range 3mo Max": "3MO MAX",
    "PPA Range 6mo Min": "6MO MIN",
    "PPA Range 6mo Max": "6MO MAX",
    "PPA Range 1yr Min": "1YR MIN",
    "PPA Range 1yr Max": "1YR MAX",

    // GINI
    "Gini 1mo": "1MO",
    "Gini 3mo": "3MO",
    "Gini 6mo": "6MO",
    "Gini 1yr": "1YR",
};

export function format_metric_value(key, value) {
    if (key.includes("STR")) {
        return formatPercent(Math.round(value));
    } else if (key.includes("PPA") || key.includes("Price")) {
        return formatMoneyRounded(Math.round(value));
    }
    return value;
}

export function format_metric_label(key) {
    return METRIC_LABEL[key] || key;
}

export function get_metric_display_group(key) {
    return METRIC_TO_GROUP[key] || "";
}
