import React from "react";
import { createRoot } from "react-dom/client";
import { toast } from "react-toastify";

import Dropdown from "react-bootstrap/Dropdown";

import { ToolTipBox } from "components";

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

// Mapbox Plugins
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import RulerControl from "@mapbox-controls/ruler";
import "@mapbox-controls/ruler/src/index.css";

import { API_URL, MAPRIGHT_TOKEN } from "settings";
import { calculate_color_ranges, simple_gradient } from "functions";
import { MAPRIGHT_STYLES } from "constants";

const MAPRIGHT_SOURCES = [
    "wetlands",
    "floodplain_1",
    "floodplain_2",
    "contour_lines_1",
    "contour_lines_2",
    "contour_lines_3",
    "contour_lines_4",
    "contour_lines_5",
    "contour_lines_6",
    "contour_lines_7",
    "contour_lines_8",
    "contour_lines_9",
    "contour_lines_10",
    "contour_lines_11",
    "contour_lines_12",
    "contour_lines_13",
    "contour_lines_14",
];

const COUNTY_LAYERS = ["county_fill", "county_borders", "county_line_saved_market"];
const ZIP_LAYERS = ["zip_fill", "zip_borders", "zip_line_saved_market"];
const EMPTY_COUNTY_LAYERS = ["empty_county_borders", "empty_county_fill"];
const EMPTY_ZIP_LAYERS = ["empty_zip_borders", "empty_zip_fill"];
const ALL_LAYERS = [
    ...COUNTY_LAYERS,
    ...ZIP_LAYERS,
    ...EMPTY_COUNTY_LAYERS,
    ...EMPTY_ZIP_LAYERS,
];

const BORDER_COLOR = "rgba(0, 0, 0, 0.6)";
const EMPTY_BORDER_COLOR = "rgb(33, 212, 253)";

const COUNTY_FILTER = ["all", ["==", "level", 2], ["==", "iso_a2", "US"]];
const ZIP_FILTER = ["all", ["==", "level", 1], ["==", "iso_a2", "US"]];

const ZIP_MIN_ZOOM = 6;

export default class MapboxMap extends React.PureComponent {
    state = {
        map: null,
        lng: -96.507,
        lat: 38.0255,
        zoom: 3.5,

        geocoder: null,

        heatmapVisible: true,
        wetlandsVisible: false,
        floodVisible: false,
        contourVisible: false,
    };

    constructor(props) {
        super(props);

        this.mapContainerRef = React.createRef();

        this.toggleHeatmap = this.toggleHeatmap.bind(this);
        this.toggleLayer = this.toggleLayer.bind(this);

        this.addMapSources = this.addMapSources.bind(this);
        this.addMaprightSources = this.addMaprightSources.bind(this);

        this.addCountyLayers = this.addCountyLayers.bind(this);
        this.addZipLayers = this.addZipLayers.bind(this);

        this.updateFeatureState = this.updateFeatureState.bind(this);
        this.updateLayerStyle = this.updateLayerStyle.bind(this);
        this.updateLayerVisibility = this.updateLayerVisibility.bind(this);
        this.updateMaprightLayerVisibility =
            this.updateMaprightLayerVisibility.bind(this);

        this.onLoad = this.onLoad.bind(this);
        this.triggerGeocode = this.triggerGeocode.bind(this);
        this.moveToLocation = this.moveToLocation.bind(this);
        this.onRightClick = this.onRightClick.bind(this);
    }

    initMap() {
        const { lng, lat, zoom } = this.state;
        try {
            return new mapboxgl.Map({
                projection: "mercator",
                container: this.mapContainerRef.current,
                style: "mapbox://styles/kmlandinsights/clyvxfp7m005001px4kv01z02",
                center: [lng, lat],
                zoom: zoom,
                minZoom: 3.5,
                maxZoom: 20,
                performanceMetricsCollection: false,
            });
        } catch (ex) {
            console.error(ex);
            toast.error(ex.message);
        }
        return null;
    }

    componentDidMount() {
        const map = this.initMap();
        if (!map) {
            return;
        }

        map.on("load", () => {
            // Ensure map is present in state before calling onLoad. This is to
            // safeguard against "Style is not done loading" errors which may
            // occur if updateFeatureState is called before the map is done
            // loading.
            this.setState({ map }, this.onLoad);
        });

        map.on("zoom", () => {
            this.setState({
                zoom: map.getZoom(),
            });
        });

        const tooltipContainer = document.createElement("div");
        const tooltip = new mapboxgl.Marker(tooltipContainer)
            .setLngLat([0, 0])
            .addTo(map);
        const tooltipRoot = createRoot(tooltipContainer);

        map.on("mousemove", (e) => {
            const features = map.queryRenderedFeatures(e.point);
            const feature = features.find((feature) =>
                feature.layer.id.includes("fill"),
            );

            tooltip.setLngLat(e.lngLat);

            if (feature) {
                this.setTooltip(feature);
            } else {
                this.setTooltip(null);
            }
        });

        map.on("contextmenu", this.onRightClick);

        const contextMenuDiv = document.createElement("div");
        const contextMenuRoot = createRoot(contextMenuDiv);
        const contextMenuPopup = new mapboxgl.Popup({
            closeButton: false,
            className: "disable-popup-tip",
            focusAfterOpen: false,
        })
            .setDOMContent(contextMenuDiv)
            .addTo(map);

        this.setState({
            tooltipRoot,
            contextMenuPopup,
            contextMenuRoot,
        });
    }

    componentDidUpdate(prevProps) {
        // Ensure map is done loading before calling updateFeatureState,
        // updateLayerStyle, etc.
        if (!this.state.map) {
            console.log("Map not loaded yet!");
            return;
        }
        if (this.props.data_timestamp != prevProps.data_timestamp) {
            this.updateFeatureState();
            this.updateLayerStyle();
        }
        if (this.props.geo_scale != prevProps.geo_scale) {
            this.updateFeatureState();
            this.updateLayerStyle();
            this.updateLayerVisibility();
        }
        if (this.props.geocoding_input != prevProps.geocoding_input) {
            this.triggerGeocode();
        }
        if (this.props.markets != prevProps.markets) {
            this.updateFeatureState();
            this.updateLayerStyle();
        }
    }

    onRightClick(e) {
        const { map } = this.state;
        const features = map.queryRenderedFeatures(e.point);
        const feature = features.find((feature) => feature.layer.id.includes("fill"));
        if (!feature) {
            return;
        }

        const regionID = feature.state?.data_points?.id;
        const marketName = feature.state?.data_points?.name;
        const marketState = feature.state?.data_points?.state;

        // region ID feature state may not be present on My Markets page
        if (!regionID) {
            return;
        }

        const market = this.props.findMarketByRegionID(regionID);
        const isSavedMarket = !!market;

        const { contextMenuPopup, contextMenuRoot } = this.state;
        contextMenuPopup.setLngLat(e.lngLat);
        contextMenuPopup.addTo(map);

        contextMenuRoot.render(
            <ContextMenu
                isSavedMarket={isSavedMarket}
                toggleSavedMarket={() => {
                    this.props.toggleSavedMarket(regionID);
                    contextMenuPopup.remove();
                }}
                marketName={marketName}
                marketState={marketState}
            />,
        );
    }

    triggerGeocode() {
        this.state.geocoder.query(this.props.geocoding_input);
    }

    moveToLocation(position) {
        const { map } = this.state;

        // Move map to the geocoded location
        if (position.bbox) {
            map.fitBounds(position.bbox, { duration: 0 });
        } else {
            map.flyTo({ center: position.center, zoom: 12, duration: 0 });
        }
    }

    addMaprightSources(map) {
        for (let name of MAPRIGHT_SOURCES) {
            const layers = MAPRIGHT_STYLES[name].layers;

            map.addSource(name, MAPRIGHT_STYLES[name].sources.composite);

            for (let layer of layers) {
                if (layer.id == "background") {
                    continue;
                }
                if (name.includes("floodplain") && layer.id.includes("500")) {
                    continue;
                }

                if (layer.source == "composite") {
                    layer.source = name;
                }

                map.addLayer(layer);
            }
        }
    }

    toggleLayer(layer) {
        if (layer === "wetlands") {
            this.setState(
                { wetlandsVisible: !this.state.wetlandsVisible },
                this.updateMaprightLayerVisibility,
            );
        } else if (layer === "flood") {
            this.setState(
                { floodVisible: !this.state.floodVisible },
                this.updateMaprightLayerVisibility,
            );
        } else if (layer === "contour") {
            this.setState(
                { contourVisible: !this.state.contourVisible },
                this.updateMaprightLayerVisibility,
            );
        }
    }

    onLoad() {
        const { map } = this.state;

        this.addMapSources(map);
        this.addMaprightSources(map);
        this.addCountyLayers(map);
        this.addZipLayers(map);

        this.updateLayerVisibility();
        this.updateMaprightLayerVisibility();

        this.updateFeatureState();
        this.updateLayerStyle();

        // Add geocoder control to the map
        const geocoder = new MapboxGeocoder({
            accessToken: MAPRIGHT_TOKEN,
            mapboxgl: mapboxgl,
            marker: false, // Disable default marker
            placeholder: "Enter location...", // Placeholder text for the input field
            types: "district,postcode,address",
            countries: "us",
            clearAndBlurOnEsc: true,
        });

        geocoder.on("result", (e) => {
            this.moveToLocation(e.result);
        });

        map.addControl(geocoder);

        map.loadImage("/static/images/mapright/LightBlue.png", (err, image) => {
            map.addImage("wetlands27", image);
        });
        map.loadImage("/static/images/mapright/DarkBlue.png", (err, image) => {
            map.addImage("500Flood", image);
        });
        map.loadImage("/static/images/mapright/DarkBlue.png", (err, image) => {
            map.addImage("100Flood", image);
        });
        map.loadImage("/static/images/mapright/DarkBlue.png", (err, image) => {
            map.addImage("Floodway7", image);
        });

        map.addControl(
            new mapboxgl.FullscreenControl({
                container: document.getElementById("fullscreen-map-container"),
            }),
            "bottom-right",
        );

        // Add ruler control to the map
        map.addControl(
            new RulerControl({
                units: "feet", // Set units to feet
                labelFormat: (n) => `${n.toFixed(2)} ft`, // Customize label format to show feet
            }),
            "bottom-right",
        );

        this.setState({ geocoder });
    }

    toggleHeatmap() {
        const { map } = this.state;
        const heatmapVisible = !this.state.heatmapVisible;
        this.setState({ heatmapVisible }, this.updateLayerVisibility);
    }

    setTooltip(feature) {
        if (feature) {
            this.state.tooltipRoot.render(
                <ToolTipBox
                    feature={feature}
                    data_order={this.props.map_lookup_order}
                />,
            );
        } else {
            this.state.tooltipRoot.render(null);
        }
    }

    addMapSources(map) {
        map.addSource("maptiler_source", {
            type: "vector",
            tiles: [`${API_URL}/tiles/countries/{z}/{x}/{y}.pbf`],
        });

        map.addSource("mapbox-dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxzoom: 18,
        });

        // add the DEM source as a terrain layer
        map.setTerrain({ source: "mapbox-dem", exaggeration: 1.0 });
    }

    addCountyLayers(map) {
        const countyLayer = {
            source: "maptiler_source",
            "source-layer": "administrative",
            filter: COUNTY_FILTER,
        };

        map.addLayer({
            id: "empty_county_borders",
            type: "line",
            paint: {
                "line-width": 0.5,
                "line-color": EMPTY_BORDER_COLOR,
            },
            layout: {
                visibility: "none",
            },
            ...countyLayer,
        });

        map.addLayer({
            id: "empty_county_fill",
            type: "fill",
            paint: {
                "fill-opacity": 0,
            },
            layout: {
                visibility: "none",
            },
            ...countyLayer,
        });

        map.addLayer({
            id: "county_fill",
            type: "fill",
            paint: {
                "fill-opacity": 0.5,
            },
            ...countyLayer,
        });

        map.addLayer({
            id: "county_borders",
            type: "line",
            paint: {
                "line-width": 0.5,
                "line-color": BORDER_COLOR,
            },
            ...countyLayer,
        });

        if (this.props.outlineSavedMarkets) {
            map.addLayer({
                id: "county_line_saved_market",
                type: "line",
                paint: {
                    "line-width": 5,
                    "line-color": "#cb0c9f",
                    "line-opacity": [
                        "case",
                        ["boolean", ["feature-state", "isSavedMarket"], false],
                        0.75,
                        0,
                    ],
                },
                ...countyLayer,
            });
        }
    }

    addZipLayers(map) {
        const zipLayer = {
            source: "maptiler_source",
            "source-layer": "postal",
            filter: ZIP_FILTER,
        };

        map.addLayer({
            id: "empty_zip_borders",
            type: "line",
            paint: {
                "line-width": 0.5,
                "line-color": EMPTY_BORDER_COLOR,
            },
            layout: {
                visibility: "none",
            },
            ...zipLayer,
        });

        map.addLayer({
            id: "empty_zip_fill",
            type: "fill",
            paint: {
                "fill-opacity": 0,
            },
            layout: {
                visibility: "none",
            },
            ...zipLayer,
        });

        map.addLayer({
            id: "zip_fill",
            type: "fill",
            paint: {
                "fill-opacity": 0.5,
            },
            ...zipLayer,
        });

        map.addLayer({
            id: "zip_borders",
            type: "line",
            paint: {
                "line-width": 0.5,
                "line-color": BORDER_COLOR,
            },
            ...zipLayer,
        });

        if (this.props.outlineSavedMarkets) {
            map.addLayer({
                id: "zip_line_saved_market",
                type: "line",
                paint: {
                    "line-width": 5,
                    "line-color": "#cb0c9f",
                    "line-opacity": [
                        "case",
                        ["boolean", ["feature-state", "isSavedMarket"], false],
                        1,
                        0,
                    ],
                },
                ...zipLayer,
            });
        }
    }

    updateLayerStyle() {
        const { map } = this.state;

        const invert_colors = this.props.invert_colors;
        const layerColorRange = calculate_color_ranges(
            this.props.map_color_data,
            invert_colors,
        );

        map.setPaintProperty("county_fill", "fill-color", [
            "case",
            ["==", ["coalesce", ["feature-state", "value"], 0], 0],
            "rgba(0, 0, 0, 1.0)",
            layerColorRange,
        ]);

        map.setPaintProperty("zip_fill", "fill-color", [
            "case",
            ["==", ["coalesce", ["feature-state", "value"], 0], 0],
            "rgba(0, 0, 0, 1.0)",
            layerColorRange,
        ]);
    }

    updateLayerVisibility() {
        const { map, heatmapVisible } = this.state;
        const { geo_scale } = this.props;

        let visibleLayers = [];

        if (heatmapVisible && geo_scale == "County") {
            visibleLayers = COUNTY_LAYERS;
        } else if (heatmapVisible && geo_scale == "ZIP") {
            visibleLayers = ZIP_LAYERS;
        } else if (!heatmapVisible && geo_scale == "County") {
            visibleLayers = EMPTY_COUNTY_LAYERS;
        } else if (!heatmapVisible && geo_scale == "ZIP") {
            visibleLayers = EMPTY_ZIP_LAYERS;
        }

        for (let id of ALL_LAYERS) {
            const visibility = visibleLayers.includes(id) ? "visible" : "none";
            if (
                map.getLayer(id) &&
                map.getLayoutProperty(id, "visibility") != visibility
            ) {
                map.setLayoutProperty(id, "visibility", visibility);
            }
        }
    }

    updateMaprightLayerVisibility() {
        const { map } = this.state;
        const { contourVisible, floodVisible, wetlandsVisible } = this.state;

        for (let name of MAPRIGHT_SOURCES) {
            const visibility =
                (name.includes("contour") && contourVisible) ||
                (name.includes("flood") && floodVisible) ||
                (name.includes("wetlands") && wetlandsVisible)
                    ? "visible"
                    : "none";

            const layers = MAPRIGHT_STYLES[name].layers;
            for (let layer of layers) {
                if (
                    map.getLayer(layer.id) &&
                    map.getLayoutProperty(layer.id, "visibility") != visibility
                ) {
                    map.setLayoutProperty(layer.id, "visibility", visibility);
                }
            }
        }
    }

    // Update heatmap and saved filters map feature state
    updateFeatureState(e) {
        const { map } = this.state;
        const { geo_scale, map_color_data, map_lookup_data } = this.props;

        const source = "maptiler_source";
        const sourceLayer = geo_scale == "County" ? "administrative" : "postal";

        const savedMarketIDs =
            this.props.markets?.map((market) => market.region.gid) || [];

        // Clear feature state for entire layer
        map.removeFeatureState({ source, sourceLayer });

        for (let id in map_color_data) {
            const value = parseFloat(map_color_data[id]);
            const isSavedMarket = savedMarketIDs.includes(id);

            // Update heatmap and saved markets features state
            map.setFeatureState(
                { source, sourceLayer, id },
                {
                    value,
                    data_points: map_lookup_data[id],
                    isSavedMarket,
                },
            );
        }
    }

    render() {
        const fullscreenStyle = this.props.fullscreen ? "d-flex flex-column" : "";
        const mapFiltersStyle = this.props.fullscreen ? "row bg-white p-2" : "row mb-4";

        return (
            <div id="fullscreen-map-container" className={fullscreenStyle}>
                <div className={mapFiltersStyle}>{this.props.mapFilters}</div>
                <div
                    id="map-with-custom-overlays"
                    style={{
                        position: "relative",
                        height: this.props.fullscreen ? "100%" : 575,
                    }}
                >
                    <div className="custom-map-controls">
                        <button
                            onClick={() => this.toggleLayer("wetlands")}
                            className="btn btn-outline-secondary btn-icon-only custom-map-visiblity ms-sm-1"
                            style={{ fontSize: "16px" }}
                            title={
                                this.state.wetlandsVisible
                                    ? "Disable Wetlands"
                                    : "Enable Wetlands"
                            }
                        >
                            <img
                                className="noun-icon"
                                src="/static/images/wetland.svg"
                            />
                            {this.state.wetlandsVisible ? null : (
                                <div className="strike-through-inline" />
                            )}
                        </button>
                        <button
                            onClick={() => this.toggleLayer("flood")}
                            className="btn btn-outline-secondary btn-icon-only custom-map-visiblity ms-sm-1"
                            style={{ fontSize: "16px" }}
                            title={
                                this.state.floodVisible
                                    ? "Disable Flood"
                                    : "Enable Flood"
                            }
                        >
                            <img className="noun-icon" src="/static/images/flood.svg" />
                            {this.state.floodVisible ? null : (
                                <div className="strike-through-inline" />
                            )}
                        </button>
                        <button
                            onClick={() => this.toggleLayer("contour")}
                            className="btn btn-outline-secondary btn-icon-only custom-map-visiblity ms-sm-1"
                            style={{ fontSize: "16px" }}
                            title={
                                this.state.contourVisible
                                    ? "Disable Contour"
                                    : "Enable Contour"
                            }
                        >
                            <img
                                className="noun-icon"
                                src="/static/images/contour.svg"
                            />
                            {this.state.contourVisible ? null : (
                                <div className="strike-through-inline" />
                            )}
                        </button>
                        <button
                            onClick={this.toggleHeatmap}
                            className="btn btn-outline-secondary btn-icon-only custom-map-visiblity ms-sm-1"
                            style={{ fontSize: "16px", color: "black" }}
                            title={
                                this.state.heatmapVisible
                                    ? "Disable Heatmap"
                                    : "Enable Heatmap"
                            }
                        >
                            {!this.state.heatmapVisible ? (
                                <i className="fas fa-eye-slash"></i>
                            ) : (
                                <i className="fas fa-eye"></i>
                            )}
                        </button>
                        <Legend invert={this.props.invert_colors} />
                    </div>

                    <ZoomWarning
                        geo_scale={this.props.geo_scale}
                        zoom={this.state.zoom}
                    />

                    <div
                        ref={this.mapContainerRef}
                        className="map-container"
                        style={{ height: "100%" }}
                    />
                </div>
            </div>
        );
    }
}

function ZoomWarning({ geo_scale, zoom }) {
    if (geo_scale == "ZIP" && zoom < ZIP_MIN_ZOOM) {
        return (
            <div
                style={{
                    background: "white",
                    borderRadius: "4px",
                    padding: "2px 0px",
                    position: "absolute",
                    zIndex: "30",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    width: "275px",
                    margin: "0px auto 30px",
                    textAlign: "center",
                    fontWeight: "bold",
                }}
            >
                Please zoom in to view metrics
            </div>
        );
    }
}

function ContextMenu({ isSavedMarket, toggleSavedMarket, marketName, marketState }) {
    const fullName = `${marketName}, ${marketState}`; // Combine name and state for display
    return (
        <Dropdown.Menu show className="border border-dark">
            {isSavedMarket ? (
                <Dropdown.Item className="text-danger" onClick={toggleSavedMarket}>
                    Remove {fullName} from Saved Markets
                </Dropdown.Item>
            ) : (
                <Dropdown.Item className="text-info" onClick={toggleSavedMarket}>
                    Add {fullName} to Saved Markets
                </Dropdown.Item>
            )}
            <Dropdown.Item
                className="text-info"
                href={`/data?county=${encodeURI(fullName)}`}
                target="_blank"
            >
                Open {fullName} in Data Platform
            </Dropdown.Item>
        </Dropdown.Menu>
    );
}

function Legend({ invert }) {
    const gradient = `linear-gradient(to ${invert ? "left" : "right"}, ${simple_gradient()})`;
    return (
        <div className="custom-map-legend">
            <div style={{ position: "relative", top: "-2px" }}>
                <b
                    className="text-start"
                    style={{
                        fontSize: "10px",
                        fontWeight: "700",
                        float: "left",
                        paddingRight: "5px",
                        background: "white",
                        borderBottomRightRadius: "4px",
                    }}
                >
                    cold
                </b>

                <b
                    className="text-end"
                    style={{
                        fontSize: "10px",
                        fontWeight: "700",
                        float: "right",
                        paddingLeft: "5px",
                        background: "white",
                        borderBottomLeftRadius: "4px",
                    }}
                >
                    hot
                </b>
            </div>
            <div
                style={{
                    backgroundImage: gradient,
                    height: "30px",
                    borderRadius: "4px",
                }}
            ></div>
        </div>
    );
}
