import { LoaderFunctionArgs, Navigate, redirect } from "react-router-dom";

import { InternalLayout, ExternalLayout } from "components";
import {
    AdminDashboard,
    AdminUserDetail,
    Campaigns,
    Comping,
    ContactUs,
    Dashboard,
    FeatureIdea,
    LandScrubbingCreate,
    LandScrubbingDashboard,
    LandScrubbingResult,
    Login,
    MarketSelectionCourse,
    ParcelViewer,
    PhotoEditor,
    RealtorReportCreate,
    RealtorReportList,
    RealtorReportView,
    Register,
    RegisterOnboarding,
    RegisterSubscription,
    ResetPasswordConfirm,
    ResetPasswordRequest,
    SavedFilters,
    Settings,
    SkipTraceCreate,
    SkipTraceDetail,
    SkipTraceList,
} from "pages";
import { LandUiView } from "./land_ui/page";
import { isLoggedIn, logout } from "functions";

const routes = [
    {
        path: "/",
        Component: () => {
            if (
                isLoggedIn() &&
                // Exclude Create Subscription page to avoid a redirect loop.
                // This page requires a user session.
                !window.location.pathname.includes("/create-account/subscription")
            ) {
                return <Navigate to="/home" />;
            }
            return <ExternalLayout />;
        },
        children: [
            { index: true, path: "", element: <Login /> },
            { path: "login", element: <Login /> }, // Intentional redundant login route
            {
                path: "register",
                Component: (): any => {
                    window.location.href = "https://landinsights.co/apply";
                    return null;
                },
            },
            {
                path: "create-account",
                children: [
                    { index: true, element: <Register /> },
                    { path: "onboarding", element: <RegisterOnboarding /> },
                    { path: "subscription", element: <RegisterSubscription /> },
                ],
            },
            {
                path: "reset-password/confirm/:userID/:token",
                element: <ResetPasswordConfirm />,
            },
            { path: "reset-password", element: <ResetPasswordRequest /> },
        ],
    },
    {
        path: "/home",
        element: <InternalLayout />,
        children: [
            {
                index: true,
                path: "",
                element: <Dashboard key="market_research" title="Market Research" />,
            },
            {
                path: "market_research",
                element: <Dashboard key="market_research" title="Market Research" />,
            },
            {
                path: "my_markets",
                element: (
                    <Dashboard
                        key="my_markets"
                        title="My Markets"
                        viewingMyMarketsPage
                    />
                ),
            },
            { path: "saved_filters", element: <SavedFilters /> },
            { path: "campaigns", element: <Campaigns /> },
            {
                path: "land_scrubbing",
                children: [
                    { index: true, element: <LandScrubbingDashboard /> },
                    { path: "new", element: <LandScrubbingCreate /> },
                    { path: "results/:id", element: <LandScrubbingResult /> },
                ],
            },
            {
                path: "skip_tracing",
                children: [
                    { index: true, element: <SkipTraceList /> },
                    { path: "new", element: <SkipTraceCreate /> },
                    { path: ":id", element: <SkipTraceDetail /> },
                ],
            },
            {
                path: "realtor_report",
                children: [
                    { index: true, element: <RealtorReportList /> },
                    { path: "new", element: <RealtorReportCreate /> },
                    { path: ":state/:county", element: <RealtorReportView /> },
                    { path: ":state", element: <RealtorReportView /> },
                ],
            },
            { path: "photo_editor", element: <PhotoEditor /> },
            { path: "market_selection_course", element: <MarketSelectionCourse /> },
            { path: "comping/:parcelId", element: <Comping /> },
            { path: "settings", element: <Settings /> },
            { path: "contact_us", element: <ContactUs /> },
            { path: "feature_idea", element: <FeatureIdea /> },
            {
                path: "admin",
                children: [
                    { index: true, element: <AdminDashboard /> },
                    { path: "user/:id", element: <AdminUserDetail /> },
                ],
            },
        ],
    },
    {
        path: "/comping",
        loader: async ({ request }: LoaderFunctionArgs) => {
            const url = new URL(request.url);
            const parcel = url.searchParams.get("parcel");

            if (parcel) {
                return redirect(`/home/comping/${parcel}`);
            }
            return redirect(`/home`);
        },
    },
    { path: "/data", element: <ParcelViewer /> },
    { path: "/map", element: <Navigate to="/data" replace={true} /> },
    { path: "/land-ui", element: <LandUiView /> },
    {
        path: "/logout",
        Component: (): any => {
            logout();
            return null;
        },
    },
];

export default routes;
