import React, { useContext, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";

import { UserContext } from "context";
import { parseErrorCode, parseErrorResponse, post } from "functions";
import { INSUFFICIENT_FUNDS, DUPLICATE_LAND_SCRUB } from "constants";

import UploadCSV from "./upload";
import Headers from "./headers";
import Features from "./features";
import Optionals from "./optionals";
import Checkout from "./checkout";
import {
    STEP_UPLOAD,
    STEP_HEADERS,
    STEP_FEATURES,
    STEP_OPTIONALS,
    STEP_CHECKOUT,
    STEP_SUBMIT,
} from "./steps";

const defaultValues = {
    duplicates: true,
    land_locked: true,
    wetlands: true,
    flood_zone: true,
    wetlands_allowed: 50,
    flood_zone_allowed: 50,
    size_preference: "larger",
};

export default function CreateScrub() {
    const navigate = useNavigate();
    const { fetchWallet } = useContext(UserContext);

    const [scrubRequest, _setScrubRequest] = useState(defaultValues);
    const [step, setStep] = useState(STEP_UPLOAD);
    const [errorCode, setErrorCode] = useState();

    // State for Headers step
    const [headers, setHeaders] = useState();
    const [exampleItems, setExampleItems] = useState();

    // Callbacks called from useEffect (in child components) must be wrapped
    // with useCallback to avoid infinite loops
    const setScrubRequest = useCallback(
        (obj) => _setScrubRequest((scrubRequest) => ({ ...scrubRequest, ...obj })),
        [_setScrubRequest],
    );
    const next = useCallback(() => setStep((step) => step + 1), [setStep]);
    const prev = useCallback(() => setStep((step) => step - 1), [setStep]);
    const goto = useCallback((step) => setStep(step), [setStep]);

    const submit = async () => {
        setErrorCode(null);
        next();
        try {
            // Create or update Land Scrub
            const result = await post("/analytics/land_scrub/", scrubRequest);
            setScrubRequest({ id: result.id });

            // Start Land Scrub
            await post(`/analytics/land_scrub/${result.id}/start/`);

            navigate("/home/land_scrubbing/");
        } catch (xhr) {
            const code = parseErrorCode(xhr);
            if (code === INSUFFICIENT_FUNDS) {
                // Don't show an error modal because we have special error
                // handling for this error on the checkout page.
            } else if (code === DUPLICATE_LAND_SCRUB) {
                await Swal.fire({
                    title: "Failed Job And Trying Again?",
                    icon: "question",
                    text: "It looks you're trying to re-upload a file for a job that failed in the last 48 hours. Rest assured, we're looking into it and you don't need to re-upload this same file. In the mean time, feel free to reach out to customer support for a status update.",
                });
            } else {
                // Catch-all for all other server errors
                await Swal.fire({
                    title: "Unexpected Error",
                    icon: "error",
                    text: parseErrorResponse(xhr),
                });
            }
            setErrorCode(code);
            prev();
        }
        // Update wallet balance in nav bar upon success or failure
        fetchWallet();
    };

    const getProgress = () => {
        switch (step) {
            case STEP_HEADERS:
                return 30;
            case STEP_FEATURES:
                return 50;
            case STEP_OPTIONALS:
                return 70;
            case STEP_CHECKOUT:
                return 90;
            case STEP_SUBMIT:
                return 100;
            default:
                return 0;
        }
    };

    const getCurrentStep = () => {
        switch (step) {
            case STEP_HEADERS:
                return (
                    <Headers
                        scrubRequest={scrubRequest}
                        setScrubRequest={setScrubRequest}
                        headers={headers}
                        setHeaders={setHeaders}
                        exampleItems={exampleItems}
                        setExampleItems={setExampleItems}
                        next={next}
                        prev={prev}
                    />
                );
            case STEP_FEATURES:
                return (
                    <Features
                        scrubRequest={scrubRequest}
                        setScrubRequest={setScrubRequest}
                        next={next}
                        prev={prev}
                    />
                );
            case STEP_OPTIONALS:
                return (
                    <Optionals
                        scrubRequest={scrubRequest}
                        setScrubRequest={setScrubRequest}
                        next={next}
                        prev={prev}
                    />
                );
            case STEP_CHECKOUT:
                return (
                    <Checkout
                        scrubRequest={scrubRequest}
                        submit={submit}
                        prev={prev}
                        goto={goto}
                        errorCode={errorCode}
                    />
                );
            case STEP_SUBMIT:
                return <h5>Submitting Land Scrub Request...</h5>;
            default:
                return (
                    <UploadCSV
                        setScrubRequest={setScrubRequest}
                        setHeaders={setHeaders}
                        setExampleItems={setExampleItems}
                        next={next}
                    />
                );
        }
    };

    const progress = getProgress();

    return (
        <div className="card">
            <div className="card-header">
                <h5>Start A New Scrub</h5>
            </div>
            <div className="card-body">
                <div className="row mb-5">
                    <div className="col-12 col-lg-8 m-auto">
                        <div className="progress-wrapper mb-5">
                            <div className="progress">
                                <div
                                    className="progress-bar bg-gradient-info"
                                    role="progressbar"
                                    aria-valuenow={progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: `${progress}%` }}
                                ></div>
                            </div>
                        </div>
                        {getCurrentStep()}
                    </div>
                </div>
            </div>
        </div>
    );
}
