import { useQuery } from "@tanstack/react-query";
import { AXIOS_INSTANCE } from "../orval/axios-instance";

export interface LandUser {
    id: string;
    email: string;
    name: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    company_id: string;
    is_staff: boolean;
    is_superuser: boolean;
    is_friend: boolean;
    lia_member: boolean;
    beta: boolean;
    onboarding_survey_completed: boolean;
    onboarding_eula_completed: boolean;
    onboarding_stripe_completed: boolean;
    has_paid_subscription: boolean;
    has_unlimited_data: boolean;
    stripe_customer_id: string;
    stripe_subscription_period_end: string;
    stripe_subscription_canceled_at: string;
    data_point_preferences: string[];
    created_at: string;
    last_login: string;
    permissions: string[];
}

export function useCurrentUser() {
    const { data, isLoading, refetch } = useQuery<LandUser>({
        queryKey: ["currentUser"],
        queryFn: async () => {
            return AXIOS_INSTANCE.get("/user/user");
        },
    });

    return {
        user: data,
        isLoading,
        getUser: async () => {
            try {
                return (await refetch()).data;
            } catch (e) {
                return null;
            }
        },
    };
}
