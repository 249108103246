import React, { Component } from "react";

import { toast } from "react-toastify";
import { AGGrid, AGFilterRender } from "components";
import { Button, DeleteButton } from "library";
import { ajax_wrapper, isErrorResponse, post, parseErrorResponse } from "functions";

class OpenButton extends Component {
    render() {
        return (
            <Button
                href={`/home/?filter=${this.props.data["id"]}`}
                type="gradient-secondary"
            >
                <i className="fa fa-search"></i> Open Filter
            </Button>
        );
    }
}

function IconOnHover({ value }) {
    return (
        <div className="editable-field icon-on-hover">
            {value}
            <i className="fas fa-edit ms-1"></i>
        </div>
    );
}

export default class SavedFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            error: "",
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.onFilterNameChanged = this.onFilterNameChanged.bind(this);
    }

    componentDidMount() {
        this.refresh_data();
    }

    refresh_data() {
        ajax_wrapper("GET", "/api/filters/", {}, (value) => {
            if (isErrorResponse(value)) {
                return;
            }
            this.setState({ filters: value });
        });
    }

    onFilterNameChanged(e) {
        post(`/api/filters/${e.data.id}/`, { name: e.newValue || "" })
            .then((data) => toast.success("Filter Saved"))
            .catch((xhr) => toast.error(parseErrorResponse(xhr)));
    }

    render() {
        let rows = this.state.filters;
        for (let item of rows) {
            item["refresh_data"] = this.refresh_data;
        }

        let columns = [
            {
                field: "name",
                filter: true,
                editable: true,
                singleClickEdit: true,
                onCellValueChanged: this.onFilterNameChanged,
                cellRenderer: IconOnHover,
            },
            {
                field: "data",
                cellRenderer: AGFilterRender,
                cellDataType: false,
                autoHeight: true,
            },
            { field: "Open", cellRenderer: OpenButton },
            {
                headerName: "Delete?",
                field: "id",
                cellRenderer: DeleteButton,
                cellRendererParams: {
                    url: `/api/filters/`,
                    callback: this.refresh_data,
                },
            },
        ];

        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <h5>Saved Filters</h5>
                        <p>Market data filters that you’ve saved in the past</p>
                    </div>
                    <div className="card-body">
                        <AGGrid fill_table={true} rows={rows} columns={columns} />
                    </div>
                </div>
            </div>
        );
    }
}
