import { Icon } from "../icon/icon";
import { useSelect } from "downshift";
import clsx from "clsx";
import { Typography } from "../typography/typography";
import { useRef } from "react";
import { useResizeObserver } from "usehooks-ts";

export interface DropdownOption {
    id: string;
    label: string;
}

interface DropdownProps {
    id: string;
    options: DropdownOption[];
    onSelect: (selectedItem: DropdownOption) => void;
    selectedItem: DropdownOption | null;
    placeholder?: string;
    hideBorder?: boolean;
    className?: string;
}

export function Dropdown({
    id,
    options,
    onSelect,
    selectedItem,
    className,
    placeholder = "Select an item",
    hideBorder = false,
}: DropdownProps) {
    function itemToString(item: any) {
        return item ? item.label : "";
    }

    const ref = useRef<HTMLDivElement>(null);
    const { width = 0 } = useResizeObserver({
        ref,
        box: "border-box",
    });

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items: options,
        itemToString,
        defaultHighlightedIndex: 0,
        onSelectedItemChange: (changes) => {
            if (changes.selectedItem) {
                onSelect(changes.selectedItem);
            } else {
                onSelect(null);
            }
        },
    });

    return (
        <div
            ref={ref}
            className={clsx(
                "lui-w-full lui-h-full lui-overflow-hidden lui-bg-white",
                {
                    "lui-border lui-border-solid lui-border-gray-300 lui-rounded-3xl":
                        !hideBorder,
                    // Remove rounded bottom left corner when dropdown is open
                    "lui-rounded-b-none": isOpen && !hideBorder,
                    "lui-rounded-inherit": !!hideBorder,
                },
                className,
            )}
        >
            <div
                className="lui-w-full lui-gap-1 focus-visible:lui-outline-none lui-p-2  lui-flex lui-justify-between lui-items-center lui-cursor-pointer lui-px-3 lui-pl-4 lui-py-3"
                {...getToggleButtonProps()}
            >
                <Typography
                    variant="span"
                    color={!!selectedItem ? "gray-1000" : "gray-700"}
                    weight={!!selectedItem ? "medium" : "regular"}
                    {...getLabelProps()}
                >
                    {placeholder && !selectedItem && placeholder}
                    {selectedItem && selectedItem.label}
                </Typography>
                <Icon name="ChevronDown" color="gray-700" />
            </div>
            <ul
                className={clsx(
                    `lui-absolute lui-z-10 lui-bg-white lui-max-h-80 lui-overflow-auto lui-p-0  lui-py-2 `,
                    "lui-rounded-b-3xl",
                    "lui-border lui-border-solid",
                    {
                        "lui-hidden": !isOpen,
                        "lui-border-white": !isOpen,
                        "lui-border-solid lui-border-t lui-border-gray-200 ": isOpen,

                        [`lui-dropdown-${id}--open`]: isOpen, // This is used for to style parent component
                    },
                )}
                style={{
                    width: `${Math.floor(width)}px`,
                }}
                {...getMenuProps()}
            >
                {isOpen &&
                    options.map((item, index) => (
                        <li
                            className={clsx(
                                "lui-cursor-pointer",
                                highlightedIndex === index && "lui-bg-gray-100",
                                selectedItem?.id === item.id && "lui-font-bold",
                                selectedItem?.id === item.id && "lui-bg-primary-50",
                                "lui-py-2 lui-px-4 lui-flex lui-flex-col lui-justify-center",
                            )}
                            key={item.id}
                            {...getItemProps({ item, index })}
                        >
                            <Typography
                                variant="span"
                                className="lui-flex lui-justify-between"
                            >
                                {item.label}
                                {selectedItem?.id === item.id && (
                                    <Icon name="Check" color="primary-500" />
                                )}
                            </Typography>
                        </li>
                    ))}
            </ul>
        </div>
    );
}
