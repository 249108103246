import React from "react";

import { toast } from "react-toastify";

import { Modal, Button, Loading, Alert } from "library";
import { ajax_wrapper } from "functions";

class DeleteModalContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            waiting_for_response: false,
        };

        this.delete = this.delete.bind(this);
    }

    delete() {
        this.setState(
            {
                waiting_for_response: true,
            },
            function () {
                this.props.delete();
            },
        );
    }

    render() {
        let delete_type = "gradient-danger";
        let text_style = {};

        return (
            <div
                style={{
                    padding: "1rem",
                    background: "white",
                    marginTop: "20px",
                }}
            >
                <Loading loaded={!this.state.waiting_for_response} cover={true}>
                    <h5 className="mb-5" style={text_style}>
                        Are you sure?
                    </h5>
                    <div className="row">
                        <div className="col-lg-6" style={{ textAlign: "center" }}>
                            <Button
                                onClick={this.delete}
                                type={delete_type}
                                disabled={this.state.waiting_for_response}
                            >
                                {this.props.delete_text}
                            </Button>
                        </div>
                        <div className="col-lg-6" style={{ textAlign: "center" }}>
                            <Button type="gradient-info" onClick={this.props.close}>
                                Nevermind
                            </Button>
                        </div>
                    </div>
                </Loading>
            </div>
        );
    }
}

export default class DeleteButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            waiting_for_response: false,
            show_confirm_modal: false,
        };

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.delete = this.delete.bind(this);
        this.delete_callback = this.delete_callback.bind(this);
    }

    open() {
        this.setState({ show_confirm_modal: true });

        if (this.props.inline_activate) {
            this.props.inline_activate();
        }
    }

    close() {
        this.setState({ show_confirm_modal: false });

        if (this.props.inline_deactivate) {
            this.props.inline_deactivate();
        }
    }

    delete() {
        let url = `${this.props.url}`;
        if (this.props.value) {
            url = `${this.props.url}${this.props.value}/`;
        }

        this.setState(
            {
                waiting_for_response: true,
            },
            function () {
                ajax_wrapper("DELETE", url, {}, this.delete_callback);
            }.bind(this),
        );
    }

    delete_callback() {
        this.setState({
            show_confirm_modal: false,
        });

        toast.info("Delete Complete!");

        if (this.props.callback) {
            this.props.callback();
        }
    }

    render() {
        let disabled = this.state.waiting_for_response;
        let delete_text = this.props.text ? this.props.text : "Delete";

        const modalEnabled =
            "modalEnabled" in this.props ? this.props.modalEnabled : true;
        const onClick = modalEnabled ? this.open : this.delete;

        let main_button = (
            <Button onClick={onClick} type="gradient-danger" disabled={disabled}>
                {delete_text}
            </Button>
        );

        let content = <div style={this.props.style}>{main_button}</div>;

        let modal = null;
        if (this.state.show_confirm_modal) {
            if (this.props.inline) {
                main_button = null;
                modal = (
                    <DeleteModalContent
                        delete_text={delete_text}
                        inline={this.props.inline}
                        close={this.close}
                        delete={this.delete}
                    />
                );
            } else {
                modal = (
                    <Modal show={true} on_hide={this.close}>
                        <DeleteModalContent
                            delete_text={delete_text}
                            close={this.close}
                            delete={this.delete}
                        />
                    </Modal>
                );
            }

            content = modal;
        }

        return content;
    }
}
