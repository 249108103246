import { Icon } from "../land_ui/icon/icon";
import { Typography } from "../land_ui/typography/typography";

interface ErrorMessageBoxProps {
    children?: React.ReactNode;
    isShown?: boolean;
}
export function ErrorMessageBox({ children, isShown }: ErrorMessageBoxProps) {
    if (!isShown) {
        return null;
    }

    return (
        <Typography
            color="red-900"
            className="lui-p-3 lui-m-3 lui-border lui-border-solid lui-border-red-900 lui-rounded-xl lui-flex lui-gap-3"
        >
            <Icon name="Announcement" color="inherit" /> {children}
        </Typography>
    );
}
