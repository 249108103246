import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useWalletApi } from "../../hooks/useWalletApi";
import { Button } from "../../land_ui/button/button";
import { Input } from "../../land_ui/input/input";
import { Modal } from "../../land_ui/modal/modal";
import {
    ParcelExportLandScrub,
    ParcelExportSkipTrace,
    ParcelsListParams,
} from "../../orval/gen/model";
import { ParcelExportPriceBreakdown } from "../../pages/parcel_viewer/types";
import { PurchaseCreditsModal } from "./purchase_credits";
import { formatFileName, parseFileName } from "../../functions/string_utils";

export interface ExportsCalculatePriceRequest {
    filename: string;
    skip_trace: ParcelExportSkipTrace;
    land_scrub: ParcelExportLandScrub;
    search_filters: ParcelsListParams;
}
interface PurchaseExportProps {
    details: ParcelExportPriceBreakdown;
    purchaseExportApi: (newFileName: string) => void;
    isLoading?: boolean;
    fileName: string;
}
export function PurchaseExport({
    details,
    purchaseExportApi,
    isLoading,
    fileName,
}: PurchaseExportProps) {
    const purchaseButtonRef = useRef(null);
    const [isPurchaseOpen, setIsPurchaseOpen] = useState(false);
    const [isExportNameModalOpen, setIsExportNameModalOpen] = useState(false);
    const { wallet, fetchWallet } = useWalletApi({ enabled: false });
    const [newFileName, setNewFileName] = useState(fileName);

    return (
        <>
            <Button
                ref={purchaseButtonRef}
                isLoading={isLoading}
                disabled={isLoading}
                onClick={async () => {
                    setIsExportNameModalOpen(true);
                }}
            >
                Purchase Now
            </Button>

            {isExportNameModalOpen && (
                <ExportNameModal
                    exportFileName={parseFileName(fileName)}
                    isOpen={isExportNameModalOpen}
                    setIsOpen={(value) => {
                        setIsExportNameModalOpen(value);
                        if (!value) {
                            purchaseButtonRef.current?.focus();
                        }
                    }}
                    onSuccess={async (value) => {
                        const fileWithExt = formatFileName(value, "csv");
                        setNewFileName(fileWithExt);

                        const response = await fetchWallet();

                        setIsExportNameModalOpen(false);
                        if (details?.total_price > response.data?.balance.cash) {
                            setIsPurchaseOpen(true);
                        } else {
                            purchaseExportApi(fileWithExt);
                        }
                    }}
                />
            )}

            {isPurchaseOpen && (
                <PurchaseCreditsModal
                    onError={() => {}}
                    onSuccess={() => {
                        purchaseExportApi(newFileName);
                    }}
                    minCredits={details.total_price - wallet?.balance?.cash}
                    isOpen={isPurchaseOpen}
                    setIsOpen={(value) => {
                        setIsPurchaseOpen(value);

                        if (!value) {
                            purchaseButtonRef.current?.focus();
                        }
                    }}
                />
            )}
        </>
    );
}

function ExportNameModal({
    exportFileName,
    isOpen,
    setIsOpen,
    onSuccess,
}: {
    exportFileName: string;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onSuccess: (exportFileName: string) => void;
}) {
    const schema = z.object({
        filename: z.string(),
    });

    type FormData = z.infer<typeof schema>;
    const { register, handleSubmit } = useForm<FormData>({
        defaultValues: {
            filename: exportFileName,
        },
    });
    const onSubmit = (data: FormData) => {
        onSuccess(data.filename);
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header preventAutoFocus>Name Your Export</Modal.Header>
                <Modal.Content className="lui-p-6">
                    <Input
                        type="text"
                        name="filename"
                        label="Export Name"
                        placeholder="Enter export name"
                        autoFocus
                        autoSelectionOnFocus
                        {...register("filename")}
                    />
                </Modal.Content>

                <Modal.Footer className="lui-flex lui-justify-end">
                    <Button
                        variant="inline"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" className="lui-px-5">
                        Continue
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
