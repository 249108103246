import React, { useContext, useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { UserContext } from "context";
import {
    fetch,
    formatCredits,
    formatMoneyCents,
    parseErrorResponse,
    post,
    prettify_string,
} from "functions";

const ReactSwal = withReactContent(Swal);

export function PurchaseCreditsModal({ onConfirmed, onDismissed }) {
    const { purchaseCredits } = useContext(UserContext);
    const ref = useRef(0);

    useEffect(() => {
        const showModal = async () => {
            const result = await ReactSwal.fire({
                title: `Purchase Credits`,
                html: <PurchaseForm amountRef={ref} />,
                preConfirm: () => purchaseCredits(ref.current),
                showCancelButton: true,
                confirmButtonText: "Purchase",
                validationMessage: "Amount must be a positive integer",
            });
            if (result.isConfirmed && onConfirmed) {
                onConfirmed();
            } else if (result.isDismissed && onDismissed) {
                onDismissed();
            }
        };

        showModal();
    }, [onConfirmed, onDismissed, purchaseCredits]);
}

function PurchaseForm({ amountRef }) {
    const [amount, setAmount] = useState(0);
    const inputRef = useRef(null);

    const onChange = (value) => {
        let n = parseInt(value, 10) || 0;
        n = Math.max(0, Math.min(n, 1000000));
        setAmount(n);
        // XXX: workaround for SweetAlert which doesn't support useState in
        // child components
        amountRef.current = n;
    };

    useEffect(() => {
        if (inputRef.current != null) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div className="d-flex flex-column gap-4 m-1">
            <div className="text-lg">How many would you like?</div>
            <div className="text-sm text-bold text-info">
                You will be charged {formatMoneyCents(amount)} for{" "}
                {formatCredits(amount)}.
            </div>
            <div>
                <input
                    ref={inputRef}
                    type="number"
                    value={amount}
                    onChange={(e) => onChange(e.target.value)}
                    className="text-lg"
                />
            </div>
            <div className="text-xs text-bold">
                * Minimum purchase order of {formatCredits(100)}.<br />* Credits cannot
                be refunded, exchanged, or sold.
            </div>
        </div>
    );
}

export function GiftCreditsModal({ userID, onConfirmed, onDismissed }) {
    const { giftCredits } = useContext(UserContext);

    useEffect(() => {
        const showModal = async () => {
            const result = await ReactSwal.fire({
                title: `Gift Credits`,
                text: "How many?",
                input: "number",
                inputValue: 0,
                preConfirm: () => giftCredits(userID, Swal.getInput()?.value || 0),
                showCancelButton: true,
                confirmButtonText: "Submit",
                validationMessage: "Amount must be a positive integer",
            });
            if (result.isConfirmed && onConfirmed) {
                onConfirmed();
            } else if (result.isDismissed && onDismissed) {
                onDismissed();
            }
        };

        showModal();
    }, [onConfirmed, onDismissed, giftCredits, userID]);
}

export function RefundCreditsModal({
    userID,
    transactionID,
    onConfirmed,
    onDismissed,
}) {
    const { refundCredits } = useContext(UserContext);

    useEffect(() => {
        const showModal = async () => {
            const result = await ReactSwal.fire({
                title: `Refund Credits`,
                inputLabel: "Note (for internal use only):",
                input: "textarea",
                preConfirm: () =>
                    refundCredits(userID, transactionID, Swal.getInput()?.value || ""),
                showCancelButton: true,
                confirmButtonText: "Submit",
            });
            if (result.isConfirmed && onConfirmed) {
                onConfirmed();
            } else if (result.isDismissed && onDismissed) {
                onDismissed();
            }
        };

        showModal();
    }, [onConfirmed, onDismissed, refundCredits, userID, transactionID]);
}

export function RefillCreditsModal({ amount, onConfirmed, onDismissed }) {
    const { balance, purchaseCredits } = useContext(UserContext);

    useEffect(() => {
        const showModal = async () => {
            const purchaseAmount = Math.max(amount - balance, 100);
            const result = await ReactSwal.fire({
                title: `You don't have enough credits.`,
                html: (
                    <>
                        <p className="text-lg">Let’s charge you up now.</p>
                        <p className="text-sm text-bold">
                            This job requires {formatCredits(amount)}
                        </p>
                        <p className="text-sm text-bold text-primary mb-0">
                            You'll need to purchase an additional{" "}
                            {formatCredits(purchaseAmount)} credits to complete it
                        </p>
                    </>
                ),
                input: "number",
                inputValue: purchaseAmount,
                preConfirm: () => purchaseCredits(Swal.getInput()?.value || 0),
                showCancelButton: true,
                confirmButtonText: "Purchase Credits Now",
                validationMessage: "Amount must be a positive integer",
            });
            if (result.isConfirmed && onConfirmed) {
                onConfirmed();
            } else if (result.isDismissed && onDismissed) {
                onDismissed();
            }
        };

        if (balance < amount) {
            showModal();
        }
    }, [amount, onConfirmed, onDismissed, balance, purchaseCredits]);
}
