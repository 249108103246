import React, { Component, useContext } from "react";
import { useLocation, Link } from "react-router-dom";

import { UserContext } from "context";

const navItemData = [
    { name: "market_research", label: "Market Research", icon: "fa fa-search" },
    { name: "my_markets", label: "My Markets", icon: "fas fa-bullseye" },
    { name: "saved_filters", label: "Saved Filters", icon: "fas fa-filter" },
    {
        name: "campaigns",
        label: "Campaigns",
        icon: "fas fa-envelope-open-text",
    },
    {
        name: "realtor_report",
        label: "Realtor Report",
        icon: "fa fa-map",
    },
    {
        name: "land_scrubbing",
        label: "Land Scrubbing",
        icon: "fa fa-search",
    },
    {
        name: "skip_tracing",
        label: "Skip Tracing",
        icon: "fas fa-address-book",
    },
    {
        name: "photo_editor",
        label: "Listing Photo Editor",
        icon: "fas fa-photo-film",
    },
    {
        name: "comping",
        label: "Comping Tool",
        icon: "fas fa-file-export",
        staffOnly: true,
    },
    {
        name: "parcel_viewer",
        path: "/map",
        label: "Parcel Viewer",
        icon: "fas fa-map-marker",
        staffOnly: true,
    },
    { name: "admin", label: "Admin", icon: "fas fa-lock", staffOnly: true },
    { name: "settings", label: "Settings", icon: "fa fa-cog" },
    {
        name: "feature_idea",
        label: "Request a Feature",
        icon: "ni ni-spaceship",
    },
];

class NavItem extends Component {
    render() {
        const { name, label, path, icon, imgIcon, active, beta } = this.props;

        let nav_image = (
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2">
                <i
                    className={icon}
                    style={{
                        opacity: 1,
                        fontSize: "14px",
                        position: "relative",
                        top: "0px",
                        left: "0px",
                    }}
                ></i>
            </div>
        );

        if (imgIcon) {
            nav_image = (
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2">
                    <img
                        src={imgIcon}
                        style={{
                            width: "15px",
                            opacity: ".2",
                        }}
                    />
                </div>
            );
        }

        return (
            <li className="nav-item">
                <Link className={`nav-link ${active ? "active" : ""}`} to={path}>
                    {nav_image}
                    <span className="nav-link-text ms-1">{label}</span>
                    {beta && (
                        <span className="badge bg-gradient-success ms-2">beta</span>
                    )}
                </Link>
            </li>
        );
    }
}

export default function Sidebar({ className }) {
    const { user } = useContext(UserContext);
    const location = useLocation();

    // Map "/home/foo/bar" to ["home", "foo", "bar"]
    let normalizedPath = location.pathname.split("/").filter((s) => s);
    // Remove "home" from first element
    normalizedPath.shift();

    const current = normalizedPath[0] || "";

    const isActive = (name) =>
        current === name || (current == "" && name == "market_research");

    let nav_items = [];
    for (let item of navItemData) {
        if (item.staffOnly && !user.is_staff) {
            continue;
        }
        if (item.betaOnly && !(user.is_staff || user.beta)) {
            continue;
        }
        const path = item.path || `/home/${item.name}`;
        nav_items.push(
            <NavItem
                key={item.name}
                name={item.name}
                label={item.label}
                path={path}
                icon={item.icon}
                imgIcon={item.imgIcon}
                active={isActive(item.name)}
                beta={item.beta}
            />,
        );
    }

    return (
        <aside
            className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 ${className}`}
            id="sidenav-main"
        >
            <div className="sidenav-header text-center">
                <Link className="navbar-brand m-0" to="/">
                    <img
                        id="land-insights-logo"
                        src="/static/images/land_insights_logo.png"
                        alt="Logo"
                    />
                </Link>
            </div>

            <hr className="horizontal dark mt-0" />

            <div
                className="collapse navbar-collapse w-auto"
                style={{ height: "calc(100vh - 130px)" }}
            >
                <ul className="navbar-nav">{nav_items}</ul>
                <hr className="horizontal dark" />
                <div
                    className="card shadow-none"
                    style={{
                        backgroundImage:
                            "url('/static/images/blue_curved_background.png')",
                        backgroundSize: "cover",
                    }}
                >
                    <div className="card-body text-start p-3 w-100">
                        <div className="icon icon-shape icon-sm bg-white shadow text-center mb-3 d-flex align-items-center justify-content-center border-radius-md">
                            <img
                                src={"/static/images/sparkle.png"}
                                style={{
                                    width: "24px",
                                    opacity: ".2",
                                }}
                            />
                        </div>
                        <div className="docs-info">
                            <h6
                                className="text-white up mb-0"
                                style={{ fontSize: "15px" }}
                            >
                                Land Insights Course
                            </h6>
                            <p className="text-white text-xs font-weight-bold">
                                Get started now!
                            </p>
                            <a
                                href="https://sso.teachable.com/secure/1967990/identity/login/password"
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-white btn-sm w-100 mb-0"
                            >
                                Open Course
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
}
