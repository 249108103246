export const PenMinusIcon = () => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 4.99985H8M15 6.99985L8.83447 8.76143C8.52956 8.84855 8.37711 8.8921 8.25117 8.97398C8.13969 9.04647 8.04379 9.14045 7.96907 9.25045C7.88466 9.3747 7.83803 9.52625 7.74477 9.82933L4 21.9998M4 21.9998L16.1705 18.2551C16.4736 18.1618 16.6251 18.1152 16.7494 18.0308C16.8594 17.9561 16.9534 17.8602 17.0259 17.7487C17.1077 17.6227 17.1513 17.4703 17.2384 17.1654L19 10.9998M4 21.9998L10.586 15.4138M21.8686 7.86848L18.1314 4.13122C17.7354 3.7352 17.5373 3.53719 17.309 3.46301C17.1082 3.39775 16.8918 3.39775 16.691 3.46301C16.4627 3.53719 16.2646 3.7352 15.8686 4.13122L15.1314 4.86848C14.7354 5.2645 14.5373 5.4625 14.4632 5.69083C14.3979 5.89168 14.3979 6.10802 14.4632 6.30887C14.5373 6.53719 14.7354 6.7352 15.1314 7.13122L18.8686 10.8685C19.2646 11.2645 19.4627 11.4625 19.691 11.5367C19.8918 11.6019 20.1082 11.6019 20.309 11.5367C20.5373 11.4625 20.7354 11.2645 21.1314 10.8685L21.8686 10.1312C22.2646 9.7352 22.4627 9.53719 22.5368 9.30887C22.6021 9.10802 22.6021 8.89168 22.5368 8.69083C22.4627 8.4625 22.2646 8.26449 21.8686 7.86848ZM12 11.9998C13.1046 11.9998 14 12.8953 14 13.9998C14 15.1044 13.1046 15.9998 12 15.9998C10.8954 15.9998 10 15.1044 10 13.9998C10 12.8953 10.8954 11.9998 12 11.9998Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
