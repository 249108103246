import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Footer } from "library";
import { BaseLayout } from "components";

export default class ExternalLayout extends Component {
    render() {
        const n = Math.floor(Math.random() * 74);
        return (
            <BaseLayout>
                <div
                    className="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
                    style={{
                        width: "calc(100% - 2rem)",
                        minHeight: "50vh",
                        borderRadius: "0.75rem",
                        backgroundImage: `url(/static/images/bg/${n}.jpg)`,
                    }}
                >
                    <span className="mask bg-gradient-dark opacity-6"></span>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 text-center mx-auto">
                                <h1 className="text-white mb-2 mt-5">Land Insights</h1>
                                <p className="text-lead text-white">
                                    Welcome to Land Insights
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
                        <Outlet />
                    </div>
                </div>

                <Footer />
                <ToastContainer position="bottom-right" autoClose={5000} />
            </BaseLayout>
        );
    }
}
