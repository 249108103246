import React from "react";

// eslint-disable-next-line no-unused-vars -- side-effect: chart.js/auto registers all chart types
import Chart from "chart.js/auto";

import { Bar } from "react-chartjs-2";

export default function BarChart({ labels, datasets }) {
    const options = {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                type: "linear",
                display: true,
                position: "left",
                grid: {
                    drawBorder: false,
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: false,
                    borderDash: [5, 5],
                },
                ticks: {
                    display: true,
                    padding: 10,
                    color: "#b2b9bf",
                    font: {
                        size: 11,
                        family: "Open Sans",
                        style: "normal",
                        lineHeight: 2,
                    },
                },
            },
            x: {
                grid: {
                    drawBorder: false,
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                    borderDash: [5, 5],
                },
                ticks: {
                    display: true,
                    color: "#b2b9bf",
                    padding: 20,
                    font: {
                        size: 11,
                        family: "Open Sans",
                        style: "normal",
                        lineHeight: 2,
                    },
                },
            },
        },
    };

    const styles = [
        {
            borderColor: "#cb0c9f", // Purple
            borderWidth: 2,
            backgroundColor: (context) => {
                // Purple gradient
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 300, 0, 0);
                gradient.addColorStop(1, "rgba(203, 12, 159, 0.2)");
                gradient.addColorStop(0.2, "rgba(203, 12, 159, 0)");
                return gradient;
            },
        },
        {
            borderColor: "#3A416F", // Blue
            borderWidth: 2,
            backgroundColor: (context) => {
                // Blue gradient
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 300, 0, 0);
                gradient.addColorStop(1, "rgba(58, 65, 111, 0.2)");
                gradient.addColorStop(0.2, "rgba(58, 65, 111, 0)");
                return gradient;
            },
        },

        {
            borderColor: "#17ad37", // Green
            borderWidth: 2,
            backgroundColor: (context) => {
                // Green gradient
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 300, 0, 0);
                gradient.addColorStop(1, "rgba(23, 173, 55, 0.2)");
                gradient.addColorStop(0.2, "rgba(23, 173, 55, 0)");
                return gradient;
            },
        },
    ];

    const data = {
        labels,
        datasets: datasets.map((ds, i) => ({
            ...styles[i % styles.length],
            ...ds,
        })),
    };

    return (
        <div style={{ height: "300px" }}>
            <Bar options={options} data={data} />
        </div>
    );
}
