import { useQuery } from "@tanstack/react-query";
import { AXIOS_INSTANCE } from "../orval/axios-instance";
import { useCallback } from "react";

export interface WalletType {
    balance: {
        cash: number;
        gift: number;
    };
    transactions: [];
}

interface useWalletProps {
    enabled: boolean;
}
export function useWalletApi({ enabled }: useWalletProps) {
    const fetchWallet = useCallback(async () => {
        const response = await AXIOS_INSTANCE.get<WalletType>("/user/user/wallet/");

        return response.data;
    }, []);

    const {
        data: wallet,
        isLoading,
        error,
        refetch,
    } = useQuery({
        queryKey: ["wallet"],
        queryFn: fetchWallet,
        enabled,
    });

    return {
        error,
        wallet,
        isLoading,
        fetchWallet: refetch,
    };
}
