import { SourceProps } from "react-map-gl";
import { API_URL, TILER_URL } from "../../settings";

export const maptilerSource: SourceProps = {
    id: "maptiler_source",
    type: "vector",
    tiles: [`${API_URL}/tiles/countries/{z}/{x}/{y}.pbf`],
    maxzoom: 10,
};
export const parcelSource: SourceProps = {
    id: "tiler_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/parcels/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};
export const wetlandsSource: SourceProps = {
    id: "wetland_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/wetlands/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};
export const buildingsSource: SourceProps = {
    id: "buildings_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/buildings/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};
export const roadsSource: SourceProps = {
    id: "roads_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/roads/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};
export const compSource: SourceProps = {
    id: "comp_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/comps/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};
export const floodzoneSource: SourceProps = {
    id: "floodzone_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/floodzones/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};
export const ownerSource: SourceProps = {
    id: "owner_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/owners/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};
