import { ReactNode, useState } from "react";
import { Icon } from "../icon/icon";
import { Typography } from "../typography/typography";
import { Button } from "../button/button";
import clsx from "clsx";

import "./accordion.css";

interface AccordionProps {
    children: ReactNode;
    title: ReactNode;
    // If you pass isOpen prop, you should also pass onToggle prop
    isOpen?: boolean;
    onToggle?: (expanded: boolean) => void;
}
export function Accordion({
    children,
    title,
    isOpen = false,
    onToggle,
}: AccordionProps) {
    const [isExpanded, setIsExpanded] = useState(isOpen);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
        onToggle?.(!isExpanded);
    };

    return (
        <div className="lui-accordion lui-p-6 lui-border-b lui-border-y-0 lui-border-x-0 lui-border-solid lui-border-gray-200  ">
            <Button
                variant="base"
                className="lui-flex lui-justify-between lui-items-center lui-w-full"
                onClick={handleToggle}
                fullWidth
            >
                <span className="lui-w-full lui-flex lui-justify-between">
                    <Typography variant="span" size="lg" weight="bold">
                        {title}
                    </Typography>
                    <span className="lui-accordion-icon">
                        <Icon
                            name="ChevronDown"
                            color="gray-700"
                            className={clsx("lui-transition-all lui-duration-300", {
                                "lui-rotate-180": isExpanded,
                            })}
                        />
                    </span>
                </span>
            </Button>
            <Typography
                className={clsx("lui-accordion__content", {
                    "lui-accordion__content--open lui-mt-6": isExpanded,
                    "lui-accordion__content--close": !isExpanded,
                })}
            >
                {children}
            </Typography>
        </div>
    );
}
