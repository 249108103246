import React, { useEffect, useState, useCallback } from "react";

// Source: https://github.com/YelyseiLukin/react-files-drag-and-drop/tree/master
export default function FilesDragAndDrop({
    onUpload,
    children,
    count,
    formats,
    openDialogOnClick = false,
    hoverText = "Drop files here",
    successText = ({ files }) => {
        return `Selected file: ${files.map((f) => f.name).join(", ")}`;
    },
    errorCountText = ({ count }) =>
        `Only ${count} file${count !== 1 ? "s" : ""} can be uploaded at a time`,
    errorFormatText = ({ formats }) =>
        `Only following file formats are acceptable: ${formats && formats.join(", ")}`,
    containerStyles = {},
    hoverMessageStyles = {},
    successMessageStyles = {},
    errorMessageStyles = {},
    successTime = 10000000,
    errorTime = 2000,
    onDragEnter,
    onDragLeave,
    onDrop,
}) {
    const [dragging, setDragging] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        text: null,
        type: null,
    });

    const drag = React.useRef(null);
    const drop = React.useRef(null);
    const input = React.useRef(null);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragEnter = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (e.target !== drag.current) {
                setDragging(true);

                onDragEnter && onDragEnter();
            }
        },
        [drag, setDragging, onDragEnter],
    );

    const handleDragLeave = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (e.target === drag.current) {
                setDragging(false);

                onDragLeave && onDragLeave();
            }
        },
        [drag, setDragging, onDragLeave],
    );

    const showMessage = useCallback(
        (text, type, timeout) => {
            setMessage({
                show: true,
                text,
                type,
            });

            setTimeout(
                () =>
                    setMessage({
                        show: false,
                        text: null,
                        type: null,
                    }),
                timeout,
            );
        },
        [setMessage],
    );

    const handleUpload = useCallback(
        (files) => {
            if (count && count < files.length) {
                showMessage(
                    typeof errorCountText === "function"
                        ? errorCountText({ count })
                        : errorCountText,
                    "error",
                    errorTime,
                );

                return;
            }

            if (
                formats &&
                files.some(
                    (file) =>
                        !formats.some((format) =>
                            file.name.toLowerCase().endsWith(format.toLowerCase()),
                        ),
                )
            ) {
                showMessage(
                    typeof errorFormatText === "function"
                        ? errorFormatText({ formats })
                        : errorFormatText,
                    "error",
                    errorTime,
                );

                return;
            }

            if (files && files.length) {
                showMessage(
                    typeof successText === "function"
                        ? successText({ files })
                        : successText,
                    "success",
                    successTime,
                );

                onUpload(files);
            }
        },
        [
            onUpload,
            showMessage,
            formats,
            count,
            errorCountText,
            errorFormatText,
            errorTime,
            successText,
            successTime,
        ],
    );

    const handleDrop = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            setDragging(false);

            const files = e.dataTransfer ? [...e.dataTransfer.files] : [];

            onDrop && onDrop(files);

            handleUpload(files);
        },
        [setDragging, handleUpload, onDrop],
    );

    const handleSelectFiles = useCallback(
        (e) => {
            const files = [...e.target.files];

            handleUpload(files);
        },
        [handleUpload],
    );

    const openFileDialog = () => {
        input && input.current.click();
    };

    useEffect(() => {
        const el = drop.current;
        el.addEventListener("dragover", handleDragOver);
        el.addEventListener("drop", handleDrop);
        el.addEventListener("dragenter", handleDragEnter);
        el.addEventListener("dragleave", handleDragLeave);

        return () => {
            el.removeEventListener("dragover", handleDragOver);
            el.removeEventListener("drop", handleDrop);
            el.removeEventListener("dragenter", handleDragEnter);
            el.removeEventListener("dragleave", handleDragLeave);
        };
    }, [handleDragOver, handleDrop, handleDragEnter, handleDragLeave]);

    return (
        <div
            ref={drop}
            style={{
                ...styles.container,
                cursor: openDialogOnClick ? "pointer" : "default",
                ...containerStyles,
            }}
            onClick={openDialogOnClick ? openFileDialog : undefined}
        >
            {openDialogOnClick && (
                <input
                    ref={input}
                    type="file"
                    style={{ ...styles.input }}
                    accept={
                        formats
                            ? formats.map((format) => `.${format}`).join(", ")
                            : undefined
                    }
                    multiple={!count || count > 1}
                    onChange={handleSelectFiles}
                />
            )}
            {message.show && (
                <div
                    style={{
                        ...styles.message,
                        ...(message.type === "success" && styles.messageSuccess),
                        ...(message.type === "error" && styles.messageError),
                        ...hoverMessageStyles,
                        ...(message.type === "success" && successMessageStyles),
                        ...(message.type === "error" && errorMessageStyles),
                    }}
                >
                    {message.text}
                </div>
            )}
            {dragging && (
                <div
                    ref={drag}
                    style={{
                        ...styles.message,
                        ...hoverMessageStyles,
                    }}
                >
                    {typeof hoverText === "function"
                        ? hoverText({ formats, count })
                        : hoverText}
                </div>
            )}
            {children}
        </div>
    );
}

const styles = {
    container: {
        position: "relative",
        width: "100%",
        // height: '100%',
    },
    input: {
        position: "absolute",
        left: "0",
        top: "0",
        width: "1px",
        height: "1px",
        opacity: "0",
        cursor: "default",
    },
    message: {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100%",
        height: "100%",
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexFlow: "column nowrap",
        backgroundColor: "#e7e7e7",
        color: "#7f8e99",
        fontSize: "24px",
        fontFamily: "Tahoma, sans-serif",
        opacity: "1",
        textAlign: "center",
    },
    messageSuccess: {
        backgroundColor: "#e7f7e7",
        color: "#8ecf99",
    },
    messageError: {
        backgroundColor: "#f7e7e7",
        color: "#cf8e99",
    },
};
