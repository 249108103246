import { useParams } from "react-router-dom";

export default function Comping() {
    let { parcelId } = useParams();
    console.log(parcelId);
    return (
        <div className="card">
            <div className="card-header pb-0 d-sm-flex justify-content-between">
                <h5>Comping Tool</h5>
            </div>
            <div className="card-body">
                <strong>Coming Soon</strong>
            </div>
        </div>
    );
}
