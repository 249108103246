import { Popup } from "react-map-gl";
import { Button } from "../land_ui/button/button";
import { Divider } from "../land_ui/Divider/Divider";
import { Popover } from "../land_ui/popover/popover";
import { Typography } from "../land_ui/typography/typography";

export interface MapboxPopupProps {
    longitude: number;
    latitude: number;
    onClose?: () => void;
    children?: React.ReactNode;
    title: string;
    footer?: React.ReactNode;
}
export default function MapboxPopup({
    title,
    children,
    onClose,
    longitude,
    latitude,
    footer,
}: MapboxPopupProps) {
    return (
        <Popover isOpen={true} setIsOpen={() => onClose()}>
            <Popup
                longitude={longitude}
                latitude={latitude}
                anchor={"bottom"}
                offset={17}
                onClose={onClose}
                closeOnClick={false}
                closeButton={false}
                closeOnMove
            >
                <div className="lui-rounded-2xl lui-mapbox-popup">
                    <div className="lui-flex lui-justify-between lui-items-center lui-p-3">
                        <Typography weight="medium">{title}</Typography>
                        <Button
                            variant="base"
                            onClick={() => {
                                onClose && onClose();
                            }}
                            icon="Close"
                        />
                    </div>
                    <Divider horizontal />

                    <div className="lui-grid lui-grid-cols-2 lui-justify-between lui-gap-x-6 lui-gap-y-3 lui-p-3">
                        {children}
                    </div>

                    {footer && (
                        <>
                            <Divider horizontal />
                            <div className="lui-p-3">{footer}</div>
                        </>
                    )}
                </div>
            </Popup>
        </Popover>
    );
}
