import { useEffect, useState } from "react";
import { useDebounceCallback, useIntersectionObserver } from "usehooks-ts";

interface usePaginationProps<T> {
    error?: unknown;
    currentPage: number;
    onNextPage: (newPage: number) => void;
    data: T[];
    totalCount: number;

    // This root is only needed if you have scroll outside of body e.g. sidebar content container
    root?: Document | Element;

    // The pixel distance from the bottom of the element to trigger the next page
    proximityPixel?: string;
}
export function usePagination<T>({
    currentPage,
    onNextPage,
    data,
    error,
    totalCount,
    root,
    proximityPixel = "300px",
}: usePaginationProps<T>) {
    const [dataList, setDataList] = useState<T[]>([]);
    const { isIntersecting, ref } = useIntersectionObserver({
        threshold: 0.1,
        rootMargin: proximityPixel,
        initialIsIntersecting: false,
        root: root,
    });

    const nextPage = useDebounceCallback(() => {
        if (!error) {
            onNextPage(currentPage + 1);
        }
    }, 1000);

    useEffect(() => {
        // Only trigger when the element is intersecting and not fetching
        // If the current list is less than the total count, fetch the next page
        if (isIntersecting && dataList?.length < totalCount) {
            nextPage();
        }

        // Only run when the element is intersecting
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIntersecting]);

    useEffect(() => {
        if (data && data.length > 0) {
            setDataList([...dataList, ...data]);
        }

        // This only runs when the data changes (not on mount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const triggerElement = <div className="lui-text-sm lui-w-full" ref={ref} />;

    return {
        triggerElement,
        dataList,
        isLoading: dataList.length < totalCount,
    };
}
