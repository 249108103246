import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import "./css/map.css";
import "./css/base.css";
import "./css/kanban.css";
import "./css/parcel_viewer.css";

import { SENTRY_DSN, HEAP_ANALYTICS_ID, INTERCOM_APP_ID } from "settings";
import routes from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
});

// Load routes into browser
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(routes);
const queryClient = new QueryClient();

// Initializing React DOM management
ReactDOM.createRoot(document.getElementById("root")).render(
    <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
    </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// Design Files
// https://www.figma.com/file/HqIi7zk6LNuNUjpmF38xIa/LandInsights-V2?type=design&node-id=109-11851&mode=design&t=W6FBfYFlNgVXl5Q5-0
// https://www.figma.com/file/6zTDaJnbwQdMkzpS4zNoF7/LandInsights-UX?type=whiteboard&node-id=0-1&t=BuQurwVHQZm8tHnL-0
// https://www.creative-tim.com/learning-lab/bootstrap/quick-start/soft-ui-dashboard
// https://mui.com/material-ui/

// prettier-ignore
// eslint-disable-next-line
window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
window.heap.load(HEAP_ANALYTICS_ID);

// prettier-ignore
// eslint-disable-next-line
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + INTERCOM_APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
