import { useForm } from "react-hook-form";
import { Button } from "../../../land_ui/button/button";
import { Input } from "../../../land_ui/input/input";
import { Modal } from "../../../land_ui/modal/modal";
import { Typography } from "../../../land_ui/typography/typography";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSavedlistsPartialUpdate } from "../../../orval/gen/api";
import { ErrorMessageBox } from "../../../components/ErrorMessageBox";
import { useQueryClient } from "@tanstack/react-query";

interface RenameSaveFilterModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    filterId: string;
    filterName: string;
}
export function RenameSaveFilterModal({
    isOpen,
    setIsOpen,
    filterId,
    filterName,
}: RenameSaveFilterModalProps) {
    const queryClient = useQueryClient();
    const { mutate, error, isPending } = useSavedlistsPartialUpdate({
        mutation: {
            onSuccess: () => {
                setIsOpen(false);
                queryClient.invalidateQueries({
                    queryKey: ["savedFilterList"],
                });
            },
        },
    });

    const schema = z.object({
        filterName: z
            .string()
            .min(1, { message: "Filter name is required" })
            .max(50, { message: "Filter name must be less than 50 characters" }),
    });

    type FormData = z.infer<typeof schema>;

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        mode: "onChange",
        resolver: zodResolver(schema),
        defaultValues: {
            filterName: filterName,
        },
    });
    if (!isOpen) {
        return null;
    }

    const onSubmit = (data: FormData) => {
        mutate({
            id: filterId,
            data: {
                title: data.filterName,
            },
        });
    };
    let filterNames = watch("filterName");
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header preventAutoFocus>
                    <Typography size="xl" weight="medium">
                        Rename Filter
                    </Typography>
                </Modal.Header>
                <Modal.Content className="lui-p-6 lui-flex lui-flex-col lui-gap-2">
                    <Typography size="md" weight="medium">
                        Name
                    </Typography>
                    <Input
                        className="lui-rounded-lg"
                        placeholder="Rename Filter"
                        error={errors.filterName?.message}
                        autoFocus
                        autoSelectionOnFocus
                        endAdornment={
                            <Typography size="md" className="lui-ml-2" color="gray-600">
                                {filterNames.length}/50
                            </Typography>
                        }
                        maxLength={50}
                        {...register("filterName")}
                    />

                    <ErrorMessageBox isShown={!!error}>
                        Something wen't wrong, please try again later!
                    </ErrorMessageBox>
                </Modal.Content>
                <Modal.Footer className="lui-flex lui-justify-end">
                    <Button
                        variant="base"
                        className="lui-text-start lui-py-2 lui-px-4"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        className="lui-py-2 lui-px-6"
                        isLoading={isPending}
                        disabled={isPending}
                    >
                        Rename
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
