import React, { Component } from "react";

import { Checkbox, Form } from "library";

export default class DataPreferences extends Component {
    constructor(props) {
        super(props);
        this.updateUser = this.updateUser.bind(this);
    }

    updateUser(newUser) {
        this.props.callback(newUser);
    }

    render() {
        const { user, data_options } = this.props;

        // Show 15 items in the first column and 12 items in the second column
        const breaks = [15, 12];

        return (
            <Form
                submit_url={`/api/users/${user.id}/`}
                defaults={{
                    data_point_preferences: user.data_point_preferences,
                }}
                submit_button_float={{ float: "right" }}
                submit_success={this.updateUser}
            >
                <Checkbox
                    options={data_options}
                    name="data_point_preferences"
                    breaks={breaks}
                />
            </Form>
        );
    }
}
