import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

import { fetch, parseErrorResponse, formatTimestamp } from "functions";
import { AGGrid } from "components";

export default function Scraping() {
    const [rows, setRows] = useState();

    const fetchJobs = async () => {
        try {
            const result = await fetch("/analytics/scraping_status/");
            setRows(result.status);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    useEffect(() => {
        fetchJobs();
    }, []);

    if (!rows) {
        return "Loading...";
    }

    const columns = [
        { field: "spider", filter: true },
        { field: "state", filter: true },
        { field: "key", filter: true },
        {
            field: "running_time",
            headerName: "Created At",
            filter: true,
            valueFormatter: ({ value }) => formatTimestamp(value),
            cellDataType: "dateString",
        },
        {
            field: "ts",
            headerName: "Updated At",
            filter: true,
            valueFormatter: ({ value }) => formatTimestamp(value),
            cellDataType: "dateString",
        },
        { field: "logs", filter: true },
        { field: "items", filter: true },
        { field: "pages", filter: true },
        { field: "version", filter: true },
    ];

    return (
        <>
            <div className="d-flex">
                <h5 className="flex-fill">Scraping</h5>
                <button className="btn" onClick={fetchJobs}>
                    Refresh
                </button>
            </div>
            <AGGrid
                fill_table={true}
                rows={rows}
                columns={columns}
                getRowId={(row) => row.data.key}
            />
        </>
    );
}
