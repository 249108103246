export function minutesAgo(isoDateTimeString) {
    // Parse the ISO formatted string into a Date object
    const dateTime = new Date(isoDateTimeString);

    // Get the current time
    const currentTime = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentTime - dateTime;

    // Convert milliseconds to minutes and round down to the nearest integer
    const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));

    return differenceInMinutes;
}
