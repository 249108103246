import RulerControl from "@mapbox-controls/ruler";
import { useCallback } from "react";
import { useMap } from "react-map-gl";
import { useQueryParam } from "../../hooks/useQueryParam";
import { Button } from "../../land_ui/button/button";
import { Icon } from "../../land_ui/icon/icon";
import { Typography } from "../../land_ui/typography/typography";
import { ControlPopover } from "./control_popover";

export const LandRuler = new RulerControl({
    units: "feet", // Set units to feet
    labelFormat: (n) => `${n.toFixed(2)} ft`, // Customize label format to show feet
    invisible: true, // Hide the control initially
    linePaint: {
        "line-color": "#0D99FF",
        "line-width": 4,
        "line-dasharray": [1, 1],
    },
    symbolPaint: {
        "text-color": "#fff",
        "text-halo-color": "#000",
        "text-halo-width": 2,
    },
    symbolLayout: {
        "text-size": 16,
    },
});

export function MapToolsControl() {
    const [, setIsRulerActive, removeIsRulerActive] = useQueryParam<boolean>(
        "ruler",
        null,
    );
    const map = useMap();
    const activateRuler = useCallback(() => {
        if (!map) return;
        if (!map.current.isStyleLoaded()) {
            return;
        }

        if (LandRuler.isActive) {
            LandRuler.deactivate();
            removeIsRulerActive();
        } else {
            LandRuler.activate();
            setIsRulerActive(true);
        }
    }, [map, removeIsRulerActive, setIsRulerActive]);

    return (
        <ControlPopover
            icon="Tool"
            tooltipContent="Tools"
            title="Tools"
            placement="left"
            dismissOnClick
        >
            <div className="lui-flex lui-justify-between lui-items-center  hover:lui-bg-gray-100 ">
                <Button
                    variant="base"
                    fullWidth
                    onClick={activateRuler}
                    className="lui-px-4 lui-py-2"
                >
                    <Typography
                        variant="span"
                        weight="medium"
                        className="lui-flex lui-gap-2"
                    >
                        <Icon name="Ruler" size="md" />
                        Ruler
                    </Typography>
                </Button>
            </div>
        </ControlPopover>
    );
}
