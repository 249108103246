/* eslint-disable react-hooks/exhaustive-deps */
import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { formatNumber } from "../../functions";
import { parseFileName } from "../../functions/string_utils";
import { usePagination } from "../../hooks/usePagination";
import { Button } from "../../land_ui/button/button";
import { Divider } from "../../land_ui/Divider/Divider";
import { LoadingSpinner } from "../../land_ui/loading/loading";
import { Sidebar, useSidebar } from "../../land_ui/sidebar/sidebar";
import { Typography } from "../../land_ui/typography/typography";
import {
    useExportsCalculatePriceCreate,
    useExportsPurchaseCreate,
    useParcelsList,
} from "../../orval/gen/api";
import {
    FullParcel,
    ParcelExportLandScrub,
    ParcelExportSkipTrace,
    ParcelsListParams,
} from "../../orval/gen/model";
import { generateExportListFilename } from "../../pages/parcel_viewer/controls/filter";
import { ExportListMenu } from "../../pages/parcel_viewer/map_menu/export_list_menu";
import {
    CountyOption,
    ParcelExportPriceBreakdown,
} from "../../pages/parcel_viewer/types";
import { ErrorMessageBox } from "../ErrorMessageBox";
import { ExportDetailCard } from "../parcel_detail/parcel_detail_card";
import { SummaryExportPriceDetails } from "./cost_breakdown";
import { ExportsCalculatePriceRequest, PurchaseExport } from "./purchase_export";
import { SaveFilterModal } from "./save_filter_modal";

interface MapFilterParcelProps {
    parcelParams: ParcelsListParams;
    setIsParcelList: (value: boolean) => void;
    countyOption: CountyOption;
    landScrubs: ParcelExportLandScrub;
    skipTrace: ParcelExportSkipTrace;
    resetFilterForm: () => void;
}
const PER_PAGE = 10;

export function MapFilterParcel({
    parcelParams,
    setIsParcelList,
    countyOption,
    landScrubs,
    skipTrace,
    resetFilterForm,
}: MapFilterParcelProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const { isOpen, toggle } = useSidebar();
    const [isCreateFilterModalOpen, setIsCreateFilterOpen] = useState(false);
    const queryClient = useQueryClient();

    const {
        mutate,
        data: calculatePriceDetails,
        error,
    } = useExportsCalculatePriceCreate();

    const { data, isLoading: isLoadingParcels } = useParcelsList(
        {
            ...parcelParams,
            page: currentPage,
            page_size: PER_PAGE,
        },
        {
            query: {
                placeholderData: keepPreviousData,
            },
        },
    );

    const { dataList, triggerElement, isLoading } = usePagination<FullParcel>({
        currentPage,
        data: data?.results,
        totalCount: data?.count,
        root: ref.current,
        onNextPage: () => {
            setCurrentPage(currentPage + 1);
        },
    });

    const payloadRequest = useMemo<ExportsCalculatePriceRequest>(() => {
        const filename = generateExportListFilename({
            county: countyOption.county,
            id: countyOption.id,
            label: countyOption.label,
            state: countyOption.state,
        });

        return {
            filename,
            skip_trace: skipTrace,
            land_scrub: landScrubs,
            search_filters: parcelParams,
        };
    }, [countyOption, landScrubs, parcelParams, skipTrace, isOpen]);

    const {
        mutate: purchaseExportApi,
        data: purchaseExportData,
        isPending: isPurchaseExportLoading,
    } = useExportsPurchaseCreate({
        mutation: {
            onSuccess: () => {
                // Invalidate export list since we have new export now
                queryClient.invalidateQueries({
                    queryKey: ["exportList"],
                });
            },
        },
    });

    useEffect(() => {
        if (countyOption?.county && !!parcelParams && isOpen) {
            mutate({
                // TODO(API-TYPE-ISSUE): Fix the type in the calculate_price api
                data: {
                    ...payloadRequest,

                    //
                    user: null,
                    file: null,
                },
            });
        }

        // This is a hack to prevent multiple request
        // We should remove this once we move to CalculatePrice to use GET instead of POST
    }, [JSON.stringify(payloadRequest)]);

    // If the purchase is successful, show the export list menu
    if (purchaseExportData) {
        return (
            <ExportListMenu
                exportId={purchaseExportData.id}
                onClose={() => {
                    setIsParcelList(false);
                    resetFilterForm();
                }}
            />
        );
    }

    return (
        <>
            <Sidebar.Header>
                <div className="lui-flex lui-gap-6">
                    <Button
                        variant="base"
                        icon="LeftArrow"
                        className="lui-flex lui-items-center"
                        onClick={() => {
                            setIsParcelList(false);
                        }}
                    />

                    <Typography size="xl" weight="medium">
                        {isLoadingParcels ? null : formatNumber(data?.count ?? 0)}{" "}
                        Parcels
                    </Typography>
                </div>
            </Sidebar.Header>

            <Sidebar.Content ref={ref}>
                {dataList.map((parcel, i) => (
                    <ExportDetailCard
                        key={`${i}-parcel-${parcel.APN}`}
                        parcel={parcel}
                        countyLabel={countyOption.label}
                        onSeeDetailClick={() => {
                            toggle();
                        }}
                    />
                ))}
                {triggerElement}
                {(isLoadingParcels || isLoading) && (
                    <div className="lui-py-5 lui-text-center">
                        <LoadingSpinner />
                    </div>
                )}
            </Sidebar.Content>

            <Sidebar.Footer fluid>
                <ErrorMessageBox isShown={!!error}>
                    Something wen't wrong while calculating the credits, please try
                    again.
                </ErrorMessageBox>

                <SummaryExportPriceDetails
                    details={
                        // TODO(API-TYPE-ISSUE): Fix the type in the calculate_price api
                        calculatePriceDetails as unknown as ParcelExportPriceBreakdown
                    }
                />

                <Divider horizontal />
                <div className="lui-flex lui-justify-between lui-gap-6 lui-p-6">
                    <SaveFilterModal
                        isOpen={isCreateFilterModalOpen}
                        setIsOpen={setIsCreateFilterOpen}
                        trigger={
                            <Button
                                variant="inline"
                                onClick={() => {
                                    setIsCreateFilterOpen(true);
                                }}
                            >
                                Save filter
                            </Button>
                        }
                        saveFilter={{
                            ...parcelParams,
                            countyOption,
                        }}
                        initialFilterName={parseFileName(payloadRequest.filename)}
                    />

                    <PurchaseExport
                        isLoading={isPurchaseExportLoading}
                        details={
                            calculatePriceDetails as unknown as ParcelExportPriceBreakdown
                        }
                        fileName={payloadRequest.filename}
                        purchaseExportApi={(fileName: string) => {
                            purchaseExportApi({
                                data: {
                                    ...payloadRequest,
                                    filename: fileName,

                                    // TODO(API-TYPE-ISSUE): Fix the type in the calculate_price api
                                    user: null,
                                    file: null,
                                },
                            });
                        }}
                    />
                </div>
            </Sidebar.Footer>
        </>
    );
}
