import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { fetch, parseErrorResponse, formatDate } from "functions";
import { AGGrid } from "components";
import { Loading, Button } from "library";

function formatExpirationDate({ value }) {
    if (!value) {
        return "Never";
    }
    return formatDate(value);
}

function Actions({ data }) {
    const { state, county, expired } = data;
    return (
        <div className="d-flex gap-2">
            {expired ? (
                <Button className="btn-outline-secondary" disabled>
                    Expired
                </Button>
            ) : (
                <Link
                    className="btn bg-gradient-info"
                    to={`/home/realtor_report/${state}/${county}`}
                >
                    View
                </Link>
            )}
        </div>
    );
}

export default function RealtorReportList() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        { headerName: "County", field: "county" },
        { headerName: "State", field: "state" },
        {
            headerName: "Report",
            cellRenderer: Actions,
        },
    ];

    useEffect(() => {
        fetch("/analytics/realtor_report_claim/")
            .then((data) => {
                setLoading(false);
                setRows(data || []);
            })
            .catch((xhr) => toast.error(parseErrorResponse(xhr)));
    }, []);

    if (loading) {
        return <Loading />;
    }
    if (rows.length === 0) {
        return <RealtorReportEmptyList />;
    }

    return (
        <div className="card">
            <div className="card-header pb-0 d-sm-flex justify-content-between">
                <h5>Realtor Report</h5>
                <Link className="btn bg-gradient-success" to="/home/realtor_report/new">
                    New County Realtor Report
                </Link>
            </div>
            <div className="card-body">
                <AGGrid fill_table={true} rows={rows} columns={columns} />
            </div>
        </div>
    );
}

function RealtorReportEmptyList() {
    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>Realtor Report</h5>
            </div>
            <div className="card-body">
                <div className="d-flex flex-column gap-2 w-md-75 w-lg-50 mx-auto">
                    <p className="lead text-bold text-center">
                        Find The Best Agent In Your Market With A Click Of A Button
                    </p>
                    <p className="mb-4 text-center">
                        Our Realtor Report shows you the agents doing the most volume,
                        have the most active listings and their phone number (if
                        available)
                    </p>
                    <Link
                        className="btn bg-gradient-success btn-lg fs-5"
                        to="/home/realtor_report/new"
                    >
                        Unlock Your First Report Now
                    </Link>
                    <Link
                        className="btn bg-gradient-secondary"
                        to="/home/realtor_report/CO/Costilla County"
                    >
                        View Sample Report
                    </Link>
                </div>
            </div>
        </div>
    );
}
