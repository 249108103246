import clsx from "clsx";
import { Typography } from "../typography/typography";
import React, { forwardRef } from "react";

import "./Toggle.css";

interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
    ({ label, disabled, ...props }: ToggleProps, ref) => {
        return (
            <label
                className={clsx(
                    "lui-inline-flex lui-items-center lui-gap-2 lui-cursor-pointer lui-m-0 lui-p-0 lui-w-full lui-justify-between",
                    {
                        "lui-cursor-not-allowed": disabled,
                    },
                )}
            >
                {label && <Typography variant="span">{label}</Typography>}
                <span className={clsx(`lui-toggle`)}>
                    <input
                        ref={ref}
                        name={props.name}
                        type="checkbox"
                        disabled={disabled}
                        className="lui-toggle-input"
                        // prevent warning, it shows up when the toggle is disable
                        // Usually the props onChange should be used if you want to use this component
                        onChange={() => {}}
                        {...props}
                    />

                    <span className={clsx("lui-toggle-button", {})} />
                </span>
            </label>
        );
    },
);
