import clsx from "clsx";
import {
    cloneElement,
    createContext,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import {
    FloatingPortal,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useMergeRefs,
} from "@floating-ui/react";
import { useControlledState } from "../../hooks/useControlledState";
import { Button } from "../button/button";
import { Typography } from "../typography/typography";
import "./sidebar.css";

const SidebarContext = createContext({
    isOpen: false,
    toggle: () => {},
    removeBackdrop: false,
    setRemoveBackdrop: (v: boolean) => {},
});

/**
 * Hook to use the sidebar context
 */
export function useSidebar() {
    return useContext(SidebarContext);
}

interface SidebarProps {
    children: React.ReactNode;
    trigger?: React.ReactElement;
    className?: string;
    onClose?: () => void;
    onOpen?: () => void;
    removeBackdrop?: boolean;
    isOpen?: boolean;
    setIsOpen?: (isOpen: boolean) => void;
}

export function Sidebar({
    children,
    trigger,
    className,
    onClose = () => {},
    onOpen = () => {},
    removeBackdrop = false,
    isOpen: isSidebarOpen,
    setIsOpen: setIsSidebarOpen,
}: SidebarProps) {
    const ref = useRef(null);
    const { state: isOpen, setState: setIsOpen } = useControlledState(
        "Sidebar",
        isSidebarOpen,
        setIsSidebarOpen,
    );
    const [removeBackdropState, setRemoveBackdrop] = useState(removeBackdrop);
    const { refs, context } = useFloating({
        open: isOpen,
        onOpenChange(nextOpen, _event, reason) {
            const hasPopover = document.querySelector(
                // TODO: Use const id and import it from each component
                ".lui-popover, .lui-modal--open, .lui-autocomplete-popover, .lui-tooltip",
            );

            // Prevent closing the sidebar when a popover is open
            if (reason === "escape-key" && hasPopover) {
                return;
            }

            setIsOpen(nextOpen);

            if (!nextOpen) {
                document.body.style.overflow = "auto";
                onClose();
            } else {
                onOpen();
            }
        },
    });

    // Ensures that the sidebar doesn't close when popover/autocomplete is closing on escape key
    const dismiss = useDismiss(context, {
        bubbles: true,
        capture: true,
        outsidePress: !removeBackdropState,
    });
    const click = useClick(context, {});

    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

    const toggle = useCallback(() => {
        const newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
        if (!newIsOpen) {
            document.body.style.overflow = "auto";
            ref.current?.focus();
            onClose?.();
        } else {
            // prevent scrolling when sidebar is open
            document.body.style.overflow = "hidden";
        }
    }, [isOpen, onClose, setIsOpen]);
    const mergedRef = useMergeRefs([ref, refs.setReference]);

    const triggerElement = trigger
        ? cloneElement(trigger, {
              ...getReferenceProps({
                  ref: mergedRef,
                  ...trigger.props,
                  onClick: () => {
                      // Trigger the original onClick event
                      if (trigger.props?.onClick) {
                          trigger.props?.onClick();
                      }
                  },
              }),
              className: clsx("lui-cursor-pointer"),
          })
        : null;

    function setDrop(v: boolean) {
        setRemoveBackdrop(v);
    }

    return (
        <SidebarContext.Provider
            value={{ isOpen, toggle, removeBackdrop, setRemoveBackdrop: setDrop }}
        >
            {triggerElement}
            <FloatingPortal>
                <div
                    ref={refs.setFloating}
                    className={clsx("lui-sidebar lui-z-30", {
                        "lui-sidebar--open": isOpen,
                        "lui-sidebar--closed": !isOpen,
                        "lui-sidebar--bg": !removeBackdropState,
                        className,
                    })}
                    {...getFloatingProps()}
                >
                    <div
                        className={clsx(
                            "lui-sidebar__section",

                            className,
                        )}
                    >
                        {children}
                    </div>
                    <div className="lui-sidebar__empty" onClick={() => toggle()} />
                </div>
            </FloatingPortal>
        </SidebarContext.Provider>
    );
}

Sidebar.Header = function SidebarHeader({
    children,
    hideClose,
    preventAutoFocus,
    className,
    onClose,
}: {
    children: React.ReactNode;
    hideClose?: boolean;
    preventAutoFocus?: boolean;
    className?: string;
    onClose?: () => void;
}) {
    const ref = useRef(null);
    const { isOpen, toggle } = useContext(SidebarContext);
    useEffect(() => {
        if (ref.current && !preventAutoFocus) ref.current?.focus();
    }, [preventAutoFocus]);

    return (
        <Typography
            size="xl"
            weight="bold"
            className={clsx("lui-sidebar__header", {
                className,
            })}
        >
            {children}
            {!hideClose && isOpen && (
                <div className="lui-flex lui-h-full lui-items-center">
                    {" "}
                    <Button
                        ref={ref}
                        variant="base"
                        icon="Close"
                        onClick={() => {
                            toggle();
                            onClose?.();
                        }}
                    />
                </div>
            )}
        </Typography>
    );
};

interface SidebarContentProps {
    children: React.ReactNode;
    className?: string;
}
Sidebar.Content = forwardRef<HTMLDivElement, SidebarContentProps>(
    ({ children, className }, ref) => {
        return (
            <div className={clsx("lui-sidebar__content", className)} ref={ref}>
                {children}
            </div>
        );
    },
);

Sidebar.Footer = function SidebarHeader({
    children,
    className,
    fluid,
}: {
    children: React.ReactNode;
    className?: string;
    fluid?: boolean;
}) {
    return (
        <div
            className={clsx(
                "lui-sidebar__footer",

                "lui-border-y-0 lui-border-x-0 lui-border-t",
                "lui-border-gray-100 lui-border-solid",
                { "lui-p-6": !fluid },

                className,
            )}
        >
            {children}
        </div>
    );
};
