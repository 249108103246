import { bbox } from "@turf/turf";
import { fetch, isLoggedIn, logout } from "functions";
import { FeatureCollection } from "geojson";
import { isNumber } from "lodash";
import { LngLatBounds } from "mapbox-gl";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Map, {
    Layer,
    MapboxMap,
    MapLayerMouseEvent,
    MapRef,
    Marker,
    Source,
} from "react-map-gl";
import { MAPBOX_TOKEN } from "settings";
import {
    DataLayerControl,
    useDataLayerControl,
} from "../../components/map_tools/data_layer_control";
import { MapHelpControl } from "../../components/map_tools/map_help_control";
import {
    MapLayerControl,
    useMapLayer,
} from "../../components/map_tools/map_layer_control";
import {
    LandRuler,
    MapToolsControl,
} from "../../components/map_tools/map_tools_control";
import { ZoomControls } from "../../components/map_tools/zoom_control";
import { useQueryParam } from "../../hooks/useQueryParam";
import { Button } from "../../land_ui/button/button";

import { MapEvent } from "react-map-gl/dist/es5/types";
import { ParcelViewerContext, UserContext } from "./context";
import ParcelDetail from "./controls/detail";
import DrawControl from "./controls/draw";
import HelpControl from "./controls/help";
import InspectControl from "./controls/inspect";
import { MapHeader } from "./map_header";
import CompPopup from "./modals/comp_popup";
import {
    buildingsSource,
    compSource,
    floodzoneSource,
    maptilerSource,
    ownerSource,
    parcelSource,
    roadsSource,
    wetlandsSource,
} from "./sources";
import {
    buildingsOutline,
    compsCircleStyle,
    compsLabelStyle,
    countyHighlightOutline,
    countyHighlightShadow,
    countyIdentify,
    countyOutline,
    floodzone100,
    floodzone500,
    floodzoneCoastal,
    floodzoneFloodway,
    ownersLabelStyle,
    parcelHighlightOutline,
    parcelHighlightShadow,
    parcelIdentify,
    parcelSelectedOutline,
    roadsOutline,
    searchResultsCluster,
    searchResultsClusterLabel,
    wetlandsFill,
    wetlandsOutline,
    zipHighlightOutline,
    zipHighlightShadow,
    zipIdentify,
    zipOutline,
} from "./styles";
import {
    County,
    DebugPoint,
    MapFilter,
    Parcel,
    PopupInfo,
    SavedList,
    SearchResult,
    User,
} from "./types";

const initialViewState = {
    longitude: -80.49238,
    latitude: 34.30861,
    zoom: 14,
};

const images = ["rounded_green", "rounded_blue", "rounded_red"];

const loadImages = (map: MapboxMap, images: string[]) => {
    for (const image of images) {
        map.loadImage(`/static/images/${image}.png`, (error: any, imageData: any) => {
            if (error) {
                console.log(error);
                return;
            }
            if (!map.hasImage(image)) {
                map.addImage(image, imageData, {
                    stretchX: [[10, 54]],
                    stretchY: [[10, 54]],
                    content: [7, 7, 57, 57],
                    pixelRatio: 1,
                });
            }
        });
    }
};

// TODO(KM): Move to the type definition file
declare global {
    interface Window {
        Intercom: (action: string, options: any) => void;
    }
}

export default function ParcelViewer() {
    const [user, setUser] = useState<User>();
    useEffect(() => {
        if (!isLoggedIn()) {
            logout();
            return;
        }

        const fetchUser = async () => {
            try {
                const user = await fetch("/user/user/");
                setUser(user);

                // hides the Intercom chat widget at the bottom right of the screen
                window.Intercom("update", {
                    hide_default_launcher: true,
                });
            } catch (xhr) {
                // TODO: show error modal
                console.error("Error loading user", xhr);
            }
        };

        fetchUser();
    }, [setUser]);

    const context = { user };

    return (
        <UserContext.Provider value={context}>
            {user && !user.has_paid_subscription && <SubscriptionRequiredModal />}
            <ParcelViewerMap />
        </UserContext.Provider>
    );
}

function SubscriptionRequiredModal() {
    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Subscription Required
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <p>There's a problem with your subscription.</p>
                <p>
                    If you canceled and are looking to resubscribe, contact{" "}
                    <a href="mailto:hello@landinsights.co">hello@landinsights.co</a>
                </p>
            </Modal.Body>
        </Modal>
    );
}

function ParcelViewerMap() {
    const mapRef = useRef<MapRef>();
    const [isDebugMode] = useQueryParam("debug", false);
    const { mapLayerOption, setMapLayer } = useMapLayer();
    const { dataLayer } = useDataLayerControl();

    // Remove later
    const [styleLoaded, setStyleLoaded] = useState(true);

    // Map features state
    const [county, setCounty] = useState<County>();
    const [searchResult, _setSearchResult] = useState<SearchResult>();

    // Render no features
    const emptyFilter = ["==", "code", ""];

    // mapFilter is the active map feature (parcel/county/zip)
    const [mapFilter, setMapFilter] = useState<MapFilter>();
    // savedList is the active Saved List
    const [savedList, setSavedList] = useState<SavedList>();
    const [isRulerActive, , removeIsRulerActive] = useQueryParam<boolean>(
        "ruler",
        null,
    );
    const [activeParcel, setActiveParcel, removeActiveParcel] = useQueryParam<number>(
        "parcel",
        null,
    );
    const [popupInfo, setPopupInfo] = useState<PopupInfo>(null);

    const addTerrainSource = () => {
        if (!mapRef?.current) return;

        const map = mapRef.current.getMap();
        map.addSource("mapbox-dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxzoom: 18,
        });

        map.setTerrain({ source: "mapbox-dem" });
    };

    const updateMapOnLoad = useCallback(() => {
        if (!mapRef?.current) return;

        const map = mapRef.current.getMap();
        map.addControl(LandRuler);
        addTerrainSource();
        loadImages(map, images);
    }, []);

    const onLoad = (event: MapEvent<any>) => {
        updateMapOnLoad();
        if (isRulerActive && !LandRuler.isActive) {
            LandRuler.activate();
        }
    };

    // Check for querystring param to enable debug mode
    const [debugPoints, setDebugPoints] = useState<DebugPoint[]>();

    useEffect(() => {
        if (activeParcel) {
            fetch(`/api/property/parcels/${activeParcel}/`)
                .then((parcel) => {
                    const bounds = bbox(parcel.shape);
                    if (bounds) {
                        mapRef.current.fitBounds(
                            [
                                [bounds[0], bounds[1]],
                                [bounds[2], bounds[3]],
                            ],
                            {
                                padding: 200,
                                duration: 0,
                            },
                        );
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setSearchResult = useCallback(
        (searchResult: SearchResult) => {
            _setSearchResult(searchResult);

            const parcels = searchResult?.results || [];

            // Clear prior selected zip/county
            setMapFilter(null);

            // Fit map bounds to search results extent
            const bbox = calculateBounds(parcels);
            if (bbox) {
                mapRef.current.fitBounds(bbox, {
                    padding: 100,
                    duration: 0,
                });
            }

            // Reset feature state of parcels layer
            mapRef.current.removeFeatureState({
                source: "tiler_source",
                sourceLayer: "parcels",
            });

            // Set feature state of each parcel in parcels layer if the ID exists in parcels searchResults
            parcels.forEach((parcel: Parcel) => {
                mapRef.current.setFeatureState(
                    {
                        source: "tiler_source",
                        sourceLayer: "parcels",
                        id: parcel.PropertyID,
                    },
                    { searchResultMatch: true },
                );
            });
        },
        [mapRef, _setSearchResult, setMapFilter],
    );

    // Handle switching map layers. This is a destructive process in Mapbox GL
    // which is not gracefully handled by react-map-gl. Sources and layers must
    // be removed and then readded after styles are done loading to avoid
    // "Style is not done loading" errors.
    // Ref: https://github.com/visgl/react-map-gl/issues/1122
    const setMapStyle = useCallback(
        (mapStyle: string) => {
            const map = mapRef.current.getMap();

            // Restore map layers when styles are done loading
            map.once("style.load", () => {
                setStyleLoaded(true);
                // Fixes an issue where MLS bg images don't load on style change
                updateMapOnLoad();
            });

            // Restore map feature state when tiles have reloaded
            map.once("idle", () => {
                setSearchResult(searchResult);
            });

            // Remove map layers
            setStyleLoaded(false);
            // @ts-ignore
            setMapLayer(mapStyle);
        },
        [searchResult, setMapLayer, setSearchResult, updateMapOnLoad],
    );

    const closePopup = () => setPopupInfo(null);

    const onClick = (e: MapLayerMouseEvent) => {
        const feature = e.features && e.features[0];
        if (!feature) {
            return;
        }
        if (feature.source == "comp_source" && feature.geometry.type == "Point") {
            setPopupInfo({
                latitude: feature.geometry.coordinates[1],
                longitude: feature.geometry.coordinates[0],
                title: "Comp details",
                properties: feature.properties,
            });
        } else if (feature.source == "tiler_source" && !isRulerActive) {
            // Prevent when the ruler is active
            const id = feature?.id;

            if (isNumber(id) && id !== activeParcel) {
                setActiveParcel(id);
            } else {
                removeActiveParcel();
            }

            // Clear map filter if active parcel changed
            if (id !== activeParcel) {
                setMapFilter(null);
            }
        }
    };

    const activeParcelFilter = useMemo(
        () => ["==", "id", activeParcel || ""],
        [activeParcel],
    );

    // Set body class name on mount
    useEffect(() => {
        document.body.className = "parcel-viewer";
        return () => {
            // Clear class name on dismount
            document.body.className = "";
        };
    }, []);

    const context = {
        setMapFilter,
        county,
        setCounty,
        savedList,
        setSavedList,
        setDebugPoints,
        searchResult,
        setSearchResult,
    };

    // Apply map filter
    let zipFilter = emptyFilter;
    let countyFilter = emptyFilter;
    let parcelFilter = emptyFilter;
    let identifyFilter = emptyFilter;
    if (mapFilter) {
        const { identifyLayer, filter, inverseFilter } = mapFilter;
        identifyFilter = filter;
        if (identifyLayer === "county_identify") {
            countyFilter = inverseFilter;
        } else if (identifyLayer === "zip_identify") {
            zipFilter = inverseFilter;
        } else if (identifyLayer === "parcel_identify") {
            parcelFilter = inverseFilter;
        }
    }

    const searchResultGeojson: FeatureCollection = {
        type: "FeatureCollection",
        features:
            searchResult?.results
                ?.filter((parcel) => parcel?.point)
                ?.map((parcel) => ({
                    id: parcel.PropertyID,
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: parcel.point,
                    },
                    properties: {},
                })) || [],
    };

    const onError = (e: any) => {
        if (e.message === "Map is not supported by this browser") {
            // Handle Web GL init error raised by react-map-gl
            // TODO: friendly error message
            console.log("WebGL init error. Refresh page to continue.");
        } else if (e.error?.status === 400) {
            // Ignore HTTP 400 Bad Request tile requests
        } else {
            console.error(e);
        }
    };

    const sourcesAndLayers = styleLoaded && (
        <>
            {popupInfo && (
                <CompPopup
                    key={popupInfo.properties.id}
                    longitude={popupInfo.longitude}
                    latitude={popupInfo.latitude}
                    onClose={closePopup}
                    title={popupInfo.title}
                    properties={popupInfo.properties}
                />
            )}
            <Source {...parcelSource}>
                <Layer {...parcelIdentify} />
                <Layer {...parcelHighlightShadow} filter={parcelFilter} />
                <Layer {...parcelHighlightOutline} />
                <Layer {...parcelSelectedOutline} filter={activeParcelFilter} />
            </Source>
            <Source {...ownerSource}>
                {dataLayer.ownersLayer && <Layer {...ownersLabelStyle} />}
            </Source>
            <Source {...wetlandsSource}>
                {dataLayer.wetLands && <Layer {...wetlandsFill} />}
                {dataLayer.wetLands && <Layer {...wetlandsOutline} />}
            </Source>
            <Source {...buildingsSource}>
                {dataLayer.buildingLayer && <Layer {...buildingsOutline} />}
            </Source>
            <Source {...roadsSource}>
                {dataLayer.roadsLayer && <Layer {...roadsOutline} />}
            </Source>
            <Source {...floodzoneSource}>
                {dataLayer.floodZoneLayer && <Layer {...floodzoneCoastal} />}
                {dataLayer.floodZoneLayer && <Layer {...floodzoneFloodway} />}
                {dataLayer.floodZoneLayer && <Layer {...floodzone100} />}
                {dataLayer.floodZoneLayer && <Layer {...floodzone500} />}
            </Source>
            <Source
                type="geojson"
                data={searchResultGeojson}
                cluster={true}
                clusterMaxZoom={11}
                tolerance={0.5}
            >
                <Layer {...searchResultsCluster} />
                <Layer {...searchResultsClusterLabel} />
            </Source>
            <Source {...maptilerSource}>
                {dataLayer.countyLayer && <Layer {...countyOutline} />}
                {dataLayer.zipLayer && <Layer {...zipOutline} />}
                <Layer {...countyIdentify} />
                <Layer {...countyHighlightOutline} filter={identifyFilter} />
                <Layer {...countyHighlightShadow} filter={countyFilter} />
                <Layer {...zipIdentify} />
                <Layer {...zipHighlightOutline} filter={identifyFilter} />
                <Layer {...zipHighlightShadow} filter={zipFilter} />
            </Source>
            <Source {...compSource}>
                {dataLayer.mlsComps && <Layer {...compsCircleStyle} />}
                {dataLayer.mlsComps && <Layer {...compsLabelStyle} />}
            </Source>
            {((isDebugMode && debugPoints) || []).map(({ point, label, color }, i) => (
                <Marker
                    key={i}
                    longitude={point[0]}
                    latitude={point[1]}
                    color={color}
                    draggable
                ></Marker>
            ))}
        </>
    );

    return (
        <ParcelViewerContext.Provider value={context}>
            <div className={"parcel-viewer-wrapper"}>
                <Map
                    hash
                    initialViewState={initialViewState}
                    ref={mapRef}
                    mapboxAccessToken={MAPBOX_TOKEN}
                    mapStyle={mapLayerOption}
                    minZoom={4}
                    maxZoom={20}
                    onClick={onClick}
                    onLoad={onLoad}
                    interactiveLayerIds={[
                        "parcel_identify",
                        "comps-circle",
                        "comps-label",
                    ]}
                    onError={onError}
                >
                    {sourcesAndLayers}

                    <MapHeader
                        setActiveParcel={setActiveParcel}
                        setSearchResult={setSearchResult}
                        setShowFilterPanel={(isOpen) => {
                            removeActiveParcel();
                        }}
                    />

                    {activeParcel && (
                        <ParcelDetail
                            parcelID={activeParcel}
                            onClose={() => removeActiveParcel()}
                        />
                    )}
                    {/* Top Right Map Options */}
                    <div className=" lui-flex lui-flex-col lui-gap-3 lui-absolute lui-right-0 lui-top-0 lui-m-6">
                        <Button
                            variant="secondary"
                            icon="LandInsights"
                            href="/"
                            openInNewTab
                        />
                    </div>

                    {isDebugMode && (
                        <>
                            <HelpControl />
                            <InspectControl />

                            {styleLoaded && <DrawControl position={"top-right"} />}
                        </>
                    )}

                    {/* Bottom Right Map Options */}
                    <div className="lui-flex lui-flex-col lui-gap-3 lui-absolute lui-right-0 lui-bottom-0 lui-m-6">
                        <DataLayerControl />
                        <MapToolsControl />
                        <MapLayerControl setLayer={setMapStyle} />
                        <ZoomControls />
                    </div>

                    {/* Bottom Left Map Options */}
                    <div className="lui-flex lui-flex-col lui-gap-3 lui-absolute lui-left-0 lui-bottom-2 lui-my-6 lui-mx-2">
                        <MapHelpControl />
                    </div>

                    {/* Center bottom options */}
                    {isRulerActive && (
                        <div className="lui-flex lui-absolute lui-bottom-0 lui-left-1/2 -lui-translate-x-1/2 lui-justify-center lui-gap-3 lui-m-6 lui-w-fit ">
                            <div>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        LandRuler.deactivate();
                                        removeIsRulerActive();
                                    }}
                                    icon="Ruler"
                                >
                                    Exit Ruler
                                </Button>
                            </div>
                        </div>
                    )}
                </Map>
            </div>
        </ParcelViewerContext.Provider>
    );
}

function getBBox(parcel: Parcel): LngLatBounds {
    const lng0 = parcel.bbox ? parcel.bbox[0] : parcel.point[0];
    const lat0 = parcel.bbox ? parcel.bbox[1] : parcel.point[1];
    const lng1 = parcel.bbox ? parcel.bbox[2] : parcel.point[0];
    const lat1 = parcel.bbox ? parcel.bbox[3] : parcel.point[1];
    return new LngLatBounds([lng0, lat0], [lng1, lat1]);
}

function calculateBounds(parcels: Parcel[]): LngLatBounds {
    if (!parcels || parcels.length === 0) {
        return null;
    }
    const bounds = new LngLatBounds();
    for (const parcel of parcels) {
        if (parcel?.point) {
            bounds.extend(getBBox(parcel));
        }
    }
    return bounds;
}
