import { useLocalStorage } from "usehooks-ts";
import { Toggle } from "../../land_ui/toggle_button/Toggle";
import { ControlPopover } from "./control_popover";

export function useDataLayerControl() {
    const [dataLayer, setDataLayer] = useLocalStorage("dataLayerControl", {
        wetLands: false,
        floodZoneLayer: false,
        soilLayer: false,
        buildingLayer: false,
        mlsComps: false,
        countyLayer: false,
        zipLayer: false,
        roadsLayer: false,
        ownersLayer: false,
    });

    return {
        dataLayer,
        setDataLayer: (newDataLayer: Partial<typeof dataLayer>) => {
            setDataLayer({
                ...dataLayer,
                ...newDataLayer,
            });
        },
    };
}
export function DataLayerControl() {
    const { dataLayer, setDataLayer } = useDataLayerControl();

    let layerOptions = [
        {
            name: "Wetlands Layer",
            state: dataLayer.wetLands,
            setState: () => {
                setDataLayer({
                    wetLands: !dataLayer.wetLands,
                });
            },
        },
        {
            name: "Flood Zone Layer",
            state: dataLayer.floodZoneLayer,
            setState: () => {
                setDataLayer({
                    ...dataLayer,
                    floodZoneLayer: !dataLayer.floodZoneLayer,
                });
            },
        },

        {
            name: "Soil Layer",
            state: dataLayer.soilLayer,
            setState: () => {
                setDataLayer({
                    soilLayer: !dataLayer.soilLayer,
                });
            },
        },

        {
            name: "Building Layer",
            state: dataLayer.buildingLayer,
            setState: () => {
                setDataLayer({
                    buildingLayer: !dataLayer.buildingLayer,
                });
            },
        },

        {
            name: "Owner Layer",
            state: dataLayer.ownersLayer,
            setState: () => {
                setDataLayer({
                    ownersLayer: !dataLayer.ownersLayer,
                });
            },
        },

        {
            name: "Roads Layer",
            state: dataLayer.roadsLayer,
            setState: () => {
                setDataLayer({
                    roadsLayer: !dataLayer.roadsLayer,
                });
            },
        },

        {
            name: "MLS Comps",
            state: dataLayer.mlsComps,
            setState: () => {
                setDataLayer({
                    mlsComps: !dataLayer.mlsComps,
                });
            },
        },

        {
            name: "County Layer",
            state: dataLayer.countyLayer,
            setState: () => {
                setDataLayer({
                    countyLayer: !dataLayer.countyLayer,
                });
            },
        },
        {
            name: "Zip Layer",
            state: dataLayer.zipLayer,
            setState: () => {
                setDataLayer({
                    zipLayer: !dataLayer.zipLayer,
                });
            },
        },
    ];

    return (
        <ControlPopover
            icon="LayerTwo"
            tooltipContent="Data Layers"
            title="Data Layers"
            placement="left"
        >
            {layerOptions.map((layer) => (
                <div
                    key={layer.name}
                    className="lui-flex lui-w-full lui-justify-between lui-items-center lui-px-4 lui-py-2 hover:lui-bg-gray-100 "
                >
                    <Toggle
                        checked={layer.state}
                        onClick={layer.setState}
                        label={layer.name}
                    />
                </div>
            ))}
        </ControlPopover>
    );
}
