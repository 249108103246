import { minutesAgo } from "functions";

import { SKIP_TRACE_SUCCESS, SKIP_TRACE_FAILURE } from "constants";

// Map skip trace status to Bootstrap color
export function progressColor(status) {
    switch (status) {
        case SKIP_TRACE_SUCCESS:
            return "success";
        case SKIP_TRACE_FAILURE:
            return "danger";
        default:
            return "info";
    }
}

export function isWorking(status) {
    switch (status) {
        case SKIP_TRACE_SUCCESS:
        case SKIP_TRACE_FAILURE:
            return false;
        default:
            return true;
    }
}

// Display percent completed as 99% if the paid/generate steps are in progress
export function percentCompleted(skipTrace) {
    const paidAndGeneratedReport = skipTrace.paid_at && skipTrace.result_csv;
    if (skipTrace.percent_completed === 100 && !paidAndGeneratedReport) {
        return 99;
    }
    return skipTrace.percent_completed;
}

export function showRetryButton(skipTrace) {
    // Show retry button if there was a Stripe error to allow users to retry
    // failed payments.
    if (skipTrace.stripe_error) {
        return true;
    }
    // Show retry button if the paid/generate steps have not completed 1 minute
    // after the skip trace job has completed, which could happen if there was
    // an error with the Celery job.
    const paidAndGeneratedReport = skipTrace.paid_at && skipTrace.result_csv;
    return (
        skipTrace.percent_completed === 100 &&
        !paidAndGeneratedReport &&
        minutesAgo(skipTrace.updated_at) >= 1
    );
}

export function showStartButton(skipTrace) {
    return skipTrace.status === "pending";
}

export function showDownloadButton(skipTrace) {
    return skipTrace.percent_completed === 100 && skipTrace.result_csv;
}
