import React from "react";
import { Widget } from "@typeform/embed-react";

import { Card } from "library";
import { IS_DEV, TYPEFORM_FEATURE_IDEA_FORM_ID } from "settings";

export default function FeatureIdea() {
    return (
        <Card>
            <div className="card-body text-center">
                <h6 class="mb-0">Got A Killer Feature Idea?</h6>
                <p class="text-sm mb-0">Tell us about it now!</p>
                <hr class="horizontal dark my-3"></hr>
                <Widget
                    id={TYPEFORM_FEATURE_IDEA_FORM_ID}
                    style={{ height: 500 }}
                    autoFocus={true}
                    enableSandbox={IS_DEV}
                />
            </div>
        </Card>
    );
}
