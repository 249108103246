import { useCallback, useContext, useEffect, useState } from "react";

import { Feature } from "geojson";
import { useMap } from "react-map-gl";
import { MapFilter } from "../../components/map_filter/map_filter";
import { QuickSearch } from "../../land_ui/quick_search/quick_search";
import { Typography } from "../../land_ui/typography/typography";
import { ParcelViewerContext } from "./context";
import { findFeatureOnMap, findParcelOnMap } from "./controls/search";
import { MapMenu } from "./map_menu/menu";
import { FIPSLookupResult, Point, SearchResult } from "./types";
import { Button } from "../../land_ui/button/button";
import { useParcel } from "../../hooks/useParcel";
import { Sidebar } from "../../land_ui/sidebar/sidebar";
import { ExportListMenu } from "./map_menu/export_list_menu";
import { useQueryParam } from "../../hooks/useQueryParam";
import { fetch } from "../../functions";

interface MapHeaderProps {
    setActiveParcel: (parcel: number) => void;
    setSearchResult: (result: SearchResult) => void;
    setShowFilterPanel: (showFilterPanel: boolean) => void;
}

export function MapHeader({
    setActiveParcel,
    setSearchResult,
    setShowFilterPanel,
}: MapHeaderProps) {
    const [parcelExport, , removeParcelExport] = useQueryParam("parcelExport", null);
    const { setMapFilter, county, setCounty, setSavedList } =
        useContext(ParcelViewerContext);
    const { current: map } = useMap();
    const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
    const { removeParcelQuery } = useParcel();
    // Locate parcel on map and mark it active
    const highlightParcel = useCallback(
        async (point: Point) => {
            setMapFilter(null);

            const result = await findParcelOnMap(map, point);

            if (result && result.mapFeature) {
                const { identifyLayer, idField, mapFeature } = result;
                const parcelID = parseInt(`${mapFeature.id}`, 10);
                const filter = ["==", idField, parcelID];
                const inverseFilter = ["!", filter];
                setMapFilter({ identifyLayer, filter, inverseFilter });
                setActiveParcel(parcelID);
            }
        },
        [map, setMapFilter, setActiveParcel],
    );

    // Locate feature on map and highlight it by applying shadow filter
    const highlightFeature = useCallback(
        async (feature: Feature) => {
            // Reset filters
            setMapFilter(null);

            const result = await findFeatureOnMap(map, feature);

            if (result && result.mapFeature) {
                const { identifyLayer, filter, idField, mapFeature } = result;

                // Filter layer by feature ID
                let newFilter = ["==", idField, mapFeature.id];
                let inverseFilter = ["!", newFilter];

                // Include County/Zip layer filter
                if (filter) {
                    newFilter = ["all", filter, newFilter];
                    inverseFilter = ["all", filter, inverseFilter];
                }

                // Apply map filter for shadow layer
                setMapFilter({ identifyLayer, filter: newFilter, inverseFilter });

                // Mark county as "active" for Parcel # search
                const featureType = feature?.properties?.feature_type;
                if (featureType === "district") {
                    setCounty({
                        // id is the county FIPS code
                        id: mapFeature.properties.code,
                        name: feature.properties.name,
                    });
                }
            }
        },
        [map, setMapFilter, setCounty],
    );

    const [activeCounty, , removeActiveCounty] = useQueryParam<string>("county", null);
    useEffect(() => {
        if (activeCounty) {
            (async () => {
                const result: FIPSLookupResult = await fetch(
                    `/api/property/fips_lookup/?name=${activeCounty}`,
                );
                if (result?.results.length > 0) {
                    const county = result.results[0];
                    const savedList = {
                        id: "temp-search-filter",
                        title: "temp-search-filter",
                        search_filters: {
                            county: county.id,
                            countyOption: {
                                id: county.id,
                                label: county.label,
                                county: county.label,
                                state: county.state,
                            },
                        },
                    };
                    setSavedList(savedList);
                    setIsFilterSidebarOpen(true);
                    removeActiveCounty();
                }
            })();
        }
    }, [activeCounty, removeActiveCounty, setSavedList]);

    return (
        <div className="lui-absolute  lui-z-20 lui-top-5 lui-left-6 lui-flex lui-gap-3 lui-items-center">
            <MapMenu
                onFilterClick={() => {
                    setIsFilterSidebarOpen(true);
                }}
            />
            <Button
                variant="primary"
                icon="Filter"
                onClick={() => {
                    setIsFilterSidebarOpen(true);
                    removeParcelQuery();
                }}
            >
                Filter Parcels
            </Button>

            {isFilterSidebarOpen && (
                <MapFilter
                    isOpen={isFilterSidebarOpen}
                    setIsOpen={setIsFilterSidebarOpen}
                />
            )}

            <Typography color="white" weight="bold" size="lg">
                or
            </Typography>
            <QuickSearch
                county={county}
                onSearch={(search) => {
                    if (search.point) {
                        highlightParcel(search.point);
                    }

                    if (search.ownerResults) {
                        setSearchResult(search.ownerResults);
                    }

                    if (search.feature) {
                        highlightFeature(search.feature);
                    }
                }}
            />

            {parcelExport && (
                <Sidebar
                    isOpen={!!parcelExport}
                    setIsOpen={(isOpen) => {
                        if (!isOpen) {
                            removeParcelExport();
                        }
                    }}
                >
                    <ExportListMenu exportId={parcelExport} />
                </Sidebar>
            )}
        </div>
    );
}
