import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

export const IS_DEV = process.env.REACT_APP_ENV === "dev";
export const IS_PROD = process.env.REACT_APP_ENV === "aws-production";
export const IS_STAGING = process.env.REACT_APP_ENV === "aws-staging";

// Populate API_URL even for requests on same domain because Mapbox layer
// sources with relative URLs are ignored.
export const API_URL = IS_PROD
    ? "https://app-api.landinsights.co"
    : IS_STAGING
      ? "https://staging-api.landinsights.co"
      : IS_DEV
        ? "http://localhost:8000"
        : "";

export const TILER_URL = IS_PROD
    ? "https://app-tiler.landinsights.co"
    : IS_STAGING
      ? "https://staging-tiler.landinsights.co"
      : IS_DEV
        ? "http://localhost:8080"
        : "";
export const STRIPE_PRICING_TABLE_ID = IS_PROD
    ? "prctbl_1OwRP2H55IxCIyzHcq8zJkPd"
    : "prctbl_1OvrAbH55IxCIyzHGsmuMXqV";

export const STRIPE_PUBLISHABLE_KEY = IS_PROD
    ? "pk_live_51NRJozH55IxCIyzH1SUPDv5KHNPoknCt9TbiqywxYQYD34OHZBTSF7Yy99dHRaB9cly7jzJhbcsTZAJjllePht6900GrwOVpCG"
    : "pk_test_51NRJozH55IxCIyzHUvg0Y8J9uvFen7CbzJkdi3tnvexjJfFhaVkPzVeoI2aaPKR2x93ku2eDbp8DbjXJ2yi7B9z500FjqNfCvF";

export const TYPEFORM_ONBOARDING_FORM_ID = IS_PROD ? "JjTYC62z" : "xoqOxLZH";

export const TYPEFORM_FEATURE_IDEA_FORM_ID = "grHJJIYs";

// GOOGLE OAUTH USES THIS DOCUMENTATION!!!!
// https://developers.google.com/identity/oauth2/web/guides/use-token-model
export const GOOGLE_CLIENT_ID =
    "297720701797-4r7ijvdjgu5e7tf23jto6g4dqm8k23cs.apps.googleusercontent.com";

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_ENABLED
    ? "https://292b9b1a6ae00fc3d44dc19abe46aaf8@o4506786682044416.ingest.sentry.io/4506786684010496"
    : "";

export const SIMPLE_FILE_UPLOAD_KEY = IS_PROD
    ? "037c97714b33f8e9bf56e5cf7cef0216"
    : "2edd7a89ae5b0fd145280b18b6fe64d5";

export const HEAP_ANALYTICS_ID = IS_PROD ? "2577908468" : "2850922395";

export const INTERCOM_APP_ID = IS_PROD ? "egggmiv5" : "iekam0xm";

export const MAPBOX_TOKEN =
    "pk.eyJ1Ijoia21sYW5kaW5zaWdodHMiLCJhIjoiY2x1MnlhazdiMHAzaTJpb3R0NWdhcXA4cSJ9.7Nu_8dTOCW43M2dATjghDw";

export const MAPRIGHT_TOKEN =
    "pk.eyJ1IjoibWFwcmlnaHQyIiwiYSI6IkpMTE8tT0EifQ.BVYqz1Tp-th28TXPgg3rrQ";

// Configure Mapbox GL token
mapboxgl.accessToken = MAPRIGHT_TOKEN;

export const REPORT_ALL_TOKEN = "xkVu1t45jp";
