import Axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { getAuthToken, refreshToken } from "../functions/ajax";
import { API_URL } from "../settings";

export const AXIOS_INSTANCE = Axios.create({
    baseURL: API_URL,
    paramsSerializer: {
        serialize: (params: any) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
        },
    },
});

export const customInstance = async <T>(config: AxiosRequestConfig): Promise<T> => {
    let headers = { ...config.headers };

    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE({
        ...config,
        cancelToken: source.token,
        headers,
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: "comma" });
        },
    }).then(({ data }) => data);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    promise.cancel = () => {
        source.cancel("Query was cancelled");
    };

    return promise;
};

// Add a request interceptor to attach the auth token
AXIOS_INSTANCE.interceptors.request.use(
    (config) => {
        const token = getAuthToken();
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

// Add a response interceptor to handle 401 errors and retry the request
AXIOS_INSTANCE.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;

            try {
                await refreshToken();
                return AXIOS_INSTANCE(originalRequest); // Retry the original request
            } catch (refreshError) {
                // Redirect to login if token refresh fails
                window.location.href = "/login";
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    },
);
