import clsx from "clsx";

type DividerProps = {
    className?: string;
    horizontal?: boolean;
};

export function Divider({ className, horizontal = false }: DividerProps) {
    return (
        <div
            className={clsx(
                horizontal ? "lui-h-0.5 lui-w-full" : "lui-h-full lui-w-0.5", // Horizontal or vertical styles
                "lui-bg-slate-100",
                className,
            )}
        />
    );
}
