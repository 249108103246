import { createContext, useContext } from "react";

import type {
    User,
    County,
    MapFilter,
    SavedList,
    SearchResult,
    DebugPoint,
} from "./types";

interface ParcelViewerContextType {
    setMapFilter: (value: MapFilter) => void;
    county: County;
    setCounty: (value: County) => void;
    savedList: SavedList;
    setSavedList: (value: SavedList) => void;
    setDebugPoints: (points: DebugPoint[]) => void;
    searchResult: SearchResult;
    setSearchResult: (value: SearchResult) => void;
}

export const ParcelViewerContext = createContext<ParcelViewerContextType | null>(null);

interface UserContextType {
    user: User;
}

export const UserContext = createContext<UserContextType | null>(null);

export function useMapContext() {
    const context = useContext(ParcelViewerContext);
    if (!context) {
        throw new Error("useMapContext must be used within a MapProvider");
    }
    return context;
}
