import { isUndefined } from "lodash";
import { useState } from "react";

/**
 * This is a custom hook that is used to handle controlled and uncontrolled state
 * NOTE: This hook is super useful when building reusable components,
 * but you might not need it in all cases
 * @param name used to identify where this is used
 * @param state
 * @param setState
 * @returns
 */
export function useControlledState<T>(
    name: string,
    state?: T,
    setState?: (state: T) => void,
) {
    const [internalState, setInternalState] = useState<boolean>();

    if (!isUndefined(state) && !isUndefined(setState)) {
        return { state, setState };
    }

    if (!isUndefined(state) || !isUndefined(setState)) {
        throw new Error(
            `${name}: Needs both state and setState to be consider 
             controlled component otherwise it will result to unexpected behaviors
            `,
        );
    }

    return { state: internalState, setState: setInternalState };
}
