import { useIntercom } from "../../hooks/useIntercom";
import { Button } from "../../land_ui/button/button";
import { IconNameType } from "../../land_ui/icon/icon";
import { ControlPopover } from "./control_popover";

interface OptionItemProp {
    name: string;
    icon: IconNameType;
    onClick: () => void;
}
export function MapHelpControl() {
    const { show } = useIntercom();
    const options: OptionItemProp[] = [
        { name: "Report a bug", icon: "Announcement", onClick: show },
        { name: "Customer support", icon: "Lifebuoy", onClick: show },
    ];
    return (
        <ControlPopover
            icon="QuestionMark"
            tooltipContent="Help"
            title="Help"
            placement="top-start"
            dismissOnClick
        >
            {options.map((option) => (
                <div
                    key={option.name}
                    className="lui-px-4 lui-py-2 hover:lui-bg-gray-100"
                >
                    <Button
                        variant="base"
                        icon={option.icon}
                        className="lui-w-full lui-text-left"
                        onClick={option.onClick}
                    >
                        {option.name}
                    </Button>
                </div>
            ))}
        </ControlPopover>
    );
}
