import {
    FloatingPortal,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useMergeRefs,
} from "@floating-ui/react";
import clsx from "clsx";
import {
    cloneElement,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
} from "react";
import { Button } from "../button/button";
import { Typography } from "../typography/typography";

import { useControlledState } from "../../hooks/useControlledState";
import "./modal.css";

const ModalContext = createContext({
    isOpen: false,
    toggle: () => {},
});

/**
 * Hook to use the Modal context
 */
export function useModal() {
    return useContext(ModalContext);
}

interface ModalProps {
    children: React.ReactNode;
    trigger?: React.ReactElement;
    className?: string;
    onClose?: () => void;
    onOpen?: () => void;
    isError?: boolean;

    // Controlled
    isOpen?: boolean;
    setIsOpen?: (isOpen: boolean) => void;
}

export function Modal({
    children,
    trigger,
    className,
    onClose = () => {},
    onOpen = () => {},
    isError = false,
    // Controlled
    isOpen: isOpenControlled,
    setIsOpen: setIsOpenControlled,
}: ModalProps) {
    const ref = useRef(null);
    const { state: isOpen, setState: setIsOpen } = useControlledState(
        "Modal",
        isOpenControlled,
        setIsOpenControlled,
    );
    const { refs, context } = useFloating({
        open: isOpen,
        onOpenChange(nextOpen, _event, reason) {
            setIsOpen(nextOpen);

            if (!nextOpen) {
                document.body.style.overflow = "auto";
                onClose();
            } else {
                onOpen();
            }
        },
    });

    const dismiss = useDismiss(context, {
        bubbles: false,
        capture: false,
        escapeKey: true,
        outsidePress: false,
    });
    const click = useClick(context, {});

    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

    const toggle = useCallback(() => {
        const newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
        if (!newIsOpen) {
            document.body.style.overflow = "auto";
            ref.current?.focus();
            onClose?.();
        } else {
            // prevent scrolling when Modal is open
            document.body.style.overflow = "hidden";
        }
    }, [isOpen, onClose, setIsOpen]);

    const mergedRef = useMergeRefs([ref, refs.setReference]);

    const triggerElement = trigger
        ? cloneElement(trigger, {
              ...getReferenceProps({
                  ref: mergedRef,
                  ...trigger.props,
                  onClick: () => {
                      // Trigger the original onClick event
                      if (trigger.props?.onClick) {
                          trigger.props?.onClick();
                      }
                  },
              }),
              className: clsx("lui-cursor-pointer"),
          })
        : null;

    return (
        <ModalContext.Provider value={{ isOpen, toggle }}>
            {triggerElement}

            <FloatingPortal>
                {isOpen && (
                    <>
                        <div
                            className="lui-modal__backdrop lui-z-50"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        />

                        <div
                            ref={refs.setFloating}
                            className={clsx("lui-modal lui-z-50 ", {
                                "lui-modal--open": isOpen,
                                "lui-modal--closed": !isOpen,
                                className,
                            })}
                            {...getFloatingProps()}
                        >
                            <div
                                className={clsx(
                                    "lui-modal__section",

                                    {
                                        "lui-modal--is-error": isError,
                                    },
                                    className,
                                )}
                            >
                                {children}
                            </div>
                        </div>
                    </>
                )}
            </FloatingPortal>
        </ModalContext.Provider>
    );
}

Modal.Header = function ModalHeader({
    children,
    hideClose,
    preventAutoFocus,
    className,
}: {
    children: React.ReactNode;
    hideClose?: boolean;
    preventAutoFocus?: boolean;
    className?: string;
}) {
    const ref = useRef(null);
    const { isOpen, toggle } = useContext(ModalContext);

    useEffect(() => {
        if (ref.current && !preventAutoFocus) ref.current?.focus();
    }, [preventAutoFocus]);

    return (
        <Typography
            size="xl"
            weight="bold"
            className={clsx("lui-modal__header", {
                className,
            })}
        >
            {children}
            {!hideClose && isOpen && (
                <div className="lui-flex lui-h-full lui-items-center">
                    <Button ref={ref} variant="base" icon="Close" onClick={toggle} />
                </div>
            )}
        </Typography>
    );
};

Modal.Content = function ModalHeader({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return <div className={clsx("lui-modal__content", className)}>{children}</div>;
};

Modal.Footer = function ModalHeader({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return <div className={clsx("lui-modal__footer", className)}>{children}</div>;
};
