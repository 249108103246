import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { fetch, post, parseErrorResponse, formatNumber } from "functions";
import { Button } from "library";

export default function RealtorReportCreate() {
    const [counties, setCounties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [purchasing, setPurchasing] = useState(false);
    const [value, setValue] = useState();
    const navigate = useNavigate();

    const getOptionLabel = ({ county, state }) => `${county}, ${state}`;

    const filterOptions = (options, state) => {
        if (state.inputValue.length > 2) {
            return options.filter((item) =>
                String(state.getOptionLabel(item))
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase()),
            );
        }
        return [];
    };

    const purchase = async (duration) => {
        setPurchasing(true);
        try {
            const { county, state } = value;
            const data = await post("/analytics/realtor_report_claim/", {
                county,
                state,
                duration,
            });
            toast.success("Successfully unlocked Realtor Report");
            navigate(`/home/realtor_report/${state}/${county}`);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
        setPurchasing(false);
    };

    const purchaseEnabled = value && !purchasing;

    useEffect(() => {
        fetch("/analytics/realtor_report/counties/")
            .then((data) => {
                setLoading(false);
                setCounties(data || []);
            })
            .catch((xhr) => toast.error(parseErrorResponse(xhr)));
    }, []);

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>Unlock County Realtor Report</h5>
            </div>
            <div className="card-body">
                <div className="d-flex flex-column gap-2 w-md-75 w-lg-50 mx-auto text-center">
                    <p className="lead">Select County</p>
                    <Autocomplete
                        freeSolo
                        id="combo-box-demo"
                        className="mb-4"
                        options={counties}
                        loading={loading}
                        filterOptions={filterOptions}
                        getOptionLabel={getOptionLabel}
                        onChange={(e, newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Type to Search" />
                        )}
                    />
                    {value && (
                        <p className="mb-4">
                            There are{" "}
                            <strong>{formatNumber(value.agents)} realtors</strong> in
                            this report that have{" "}
                            <strong>sold {formatNumber(value.comps)} comps</strong> in{" "}
                            <strong>
                                {value.county}, {value.state}
                            </strong>
                            .
                        </p>
                    )}
                    {/*<Button
                        disabled={!purchaseEnabled}
                        className="btn bg-gradient-info btn-lg"
                        onClick={() => purchase('year')}
                    >
                        Unlock For 1 Year ($20)
                    </Button>*/}
                    <Button
                        disabled={!purchaseEnabled}
                        className="btn bg-gradient-success btn-lg"
                        onClick={() => purchase("forever")}
                    >
                        Unlock Report
                    </Button>
                    <p className="mt-3">Updates Once A Month!</p>
                </div>
            </div>
        </div>
    );
}
