import React, { Component } from "react";

import { prettify_string } from "functions";

const COMPARATOR_LOOKUP = {
    contains: "contains",
    notContains: "does NOT contain",
    equals: "is equal to",
    notEqual: "is NOT equal to",
    startsWith: "begins with",
    endsWith: "ends with",
    blank: "is blank", // NO filter
    notBlank: "is NOT blank", // NO filter

    greaterThan: ">",
    greaterThanOrEqual: ">=",
    lessThan: "<",
    lessThanOrEqual: "<=",
    inRange: "is between", // filter and filterTo

    asc: "Ascending",
    desc: "Descending",
};

function print_comparator_details(key, data) {
    if (["sorting"].includes(key)) {
        const items = [];
        let i = 0;
        for (let sort of data) {
            items.push(
                <div key={i++} style={{ lineHeight: "normal" }}>
                    <b>{`${prettify_string(sort["colId"])}`}</b>
                    {` sorted in ${COMPARATOR_LOOKUP[sort["sort"]]} order`}
                </div>,
            );
        }
        if (items.length === 0) {
            return null;
        }
        return <div>{items}</div>;
    } else if (["order", "map_filter_data"].includes(key)) {
        return null;
    } else if (["inRange"].includes(data["type"])) {
        return (
            <div style={{ lineHeight: "normal" }}>
                <b>{`${prettify_string(key)}`}</b>
                {` ${COMPARATOR_LOOKUP[data["type"]]} ${data["filter"]} and ${data["filterTo"]}`}
            </div>
        );
    } else if (["blank", "notBlank"].includes(data["type"])) {
        return (
            <div style={{ lineHeight: "normal" }}>
                <b>{`${prettify_string(key)}`}</b>
                {` ${COMPARATOR_LOOKUP[data["type"]]}`}
            </div>
        );
    }
    return (
        <div style={{ lineHeight: "normal" }}>
            <b>{`${prettify_string(key)}`}</b>
            {` ${COMPARATOR_LOOKUP[data["type"]]} ${data["filter"]}`}
        </div>
    );
}

export default class AGFilterRender extends Component {
    render() {
        let container_style = {
            color: "#545454",
            textAlign: "left",
            background: "#f6f6f6",
            border: "thin solid #dadada",
            borderRadius: "3px",
            padding: "1rem",
            fontSize: ".9rem",
        };

        let i = 0;
        let fields = [];
        for (let key in this.props.value) {
            let item = this.props.value[key];
            if (!item) {
                continue;
            }

            if ("conditions" in item) {
                let items = [];
                for (let condition of item["conditions"]) {
                    const el = print_comparator_details(key, condition);
                    if (el) {
                        items.push(<div key={i++}>{el}</div>);
                    }
                }
                if (items.length === 0) {
                    continue;
                }
                fields.push(
                    <div key={i++} style={{ lineHeight: "normal" }}>
                        {items}
                    </div>,
                );
            } else {
                const el = print_comparator_details(key, item);
                if (el) {
                    fields.push(<div key={i++}>{el}</div>);
                }
            }
        }

        return (
            <div style={container_style}>
                {fields.length === 0 ? "No filters applied" : fields}
            </div>
        );
    }
}
