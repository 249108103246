import { replace } from "lodash";

export function removeFileExtension(file: string) {
    return file.replace(/\.[^/.]+$/, "");
}

export function parseFileName(file: string) {
    return removeFileExtension(replace(file, /[_, -]/g, " "));
}

export function formatFileName(file: string, ext: string) {
    return replace(file, / /g, "_") + "." + ext;
}
