import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Widget } from "@typeform/embed-react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { Loading } from "library";
import { fetch, post, parseErrorResponse } from "functions";
import { withNavigate } from "hooks";
import { IS_DEV, TYPEFORM_ONBOARDING_FORM_ID } from "settings";

export default function RegisterOnboarding() {
    const [user, setUser] = useState();
    const [done, setDone] = useState(false);
    const navigate = useNavigate();

    // TypeForm Widget sets "overflow: hidden" on the body element when
    // fullScreen mode is enabled and it doesn't clean up after itself after
    // submitting the form.
    useEffect(() => {
        return () => {
            document.body.style.overflow = "";
        };
    }, []);

    // Load user on page load
    useEffect(() => {
        var timeoutID;

        async function fetchUser() {
            try {
                const user = await fetch("/user/user/");
                setUser(user);
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
            }
        }

        fetchUser();
    }, [setUser, navigate]);

    const onSubmit = async () => {
        try {
            await post("/user/user/", {
                onboarding_survey_completed: true,
            });
            setDone(true);
        } catch (xhr) {
            Swal.fire({
                title: "Error",
                text: parseErrorResponse(xhr),
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    if (!user) {
        return <Loading />;
    }

    if (done) {
        return (
            <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
                <div className="card">
                    <div className="card-header text-center pb-0">
                        <h5>Your Demo Call Has Been Scheduled</h5>
                    </div>
                    <div className="card-body">
                        <p>
                            We've successfully received your booking and sent over an
                            confirmation email. We are excited to show you the full
                            capabilities of Land Insights and how you can 2x your
                            marketing returns in your business.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Widget
            id={TYPEFORM_ONBOARDING_FORM_ID}
            fullScreen={true}
            autoFocus={true}
            onSubmit={onSubmit}
            enableSandbox={IS_DEV}
        />
    );
}
