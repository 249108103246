import { AllGeoJSON, bbox } from "@turf/turf";
import { useMap } from "react-map-gl";
import { useParcelsRetrieve } from "../orval/gen/api";
import { useQueryParam } from "./useQueryParam";

interface useParcelProps {
    parcelId: number;
    enabled?: boolean;
}
/**
 * Specific hook with utilities for parcels controls and navigation
 */
export function useParcel(props?: useParcelProps) {
    const { value, setValue, removeValue } = useQueryParam<number>("parcel", null);
    const {
        refetch,
        isLoading,
        data: parcel,
        error,
    } = useParcelsRetrieve(props?.parcelId, {
        query: {
            enabled: !!props?.enabled,
            queryKey: ["parcelsRetrieve", props?.parcelId],
        },
    });
    const mapRef = useMap();

    async function goToParcelInMap() {
        const parcelData = await refetch();
        setValue(props?.parcelId);

        if (!parcelData?.data?.shape) {
            return;
        }

        const bounds = bbox(parcelData.data.shape as unknown as AllGeoJSON);
        if (bounds) {
            mapRef.current.fitBounds(
                [
                    [bounds[0], bounds[1]],
                    [bounds[2], bounds[3]],
                ],
                {
                    padding: 200,
                    duration: 0,
                },
            );
        }
    }

    return {
        parcel,
        goToParcelInMap,
        removeParcelQuery: removeValue,
        setParcelQuery: setValue,
        parcelQueryId: value,
        isSelectedParcel: value === props?.parcelId,
        isLoading,
        error,
    };
}
