//https://id.land/ranching/maps/7a5f64287b7da8abbdbc0dfb1e6b70ee/embed
//https://api.id.land/mapright_layers/styles.json

const MAPRIGHT_STYLES = {
    hydro_4: {
        version: 8,
        name: 'Hydro Group 2 REAL',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [0, -1.1368683772161603e-13],
        zoom: 0.9277778487151565,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.2c410d08,mapright2.db3801eb,mapright2.d189982c,mapright2.b71e52da,mapright2.beb2e742,mapright2.8c8afc98,mapright2.96wcmn53,mapright2.d00c0c38,mapright2.1w6ww1oq,mapright2.85iv5tc9,mapright2.2994d5f1,mapright2.8ccb3437,mapright2.aqhtmmm8',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjab6yro933ko2rpayqqifndt',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'fl-ga-nc-sc-smallwb-071217',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'FL_GA_NC_SC_SmallWB_071217',
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'ok-small-waterbodies-20140804',
                paint: {
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                },
                'source-layer': 'OK_Small_Waterbodies_20140804',
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'tx-small-waterbodies-20140730',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'TX_Small_Waterbodies_20140730',
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'al-ar-la-ms-smallwb-071217',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'AL_AR_LA_MS_SmallWB_071217',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'fl-ga-nc-sc-medwb-071217',
                paint: {
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                },
                'source-layer': 'FL_GA_NC_SC_MedWB_071217',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'al-ar-la-ms-ok-tx-medwb-071217',
                paint: {
                    'fill-antialias': false,
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-opacity': 0.53,
                },
                'source-layer': 'AL_AR_LA_MS_OK_TX_MedWB_071217',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'national-large-waterbodies-20150302',
                paint: {
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                },
                'source-layer': 'National_Large_Waterbodies_20150302',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'fl-ga-nc-sc-streams',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'FL_GA_NC_SC_Streams_032917',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'al-ar-la-ms-streams',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'AL_AR_LA_MS_Streams_032517',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'tx-ok-streams',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'TX_OK_Streams_031517',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'al-fl-ga-ms-nc-sc-creeks-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2],
                            [12, 2.4],
                            [14, 3],
                            [16, 4],
                        ],
                    },
                },
                'source-layer': 'AL_FL_GA_MS_NC_SC_Creeks_072717',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'al-fl-ga-ms-nc-sc-creeks-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1],
                            [12, 1.2],
                            [14, 1.5],
                            [16, 2],
                        ],
                    },
                },
                'source-layer': 'AL_FL_GA_MS_NC_SC_Creeks_072717',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'ar-la-ok-tx-creeks-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2],
                            [12, 2.4],
                            [14, 3],
                            [16, 4],
                        ],
                    },
                },
                'source-layer': 'AR_LA_OK_TX_Creeks_072717',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'ar-la-ok-tx-creeks-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1],
                            [12, 1.2],
                            [14, 1.5],
                            [16, 2],
                        ],
                    },
                },
                'source-layer': 'AR_LA_OK_TX_Creeks_072717',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'national-rivers-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [8, 1.5],
                            [10, 3],
                            [12, 3.6],
                            [14, 4.4],
                            [16, 5],
                        ],
                    },
                },
                'source-layer': 'National_Rivers_20150302',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'national-rivers-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [8, 0.75],
                            [10, 1.5],
                            [12, 1.8],
                            [14, 2.2],
                            [16, 2.5],
                        ],
                    },
                },
                'source-layer': 'National_Rivers_20150302',
            },
            {
                minzoom: 11,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [11, 200],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': 0.1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'al-fl-ga-ms-nc-sc-creeks-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,56,67,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'AL_FL_GA_MS_NC_SC_Creeks_072717',
            },
            {
                minzoom: 11,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [11, 200],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': 0.1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'ar-la-ok-tx-creeks-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,55,66,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'AR_LA_OK_TX_Creeks_072717',
            },
            {
                minzoom: 9,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [9, 150],
                            [11, 200],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': {
                        base: 1,
                        stops: [
                            [0, 0],
                            [10, 0.1],
                        ],
                    },
                },
                type: 'symbol',
                source: 'composite',
                id: 'national-rivers-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,55,66,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'National_Rivers_20150302',
            },
        ],
        created: '2017-11-22T15:16:09.066Z',
        modified: '2017-12-12T18:33:41.591Z',
        id: 'cjab6yro933ko2rpayqqifndt',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    abstracts: {
        version: 8,
        name: 'PLSS_ABS_REAL_031721-WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.40.0',
                android: '5.2.0',
                ios: '3.7.0',
            },
            'mapbox:groups': {},
        },
        center: [-102.97309851680944, 33.63773740908597],
        zoom: 11.083048051595544,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.ebeaab11,mapright2.9c3143b6,mapright2.daca7649,mapright2.e061bea8,mapright2.ec3cc6ab,mapright2.7d05dc3f,mapright2.d8e09c86,mapright2.6rmzil74,mapright2.b3eec0c1,mapright2.c4145560,mapright2.04b18116,mapright2.ce6843c1,mapright2.d91475fe,mapright2.6aad4903',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cizhese5q000l2roc7t8ww52f/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: 'az-co-id-ut-wy-plss-poly-020121',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'az_co_id_ut_wy_plss_poly_020121',
                minzoom: 10,
            },
            {
                id: 'ca-or-nv-wa-plss-poly-020121',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                source: 'composite',
                'source-layer': 'ca_or_nv_wa_plss_poly_020121',
                minzoom: 10,
            },
            {
                id: 'al-fl-la-ms-plss-poly-020121',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'al_fl_la_ms_plss_poly_020121',
                minzoom: 10,
            },
            {
                id: 'il-in-mn-oh-wi-plss-poly-020221',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {
                    visibility: 'none',
                },
                source: 'composite',
                'source-layer': 'il_in_mn_oh_wi_plss_poly_020221',
                minzoom: 10,
            },
            {
                id: 'il-in-mn-oh-wi-plss-poly-062022',
                type: 'line',
                paint: {
                    'line-color': 'hsl(300, 60%, 1%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Merge_PLSS',
                minzoom: 11,
            },
            {
                id: 'ia-mt-nd-ne-sd-plss-poly-012721',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'ia_mt_nd_ne_sd_plss_poly_012721',
                minzoom: 11,
            },
            {
                id: 'ar-ks-mo-nm-ok-plss-poly-012721',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'ar_ks_mo_nm_ok_plss_poly_012721',
                minzoom: 11,
            },
            {
                id: 'mi-plss-webaux',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'MI_PLSS_Webaux',
                minzoom: 11,
            },
            {
                id: 'otls-polygons-webaux copy',
                paint: {
                    'line-color': 'hsl(300, 60%, 1%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'OTLS_Polygons_Webaux',
                minzoom: 11,
            },
            {
                id: 'az-co-id-ut-wy-plss-poly-020121 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'az_co_id_ut_wy_plss_poly_020121',
            },
            {
                id: 'ca-or-nv-wa-plss-poly-020121 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                source: 'composite',
                'source-layer': 'ca_or_nv_wa_plss_poly_020121',
                minzoom: 10,
            },
            {
                id: 'al-fl-la-ms-plss-poly-020121 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'al_fl_la_ms_plss_poly_020121',
                minzoom: 10,
            },
            {
                id: 'il-in-mn-oh-wi-plss-poly-020221 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                layout: {
                    visibility: 'none',
                },
                source: 'composite',
                'source-layer': 'il_in_mn_oh_wi_plss_poly_020221',
            },
            {
                id: 'il-in-mn-oh-wi-plss-poly-062022 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(287, 100%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Merge_PLSS',
            },
            {
                id: 'ia-mt-nd-ne-sd-plss-poly-012721 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'ia_mt_nd_ne_sd_plss_poly_012721',
            },
            {
                id: 'ar-ks-mo-nm-ok-plss-poly-012721 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'ar_ks_mo_nm_ok_plss_poly_012721',
                minzoom: 11,
            },
            {
                id: 'mi-plss-webaux copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                source: 'composite',
                'source-layer': 'MI_PLSS_Webaux',
                minzoom: 10,
            },
            {
                id: 'otls-polygons-webaux',
                paint: {
                    'line-color': 'hsl(287, 100%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'OTLS_Polygons_Webaux',
                minzoom: 11,
            },
            {
                minzoom: 12,
                layout: {
                    'text-font': [
                        'Marselis Slab Offc Pro Bold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        12,
                        13,
                        15,
                        16,
                        19,
                    ],
                    'text-field': [
                        'concat',
                        ['get', 'ABSTRACT_L'],
                        '\n',
                        ['get', 'LEVEL3_SUR'],
                    ],
                },
                type: 'symbol',
                source: 'composite',
                id: 'otls-labels-20221004-2tsg9d',
                paint: {
                    'text-color': 'rgba(252,237,253,1)',
                    'text-halo-color': 'rgba(4,1,4,1)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.4,
                        13,
                        1.6,
                        16,
                        1.8,
                    ],
                    'text-halo-blur': 1,
                },
                'source-layer': 'OTLS_Labels_20221004-2tsg9d',
            },
            {
                id: 'az-ca-id-mt-nv-or-ut-wa-wy-centroids-030921',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(296, 80%, 96%)',
                    'text-halo-color': 'hsl(300, 60%, 1%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.4,
                        13,
                        1.6,
                        16,
                        1.8,
                    ],
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'TRS']],
                    'text-font': [
                        'Marselis Slab Offc Pro Bold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        12,
                        13,
                        15,
                        16,
                        19,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        4,
                        13,
                        10,
                    ],
                },
                source: 'composite',
                'source-layer': 'AZ_CA_ID_MT_NV_OR_UT_WA_WY_Centroids_030921',
            },
            {
                id: 'al-ar-co-fl-ks-la-mo-ms-nm-ok-plss-centroids-031521',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(296, 80%, 96%)',
                    'text-halo-color': 'hsl(300, 60%, 1%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.4,
                        13,
                        1.6,
                        16,
                        1.8,
                    ],
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'TRS']],
                    'text-font': [
                        'Marselis Slab Offc Pro Bold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        12,
                        13,
                        15,
                        16,
                        19,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        4,
                        13,
                        10,
                    ],
                },
                source: 'composite',
                'source-layer':
                    'al_ar_co_fl_ks_la_mo_ms_nm_ok_plss_centroids_031521',
                minzoom: 12,
            },
            {
                id: 'ia-il-in-mi-mn-nd-ne-oh-sd-wi-plss-centroids-031521',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(296, 80%, 96%)',
                    'text-halo-color': 'hsl(300, 60%, 1%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.4,
                        13,
                        1.6,
                        16,
                        1.8,
                    ],
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'TRS']],
                    'text-font': [
                        'Marselis Slab Offc Pro Bold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        12,
                        13,
                        15,
                        16,
                        19,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        4,
                        13,
                        10,
                    ],
                    visibility: 'none',
                },
                source: 'composite',
                'source-layer':
                    'ia_il_in_mi_mn_nd_ne_oh_sd_wi_plss_centroids_031521',
            },
            {
                id: 'il-update-mergecent',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(296, 80%, 96%)',
                    'text-halo-color': 'hsl(300, 60%, 1%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.4,
                        13,
                        1.6,
                        16,
                        1.8,
                    ],
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'Label_Pt']],
                    'text-font': [
                        'Marselis Slab Offc Pro Bold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        12,
                        13,
                        15,
                        16,
                        19,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        4,
                        13,
                        10,
                    ],
                    'text-transform': 'uppercase',
                },
                source: 'composite',
                'source-layer': 'IL_Update_MergeCent',
            },
        ],
        created: '2017-02-22T20:24:59.894Z',
        modified: '2023-06-15T22:10:18.870Z',
        id: 'cizhese5q000l2roc7t8ww52f',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    state_lands: {
        version: 8,
        name: 'Public Lands V2',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.0.0',
                ios: '10.0.0',
                js: '2.3.0',
            },
            'mapbox:groups': {
                '37a8f620e5093da6aef33670992c7b4c': {
                    name: 'Hawaii Group',
                    collapsed: true,
                },
                '2aacc0ddd5906c73a722934b4e3e7cf1': {
                    name: 'West 1B Group',
                    collapsed: true,
                },
                '8bc6529b5c607cd963c54a1efd18f8c7': {
                    name: 'Alaska 2 Group',
                    collapsed: true,
                },
                '78a5dd599c516206e085feb583540b35': {
                    name: 'East 2 Group',
                    collapsed: true,
                },
                '59f9c5a7ce8ce0ea5ef2ef50fa63aaf2': {
                    name: 'West 1A Group',
                    collapsed: true,
                },
                '31ee475f16c98f7ceb508c1f6d1f43e8': {
                    name: 'East 1 Group',
                    collapsed: true,
                },
                '9d8a38dc8924c1a27bd5105e536a6708': {
                    name: 'West 2A Group',
                    collapsed: true,
                },
                '6e1226bc35db1d5946fe0b67a76e0faa': {
                    name: 'West 1B Group',
                    collapsed: true,
                },
                '1e1a37f52cf22cd3afa92449bdfd8a51': {
                    name: 'West 2B Group',
                    collapsed: false,
                },
                e41d4cf1e02f92402a2033403232d293: {
                    name: 'Alaska 1 Group',
                    collapsed: true,
                },
            },
        },
        center: [-94.6812055034892, 34.29153227070253],
        zoom: 12.854810830688944,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.c4d17ec5,mapright2.1f43248f,mapright2.6a28131b,mapright2.8c586b6a,mapright2.f1b95fd7,mapright2.087a39a8,mapright2.93b4b41b,mapright2.f8a0efd5,mapright2.1a67be44,mapright2.8dbf2e2d,mapright2.eaa88d80,mapright2.e3794c91,mapright2.e40f38e3',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cktbupaxh0ybv17ptkrwwbjjr/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'blm-continent-092221',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(53, 100%, 59%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'BLM_Continent_092221',
            },
            {
                id: 'blm-ak-092221',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(53, 100%, 59%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.4,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'BLM_AK_092221',
            },
            {
                id: 'west-1a-Other',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(21, 100%, 61%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1a_083121',
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    [
                        'match',
                        ['id'],
                        [
                            375, 433, 420, 425, 386, 391, 418, 389, 370, 379,
                            372, 438, 437, 374, 394, 413, 409, 373, 404, 421,
                            423, 388, 387, 428,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '59f9c5a7ce8ce0ea5ef2ef50fa63aaf2',
                },
            },
            {
                id: 'west-1a-Fish and Wildlife',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(304, 100%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1a_083121',
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Fish and Wildlife'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            107, 184, 182, 187, 75, 80, 181, 158, 169, 51, 124,
                            123, 145, 49, 44, 122, 167, 162, 60, 133, 104, 89,
                            88, 173, 77, 52, 178, 177, 191, 180, 179, 190, 65,
                            161, 64, 164, 117, 165, 63, 114, 78, 76, 95, 61,
                            163, 121, 185, 157, 111, 108, 45, 156, 47, 86, 92,
                            152, 139, 113, 159, 67, 142, 183,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '59f9c5a7ce8ce0ea5ef2ef50fa63aaf2',
                },
            },
            {
                id: 'west-1a-Forest Service',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(120, 97%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1a_083121',
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Forest Service'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            219, 212, 248, 249, 264, 216, 267, 234, 222, 250,
                            261, 233, 239, 240, 211, 226, 224, 227, 209, 225,
                            231, 228, 251, 207, 236, 200, 243, 259, 263, 256,
                            257, 232, 203, 204, 245, 201, 197, 215, 223, 229,
                            217, 254, 199, 205, 241, 202, 273, 213, 269, 265,
                            271, 206, 253,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '59f9c5a7ce8ce0ea5ef2ef50fa63aaf2',
                },
            },
            {
                id: 'west-1a-National Park',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(273, 80%, 46%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1a_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '59f9c5a7ce8ce0ea5ef2ef50fa63aaf2',
                },
            },
            {
                id: 'west-1a-State',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(196, 100%, 73%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1a_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['State Land'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '59f9c5a7ce8ce0ea5ef2ef50fa63aaf2',
                },
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59f9c5a7ce8ce0ea5ef2ef50fa63aaf2',
                },
                type: 'fill',
                source: 'composite',
                id: 'mn-taxforfeitedlands-121819',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.4,
                    'fill-color': 'hsl(196, 100%, 73%)',
                },
                'source-layer': 'mn_taxforfeitedlands_121819',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59f9c5a7ce8ce0ea5ef2ef50fa63aaf2',
                },
                type: 'fill',
                source: 'composite',
                id: 'mn-dnrforestry-121819',
                paint: {
                    'fill-color': 'hsl(0, 90%, 47%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'mn_dnrforestry_121819',
            },
            {
                id: 'west-1a-Local',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 90%, 47%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1a_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Local Government'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '59f9c5a7ce8ce0ea5ef2ef50fa63aaf2',
                },
            },
            {
                id: 'west-2b-Other',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(21, 100%, 61%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2b_083121',
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    [
                        'match',
                        ['id'],
                        [
                            568, 567, 569, 571, 575, 582, 574, 572, 583, 472,
                            570, 576, 474, 585, 581, 473, 578, 580, 584, 579,
                            573, 586, 577, 18498, 486, 536, 601,
                        ],
                        false,
                        true,
                    ],
                    ['match', ['get', 'Mang_Name'], ['TRIB'], false, true],
                ],
                metadata: {
                    'mapbox:group': '1e1a37f52cf22cd3afa92449bdfd8a51',
                },
            },
            {
                id: 'west-2b-Fish and Wildlife',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(304, 100%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2b_083121',
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Fish and Wildlife'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            125, 127, 70, 89, 33, 90, 114, 78, 6, 80, 110, 66,
                            92, 34, 32, 11, 46, 45, 48, 44, 108, 42, 116, 43,
                            22, 85, 121, 60, 87, 58, 76, 81, 109, 36, 12, 107,
                            67, 50, 9, 35, 14, 75, 13, 24, 25, 16, 27, 15, 7,
                            123, 100, 10, 83, 38, 74, 118, 17, 59, 68, 41, 20,
                            94, 91,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '1e1a37f52cf22cd3afa92449bdfd8a51',
                },
            },
            {
                id: 'west-2b-Forest Service',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(120, 97%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2b_083121',
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Forest Service'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            144, 135, 154, 163, 161, 130, 131, 141, 133, 136,
                            137, 159, 168, 174, 173, 146, 150, 152, 151, 132,
                            139, 143, 142, 145, 156, 129, 138, 157, 167, 170,
                            148, 128, 158, 134, 160,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '1e1a37f52cf22cd3afa92449bdfd8a51',
                },
            },
            {
                id: 'west-2b-National Park',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(273, 80%, 46%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2b_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '1e1a37f52cf22cd3afa92449bdfd8a51',
                },
            },
            {
                id: 'west-2b-State',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(196, 100%, 73%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2b_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['State Land'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '1e1a37f52cf22cd3afa92449bdfd8a51',
                },
            },
            {
                id: 'west-2b-Local',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 90%, 47%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2b_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Local Government'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '1e1a37f52cf22cd3afa92449bdfd8a51',
                },
            },
            {
                id: 'west-2a-Other',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(21, 100%, 61%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2a_083121',
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    [
                        'match',
                        ['id'],
                        [
                            359, 330, 360, 363, 362, 361, 260, 434, 442, 321,
                            334, 287, 286, 274, 275, 295, 278, 280, 296, 279,
                            297, 341, 276, 320,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '9d8a38dc8924c1a27bd5105e536a6708',
                },
            },
            {
                id: 'west-2a-Fish and Wildlife',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(304, 100%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2a_083121',
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Fish and Wildlife'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            66, 74, 40, 71, 68, 23, 35, 20, 26, 60, 61, 43, 27,
                            62, 42, 64, 59, 25, 65, 24, 75, 36,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '9d8a38dc8924c1a27bd5105e536a6708',
                },
            },
            {
                id: 'west-2a-Forest Service',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(120, 97%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2a_083121',
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Forest Service'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            115, 113, 77, 116, 91, 84, 81, 120, 119, 95, 103,
                            86, 117, 97, 104, 114, 79, 90, 85, 111, 87, 106,
                            107, 110, 82, 108, 96, 89, 112, 94, 101, 76, 99,
                            122, 92, 105,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '9d8a38dc8924c1a27bd5105e536a6708',
                },
            },
            {
                id: 'west-2a-National Park',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(273, 80%, 46%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2a_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '9d8a38dc8924c1a27bd5105e536a6708',
                },
            },
            {
                id: 'west-2a-State',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(196, 100%, 73%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2a_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['State Land'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '9d8a38dc8924c1a27bd5105e536a6708',
                },
            },
            {
                id: 'west-2a-Local',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 90%, 47%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2a_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Local Government'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '9d8a38dc8924c1a27bd5105e536a6708',
                },
            },
            {
                id: 'west-1b-Other',
                type: 'fill',
                paint: {
                    'fill-color': '#ff7e38',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1b_083121',
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    [
                        'match',
                        ['id'],
                        [
                            212, 200, 211, 199, 290, 191, 202, 194, 214, 198,
                            203, 204,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '6e1226bc35db1d5946fe0b67a76e0faa',
                },
            },
            {
                id: 'west-1b-Fish and Wildlife',
                type: 'fill',
                paint: {
                    'fill-color': '#ff61f4',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1b_083121',
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Fish and Wildlife'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            31, 23, 38, 75, 21, 33, 24, 49, 36, 27, 35, 62, 71,
                            73, 74, 66, 72, 65, 67, 64, 44, 37, 68,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '6e1226bc35db1d5946fe0b67a76e0faa',
                },
            },
            {
                id: 'west-1b-Forest Service',
                type: 'fill',
                paint: {
                    'fill-color': '#63fd63',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1b_083121',
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Forest Service'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            110, 80, 83, 109, 85, 81, 82, 89, 107, 91, 104, 106,
                            105, 99, 98, 92, 112, 116, 94, 87, 346, 111, 352,
                            336, 341, 101, 93, 77, 118, 119, 96, 78, 114, 100,
                            84,
                        ],
                        false,
                        true,
                    ],
                ],
                metadata: {
                    'mapbox:group': '6e1226bc35db1d5946fe0b67a76e0faa',
                },
            },
            {
                id: 'west-1b-National Park',
                type: 'fill',
                paint: {
                    'fill-color': '#7f17d3',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1b_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '6e1226bc35db1d5946fe0b67a76e0faa',
                },
            },
            {
                id: 'west-1b-State',
                type: 'fill',
                paint: {
                    'fill-color': '#75daff',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1b_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['State Land'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '6e1226bc35db1d5946fe0b67a76e0faa',
                },
            },
            {
                id: 'west-1b-Local',
                type: 'fill',
                paint: {
                    'fill-color': '#e40c0c',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1b_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Local Government'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '6e1226bc35db1d5946fe0b67a76e0faa',
                },
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '31ee475f16c98f7ceb508c1f6d1f43e8',
                },
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    ['match', ['id'], [229, 234, 242, 236], false, true],
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-1-Other',
                paint: {
                    'fill-color': 'hsl(21, 100%, 61%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_1_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '31ee475f16c98f7ceb508c1f6d1f43e8',
                },
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Fish and Wildlife'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            79, 80, 77, 78, 73, 72, 81, 76, 26, 64, 65, 63, 83,
                            40, 70, 66, 85, 86, 2, 88, 89, 23, 15, 60, 41, 42,
                            49, 22, 69, 68, 59, 58, 34, 84, 37, 17, 55, 27, 19,
                        ],
                        false,
                        true,
                    ],
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-1-Fish and Wildlife',
                paint: {
                    'fill-color': 'hsl(304, 100%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_1_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '31ee475f16c98f7ceb508c1f6d1f43e8',
                },
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Forest Service'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [100, 96, 98, 97, 91, 92, 95, 102, 101],
                        false,
                        true,
                    ],
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-1-Forest Service',
                paint: {
                    'fill-color': 'hsl(120, 97%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_1_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '31ee475f16c98f7ceb508c1f6d1f43e8',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-1-National Park',
                paint: {
                    'fill-color': 'hsl(273, 80%, 46%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_1_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '31ee475f16c98f7ceb508c1f6d1f43e8',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['State Land'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-1-State',
                paint: {
                    'fill-color': 'hsl(196, 100%, 73%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_1_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '31ee475f16c98f7ceb508c1f6d1f43e8',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Local Government'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-1-Local',
                paint: {
                    'fill-color': 'hsl(0, 90%, 47%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_1_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '78a5dd599c516206e085feb583540b35',
                },
                filter: ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'east-2-Other',
                paint: {
                    'fill-color': 'hsl(21, 100%, 61%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_2_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '78a5dd599c516206e085feb583540b35',
                },
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Fish and Wildlife'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            111, 110, 88, 42, 137, 43, 86, 79, 69, 146, 84, 75,
                            38921, 103, 83, 102, 118, 136, 87, 16, 157, 26, 153,
                            134, 120, 133, 63, 131, 39, 149, 41, 144, 124, 96,
                            19, 116, 129, 73, 72, 89, 54, 23, 101, 123, 68, 58,
                            166, 91, 92, 46, 27, 159, 45, 126, 29, 37, 38, 36,
                            59, 142, 60, 81, 74, 44, 150, 112, 155, 139, 151,
                            117, 48, 52, 127, 85, 128, 77, 147, 99, 33,
                        ],
                        false,
                        true,
                    ],
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-2-Fish and Wildlife',
                paint: {
                    'fill-color': 'hsl(304, 100%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_2_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '78a5dd599c516206e085feb583540b35',
                },
                filter: [
                    'all',
                    [
                        'match',
                        ['get', 'Mang_Type'],
                        ['Forest Service'],
                        true,
                        false,
                    ],
                    [
                        'match',
                        ['id'],
                        [
                            179, 189, 187, 190, 174, 183, 191, 184, 193, 201,
                            195, 168, 207, 208, 211, 176, 167, 200, 182, 199,
                            212, 185, 213, 181, 210,
                        ],
                        false,
                        true,
                    ],
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-2-Forest Service',
                paint: {
                    'fill-color': 'hsl(120, 97%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_2_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '78a5dd599c516206e085feb583540b35',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-2-National Park',
                paint: {
                    'fill-color': 'hsl(273, 80%, 46%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_2_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '78a5dd599c516206e085feb583540b35',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['State Land'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-2-State',
                paint: {
                    'fill-color': 'hsl(196, 100%, 73%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_2_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '78a5dd599c516206e085feb583540b35',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Local Government'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'east-2-Local',
                paint: {
                    'fill-color': 'hsl(0, 90%, 47%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_East_2_083121',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '37a8f620e5093da6aef33670992c7b4c',
                },
                filter: ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'hi-Other',
                paint: {
                    'fill-color': 'hsl(21, 100%, 61%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_HI_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '37a8f620e5093da6aef33670992c7b4c',
                },
                filter: ['match', ['get', 'Mang_Type'], ['BLM'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'hi-BLM',
                paint: {
                    'fill-color': 'hsl(53, 100%, 59%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_HI_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '37a8f620e5093da6aef33670992c7b4c',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Fish and Wildlife'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'hi-Fish and Wildlife',
                paint: {
                    'fill-color': 'hsl(304, 100%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_HI_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '37a8f620e5093da6aef33670992c7b4c',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Forest Service'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'hi-Forest Service',
                paint: {
                    'fill-color': 'hsl(120, 97%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_HI_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '37a8f620e5093da6aef33670992c7b4c',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'hi-National Park',
                paint: {
                    'fill-color': 'hsl(273, 80%, 46%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_HI_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '37a8f620e5093da6aef33670992c7b4c',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['State Land'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'hi-State',
                paint: {
                    'fill-color': 'hsl(196, 100%, 73%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_HI_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '37a8f620e5093da6aef33670992c7b4c',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Local Government'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'hi-Local',
                paint: {
                    'fill-color': 'hsl(0, 90%, 47%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_HI_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e41d4cf1e02f92402a2033403232d293',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Fish and Wildlife'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ak-1-Fish and Wildlife',
                paint: {
                    'fill-color': 'hsl(304, 100%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_AK_1_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e41d4cf1e02f92402a2033403232d293',
                },
                filter: ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ak-1-Other',
                paint: {
                    'fill-color': 'hsl(21, 100%, 61%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_AK_1_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8bc6529b5c607cd963c54a1efd18f8c7',
                },
                filter: ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ak-2-Other',
                paint: {
                    'fill-color': 'hsl(21, 100%, 61%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_AK_2_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8bc6529b5c607cd963c54a1efd18f8c7',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Fish and Wildlife'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ak-2-Fish and Wildlife',
                paint: {
                    'fill-color': 'hsl(304, 100%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_AK_2_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8bc6529b5c607cd963c54a1efd18f8c7',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Forest Service'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ak-2-Forest Service',
                paint: {
                    'fill-color': 'hsl(120, 97%, 69%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_AK_2_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8bc6529b5c607cd963c54a1efd18f8c7',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ak-2-National Park',
                paint: {
                    'fill-color': 'hsl(273, 80%, 46%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_AK_2_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8bc6529b5c607cd963c54a1efd18f8c7',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['State Land'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ak-2-State',
                paint: {
                    'fill-color': 'hsl(196, 100%, 73%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_AK_2_090821',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8bc6529b5c607cd963c54a1efd18f8c7',
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['Local Government'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ak-2-Local',
                paint: {
                    'fill-color': 'hsl(0, 90%, 47%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                'source-layer': 'PADUS_2_1_AK_2_090821',
            },
            {
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    ['match', ['get', 'Mang_Name'], ['TRIB'], false, true],
                ],
                type: 'symbol',
                source: 'composite',
                id: 'ak-2-Other Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(36, 81%, 21%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PADUS_2_1_AK_2_090821',
            },
            {
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                type: 'symbol',
                source: 'composite',
                id: 'ak-2-NP Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(273, 80%, 46%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PADUS_2_1_AK_2_090821',
            },
            {
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    ['match', ['get', 'Mang_Name'], ['TRIB'], false, true],
                ],
                type: 'symbol',
                source: 'composite',
                id: 'ak-1-Other Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(36, 81%, 21%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PADUS_2_1_AK_1_090821',
            },
            {
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    ['match', ['get', 'Mang_Name'], ['TRIB'], false, true],
                ],
                type: 'symbol',
                source: 'composite',
                id: 'hi-Other Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(36, 81%, 21%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PADUS_2_1_HI_090821',
            },
            {
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                type: 'symbol',
                source: 'composite',
                id: 'hi-NP Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(273, 80%, 46%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PADUS_2_1_HI_090821',
            },
            {
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    ['match', ['get', 'Mang_Name'], ['TRIB'], false, true],
                ],
                type: 'symbol',
                source: 'composite',
                id: 'east-2-Other Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(36, 81%, 21%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PADUS_2_1_East_2_083121',
            },
            {
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                type: 'symbol',
                source: 'composite',
                id: 'east-2-NP Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(273, 80%, 46%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PADUS_2_1_East_2_083121',
            },
            {
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    ['match', ['get', 'Mang_Name'], ['TRIB'], false, true],
                ],
                type: 'symbol',
                source: 'composite',
                id: 'east-1-Other Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(36, 81%, 21%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PADUS_2_1_East_1_083121',
            },
            {
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                type: 'symbol',
                source: 'composite',
                id: 'east-1-NP Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(273, 80%, 46%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PADUS_2_1_East_1_083121',
            },
            {
                id: 'west-1a-Other Labels',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(36, 81%, 21%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1a_083121',
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    ['match', ['get', 'Mang_Name'], ['TRIB'], false, true],
                ],
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
            },
            {
                id: 'west-1a-NP Labels',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(273, 80%, 46%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1a_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
            },
            {
                id: 'west-2b-Other copy',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(36, 81%, 21%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2b_083121',
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    ['match', ['id'], [601, 570], false, true],
                    ['match', ['get', 'Mang_Name'], ['TRIB'], false, true],
                ],
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
            },
            {
                id: 'west-2b-NP Labels',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(273, 80%, 46%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2b_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
            },
            {
                id: 'west-2a-Other Labels',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(36, 81%, 21%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2a_083121',
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    ['match', ['get', 'Mang_Name'], ['TRIB'], false, true],
                ],
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
            },
            {
                id: 'west-2a-NP Labels',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(273, 80%, 46%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_2a_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
            },
            {
                id: 'west-1b-Other Labels',
                type: 'symbol',
                paint: {
                    'text-color': '#ffffff',
                    'text-halo-color': '#613e0a',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1b_083121',
                filter: [
                    'all',
                    ['match', ['get', 'Mang_Type'], ['Other'], true, false],
                    ['match', ['get', 'Mang_Name'], ['TRIB'], false, true],
                ],
            },
            {
                id: 'west-1b-NP Labels',
                type: 'symbol',
                paint: {
                    'text-color': '#ffffff',
                    'text-halo-color': '#7f17d3',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.75,
                        12,
                        2,
                        14,
                        2.5,
                    ],
                    'text-halo-blur': 1.5,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'Unit_Nm']],
                    'text-font': ['Heebo Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        10,
                        12,
                        12,
                        14,
                        14,
                    ],
                    'text-max-width': 6,
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        100,
                        11,
                        150,
                        12,
                        200,
                        14,
                        300,
                    ],
                },
                source: 'composite',
                'source-layer': 'PADUS_2_1_West_1b_083121',
                filter: [
                    'match',
                    ['get', 'Mang_Type'],
                    ['National Park'],
                    true,
                    false,
                ],
            },
        ],
        created: '2021-09-08T18:44:26.656Z',
        modified: '2022-08-31T22:24:44.923Z',
        id: 'cktbupaxh0ybv17ptkrwwbjjr',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    county_lines: {
        version: 8,
        name: 'County Line REAL 012317',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [-93.48941890699182, 30.26035097624394],
        zoom: 8,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.d1d8cb46,mapright2.650bab7c,mapright2.8eb86f66',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ciyaofe0e004c2snplg8jek7s',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 8,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'counties-2017',
                paint: {
                    'line-color': 'hsl(191, 38%, 79%)',
                    'line-width': 1.25,
                },
                'source-layer': 'Counties_2017',
            },
            {
                minzoom: 8,
                layout: {
                    visibility: 'visible',
                    'text-field': '{NAME} parish',
                    'text-font': [
                        'Clan Offc Pro Extd Medium',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [8, 9.75],
                            [14, 13.75],
                        ],
                    },
                    'text-letter-spacing': 0,
                    'text-transform': 'uppercase',
                    'text-max-width': 6,
                },
                type: 'symbol',
                source: 'composite',
                id: 'parish-2017-labels',
                paint: {
                    'text-color': 'hsl(191, 38%, 79%)',
                    'text-halo-color': 'hsl(0, 0%, 0%)',
                    'text-halo-width': {
                        base: 1,
                        stops: [
                            [8, 1],
                            [13, 1.55],
                        ],
                    },
                },
                'source-layer': 'Parish_2017_Labels',
            },
            {
                minzoom: 8,
                layout: {
                    visibility: 'visible',
                    'text-field': '{NAME} county',
                    'text-transform': 'uppercase',
                    'text-max-width': 6,
                    'text-font': [
                        'Clan Offc Pro Extd Medium',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [8, 9.75],
                            [14, 13.75],
                        ],
                    },
                },
                type: 'symbol',
                source: 'composite',
                id: 'counties-2017-labels',
                paint: {
                    'text-color': 'hsl(191, 38%, 79%)',
                    'text-halo-color': 'hsl(0, 0%, 0%)',
                    'text-halo-width': {
                        base: 1,
                        stops: [
                            [8, 1],
                            [13, 1.55],
                        ],
                    },
                },
                'source-layer': 'Counties_2017_Labels',
            },
        ],
        created: '2017-01-23T22:40:43.785Z',
        modified: '2017-01-23T23:02:30.352Z',
        id: 'ciyaofe0e004c2snplg8jek7s',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_1: {
        version: 8,
        name: 'KS-OK-TX-Contours-REAL',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:groups': {
                f02b48c87cc073dcca12c1adc08eca15: {
                    name: 'TX Contours',
                    collapsed: true,
                },
                '7984de3735daee7374d094b41a6c2e84': {
                    name: 'OK Group',
                    collapsed: true,
                },
                '47d35d2eb4e6174f12ce9f99eebc588c': {
                    name: 'KS Group',
                    collapsed: true,
                },
            },
            'mapbox:sdk-support': {
                js: '0.40.0',
                android: '5.2.0',
                ios: '3.7.0',
            },
        },
        center: [-96.11392962587405, 36.169440935285905],
        zoom: 13.831996068630879,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.a4f026c0,mapright2.0e188d1e,mapright2.dd985cbb,mapright2.fd6871e8,mapright2.ff52caf6,mapright2.24b228bf,mapright2.6501080d,mapright2.cfde56a8,mapright2.9070d901,mapright2.29fb8f3d,mapright2.b123f936,mapright2.10398f7c,mapright2.f0cdf35b,mapright2.831624c5',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cj1cg8c2a005d2slj3pj3trby',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                type: 'line',
                source: 'composite',
                id: 'tx-contours-pt5-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'TX_stratcontours_pt5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                type: 'line',
                source: 'composite',
                id: 'tx-contours-pt4-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'TX_stratcontours_pt4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                type: 'line',
                source: 'composite',
                id: 'tx-contours-pt3-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'TX_stratcontours_pt3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                type: 'line',
                source: 'composite',
                id: 'tx-contours-pt2-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'TX_stratcontours_pt2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                type: 'line',
                source: 'composite',
                id: 'tx-contours-pt1-black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'TX_stratcontours_pt1_webaux',
            },
            {
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                id: 'tx-contours-pt5-yellow',
                paint: {
                    'line-color': '#f7b708',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'TX_stratcontours_pt5_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                id: 'tx-contours-pt4-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'TX_stratcontours_pt4_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                id: 'tx-contours-pt3-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'TX_stratcontours_pt3_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                id: 'tx-contours-pt2-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'TX_stratcontours_pt2_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                id: 'tx-contours-pt1-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'TX_stratcontours_pt1_webaux',
                minzoom: 13,
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ELEVATION} FT',
                    'text-transform': 'uppercase',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                },
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                type: 'symbol',
                source: 'composite',
                id: 'tx-contours-pt5-label',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'TX_stratcontours_pt5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'symbol-placement': 'line',
                    'text-field': '{ELEVATION} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                type: 'symbol',
                source: 'composite',
                id: 'tx-contours-pt4-label',
                paint: {
                    'text-color': '#fce959',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'TX_stratcontours_pt4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ELEVATION} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                type: 'symbol',
                source: 'composite',
                id: 'tx-contours-pt2-label',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'TX_stratcontours_pt2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ELEVATION} FT',
                    'text-transform': 'uppercase',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                },
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                type: 'symbol',
                source: 'composite',
                id: 'tx-contours-pt1-label',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'TX_stratcontours_pt1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ELEVATION} FT',
                    'text-transform': 'uppercase',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': 'f02b48c87cc073dcca12c1adc08eca15',
                },
                type: 'symbol',
                source: 'composite',
                id: 'tx-contours-pt3-label',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'TX_stratcontours_pt3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'line',
                source: 'composite',
                id: 'ok-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'OK_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'line',
                source: 'composite',
                id: 'ok-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'OK_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ok-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OK_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'line',
                source: 'composite',
                id: 'ok-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'OK_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'line',
                source: 'composite',
                id: 'ok-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'OK_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ok-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OK_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'line',
                source: 'composite',
                id: 'ok-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'OK_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'line',
                source: 'composite',
                id: 'ok-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'OK_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ok-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OK_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'line',
                source: 'composite',
                id: 'ok-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'OK_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'line',
                source: 'composite',
                id: 'ok-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'OK_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '7984de3735daee7374d094b41a6c2e84',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ok-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OK_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'line',
                source: 'composite',
                id: 'ks-contours1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'KS_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'line',
                source: 'composite',
                id: 'ks-contours1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'KS_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ks-contours1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KS_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'line',
                source: 'composite',
                id: 'ks-contours2-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-blur': 1.5,
                },
                'source-layer': 'KS_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'line',
                source: 'composite',
                id: 'ks-contours2-yellow',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-blur': 0,
                },
                'source-layer': 'KS_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ks-contours2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KS_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'line',
                source: 'composite',
                id: 'ks-contours3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'KS_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'line',
                source: 'composite',
                id: 'ks-contours3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'KS_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ks-contours3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KS_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'line',
                source: 'composite',
                id: 'ks-contours4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'KS_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'line',
                source: 'composite',
                id: 'ks-contours4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'KS_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ks-contours4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KS_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'line',
                source: 'composite',
                id: 'ks-contours5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'KS_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'line',
                source: 'composite',
                id: 'ks-contours5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'KS_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '47d35d2eb4e6174f12ce9f99eebc588c',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ks-contours5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KS_Contours5_webaux',
            },
        ],
        created: '2017-04-10T18:25:57.096Z',
        modified: '2018-06-08T20:50:00.235Z',
        id: 'cj1cg8c2a005d2slj3pj3trby',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    fsa: {
        version: 8,
        name: 'FSA REAL',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:groups': {
                '7471a1b65cf4eb8a83961cd1d89a3acd': {
                    name: 'Group',
                    collapsed: false,
                },
            },
        },
        center: [-97.76662439453276, 30.275790711169037],
        zoom: 11.059885316477075,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.4bec9028,mapright2.be0f3cd3,mapright2.944b0bd4,mapright2.8a51991b,mapright2.f75cf5aa,mapright2.3fa97536,mapright2.c38a4d54,mapright2.c5b41f05,mapright2.4c188eaf,mapright2.0181d418,mapright2.5240f3e4,mapright2.a960f455,mapright2.198cfcb4,mapright2.4ca6add0,mapright2.e74aa183',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cj02wglh6001s2rnr9egiuh4t',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: 'mo-fsa-clu copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'MO_FSA_CLU',
                minzoom: 11,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'wi-mn-nd-fsa-merge copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'WI_MN_ND_FSA_Merge',
                minzoom: 11,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'il-in-ky-fsa-merge-022617 copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'IL_IN_KY_FSA_Merge_022617',
                minzoom: 11,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'ia-sd-mi-fsa-merge-022617 copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'IA_SD_MI_FSA_Merge_022617',
                minzoom: 11,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'tn-ga-ms-merge-022417 copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'TN_GA_MS_Merge_022417',
                minzoom: 10,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'sc-va-nc-fsa-merge-022517 copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'SC_VA_NC_FSA_Merge_022517',
                minzoom: 10,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'ri-nj-nh-ma-md-me-de-ct-fsa-merge-022517 copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'RI_NJ_NH_MA_MD_ME_DE_CT_FSA_Merge_022517',
                minzoom: 10,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'wv-oh-pa-ny-vt-fsa-merge-022517 copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'WV_OH_PA_NY_VT_FSA_Merge_022517',
                minzoom: 10,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'ca-az-or-wa-nv-id-fsa-merge-022717 copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'CA_AZ_OR_WA_NV_ID_FSA_Merge_022717',
                minzoom: 10,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'mt-ut-nm-co-wy-fsa-merge-022717 copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'MT_UT_NM_CO_WY_FSA_Merge_022717',
                minzoom: 10,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'ne-ks-ok-fsa-merge copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'NE_KS_OK_FSA_Merge',
                minzoom: 10,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                id: 'tx-la-ar-fsa-merge-012117 copy',
                paint: {
                    'fill-opacity': 0,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                layout: {},
                type: 'fill',
                source: 'composite',
                'source-layer': 'TX_LA_AR_FSA_Merge_012117',
                minzoom: 10,
                metadata: {
                    'mapbox:group': '7471a1b65cf4eb8a83961cd1d89a3acd',
                },
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'wi-mn-nd-fsa-merge black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'WI_MN_ND_FSA_Merge',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mo-fsa-clu black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MO_FSA_CLU',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'il-in-ky-fsa-merge-022617 black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'IL_IN_KY_FSA_Merge_022617',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'ia-sd-mi-fsa-merge-022617 black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'IA_SD_MI_FSA_Merge_022617',
            },
            {
                minzoom: 10,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'tn-ga-ms-merge-022417 black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'TN_GA_MS_Merge_022417',
            },
            {
                minzoom: 10,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'sc-va-nc-fsa-merge-022517 black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'SC_VA_NC_FSA_Merge_022517',
            },
            {
                minzoom: 10,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'ri-nj-nh-ma-md-me-de-ct-fsa-merge-022517 black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'RI_NJ_NH_MA_MD_ME_DE_CT_FSA_Merge_022517',
            },
            {
                minzoom: 10,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'wv-oh-pa-ny-vt-fsa-merge-022517 black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'WV_OH_PA_NY_VT_FSA_Merge_022517',
            },
            {
                minzoom: 10,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-az-or-wa-nv-id-fsa-merge-022717 black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_AZ_OR_WA_NV_ID_FSA_Merge_022717',
            },
            {
                minzoom: 10,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-ut-nm-co-wy-fsa-merge-022717 black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MT_UT_NM_CO_WY_FSA_Merge_022717',
            },
            {
                minzoom: 10,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-ks-ok-fsa-merge black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'NE_KS_OK_FSA_Merge',
            },
            {
                minzoom: 10,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'tx-la-ar-fsa-merge-012117 black',
                paint: {
                    'line-color': 'rgba(16,106,58,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2.5],
                            [13, 4],
                            [17, 6],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'TX_LA_AR_FSA_Merge_012117',
            },
            {
                id: 'wi-mn-nd-fsa-merge',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'WI_MN_ND_FSA_Merge',
                minzoom: 11,
            },
            {
                id: 'mo-fsa-clu',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'MO_FSA_CLU',
                minzoom: 11,
            },
            {
                id: 'il-in-ky-fsa-merge-022617',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'IL_IN_KY_FSA_Merge_022617',
                minzoom: 11,
            },
            {
                id: 'ia-sd-mi-fsa-merge-022617',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'IA_SD_MI_FSA_Merge_022617',
                minzoom: 11,
            },
            {
                id: 'tn-ga-ms-merge-022417',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'TN_GA_MS_Merge_022417',
                minzoom: 10,
            },
            {
                id: 'sc-va-nc-fsa-merge-022517',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'SC_VA_NC_FSA_Merge_022517',
                minzoom: 10,
            },
            {
                id: 'ri-nj-nh-ma-md-me-de-ct-fsa-merge-022517',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'RI_NJ_NH_MA_MD_ME_DE_CT_FSA_Merge_022517',
                minzoom: 10,
            },
            {
                id: 'wv-oh-pa-ny-vt-fsa-merge-022517',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'WV_OH_PA_NY_VT_FSA_Merge_022517',
                minzoom: 10,
            },
            {
                id: 'ca-az-or-wa-nv-id-fsa-merge-022717',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'CA_AZ_OR_WA_NV_ID_FSA_Merge_022717',
                minzoom: 10,
            },
            {
                id: 'mt-ut-nm-co-wy-fsa-merge-022717',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'MT_UT_NM_CO_WY_FSA_Merge_022717',
                minzoom: 10,
            },
            {
                id: 'ne-ks-ok-fsa-merge',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'NE_KS_OK_FSA_Merge',
                minzoom: 10,
            },
            {
                id: 'tx-la-ar-fsa-merge-012117',
                paint: {
                    'line-color': 'rgba(102,250,171,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.5],
                            [13, 1],
                            [17, 1.5],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'TX_LA_AR_FSA_Merge_012117',
                minzoom: 10,
            },
            {
                minzoom: 14,
                layout: {
                    visibility: 'visible',
                    'text-transform': 'uppercase',
                    'text-font': [
                        'Marselis Slab Offc Pro Black',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [14, 9.25],
                            [15, 11.5],
                            [18, 15],
                        ],
                    },
                    'text-field': '{Label}',
                    'text-max-width': 3,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'ia-il-in-ky-mi-mn-mo-nd-sd-wi-fsa-labels-real',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'rgba(0,36,23,1)',
                    'text-halo-width': {
                        base: 1,
                        stops: [
                            [13, 1],
                            [16, 1.25],
                        ],
                    },
                    'text-halo-blur': 1.25,
                },
                'source-layer': 'IA_IL_IN_KY_MI_MN_MO_ND_SD_WI_FSA_Labels_REAL',
            },
            {
                minzoom: 14,
                layout: {
                    visibility: 'visible',
                    'text-field': '{Label}',
                    'text-transform': 'uppercase',
                    'text-font': [
                        'Marselis Slab Offc Pro Black',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [14, 9.25],
                            [15, 11.5],
                            [18, 15],
                        ],
                    },
                    'text-max-width': 3,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'ms-ga-tn-ct-de-me-md-ma-nh-nj-ri-va-nc-sc-vt-pa-oh-wv-ny-fsa-labels-real',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'rgba(0,36,23,1)',
                    'text-halo-width': {
                        base: 1,
                        stops: [
                            [13, 1],
                            [16, 1.25],
                        ],
                    },
                    'text-halo-blur': 1.25,
                },
                'source-layer':
                    'MS_GA_TN_CT_DE_ME_MD_MA_NH_NJ_RI_VA_NC_SC_VT_PA_OH_WV_NY_FSA_Labels_REAL',
            },
            {
                minzoom: 14,
                layout: {
                    visibility: 'visible',
                    'text-field': '{Label}',
                    'text-font': [
                        'Marselis Slab Offc Pro Black',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [14, 9.25],
                            [15, 11.5],
                            [18, 15],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'text-max-width': 3,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'mt-ut-nm-co-wy-ca-az-or-wa-nv-id-ne-ks-ok-tx-la-ar-fsa-labels-real',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'rgba(0,36,23,1)',
                    'text-halo-width': {
                        base: 1,
                        stops: [
                            [13, 1],
                            [16, 1.25],
                        ],
                    },
                    'text-halo-blur': 1.25,
                },
                'source-layer':
                    'MT_UT_NM_CO_WY_CA_AZ_OR_WA_NV_ID_NE_KS_OK_TX_LA_AR_FSA_Labels_REAL',
            },
        ],
        created: '2017-03-09T21:22:52.305Z',
        modified: '2018-04-24T15:10:30.883Z',
        id: 'cj02wglh6001s2rnr9egiuh4t',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    major_aquifer: {
        version: 8,
        name: 'Major Aquifers REAL-Mobile',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:groups': {},
        },
        center: [-97.81809000009297, 30.29352799911291],
        zoom: 8.286169880948979,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.0yeue0fp',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckko9etdm0tmy17qqr8pznt4n/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 7,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'majoraquifer-webaux',
                paint: {
                    'fill-color': 'rgba(24,12,237,1)',
                    'fill-opacity': 0.25,
                    'fill-antialias': false,
                },
                'source-layer': 'majoraquifer_webaux',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'majoraquifer-webaux copy',
                paint: {
                    'line-color': 'rgba(138,155,255,1)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        0.4,
                        8,
                        0.7,
                        12,
                        1.2,
                        14,
                        1.6,
                    ],
                },
                'source-layer': 'majoraquifer_webaux',
            },
        ],
        created: '2021-02-02T17:12:05.057Z',
        modified: '2021-02-02T17:12:26.196Z',
        id: 'ckko9etdm0tmy17qqr8pznt4n',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    minor_aquifer: {
        version: 8,
        name: 'Minor Aquifers REAL-Mobile',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:groups': {},
        },
        center: [0, -7.105427357601002e-14],
        zoom: 0.9277778487151565,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.6d9xwoee',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckko8952v0sb717pazzgt46wh/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 6,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'minoraquifers-tx-011216',
                paint: {
                    'fill-color': 'rgba(43,224,227,1)',
                    'fill-antialias': false,
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        6,
                        0.2,
                        15,
                        0.25,
                    ],
                },
                'source-layer': 'minoraquifers_tx_011216',
            },
            {
                minzoom: 6,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'minoraquifers-tx-011216 copy',
                paint: {
                    'line-color': 'rgba(0,221,255,1)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        6,
                        0.4,
                        8,
                        0.7,
                        12,
                        1.2,
                        14,
                        1.6,
                    ],
                },
                'source-layer': 'minoraquifers_tx_011216',
            },
        ],
        created: '2021-02-02T16:39:40.637Z',
        modified: '2021-02-02T17:11:46.846Z',
        id: 'ckko8952v0sb717pazzgt46wh',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    fpl: {
        version: 8,
        name: 'FEMA PANELS REAL 050520',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.40.0',
                android: '5.2.0',
                ios: '3.7.0',
            },
            'mapbox:groups': {},
        },
        center: [-156.49585443768407, 20.798161575621904],
        zoom: 12.401223878059293,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.Panels_AK_20231114_1700030234,mapright2.Panels_HI_20231114_1700030281,mapright2.LOMRs_20231114_1700030545,mapright2.LOMRs_AK_20231114_1700030592,mapright2.LOMRs_HI_20231114_1700030637,mapright2.LOMR_Pts_20231115_1700033575,mapright2.LOMRs_Pts_AK_20231114_1700034938,mapright2.LOMRs_Pts_HI_20231115_1700035299,mapright2.FEMA_Pans_20231114_1700028008,mapright2.Panel_Pts_20231115_1700036262,mapright2.Pan_Pts_AK_20231115_1700036609,mapright2.Pan_Pts_HI_20231115_1700036815',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ciyal7pzu003u2rqfrq10o27x/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: 'lomr-hi-line2',
                type: 'line',
                paint: {
                    'line-width': 5,
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'LOMRs_HI_20231114_1700030635.7823226',
            },
            {
                id: 'lomr-hi-poly',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(121, 80%, 50%)',
                    'fill-opacity': 0.2,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'LOMRs_HI_20231114_1700030635.7823226',
            },
            {
                id: 'lomr-hi-line1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(128, 94%, 49%)',
                    'line-width': 1.55,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'LOMRs_HI_20231114_1700030635.7823226',
            },
            {
                id: 'lomr-ak-line2',
                type: 'line',
                paint: {
                    'line-width': 5,
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'LOMRs_AK_20231114_1700030591.310353',
            },
            {
                id: 'lomr-ak-poly',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(121, 80%, 50%)',
                    'fill-opacity': 0.2,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'LOMRs_AK_20231114_1700030591.310353',
            },
            {
                id: 'lomr-ak-line1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(128, 94%, 49%)',
                    'line-width': 1.55,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'LOMRs_AK_20231114_1700030591.310353',
            },
            {
                id: 'lomr-us-line2',
                type: 'line',
                paint: {
                    'line-blur': 1.5,
                    'line-width': 5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'LOMRs_20231114_1700030535.2574484',
            },
            {
                id: 'lomr-us-poly',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(121, 80%, 50%)',
                    'fill-opacity': 0.2,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'LOMRs_20231114_1700030535.2574484',
            },
            {
                id: 'lomr-us-line1',
                type: 'line',
                paint: {
                    'line-width': 1.55,
                    'line-color': 'hsl(128, 94%, 49%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'LOMRs_20231114_1700030535.2574484',
            },
            {
                id: 'continent-panel-line2',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 100%, 2%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 4],
                            [12, 5],
                            [14, 5.5],
                        ],
                    },
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'FEMA_Pans_20231114_1700027365.5607207',
            },
            {
                id: 'continent-panel-line1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(359, 100%, 51%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1.55],
                            [14, 2.35],
                        ],
                    },
                },
                layout: {},
                source: 'composite',
                'source-layer': 'FEMA_Pans_20231114_1700027365.5607207',
            },
            {
                id: 'hi-panel-line2',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 100%, 2%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 4],
                            [12, 5],
                            [14, 5.5],
                        ],
                    },
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Panels_HI_20231114_1700030277.4998865',
            },
            {
                id: 'hi-panel-line1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(359, 100%, 51%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1.55],
                            [14, 2.35],
                        ],
                    },
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Panels_HI_20231114_1700030277.4998865',
            },
            {
                id: 'ak-panel-line2',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 100%, 2%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 4],
                            [12, 5],
                            [14, 5.5],
                        ],
                    },
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Panels_AK_20231114_1700030228.8538733',
            },
            {
                id: 'ak-panel-line1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(359, 100%, 51%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1.55],
                            [14, 2.35],
                        ],
                    },
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Panels_AK_20231114_1700030228.8538733',
            },
            {
                id: 'lomr-ak-pt',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(120, 100%, 50%)',
                    'text-halo-color': 'hsl(0, 60%, 5%)',
                    'text-halo-width': 1,
                },
                layout: {
                    'text-field': 'lomr id {LOMR_ID} eff date {EFF_DATE}',
                    'text-font': [
                        'Netto Offc Pro Black',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [11, 10],
                            [14, 14],
                        ],
                    },
                    'text-transform': 'uppercase',
                },
                source: 'composite',
                'source-layer': 'LOMRs_Pts_AK_20231114_1700034936.8292553',
            },
            {
                id: 'lomr-us-pt',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(120, 100%, 50%)',
                    'text-halo-color': 'hsl(0, 60%, 5%)',
                    'text-halo-width': 1,
                },
                layout: {
                    'text-font': [
                        'Netto Offc Pro Black',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [11, 10],
                            [14, 14],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'text-field': 'lomr id {LOMR_ID} eff date {EFF_DATE}',
                },
                source: 'composite',
                'source-layer': 'LOMR_Pts_20231115_1700033570.4611661',
            },
            {
                id: 'lomr-hi-pt',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(120, 100%, 50%)',
                    'text-halo-color': 'hsl(0, 60%, 5%)',
                    'text-halo-width': 1,
                },
                layout: {
                    'text-field': 'lomr id {LOMR_ID} eff date {EFF_DATE}',
                    'text-font': [
                        'Netto Offc Pro Black',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [11, 10],
                            [14, 14],
                        ],
                    },
                    'text-transform': 'uppercase',
                },
                source: 'composite',
                'source-layer': 'LOMRs_Pts_HI_20231115_1700035297.9861465',
            },
            {
                id: 'continent-panel-pts',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 100%, 98%)',
                    'text-halo-color': 'hsl(0, 60%, 5%)',
                    'text-halo-width': {
                        base: 1,
                        stops: [
                            [11, 2],
                            [13, 2.2],
                            [14, 2.5],
                        ],
                    },
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': 'PANEL {FIRM_PAN} EFF DATE {EFF_DATE}',
                    'text-font': [
                        'Netto Offc Pro Black',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [11, 11],
                            [12, 14],
                            [14, 19],
                        ],
                    },
                    'text-transform': 'uppercase',
                },
                source: 'composite',
                'source-layer': 'Panel_Pts_20231115_1700036195.436613',
            },
            {
                id: 'ak-panel-pts',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 100%, 98%)',
                    'text-halo-color': 'hsl(0, 60%, 5%)',
                    'text-halo-width': {
                        base: 1,
                        stops: [
                            [11, 2],
                            [13, 2.2],
                            [14, 2.5],
                        ],
                    },
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': 'PANEL {FIRM_PAN} EFF DATE {EFF_DATE}',
                    'text-font': [
                        'Netto Offc Pro Black',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [11, 11],
                            [12, 14],
                            [14, 19],
                        ],
                    },
                    'text-transform': 'uppercase',
                },
                source: 'composite',
                'source-layer': 'Pan_Pts_AK_20231115_1700036607.3856866',
            },
            {
                id: 'hi-panel-pts',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 100%, 98%)',
                    'text-halo-color': 'hsl(0, 60%, 5%)',
                    'text-halo-width': {
                        base: 1,
                        stops: [
                            [11, 2],
                            [13, 2.2],
                            [14, 2.5],
                        ],
                    },
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': 'PANEL {FIRM_PAN} EFF DATE {EFF_DATE}',
                    'text-font': [
                        'Netto Offc Pro Black',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [11, 11],
                            [12, 14],
                            [14, 19],
                        ],
                    },
                    'text-transform': 'uppercase',
                },
                source: 'composite',
                'source-layer': 'Pan_Pts_HI_20231115_1700036812.872155',
            },
        ],
        created: '2017-01-23T21:10:47.216Z',
        modified: '2023-11-15T08:29:30.413Z',
        id: 'ciyal7pzu003u2rqfrq10o27x',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    parcels_1: {
        version: 8,
        name: '20240325_corelogic_parcel_boundaries_group_1',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.9.0',
                android: '8.6.0',
                ios: '5.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-84.388214, 33.75888],
        zoom: 13.17,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_polygons_al,mapright2.20240325_polygons_ar,mapright2.20240325_polygons_fl,mapright2.20240325_polygons_ga,mapright2.20240325_polygons_ia,mapright2.20240325_polygons_il,mapright2.20240325_polygons_ks,mapright2.20240325_polygons_la,mapright2.20240325_polygons_mo,mapright2.20240325_polygons_ms,mapright2.20240325_polygons_nc,mapright2.20240325_polygons_ok,mapright2.20240325_polygons_sc,mapright2.20240325_polygons_tn,mapright2.20240325_polygons_tx',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rg4o00cw01qpa1ij33uq/35suyk9pqrdqn9t9t1o08r6b5',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: '20240325_polygons_al',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ar',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_fl',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ga',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ia',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_il',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ks',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_la',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_mo',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ms',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_nc',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ok',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_sc',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_tn',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_tx',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
        ],
        created: '2024-04-03T16:53:19.848Z',
        modified: '2024-04-03T16:53:19.848Z',
        id: 'cluk1rg4o00cw01qpa1ij33uq',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    school_districts_elementary: {
        version: 8,
        name: 'School Districts Elementary REAL MOBILE',
        metadata: {
            'mapbox:groups': {},
            'mapbox:trackposition': true,
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:autocomposite': true,
        },
        center: [-88.69583604617156, 42.0588076768195],
        zoom: 8.457403813588094,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.ee7226f6',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckko9gdmz6hh017mj8i5s2cex/dqyx7qmk35t1cad3bbee08qdl',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                minzoom: 7,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'elementary-sd-projected',
                paint: {
                    'fill-pattern': 'thin-diagonal-lines-green32',
                },
                'source-layer': 'Elementary_SD_Projected',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'elementary-sd-projected copy',
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        2,
                        9,
                        3,
                        11,
                        4,
                    ],
                },
                'source-layer': 'Elementary_SD_Projected',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'elementary-sd-projected copy 2',
                paint: {
                    'line-color': 'hsl(128, 86%, 56%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        1.2,
                        9,
                        2,
                        11,
                        2.5,
                    ],
                },
                'source-layer': 'Elementary_SD_Projected',
            },
            {
                minzoom: 9,
                layout: {
                    'text-field': ['to-string', ['get', 'NAME']],
                    'text-font': [
                        'Alegreya SC ExtraBold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        10,
                        12,
                        13,
                        15,
                        15,
                    ],
                    'symbol-avoid-edges': true,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        6,
                        11,
                        8,
                        12,
                        10,
                    ],
                    'text-transform': 'uppercase',
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        10,
                        12,
                        40,
                    ],
                },
                type: 'symbol',
                source: 'composite',
                id: 'elementary-sd-projected copy 1',
                paint: {
                    'text-color': 'hsl(143, 84%, 67%)',
                    'text-halo-color': 'hsl(132, 38%, 5%)',
                    'text-halo-width': 1.5,
                },
                'source-layer': 'Elementary_SD_Projected',
            },
        ],
        created: '2021-02-02T17:13:17.967Z',
        modified: '2021-02-02T17:13:46.803Z',
        id: 'ckko9gdmz6hh017mj8i5s2cex',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    school_districts_secondary: {
        version: 8,
        name: 'School Districts Secondary REAL MOBILE',
        metadata: {
            'mapbox:groups': {},
            'mapbox:trackposition': true,
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:autocomposite': true,
        },
        center: [-89.31156216267715, 40.72498912983639],
        zoom: 10.559616250694765,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.69a01264',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckko9idz50tl917ovv71f4smh/dqyx7qmk35t1cad3bbee08qdl',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                minzoom: 7,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'secondary-sd-projected',
                paint: {
                    'fill-pattern': 'roydots-32',
                },
                'source-layer': 'Secondary_SD_Projected',
            },
            {
                minzoom: 7,
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'secondary-sd-projected copy 2',
                paint: {
                    'line-color': 'hsl(216, 70%, 78%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        1.2,
                        9,
                        2,
                        11,
                        2.5,
                    ],
                    'line-dasharray': [
                        'step',
                        ['zoom'],
                        ['literal', [1, 3]],
                        11,
                        ['literal', [1.5, 3]],
                    ],
                },
                'source-layer': 'Secondary_SD_Projected',
            },
            {
                minzoom: 9,
                layout: {
                    'text-field': ['to-string', ['get', 'NAME']],
                    'text-font': [
                        'Alegreya SC ExtraBold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        10,
                        12,
                        13,
                        15,
                        15,
                    ],
                    'symbol-avoid-edges': true,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        6,
                        11,
                        8,
                        12,
                        10,
                    ],
                    'text-transform': 'uppercase',
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        25,
                        10,
                        40,
                        13,
                        100,
                    ],
                },
                type: 'symbol',
                source: 'composite',
                id: 'secondary-sd-projected copy 1',
                paint: {
                    'text-color': 'hsl(214, 98%, 79%)',
                    'text-halo-color': 'hsl(0, 0%, 0%)',
                    'text-halo-width': 1.5,
                },
                'source-layer': 'Secondary_SD_Projected',
            },
        ],
        created: '2021-02-02T17:14:51.691Z',
        modified: '2021-02-02T17:15:18.188Z',
        id: 'ckko9idz50tl917ovv71f4smh',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    school_districts_unified: {
        version: 8,
        name: 'School Districts Unified REAL MOBILE',
        metadata: {
            'mapbox:groups': {},
            'mapbox:trackposition': true,
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:autocomposite': true,
        },
        center: [0, -1.1368683772161603e-13],
        zoom: 0.9277778487151563,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.beb96110',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckko9k0wj0tk717pa0nb3azzy/dqyx7qmk35t1cad3bbee08qdl',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                minzoom: 7,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'Unified_PopupFill',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-antialias': false,
                    'fill-opacity': 0,
                },
                'source-layer': 'Unified_SDs_WebAux',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'unified-sds-webaux copy',
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        2,
                        9,
                        3,
                        11,
                        4,
                    ],
                },
                'source-layer': 'Unified_SDs_WebAux',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'unified-sds-webaux copy 2',
                paint: {
                    'line-color': 'rgb(243, 88, 88)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        1.2,
                        9,
                        2,
                        11,
                        2.5,
                    ],
                },
                'source-layer': 'Unified_SDs_WebAux',
            },
            {
                minzoom: 9,
                layout: {
                    'text-field': ['to-string', ['get', 'Unif_Dist']],
                    'text-font': [
                        'Alegreya SC ExtraBold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'symbol-avoid-edges': true,
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        10,
                        12,
                        13,
                        15,
                        15,
                    ],
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        6,
                        11,
                        8,
                        12,
                        10,
                    ],
                    'text-transform': 'uppercase',
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        4,
                        10,
                        40,
                        13,
                        100,
                    ],
                },
                type: 'symbol',
                source: 'composite',
                id: 'unified-sds-webaux copy 1',
                paint: {
                    'text-color': 'hsl(59, 83%, 54%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1,
                },
                'source-layer': 'Unified_SDs_WebAux',
            },
        ],
        created: '2021-02-02T17:16:08.082Z',
        modified: '2021-02-02T17:16:42.462Z',
        id: 'ckko9k0wj0tk717pa0nb3azzy',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    chase_gibson_production: {
        version: 8,
        name: 'Chase Gibson Wells REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [-102.11646634428865, 31.86394167417781],
        zoom: 14.050145059568857,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.9927b8ee,mapright2.1253b729',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjg3w2zqa1chw2rmq2sozqr8k',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 10,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'wells-lines-041618 copy',
                paint: {
                    'line-color': 'hsl(0, 1%, 0%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [10, 0.9],
                            [12, 1.2],
                            [14, 1.7],
                            [18, 2.2],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'Wells_Lines_041618',
            },
            {
                minzoom: 10,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'wells-lines-041618',
                paint: {
                    'line-color': 'hsl(196, 83%, 53%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [10, 0.75],
                            [12, 1],
                            [14, 1.5],
                            [18, 2],
                        ],
                    },
                },
                'source-layer': 'Wells_Lines_041618',
            },
            {
                minzoom: 10,
                layout: {},
                type: 'circle',
                source: 'composite',
                id: 'wells-surfaceloc-041618',
                paint: {
                    'circle-color': 'hsl(230, 99%, 51%)',
                    'circle-stroke-width': {
                        base: 1,
                        stops: [
                            [10, 0.5],
                            [14, 1],
                        ],
                    },
                    'circle-radius': {
                        base: 1,
                        stops: [
                            [10, 2.8],
                            [16, 4],
                            [18, 5],
                        ],
                    },
                },
                'source-layer': 'Wells_SurfaceLoc_041618',
            },
        ],
        created: '2018-04-17T16:35:21.150Z',
        modified: '2018-04-24T16:03:13.855Z',
        id: 'cjg3w2zqa1chw2rmq2sozqr8k',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    chase_gibson_permits: {
        version: 8,
        name: 'Chase Gibson Permits REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [-102.2087479328959, 31.90043480916613],
        zoom: 13.396219236818263,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.9c8b384f,mapright2.26b0d609',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjg56xaqy2tb52spawg4qu2b5',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 10,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'permits-lines-041618 copy',
                paint: {
                    'line-color': 'hsl(66, 4%, 3%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [10, 0.91],
                            [12, 1.2],
                            [14, 1.7],
                            [18, 2.2],
                        ],
                    },
                },
                'source-layer': 'Permits_Lines_041618',
            },
            {
                minzoom: 10,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'permits-lines-041618',
                paint: {
                    'line-color': 'hsl(55, 92%, 60%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [10, 0.75],
                            [12, 1],
                            [14, 1.5],
                            [18, 2],
                        ],
                    },
                },
                'source-layer': 'Permits_Lines_041618',
            },
            {
                minzoom: 10,
                layout: {
                    'icon-image': 'CG_Permits3',
                    'icon-size': {
                        base: 1,
                        stops: [
                            [10, 0.4],
                            [12, 0.5],
                            [15, 0.7],
                            [18, 0.7],
                        ],
                    },
                },
                type: 'symbol',
                source: 'composite',
                id: 'permits-surfaceloc-041618',
                paint: {},
                'source-layer': 'Permits_SurfaceLoc_041618',
            },
        ],
        created: '2018-04-18T14:26:37.798Z',
        modified: '2018-04-24T16:05:11.708Z',
        id: 'cjg56xaqy2tb52spawg4qu2b5',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    plss: {
        version: 8,
        name: 'PLSS_ABS_REAL_031721-WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.40.0',
                android: '5.2.0',
                ios: '3.7.0',
            },
            'mapbox:groups': {},
        },
        center: [-102.97309851680944, 33.63773740908597],
        zoom: 11.083048051595544,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.ebeaab11,mapright2.9c3143b6,mapright2.daca7649,mapright2.e061bea8,mapright2.ec3cc6ab,mapright2.7d05dc3f,mapright2.d8e09c86,mapright2.6rmzil74,mapright2.b3eec0c1,mapright2.c4145560,mapright2.04b18116,mapright2.ce6843c1,mapright2.d91475fe,mapright2.6aad4903',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cizhese5q000l2roc7t8ww52f/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: 'az-co-id-ut-wy-plss-poly-020121',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'az_co_id_ut_wy_plss_poly_020121',
                minzoom: 10,
            },
            {
                id: 'ca-or-nv-wa-plss-poly-020121',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                source: 'composite',
                'source-layer': 'ca_or_nv_wa_plss_poly_020121',
                minzoom: 10,
            },
            {
                id: 'al-fl-la-ms-plss-poly-020121',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'al_fl_la_ms_plss_poly_020121',
                minzoom: 10,
            },
            {
                id: 'il-in-mn-oh-wi-plss-poly-020221',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {
                    visibility: 'none',
                },
                source: 'composite',
                'source-layer': 'il_in_mn_oh_wi_plss_poly_020221',
                minzoom: 10,
            },
            {
                id: 'il-in-mn-oh-wi-plss-poly-062022',
                type: 'line',
                paint: {
                    'line-color': 'hsl(300, 60%, 1%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Merge_PLSS',
                minzoom: 11,
            },
            {
                id: 'ia-mt-nd-ne-sd-plss-poly-012721',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'ia_mt_nd_ne_sd_plss_poly_012721',
                minzoom: 11,
            },
            {
                id: 'ar-ks-mo-nm-ok-plss-poly-012721',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'ar_ks_mo_nm_ok_plss_poly_012721',
                minzoom: 11,
            },
            {
                id: 'mi-plss-webaux',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'MI_PLSS_Webaux',
                minzoom: 11,
            },
            {
                id: 'otls-polygons-webaux copy',
                paint: {
                    'line-color': 'hsl(300, 60%, 1%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2.5,
                        12,
                        3,
                        14,
                        5,
                    ],
                    'line-blur': 1.5,
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'OTLS_Polygons_Webaux',
                minzoom: 11,
            },
            {
                id: 'az-co-id-ut-wy-plss-poly-020121 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'az_co_id_ut_wy_plss_poly_020121',
            },
            {
                id: 'ca-or-nv-wa-plss-poly-020121 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                source: 'composite',
                'source-layer': 'ca_or_nv_wa_plss_poly_020121',
                minzoom: 10,
            },
            {
                id: 'al-fl-la-ms-plss-poly-020121 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'al_fl_la_ms_plss_poly_020121',
                minzoom: 10,
            },
            {
                id: 'il-in-mn-oh-wi-plss-poly-020221 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                layout: {
                    visibility: 'none',
                },
                source: 'composite',
                'source-layer': 'il_in_mn_oh_wi_plss_poly_020221',
            },
            {
                id: 'il-in-mn-oh-wi-plss-poly-062022 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(287, 100%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Merge_PLSS',
            },
            {
                id: 'ia-mt-nd-ne-sd-plss-poly-012721 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'ia_mt_nd_ne_sd_plss_poly_012721',
            },
            {
                id: 'ar-ks-mo-nm-ok-plss-poly-012721 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'ar_ks_mo_nm_ok_plss_poly_012721',
                minzoom: 11,
            },
            {
                id: 'mi-plss-webaux copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                    'line-color': 'hsl(287, 100%, 50%)',
                },
                source: 'composite',
                'source-layer': 'MI_PLSS_Webaux',
                minzoom: 10,
            },
            {
                id: 'otls-polygons-webaux',
                paint: {
                    'line-color': 'hsl(287, 100%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.9,
                        12,
                        1,
                        14,
                        1.75,
                    ],
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'OTLS_Polygons_Webaux',
                minzoom: 11,
            },
            {
                minzoom: 12,
                layout: {
                    'text-font': [
                        'Marselis Slab Offc Pro Bold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        12,
                        13,
                        15,
                        16,
                        19,
                    ],
                    'text-field': [
                        'concat',
                        ['get', 'ABSTRACT_L'],
                        '\n',
                        ['get', 'LEVEL3_SUR'],
                    ],
                },
                type: 'symbol',
                source: 'composite',
                id: 'otls-labels-20221004-2tsg9d',
                paint: {
                    'text-color': 'rgba(252,237,253,1)',
                    'text-halo-color': 'rgba(4,1,4,1)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.4,
                        13,
                        1.6,
                        16,
                        1.8,
                    ],
                    'text-halo-blur': 1,
                },
                'source-layer': 'OTLS_Labels_20221004-2tsg9d',
            },
            {
                id: 'az-ca-id-mt-nv-or-ut-wa-wy-centroids-030921',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(296, 80%, 96%)',
                    'text-halo-color': 'hsl(300, 60%, 1%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.4,
                        13,
                        1.6,
                        16,
                        1.8,
                    ],
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'TRS']],
                    'text-font': [
                        'Marselis Slab Offc Pro Bold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        12,
                        13,
                        15,
                        16,
                        19,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        4,
                        13,
                        10,
                    ],
                },
                source: 'composite',
                'source-layer': 'AZ_CA_ID_MT_NV_OR_UT_WA_WY_Centroids_030921',
            },
            {
                id: 'al-ar-co-fl-ks-la-mo-ms-nm-ok-plss-centroids-031521',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(296, 80%, 96%)',
                    'text-halo-color': 'hsl(300, 60%, 1%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.4,
                        13,
                        1.6,
                        16,
                        1.8,
                    ],
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'TRS']],
                    'text-font': [
                        'Marselis Slab Offc Pro Bold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        12,
                        13,
                        15,
                        16,
                        19,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        4,
                        13,
                        10,
                    ],
                },
                source: 'composite',
                'source-layer':
                    'al_ar_co_fl_ks_la_mo_ms_nm_ok_plss_centroids_031521',
                minzoom: 12,
            },
            {
                id: 'ia-il-in-mi-mn-nd-ne-oh-sd-wi-plss-centroids-031521',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(296, 80%, 96%)',
                    'text-halo-color': 'hsl(300, 60%, 1%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.4,
                        13,
                        1.6,
                        16,
                        1.8,
                    ],
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'TRS']],
                    'text-font': [
                        'Marselis Slab Offc Pro Bold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        12,
                        13,
                        15,
                        16,
                        19,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        4,
                        13,
                        10,
                    ],
                    visibility: 'none',
                },
                source: 'composite',
                'source-layer':
                    'ia_il_in_mi_mn_nd_ne_oh_sd_wi_plss_centroids_031521',
            },
            {
                id: 'il-update-mergecent',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(296, 80%, 96%)',
                    'text-halo-color': 'hsl(300, 60%, 1%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.4,
                        13,
                        1.6,
                        16,
                        1.8,
                    ],
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'Label_Pt']],
                    'text-font': [
                        'Marselis Slab Offc Pro Bold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        12,
                        13,
                        15,
                        16,
                        19,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        4,
                        13,
                        10,
                    ],
                    'text-transform': 'uppercase',
                },
                source: 'composite',
                'source-layer': 'IL_Update_MergeCent',
            },
        ],
        created: '2017-02-22T20:24:59.894Z',
        modified: '2023-06-15T22:10:18.870Z',
        id: 'cizhese5q000l2roc7t8ww52f',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    hydro_2: {
        version: 8,
        name: 'Hydro Group 4 REAL',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [0, -1.1368683772161603e-13],
        zoom: 0.9277778487151565,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.2uz5oi2l,mapright2.387afa4b,mapright2.6b3bbed0,mapright2.1fe20cc4,mapright2.0573ec8a,mapright2.aqhtmmm8,mapright2.96wcmn53,mapright2.b8a20b82',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjab7w69z35ps2rmnsvfnodlf',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'az-ca-nv-or-ut-wa-smallwb-112117',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'AZ_CA_NV_OR_UT_WA_SmallWB_112117',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'az-ca-nv-or-ut-wa-medwb-112017',
                paint: {
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                },
                'source-layer': 'AZ_CA_NV_OR_UT_WA_MedWB_112017',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'national-large-waterbodies-20150302',
                paint: {
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-opacity': 0.53,
                    'fill-antialias': false,
                },
                'source-layer': 'National_Large_Waterbodies_20150302',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'or-wa-streams-052417',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'OR_WA_Streams_052417',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-nv-streams-052317',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'CA_NV_Streams_052317',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'az-ut-streams-052417',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'AZ_UT_Streams_052417',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'az-ca-nv-or-ut-wa-creeks-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2],
                            [12, 2.4],
                            [14, 3],
                            [16, 4],
                        ],
                    },
                },
                'source-layer': 'AZ_CA_NV_OR_UT_WA_Creeks_072717',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'az-ca-nv-or-ut-wa-creeks-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1],
                            [12, 1.2],
                            [14, 1.5],
                            [16, 2],
                        ],
                    },
                },
                'source-layer': 'AZ_CA_NV_OR_UT_WA_Creeks_072717',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'national-rivers-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [8, 1.5],
                            [10, 3],
                            [12, 3.6],
                            [14, 4.4],
                            [16, 5],
                        ],
                    },
                },
                'source-layer': 'National_Rivers_20150302',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'national-rivers-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [8, 0.75],
                            [10, 1.5],
                            [12, 1.8],
                            [14, 2.2],
                            [16, 2.5],
                        ],
                    },
                },
                'source-layer': 'National_Rivers_20150302',
            },
            {
                minzoom: 11,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [11, 200],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': 0.1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'az-ca-nv-or-ut-wa-creeks-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,55,66,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'AZ_CA_NV_OR_UT_WA_Creeks_072717',
            },
            {
                minzoom: 9,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [9, 150],
                            [11, 200],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': {
                        base: 1,
                        stops: [
                            [0, 0],
                            [10, 0.1],
                        ],
                    },
                },
                type: 'symbol',
                source: 'composite',
                id: 'national-rivers-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,56,67,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'National_Rivers_20150302',
            },
        ],
        created: '2017-11-22T15:42:07.913Z',
        modified: '2017-12-12T18:34:33.225Z',
        id: 'cjab7w69z35ps2rmnsvfnodlf',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    grazing_allotments: {
        version: 8,
        name: 'Grazing_Allotments_REAL-NEW-copy',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:groups': {},
            'mapbox:sdk-support': {
                android: '10.6.0',
                ios: '10.6.0',
                js: '2.9.0',
            },
        },
        center: [-108.13288909557315, 46.97972228634268],
        zoom: 8.837501584177998,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.805oh07j',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl9p3sv2c000c14nz6ea06gjr/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'grazing-allotments-20221012-75co7d copy 1',
                minzoom: 10,
                type: 'fill',
                paint: {
                    'fill-opacity': 0.3,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'BLM_National_Grazing_Allotmen-981rp8',
            },
            {
                id: 'grazing-allotments-20221012-75co7d copy 3',
                minzoom: 10,
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1.5,
                        14,
                        2,
                    ],
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'BLM_National_Grazing_Allotmen-981rp8',
            },
            {
                id: 'grazing-allotments-20221012-75co7d copy 5',
                minzoom: 10,
                type: 'symbol',
                paint: {
                    'text-halo-color': 'hsl(0, 3%, 99%)',
                    'text-halo-width': 1.11,
                },
                source: 'composite',
                'source-layer': 'BLM_National_Grazing_Allotmen-981rp8',
                layout: {
                    'text-field': [
                        'concat',
                        ['get', 'AllotNo'],
                        '\n',
                        ['get', 'AllotName'],
                    ],
                    'text-font': [
                        'Open Sans ExtraBold',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': 9.3,
                    'text-max-width': 2,
                },
            },
        ],
        created: '2022-10-26T03:55:26.922Z',
        modified: '2022-11-18T19:46:34.131Z',
        id: 'cl9p3sv2c000c14nz6ea06gjr',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    gma: {
        version: 8,
        name: 'GMUs_011717-MOBILE',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.40.0',
                android: '5.2.0',
                ios: '3.7.0',
            },
            'mapbox:groups': {},
        },
        center: [-111.40222690894156, 40.24424758621035],
        zoom: 8,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.aaap3bf5',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckko9sc5w0n6717n911sf0z4h/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 7,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'GMU-PopupFill',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0,
                    'fill-antialias': false,
                },
                'source-layer': 'GMU_062816',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'gmu-062816',
                paint: {
                    'line-color': 'hsl(107, 61%, 48%)',
                    'line-dasharray': [1, 3],
                    'line-width': 1.5,
                },
                'source-layer': 'GMU_062816',
            },
        ],
        created: '2021-02-02T17:22:35.640Z',
        modified: '2021-02-02T17:23:17.734Z',
        id: 'ckko9sc5w0n6717n911sf0z4h',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    hydro_3: {
        version: 8,
        name: 'Hydro Group 3 REAL',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [0, -1.1368683772161603e-13],
        zoom: 0.9277778487151565,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.aa8h4sav,mapright2.bcf2ad59,mapright2.4a92e2e6,mapright2.d52d0504,mapright2.98ce9a2c,mapright2.030832c6,mapright2.ece8389e,mapright2.dc0c82b4,mapright2.4rl5ydi6,mapright2.226b7a0a,mapright2.aqhtmmm8,mapright2.96wcmn53,mapright2.036wu34y',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjab7kpkb27fi2rlogdkq40wo',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'ne-sd-smallwb-071217',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'NE_SD_SmallWB_071217',
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'mn-nd-smallwb-071217',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'MN_ND_SmallWB_071217',
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'id-mt-wy-smallwb-112017',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'ID_MT_WY_SmallWB_112017',
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'co-ks-nm-smallwb-111217',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'CO_KS_NM_SmallWB_111217',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'mn-ne-nd-sd-medwb-071217',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'MN_NE_ND_SD_MedWB_071217',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'co-id-ks-mt-nm-wy-medwb-112017',
                paint: {
                    'fill-opacity': 0.53,
                    'fill-antialias': false,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'CO_ID_KS_MT_NM_WY_MedWB_112017',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'national-large-waterbodies-20150302',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'National_Large_Waterbodies_20150302',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'mn-nd-ne-sd-streams-111417',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'MN_ND_NE_SD_Streams_111417',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'co-ks-nm-streams-111417',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'CO_KS_NM_Streams_111417',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'id-mt-wy-streams-052317',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'ID_MT_WY_Streams_052317',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'mn-ne-nd-sd-creeks-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2],
                            [12, 2.4],
                            [14, 3],
                            [16, 4],
                        ],
                    },
                },
                'source-layer': 'MN_NE_ND_SD_Creeks_072717',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'mn-ne-nd-sd-creeks-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1],
                            [12, 1.2],
                            [14, 1.5],
                            [16, 2],
                        ],
                    },
                },
                'source-layer': 'MN_NE_ND_SD_Creeks_072717',
            },
            {
                minzoom: 11,
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'co-id-ks-mt-nm-wy-creeks-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 2],
                            [12, 2.4],
                            [14, 3],
                            [16, 4],
                        ],
                    },
                },
                'source-layer': 'CO_ID_KS_MT_NM_WY_Creeks_112117',
            },
            {
                minzoom: 11,
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'co-id-ks-mt-nm-wy-creeks-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1],
                            [12, 1.2],
                            [14, 1.5],
                            [16, 2],
                        ],
                    },
                },
                'source-layer': 'CO_ID_KS_MT_NM_WY_Creeks_112117',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'national-rivers-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [8, 1.5],
                            [10, 3],
                            [12, 3.6],
                            [14, 4.4],
                            [16, 5],
                        ],
                    },
                },
                'source-layer': 'National_Rivers_20150302',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'national-rivers-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [8, 0.75],
                            [10, 1.5],
                            [12, 1.8],
                            [14, 2.2],
                            [16, 2.5],
                        ],
                    },
                },
                'source-layer': 'National_Rivers_20150302',
            },
            {
                minzoom: 11,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [11, 200],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': 0.1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'mn-ne-nd-sd-creeks-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,55,66,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'MN_NE_ND_SD_Creeks_072717',
            },
            {
                minzoom: 11,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [11, 200],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': 0.1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'co-id-ks-mt-nm-wy-creeks-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,55,66,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'CO_ID_KS_MT_NM_WY_Creeks_112117',
            },
            {
                minzoom: 9,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [9, 150],
                            [11, 200],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': {
                        base: 1,
                        stops: [
                            [0, 0],
                            [10, 0.1],
                        ],
                    },
                },
                type: 'symbol',
                source: 'composite',
                id: 'national-rivers-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,55,66,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'National_Rivers_20150302',
            },
        ],
        created: '2017-11-22T15:33:13.044Z',
        modified: '2017-12-12T18:34:12.631Z',
        id: 'cjab7kpkb27fi2rlogdkq40wo',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    hydro_1: {
        version: 8,
        name: 'Hydro Group 1 REAL',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [-93.69143944527787, 41.575235782908806],
        zoom: 11.079586492763037,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.38df3aa5,mapright2.4685s7ue,mapright2.89un9pyn,mapright2.51da6e8c,mapright2.3639146f,mapright2.89ada5d3,mapright2.96wcmn53,mapright2.3b3a7327,mapright2.1wiakr69,mapright2.63bgy1np,mapright2.6e93f169,mapright2.c5660b40,mapright2.306ed743,mapright2.4c0e83fc,mapright2.aqhtmmm8',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjab6czgh330w2rpasb1cw0oe',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'in-ma-me-nh-ny-pa-ri-vt-wv-smallwb-071217',
                paint: {
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-opacity': 0.53,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_MA_ME_NH_NY_PA_RI_VT_WV_SmallWB_071217',
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'ct-de-ky-md-mi-nj-oh-tn-va-smallwb-071217',
                paint: {
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                },
                'source-layer': 'CT_DE_KY_MD_MI_NJ_OH_TN_VA_SmallWB_071217',
            },
            {
                minzoom: 12,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'ia-il-mo-wi-smallwb-111317',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                },
                'source-layer': 'IA_IL_MO_WI_SmallWB_111317',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'in-ma-me-nh-ny-pa-ri-vt-wv-medwb-071217',
                paint: {
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-antialias': false,
                    'fill-opacity': 0.53,
                },
                'source-layer': 'IN_MA_ME_NH_NY_PA_RI_VT_WV_MedWB_071217',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'ct-de-ky-mi-nj-oh-md-tn-va-medwb-111217',
                paint: {
                    'fill-opacity': 0.53,
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-antialias': false,
                },
                'source-layer': 'CT_DE_KY_MI_NJ_OH_MD_TN_VA_MedWB_111217',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'ia-il-mo-wi-medwb-071217',
                paint: {
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-opacity': 0.53,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_IL_MO_WI_MedWB_071217',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'national-large-waterbodies-20150302',
                paint: {
                    'fill-color': 'rgba(0,234,255,1)',
                    'fill-opacity': 0.53,
                    'fill-antialias': false,
                },
                'source-layer': 'National_Large_Waterbodies_20150302',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'ma-me-nh-ny-pa-ri-vt-wv-streams-051317',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'MA_ME_NH_NY_PA_RI_VT_WV_Streams_051317',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'ct-de-ky-nj-md-tn-va-streams-051017',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'CT_DE_KY_NJ_MD_TN_VA_Streams_051017',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'ia-il-mo-wi-streams-051617',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'IA_IL_MO_WI_Streams_051617',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'in-mi-oh-streams-051517',
                paint: {
                    'line-color': 'rgba(43,203,243,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.8],
                            [14, 1.05],
                            [17, 1.2],
                        ],
                    },
                    'line-dasharray': [2, 1.8, 1, 2],
                },
                'source-layer': 'IN_MI_OH_Streams_051517',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'in-ma-me-nh-ny-pa-ri-vt-wv-creeks-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1.6],
                            [12, 2],
                            [14, 3],
                            [16, 4],
                        ],
                    },
                },
                'source-layer': 'IN_MA_ME_NH_NY_PA_RI_VT_WV_Creeks_071217',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'in-ma-me-nh-ny-pa-ri-vt-wv-creeks-dark copy',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.8],
                            [12, 1],
                            [14, 1.5],
                            [16, 2],
                        ],
                    },
                },
                'source-layer': 'IN_MA_ME_NH_NY_PA_RI_VT_WV_Creeks_071217',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'ct-de-ky-mi-nj-oh-md-tn-va-creeks-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1.6],
                            [12, 2],
                            [14, 3],
                            [16, 4],
                        ],
                    },
                },
                'source-layer': 'CT_DE_KY_MI_NJ_OH_MD_TN_VA_Creeks_111217',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'ct-de-ky-mi-nj-oh-md-tn-va-creeks-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.8],
                            [12, 1],
                            [14, 1.5],
                            [16, 2],
                        ],
                    },
                },
                'source-layer': 'CT_DE_KY_MI_NJ_OH_MD_TN_VA_Creeks_111217',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'ia-il-mo-wi-creeks-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1.6],
                            [12, 2],
                            [14, 3],
                            [16, 4],
                        ],
                    },
                },
                'source-layer': 'IA_IL_MO_WI_Creeks_072717',
            },
            {
                minzoom: 11,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'ia-il-mo-wi-creeks-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 0.8],
                            [12, 1],
                            [14, 1.5],
                            [16, 2],
                        ],
                    },
                },
                'source-layer': 'IA_IL_MO_WI_Creeks_072717',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'national-rivers-dark',
                paint: {
                    'line-color': 'rgba(24,78,83,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [8, 1.5],
                            [10, 3],
                            [12, 3.6],
                            [14, 4.4],
                            [16, 5],
                        ],
                    },
                },
                'source-layer': 'National_Rivers_20150302',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                type: 'line',
                source: 'composite',
                id: 'national-rivers-light',
                paint: {
                    'line-color': 'rgba(25,208,225,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [8, 0.75],
                            [10, 1.5],
                            [12, 1.8],
                            [14, 2.2],
                            [16, 2.5],
                        ],
                    },
                },
                'source-layer': 'National_Rivers_20150302',
            },
            {
                minzoom: 11,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [11, 200],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': 0.1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'in-ma-me-nh-ny-pa-ri-vt-wv-creeks-dark copy 1',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,56,67,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'IN_MA_ME_NH_NY_PA_RI_VT_WV_Creeks_071217',
            },
            {
                minzoom: 7,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [11, 150],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': 0.1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'ct-de-ky-mi-nj-oh-md-tn-va-creeks-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,56,67,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'CT_DE_KY_MI_NJ_OH_MD_TN_VA_Creeks_111217',
            },
            {
                minzoom: 7,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [11, 150],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': 0.1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'ia-il-mo-wi-creeks-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,56,67,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'IA_IL_MO_WI_Creeks_072717',
            },
            {
                minzoom: 9,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [10, 8.5],
                            [12, 9.2],
                            [14, 10.25],
                            [15, 10.5],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-angle': 70,
                    'text-transform': 'uppercase',
                    'symbol-spacing': {
                        base: 1,
                        stops: [
                            [9, 150],
                            [11, 200],
                            [14, 280],
                            [15, 300],
                        ],
                    },
                    'text-font': [
                        'Roboto Black Italic',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'text-field': '{GNIS_Name}',
                    'text-letter-spacing': {
                        base: 1,
                        stops: [
                            [0, 0],
                            [10, 0.1],
                        ],
                    },
                },
                type: 'symbol',
                source: 'composite',
                id: 'national-rivers-labels',
                paint: {
                    'text-color': 'rgba(255,255,255,1)',
                    'text-halo-color': 'rgba(5,56,67,1)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                },
                'source-layer': 'National_Rivers_20150302',
            },
        ],
        created: '2017-11-22T14:59:12.731Z',
        modified: '2017-12-12T18:32:58.222Z',
        id: 'cjab6czgh330w2rpasb1cw0oe',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_3: {
        version: 8,
        name: 'OR-WA Contours REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {
                ab6bf359db1c9a6135b85f0ab1e4d035: {
                    name: 'OR Group',
                    collapsed: true,
                },
                ce9bf71caf26217851cd656e7c78b5b4: {
                    name: 'WA Group',
                    collapsed: true,
                },
            },
        },
        center: [-119.18489306325978, 46.33312781884737],
        zoom: 13.126981361888785,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.2a335e46,mapright2.aae989f4,mapright2.9a990e5b,mapright2.152bfb53,mapright2.376b4606,mapright2.7f83c175,mapright2.6895ae12,mapright2.fa0989ea,mapright2.c6baa6c6,mapright2.0405e0b9,mapright2.4a766610',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cji6edz370pkd2rqzh4p4pw2q',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-1-black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'OR_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-1-yellow',
                paint: {
                    'line-color': '#f7b708',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'OR_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'symbol',
                source: 'composite',
                id: 'or-contours-1-label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OR_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'OR_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'OR_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'symbol',
                source: 'composite',
                id: 'or-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OR_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'OR_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'OR_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'symbol',
                source: 'composite',
                id: 'or-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OR_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'OR_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'OR_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'symbol',
                source: 'composite',
                id: 'or-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OR_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'OR_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'OR_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'symbol',
                source: 'composite',
                id: 'or-contours-5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OR_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-6-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'OR_Contours_6_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'line',
                source: 'composite',
                id: 'or-contours-6-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'OR_Contours_6_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ab6bf359db1c9a6135b85f0ab1e4d035',
                },
                type: 'symbol',
                source: 'composite',
                id: 'or-contours-6-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OR_Contours_6_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'line',
                source: 'composite',
                id: 'wa-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WA_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'line',
                source: 'composite',
                id: 'wa-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'WA_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wa-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WA_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'line',
                source: 'composite',
                id: 'wa-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WA_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'line',
                source: 'composite',
                id: 'wa-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'WA_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wa-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WA_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'line',
                source: 'composite',
                id: 'wa-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WA_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'line',
                source: 'composite',
                id: 'wa-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'WA_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wa-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WA_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'line',
                source: 'composite',
                id: 'wa-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WA_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'line',
                source: 'composite',
                id: 'wa-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'WA_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wa-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WA_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'line',
                source: 'composite',
                id: 'wa-contours-5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WA_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'line',
                source: 'composite',
                id: 'wa-contours-5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'WA_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ce9bf71caf26217851cd656e7c78b5b4',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wa-contours-5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WA_Contours_5_projected',
            },
        ],
        created: '2018-06-08T20:02:43.928Z',
        modified: '2018-06-11T17:37:45.608Z',
        id: 'cji6edz370pkd2rqzh4p4pw2q',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_2: {
        version: 8,
        name: 'ID-UT Contours REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {
                b1abf046fcf8ac2b6ea398ff319a7e10: {
                    name: 'UT Group',
                    collapsed: true,
                },
                '008b06506892c9938930aa43d79fa237': {
                    name: 'ID Group',
                    collapsed: true,
                },
            },
        },
        center: [-116.58749442146689, 43.72025848033499],
        zoom: 13.355837062281493,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.54a19e69,mapright2.5fc69e75,mapright2.4dd59146,mapright2.7c058815,mapright2.f55ee10d,mapright2.90d24949,mapright2.ba9a2e82,mapright2.1cc803b8,mapright2.af5d009e,mapright2.c73dc2e0,mapright2.e570e97f',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cji67j1yz05kw2rpaiv59th4e',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'line',
                source: 'composite',
                id: 'ut-contours-1-black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'UT_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'line',
                source: 'composite',
                id: 'ut-contours-1-yellow',
                paint: {
                    'line-color': '#f7b708',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'UT_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ut-contours-1-label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'UT_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'line',
                source: 'composite',
                id: 'ut-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'UT_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'line',
                source: 'composite',
                id: 'ut-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'UT_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ut-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'UT_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'line',
                source: 'composite',
                id: 'ut-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'UT_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'line',
                source: 'composite',
                id: 'ut-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'UT_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ut-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'UT_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'line',
                source: 'composite',
                id: 'ut-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'UT_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'line',
                source: 'composite',
                id: 'ut-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'UT_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ut-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'UT_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'line',
                source: 'composite',
                id: 'ut-contours-5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'UT_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'line',
                source: 'composite',
                id: 'ut-contours-5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'UT_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'b1abf046fcf8ac2b6ea398ff319a7e10',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ut-contours-5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'UT_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ID_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ID_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'symbol',
                source: 'composite',
                id: 'id-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ID_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ID_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ID_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'symbol',
                source: 'composite',
                id: 'id-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ID_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ID_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ID_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'symbol',
                source: 'composite',
                id: 'id-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ID_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ID_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ID_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'symbol',
                source: 'composite',
                id: 'id-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ID_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ID_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ID_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'symbol',
                source: 'composite',
                id: 'id-contours-5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ID_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-6-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ID_Contours_6_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'line',
                source: 'composite',
                id: 'id-contours-6-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ID_Contours_6_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '008b06506892c9938930aa43d79fa237',
                },
                type: 'symbol',
                source: 'composite',
                id: 'id-contours-6-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ID_Contours_6_projected',
            },
        ],
        created: '2018-06-08T16:50:43.632Z',
        modified: '2018-06-26T15:24:01.235Z',
        id: 'cji67j1yz05kw2rpaiv59th4e',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_4: {
        version: 8,
        name: 'AR-IN-KY-LA-TN Contours REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:groups': {
                '2deb90a9f08edbe4fbe367e0fde2bf49': {
                    name: 'LA Contours',
                    collapsed: true,
                },
                '341b88125d1675760e96bd7bc382d2f0': {
                    name: 'AR Group',
                    collapsed: true,
                },
                '46a19bf218b42415545e7438abac44a8': {
                    name: 'TN Group',
                    collapsed: true,
                },
                bedb4ea91ca658b1b2d2783efa858713: {
                    name: 'IN Group',
                    collapsed: true,
                },
                '60e08141b087102f071303edd76b05ee': {
                    name: 'KY Group',
                    collapsed: true,
                },
            },
            'mapbox:sdk-support': {
                js: '0.40.0',
                android: '5.2.0',
                ios: '3.7.0',
            },
        },
        center: [-84.71033523602483, 38.053843983177984],
        zoom: 17.1624358521865,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.fb8983ef,mapright2.9a24d02e,mapright2.1e954bf1,mapright2.05967208,mapright2.c4ceac15,mapright2.23064115,mapright2.c69584fa,mapright2.c83fe1b9,mapright2.3eaa4ba6,mapright2.db7bc1db,mapright2.84e9585b,mapright2.e2acef5e,mapright2.7f1202f4,mapright2.82deb33d,mapright2.a8f349f5',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cj1w9nvov00002tn367olvyvv',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2deb90a9f08edbe4fbe367e0fde2bf49',
                },
                type: 'line',
                source: 'composite',
                id: 'la-contours-middle-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'LA_Contours_Middle_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2deb90a9f08edbe4fbe367e0fde2bf49',
                },
                type: 'line',
                source: 'composite',
                id: 'la-contours-north-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'LA_Contours_North_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2deb90a9f08edbe4fbe367e0fde2bf49',
                },
                type: 'line',
                source: 'composite',
                id: 'la-contours-south-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'LA_Contours_South_webaux',
            },
            {
                metadata: {
                    'mapbox:group': '2deb90a9f08edbe4fbe367e0fde2bf49',
                },
                id: 'la-contours-middle-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'LA_Contours_Middle_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': '2deb90a9f08edbe4fbe367e0fde2bf49',
                },
                id: 'la-contours-south-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'LA_Contours_South_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': '2deb90a9f08edbe4fbe367e0fde2bf49',
                },
                id: 'la-contours-north-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'LA_Contours_North_webaux',
                minzoom: 13,
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2deb90a9f08edbe4fbe367e0fde2bf49',
                },
                type: 'symbol',
                source: 'composite',
                id: 'la-contours-middle-label',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'LA_Contours_Middle_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-max-angle': 30,
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'symbol-placement': 'line',
                    'text-padding': 1,
                    visibility: 'visible',
                    'text-rotation-alignment': 'map',
                    'text-field': '{ContourEle} FT',
                },
                metadata: {
                    'mapbox:group': '2deb90a9f08edbe4fbe367e0fde2bf49',
                },
                type: 'symbol',
                source: 'composite',
                id: 'la-contours-south-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'LA_Contours_South_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-padding': 1,
                    'text-rotation-alignment': 'map',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2deb90a9f08edbe4fbe367e0fde2bf49',
                },
                type: 'symbol',
                source: 'composite',
                id: 'la-contours-north-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'LA_Contours_North_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '341b88125d1675760e96bd7bc382d2f0',
                },
                type: 'line',
                source: 'composite',
                id: 'ar-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'AR_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '341b88125d1675760e96bd7bc382d2f0',
                },
                type: 'line',
                source: 'composite',
                id: 'ar-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'AR_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '341b88125d1675760e96bd7bc382d2f0',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ar-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AR_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '341b88125d1675760e96bd7bc382d2f0',
                },
                type: 'line',
                source: 'composite',
                id: 'ar-2-tn-3-contours-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'AR_2_TN_3_Contours',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '341b88125d1675760e96bd7bc382d2f0',
                },
                type: 'line',
                source: 'composite',
                id: 'ar-2-tn-3-contours-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'AR_2_TN_3_Contours',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '341b88125d1675760e96bd7bc382d2f0',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ar-2-tn-3-contours-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AR_2_TN_3_Contours',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '341b88125d1675760e96bd7bc382d2f0',
                },
                type: 'line',
                source: 'composite',
                id: 'ar-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'AR_Contours_3_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '341b88125d1675760e96bd7bc382d2f0',
                },
                type: 'line',
                source: 'composite',
                id: 'ar-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'AR_Contours_3_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '341b88125d1675760e96bd7bc382d2f0',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ar-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AR_Contours_3_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '46a19bf218b42415545e7438abac44a8',
                },
                type: 'line',
                source: 'composite',
                id: 'tn-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'TN_Contours_1_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '46a19bf218b42415545e7438abac44a8',
                },
                type: 'line',
                source: 'composite',
                id: 'tn-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'TN_Contours_1_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '46a19bf218b42415545e7438abac44a8',
                },
                type: 'symbol',
                source: 'composite',
                id: 'tn-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'TN_Contours_1_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '46a19bf218b42415545e7438abac44a8',
                },
                type: 'line',
                source: 'composite',
                id: 'tn-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'TN_Contours_2_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '46a19bf218b42415545e7438abac44a8',
                },
                type: 'line',
                source: 'composite',
                id: 'tn-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'TN_Contours_2_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '46a19bf218b42415545e7438abac44a8',
                },
                type: 'symbol',
                source: 'composite',
                id: 'tn-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'TN_Contours_2_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'bedb4ea91ca658b1b2d2783efa858713',
                },
                type: 'line',
                source: 'composite',
                id: 'in-contours-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'IN_Contours_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'bedb4ea91ca658b1b2d2783efa858713',
                },
                type: 'line',
                source: 'composite',
                id: 'in-contours-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'IN_Contours_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ELEV} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'bedb4ea91ca658b1b2d2783efa858713',
                },
                type: 'symbol',
                source: 'composite',
                id: 'in-contours-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'IN_Contours_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'KY_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'KY_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ky-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KY_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'KY_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'KY_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ky-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KY_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'KY_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'KY_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ky-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KY_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'KY_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'KY_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ky-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KY_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'KY_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'KY_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ky-contours-5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KY_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-6-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'KY_Contours_6_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ky-contours-6-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'KY_Contours_6_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '60e08141b087102f071303edd76b05ee',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ky-contours-6-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'KY_Contours_6_projected',
            },
        ],
        created: '2017-04-24T15:17:28.581Z',
        modified: '2018-06-11T17:42:17.683Z',
        id: 'cj1w9nvov00002tn367olvyvv',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    gsa: {
        version: 8,
        name: 'MS Gas Service Areas',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [-89.89115711308801, 34.023701004282856],
        zoom: 9.356121448438836,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.10890228',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cj9h8j7yx9o692rpciinzmxzm',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'ms-gas-service-areas',
                paint: {
                    'fill-opacity': {
                        base: 1,
                        stops: [
                            [0, 0.2],
                            [10, 0.25],
                            [13, 0.18],
                            [14, 0],
                        ],
                    },
                    'fill-color': 'rgba(245,143,10,1)',
                },
                'source-layer': 'MS_Gas_Service_Areas',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'ms-gas-service-areas copy',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [7, 3],
                            [9, 4],
                            [13, 7],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MS_Gas_Service_Areas',
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'ms-gas-service-areas copy 1',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [7, 2],
                            [9, 2],
                            [13, 1.7],
                        ],
                    },
                    'line-blur': 1.5,
                    'line-color': 'hsl(38, 100%, 50%)',
                },
                'source-layer': 'MS_Gas_Service_Areas',
            },
            {
                minzoom: 10,
                layout: {
                    'text-field': 'gas provider: {UTILITY_NA}',
                    'text-transform': 'uppercase',
                    'text-size': {
                        base: 1,
                        stops: [
                            [9, 8],
                            [11, 10],
                            [22, 16],
                        ],
                    },
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'symbol-avoid-edges': true,
                    'text-padding': {
                        base: 1,
                        stops: [
                            [9, 4],
                            [14, 2],
                            [22, 0],
                        ],
                    },
                    'text-max-width': {
                        base: 1,
                        stops: [
                            [10, 4],
                            [12, 10],
                        ],
                    },
                },
                type: 'symbol',
                source: 'composite',
                id: 'ms-gas-service-areas copy 2',
                paint: {
                    'text-halo-color': 'hsl(0, 0%, 0%)',
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                    'text-color': 'hsl(46, 100%, 51%)',
                },
                'source-layer': 'MS_Gas_Service_Areas',
            },
        ],
        created: '2017-11-01T16:06:57.914Z',
        modified: '2017-11-03T16:37:16.214Z',
        id: 'cj9h8j7yx9o692rpciinzmxzm',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    wsa: {
        version: 8,
        name: 'MS Water Service Areas',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [-90.74246490488514, 32.23393981289979],
        zoom: 10.975559848244623,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.ad9d5b62',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cj9h9lpgo9phg2smo5b0elaet',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'ms-water-service-areas',
                paint: {
                    'fill-opacity': {
                        base: 1,
                        stops: [
                            [0, 0.2],
                            [10, 0.25],
                            [13, 0.18],
                            [14, 0],
                        ],
                    },
                    'fill-color': 'hsl(224, 80%, 44%)',
                },
                'source-layer': 'MS_Water_Service_Areas',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ms-water-service-areas copy',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [7, 3],
                            [9, 4],
                            [13, 7],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MS_Water_Service_Areas',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ms-water-service-areas copy 1',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [7, 0.7],
                            [9, 1],
                            [13, 1.7],
                        ],
                    },
                    'line-color': 'hsl(222, 100%, 51%)',
                },
                'source-layer': 'MS_Water_Service_Areas',
            },
            {
                minzoom: 10,
                layout: {
                    'text-field': 'water provider: {UTILITY_NA}',
                    'text-transform': 'uppercase',
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': {
                        base: 1,
                        stops: [
                            [9, 8],
                            [11, 10],
                            [22, 16],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-padding': {
                        base: 1,
                        stops: [
                            [9, 4],
                            [14, 2],
                            [22, 0],
                        ],
                    },
                    'text-max-width': {
                        base: 1,
                        stops: [
                            [10, 5],
                            [12, 10],
                        ],
                    },
                },
                type: 'symbol',
                source: 'composite',
                id: 'ms-water-service-areas copy 2',
                paint: {
                    'text-halo-color': 'hsl(0, 0%, 0%)',
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                    'text-color': 'hsl(215, 100%, 85%)',
                },
                'source-layer': 'MS_Water_Service_Areas',
            },
        ],
        created: '2017-11-01T16:36:53.252Z',
        modified: '2017-11-03T16:35:44.874Z',
        id: 'cj9h9lpgo9phg2smo5b0elaet',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    esa: {
        version: 8,
        name: 'MS Electric Service Areas',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [-89.80117032480081, 34.51471102113145],
        zoom: 8.689207122462406,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.eddcf889',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cj9h4z1wn9kvi2rpcxloi5hjg',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'ms-electric-serviceareas-050817',
                paint: {
                    'fill-color': 'hsl(68, 64%, 42%)',
                    'fill-opacity': {
                        base: 1,
                        stops: [
                            [0, 0.2],
                            [2, 0.25],
                            [13, 0.18],
                            [14, 0],
                        ],
                    },
                    'fill-outline-color': 'rgba(217,250,5,1)',
                },
                'source-layer': 'MS_Electric_ServiceAreas_050817',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ms-electric-serviceareas-050817 copy',
                paint: {
                    'line-color': 'rgba(63,64,18,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [7, 3],
                            [9, 4],
                            [13, 7],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MS_Electric_ServiceAreas_050817',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ms-electric-serviceareas-050817 copy 1',
                paint: {
                    'line-color': 'hsl(61, 100%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [7, 0.7],
                            [9, 1],
                            [13, 1.75],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MS_Electric_ServiceAreas_050817',
            },
            {
                minzoom: 9,
                layout: {
                    'text-field': 'ELECTRIC SERVICE PROVIDER: {UTILITY_NA}',
                    'text-max-width': {
                        base: 1,
                        stops: [
                            [8, 6],
                            [12, 17],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [9, 8],
                            [11, 10],
                            [22, 16],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    visibility: 'visible',
                    'text-padding': {
                        base: 1,
                        stops: [
                            [9, 4],
                            [14, 2],
                            [22, 0],
                        ],
                    },
                },
                type: 'symbol',
                source: 'composite',
                id: 'ms-electric-serviceareas-050817 copy 2',
                paint: {
                    'text-color': 'hsl(61, 91%, 54%)',
                    'text-halo-color': 'hsl(0, 0%, 0%)',
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                },
                'source-layer': 'MS_Electric_ServiceAreas_050817',
            },
        ],
        created: '2017-11-01T14:27:18.106Z',
        modified: '2017-11-03T16:39:54.943Z',
        id: 'cj9h4z1wn9kvi2rpcxloi5hjg',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    ssa: {
        version: 8,
        name: 'MS Sewer Service Areas',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [-90.17223478872415, 32.516274772341205],
        zoom: 14.26030483923516,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.237ddae1',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cj9k49ipq0b8q2sswtqhbhd8i',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 7,
                layout: {
                    visibility: 'visible',
                },
                type: 'fill',
                source: 'composite',
                id: 'ms-sewer-service-areas',
                paint: {
                    'fill-color': 'hsl(146, 98%, 50%)',
                    'fill-opacity': {
                        base: 1,
                        stops: [
                            [0, 0.2],
                            [10, 0.25],
                            [13, 0.18],
                            [14, 0],
                        ],
                    },
                },
                'source-layer': 'MS_Sewer_Service_Areas',
            },
            {
                minzoom: 7,
                layout: {},
                metadata: {},
                type: 'line',
                source: 'composite',
                id: 'ms-sewer-service-areas copy 1',
                paint: {
                    'line-color': 'hsl(119, 82%, 20%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [7, 3],
                            [9, 4],
                            [13, 7],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MS_Sewer_Service_Areas',
            },
            {
                minzoom: 7,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ms-sewer-service-areas copy',
                paint: {
                    'line-color': 'rgba(3,252,0,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [7, 0.7],
                            [9, 1],
                            [13, 1.75],
                        ],
                    },
                },
                'source-layer': 'MS_Sewer_Service_Areas',
            },
            {
                minzoom: 9,
                layout: {
                    'text-field': '{UTILITY_NA}',
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-transform': 'uppercase',
                    'text-size': {
                        base: 1,
                        stops: [
                            [9, 8],
                            [11, 10],
                            [22, 16],
                        ],
                    },
                    'text-padding': {
                        base: 1,
                        stops: [
                            [9, 4],
                            [14, 2],
                            [22, 0],
                        ],
                    },
                    'symbol-avoid-edges': true,
                    'text-max-width': {
                        base: 1,
                        stops: [
                            [10, 6],
                            [22, 10],
                        ],
                    },
                },
                type: 'symbol',
                source: 'composite',
                id: 'ms-sewer-service-areas copy 2',
                paint: {
                    'text-color': 'hsl(141, 100%, 50%)',
                    'text-halo-color': 'hsl(0, 0%, 0%)',
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                },
                'source-layer': 'MS_Sewer_Service_Areas',
            },
        ],
        created: '2017-11-03T16:30:45.367Z',
        modified: '2017-11-03T16:52:07.721Z',
        id: 'cj9k49ipq0b8q2sswtqhbhd8i',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    conservation_easements: {
        version: 8,
        name: 'Conservation Easements V2',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.0.0',
                ios: '10.0.0',
                js: '2.3.0',
            },
            'mapbox:groups': {},
        },
        center: [-106.72153837470994, 39.156821224962954],
        zoom: 11.816179738301932,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.2db5b9ac,mapright2.b97e0b7a,mapright2.83f03be5,mapright2.4d815cc5',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cktd8mzxz13u317mv0hdovw0b/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'padus-easements-continent-west',
                type: 'fill',
                paint: {
                    'fill-color': '#ffffff',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'PADUS_Easements_Continent_West',
            },
            {
                id: 'padus-easements-continent-west copy',
                type: 'line',
                paint: {
                    'line-color': '#fdfcfc',
                    'line-width': 0.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'PADUS_Easements_Continent_West',
            },
            {
                id: 'padus-easements-continent-east',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 0%, 100%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'PADUS_Easements_Continent_East',
            },
            {
                id: 'padus-easements-continent-east copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 20%, 99%)',
                    'line-width': 0.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'PADUS_Easements_Continent_East',
            },
            {
                id: 'easements-hi-090821',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 0%, 100%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Easements_HI_090821',
            },
            {
                id: 'easements-hi-090821 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 20%, 99%)',
                    'line-width': 0.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Easements_HI_090821',
            },
            {
                id: 'easements-ak-090821',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 0%, 100%)',
                    'fill-opacity': 0.4,
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Easements_AK_090821',
            },
            {
                id: 'easements-ak-090821 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 20%, 99%)',
                    'line-width': 0.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Easements_AK_090821',
            },
        ],
        created: '2021-09-09T18:02:19.922Z',
        modified: '2021-09-22T18:25:42.966Z',
        id: 'cktd8mzxz13u317mv0hdovw0b',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_5: {
        version: 8,
        name: 'NC Contours Web REAL',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
        },
        center: [-82.8239455855491, 35.108794529039585],
        zoom: 14.169041975077983,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.77f8db7b,mapright2.f04c6916,mapright2.39759ff5,mapright2.3561b243,mapright2.09adfe53,mapright2.d944fddd,mapright2.0e174c50,mapright2.5a365571,mapright2.83a6767a,mapright2.89c19e0d,mapright2.10109600,mapright2.44c9fec8',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjeu0882u032v2rpf11vfxl95',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-1-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'NC_Tran_Hend_Jack_Hay_10ft_NEW',
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'set-2-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Mac_Clay_Cher_Gram_Swa_10ft_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-3-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Set_3_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-4-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Set_4_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-5-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Set_5_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-6-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Set_6_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-7-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Set_7_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-8-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Set_8_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-9-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Set_9_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-10-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Set_10_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-11-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Set_11_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-12-project-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'Set_12_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-1-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'NC_Tran_Hend_Jack_Hay_10ft_NEW',
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'set-2-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Mac_Clay_Cher_Gram_Swa_10ft_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-3-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Set_3_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-4-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Set_4_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-5-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Set_5_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-6-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Set_6_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-7-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Set_7_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-8-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Set_8_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-9-project-yellow',
                paint: {
                    'line-color': 'rgb(242, 185, 13)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Set_9_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-10-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Set_10_Project',
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'set-11-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Set_11_Project',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'set-12-project-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                'source-layer': 'Set_12_Project',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-transform': 'uppercase',
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-1-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NC_Tran_Hend_Jack_Hay_10ft_NEW',
            },
            {
                minzoom: 13,
                layout: {
                    'symbol-placement': 'line',
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-2-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'Mac_Clay_Cher_Gram_Swa_10ft_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-3-project-label',
                paint: {
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                    'text-color': 'rgb(252, 233, 89)',
                },
                'source-layer': 'Set_3_Project',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-4-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'Set_4_Project',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-5-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'Set_5_Project',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-6-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'Set_6_Project',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-7-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'Set_7_Project',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-8-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'Set_8_Project',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-9-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'Set_9_Project',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-10-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'Set_10_Project',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-11-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'Set_11_Project',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{Z_Feet} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'set-12-project-label',
                paint: {
                    'text-color': 'rgb(252, 233, 89)',
                    'text-halo-color': 'rgb(8, 8, 8)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'Set_12_Project',
            },
        ],
        created: '2018-03-16T13:53:59.617Z',
        modified: '2018-03-28T16:32:57.791Z',
        id: 'cjeu0882u032v2rpf11vfxl95',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    city_limits: {
        version: 8,
        name: 'City Limits REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.40.0',
                android: '5.2.0',
                ios: '3.7.0',
            },
            'mapbox:groups': {},
        },
        center: [-96.55504507318176, 32.936929794198306],
        zoom: 10.052286520876308,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.12b7bef3,mapright2.dc47f6b9,mapright2.33bd043d,mapright2.2ac19d1a',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjg5g8wax025z2rqeqesekbbd/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: 'citylimits-east',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(328, 100%, 42%)',
                    'fill-opacity': {
                        base: 1,
                        stops: [
                            [8, 0.15],
                            [11, 0.1],
                            [12, 0.05],
                            [13, 0],
                        ],
                    },
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_East',
            },
            {
                id: 'citylimits-east copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(328, 95%, 59%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [9, 1],
                            [10, 1.1],
                            [12, 2.5],
                        ],
                    },
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_East',
            },
            {
                id: 'citylimits-east copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(326, 100%, 84%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [9, 1],
                            [10, 1.1],
                            [12, 2.5],
                        ],
                    },
                    'line-dasharray': [1, 1],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_East',
            },
            {
                id: 'citylimits-east copy 2',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(324, 45%, 98%)',
                    'text-halo-color': 'hsl(323, 100%, 16%)',
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': '{NAME}',
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [9, 9],
                            [11, 10],
                            [13, 12],
                            [14, 14],
                        ],
                    },
                    'text-max-width': 5,
                    'text-transform': 'uppercase',
                    'text-padding': 5,
                },
                source: 'composite',
                'source-layer': 'CityLimits_East',
            },
            {
                id: 'citylimits-west',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(328, 100%, 42%)',
                    'fill-opacity': {
                        base: 1,
                        stops: [
                            [8, 0.15],
                            [11, 0.1],
                            [12, 0.05],
                            [13, 0],
                        ],
                    },
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_West',
            },
            {
                id: 'citylimits-west copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(328, 95%, 59%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [9, 1],
                            [10, 1.1],
                            [12, 2.5],
                        ],
                    },
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_West',
            },
            {
                id: 'citylimits-west copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(326, 100%, 84%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [9, 1],
                            [10, 1.1],
                            [12, 2.5],
                        ],
                    },
                    'line-dasharray': [1, 1],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_West',
            },
            {
                id: 'citylimits-west copy 2',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(324, 45%, 98%)',
                    'text-halo-color': 'hsl(323, 100%, 16%)',
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': '{NAME}',
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [9, 9],
                            [11, 10],
                            [13, 12],
                            [14, 14],
                        ],
                    },
                    'text-max-width': 5,
                    'text-transform': 'uppercase',
                    'text-padding': 5,
                },
                source: 'composite',
                'source-layer': 'CityLimits_West',
            },
            {
                id: 'citylimits-ak',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(328, 100%, 42%)',
                    'fill-opacity': {
                        base: 1,
                        stops: [
                            [8, 0.15],
                            [11, 0.1],
                            [12, 0.05],
                            [13, 0],
                        ],
                    },
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_AK',
            },
            {
                id: 'citylimits-ak copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(328, 95%, 59%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [9, 1],
                            [10, 1.1],
                            [12, 2.5],
                        ],
                    },
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_AK',
            },
            {
                id: 'citylimits-ak copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(326, 100%, 84%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [9, 1],
                            [10, 1.1],
                            [12, 2.5],
                        ],
                    },
                    'line-dasharray': [1, 1],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_AK',
            },
            {
                id: 'citylimits-ak copy 2',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(324, 45%, 98%)',
                    'text-halo-color': 'hsl(323, 100%, 16%)',
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': '{NAME}',
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [9, 9],
                            [11, 10],
                            [13, 12],
                            [14, 14],
                        ],
                    },
                    'text-max-width': 5,
                    'text-transform': 'uppercase',
                    'text-padding': 5,
                },
                source: 'composite',
                'source-layer': 'CityLimits_AK',
            },
            {
                id: 'citylimits-hi',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(328, 100%, 42%)',
                    'fill-opacity': {
                        base: 1,
                        stops: [
                            [8, 0.15],
                            [11, 0.1],
                            [12, 0.05],
                            [13, 0],
                        ],
                    },
                    'fill-antialias': false,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_HI',
            },
            {
                id: 'citylimits-hi copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(328, 95%, 59%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [9, 1],
                            [10, 1.1],
                            [12, 2.5],
                        ],
                    },
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_HI',
            },
            {
                id: 'citylimits-hi copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(326, 100%, 84%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [9, 1],
                            [10, 1.1],
                            [12, 2.5],
                        ],
                    },
                    'line-dasharray': [1, 1],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CityLimits_HI',
            },
            {
                id: 'citylimits-hi copy 2',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(324, 45%, 98%)',
                    'text-halo-color': 'hsl(323, 100%, 16%)',
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': '{NAME}',
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [9, 9],
                            [11, 10],
                            [13, 12],
                            [14, 14],
                        ],
                    },
                    'text-max-width': 5,
                    'text-transform': 'uppercase',
                    'text-padding': 5,
                },
                source: 'composite',
                'source-layer': 'CityLimits_HI',
            },
        ],
        created: '2018-04-18T18:47:35.455Z',
        modified: '2021-06-21T15:50:08.140Z',
        id: 'cjg5g8wax025z2rqeqesekbbd',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    soils_1: {
        version: 8,
        name: 'Soils Group 1 061819',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.54.0',
                android: '6.7.0',
                ios: '4.7.0',
            },
            'mapbox:groups': {
                d6c50ccbc81418f054609285f6052662: {
                    name: 'SC WV Group',
                    collapsed: true,
                },
                '59b6418e40b9b70d146ee9eb632c15b4': {
                    name: 'GA1 Group',
                    collapsed: true,
                },
                '06a3e591ba92227383a1825fa4814aad': {
                    name: 'VA Group',
                    collapsed: true,
                },
                '9ff2ac01181841d5d9cf5a3da5557fa8': {
                    name: 'FL Group',
                    collapsed: true,
                },
                '0cea2c03af1f1b3cf92ff1bd52e0467b': {
                    name: 'MS Group',
                    collapsed: true,
                },
                '4cb6768261fe9b5256b51d0cebf614de': {
                    name: 'GA2 Group',
                    collapsed: true,
                },
                c44b7561c549a3ccd585f0e04879ad66: {
                    name: 'TX2 Group',
                    collapsed: false,
                },
                '101c9dbdee0b6afe62766367bd626ec0': {
                    name: 'TX2 Group',
                    collapsed: false,
                },
                f94f741008baf5f439b2bc5b074e6ddc: {
                    name: 'NC Group',
                    collapsed: true,
                },
                dcdd479d9bf9fe76c9fe8e8274a98ce0: {
                    name: 'TX1 Group',
                },
                d31f56739b864b847d25af61aba7161a: {
                    name: 'AR LA Group',
                    collapsed: true,
                },
                '9f7855f47f84ab275263ca66173f267f': {
                    name: 'MO Group',
                    collapsed: true,
                },
                '6b16536175f24ab4ed6eadfe9cbe8219': {
                    name: 'TN Group',
                    collapsed: true,
                },
                '2d414e3145ba82709ae3ebde9d4c691b': {
                    name: 'OK Group',
                    collapsed: true,
                },
                d804d5543c319ea2d53dedb4362b9fa7: {
                    name: 'AL Group',
                    collapsed: true,
                },
                '811ac32a7c788cb2b815d064eabf40f2': {
                    name: 'TX1 Group',
                    collapsed: true,
                },
                '9dd9779b9c63bc0bc72f1c9ab9f501f7': {
                    name: 'KY Group',
                    collapsed: true,
                },
            },
        },
        center: [-96.96220582013461, 32.843991746600736],
        zoom: 14.951086175820416,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.da502cf6,mapright2.406701ea,mapright2.bbip8bbk,mapright2.92bd3aa4,mapright2.537f75b5,mapright2.9l67r7ir,mapright2.ad99da06,mapright2.cisdapq4,mapright2.4e9278ff,mapright2.e5300163,mapright2.bfar0iwy,mapright2.8x3k0bdj,mapright2.9cra99by,mapright2.39fon451,mapright2.2fhq9s41',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjx1v62qk0jj91cnvj1paji8w/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8', '8s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MO_Soils8',
                paint: {
                    'fill-color': '#ff2200',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MO_Soils7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MO_Soils6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MO_Soils5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MO_Soils4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MO_Soils3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MO_Soils2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MO_Soils1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MO_Soils0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MO_Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                type: 'line',
                source: 'composite',
                id: 'MO_Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '9f7855f47f84ab275263ca66173f267f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'MO_Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'MO_Soil_20190214',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AR-LA_Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AR-LA_Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AR-LA_Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AR-LA_Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AR-LA_Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AR-LA_Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AR-LA_Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'AR-LA_Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'AR-LA_Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'AR-LA_Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                type: 'line',
                source: 'composite',
                id: 'AR-LA_Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'd31f56739b864b847d25af61aba7161a',
                },
                type: 'symbol',
                source: 'composite',
                id: 'AR-LA_Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'AR_LA_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MS-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MS-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MS-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MS-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MS-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MS-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MS-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MS-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'MS-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MS-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                type: 'line',
                source: 'composite',
                id: 'MS-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '0cea2c03af1f1b3cf92ff1bd52e0467b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'MS-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'MS_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'VA-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'VA-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'VA-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5e', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'VA-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'VA-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'VA-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'VA-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'VA-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'VA-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Dam', 'Water'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'VA-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                type: 'line',
                source: 'composite',
                id: 'VA-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '06a3e591ba92227383a1825fa4814aad',
                },
                type: 'symbol',
                source: 'composite',
                id: 'VA-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'VA_Soil_201904010',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA2-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA2-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA2-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA2-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA2-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA2-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA2-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1c', '1e', '1s', '1w', '1'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA2-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'GA2-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Miscellaneous water'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA2-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                type: 'line',
                source: 'composite',
                id: 'GA2-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '4cb6768261fe9b5256b51d0cebf614de',
                },
                type: 'symbol',
                source: 'composite',
                id: 'GA2-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'GA2B',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA1-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA1-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA1-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA1-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA1-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA1-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA1-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA1-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'GA1-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Miscellaneous water'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'GA1-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                type: 'line',
                source: 'composite',
                id: 'GA1-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '59b6418e40b9b70d146ee9eb632c15b4',
                },
                type: 'symbol',
                source: 'composite',
                id: 'GA1-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'GA_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AL-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AL-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6c', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AL-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AL-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AL-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AL-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AL-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AL-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'AL-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'AL-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                type: 'line',
                source: 'composite',
                id: 'AL-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'd804d5543c319ea2d53dedb4362b9fa7',
                },
                type: 'symbol',
                source: 'composite',
                id: 'AL-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'AL_Soil_20190215',
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8w', '8s', '8', '8e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OK-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OK_Soil_20190226',
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OK-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OK_Soil_20190226',
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OK-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OK_Soil_20190226',
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
            },
            {
                layout: {},
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OK-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OK_Soil_20190226',
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OK-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OK_Soil_20190226',
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3c', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OK-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OK_Soil_20190226',
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2c', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OK-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OK_Soil_20190226',
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1s', '1', '1e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OK-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OK_Soil_20190226',
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
            },
            {
                layout: {},
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OK-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OK_Soil_20190226',
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Large dam', 'Water'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OK-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OK_Soil_20190226',
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
                type: 'line',
                source: 'composite',
                id: 'OK-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'OK_Soil_20190226',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '2d414e3145ba82709ae3ebde9d4c691b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'OK-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'OK_Soil_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8w', '8'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'FL-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'FL-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'FL-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'FL-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'FL-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'FL-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'FL-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'FL-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'FL-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'FL-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                type: 'line',
                source: 'composite',
                id: 'FL-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '9ff2ac01181841d5d9cf5a3da5557fa8',
                },
                type: 'symbol',
                source: 'composite',
                id: 'FL-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'FL_Soil_20190216',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TN-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TN-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TN-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TN-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TN-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TN-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TN-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TN-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'TN-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                filter: ['match', ['get', 'name'], ['', 'Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'TN-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                type: 'line',
                source: 'composite',
                id: 'TN-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '6b16536175f24ab4ed6eadfe9cbe8219',
                },
                type: 'symbol',
                source: 'composite',
                id: 'TN-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'TN_Soil_20190330',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'KY-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'KY-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                type: 'line',
                source: 'composite',
                id: 'KY-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '9dd9779b9c63bc0bc72f1c9ab9f501f7',
                },
                type: 'symbol',
                source: 'composite',
                id: 'KY-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'KY_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NC-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NC-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NC-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NC-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NC-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NC-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NC-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NC-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'NC-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'NC-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                type: 'line',
                source: 'composite',
                id: 'NC-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'f94f741008baf5f439b2bc5b074e6ddc',
                },
                type: 'symbol',
                source: 'composite',
                id: 'NC-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'NC_Soil_20190311',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SC-WV-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SC-WV-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SC-WV-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SC-WV-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SC-WV-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SC-WV-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SC-WV-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'SC-WV-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'SC-WV-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Miscellaneous water', 'Water'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SC-WV-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                type: 'line',
                source: 'composite',
                id: 'SC-WV-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'd6c50ccbc81418f054609285f6052662',
                },
                type: 'symbol',
                source: 'composite',
                id: 'SC-WV-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'SC_WV_Soils_041519',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['8e', '8s', '8', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX2-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['7w', '7c', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX2-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['6w', '6c', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX2-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['5w', '5e', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX2-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['4w', '4c', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX2-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['3s', '3w', '3c', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX2-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['2s', '2w', '2c', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX2-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                filter: ['match', ['get', 'Capability'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'TX2-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                filter: ['match', ['get', 'Capability'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'TX2-Soils-0',
                paint: {
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                filter: [
                    'match',
                    ['get', 'Name'],
                    [
                        'Water greater than 40 acres in size',
                        'Water',
                        'Water, miscellaneous',
                        'Water, salt lakes',
                    ],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX2-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                type: 'line',
                source: 'composite',
                id: 'TX2-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'c44b7561c549a3ccd585f0e04879ad66',
                },
                type: 'symbol',
                source: 'composite',
                id: 'TX2-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'TX_Soil_2_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['8', '8w', '8c', '8e', '8s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX1-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['7', '7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX1-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['6', '6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX1-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['5', '5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX1-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['4', '4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX1-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['3', '3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX1-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['2', '2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX1-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                filter: [
                    'match',
                    ['get', 'Capability'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX1-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                filter: ['match', ['get', 'Capability'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'TX1-Soils-0',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                filter: [
                    'match',
                    ['get', 'Name'],
                    [
                        'Water',
                        'Water greater than 40 acres in size',
                        'Water, miscellaneous',
                        'Water, salt lakes',
                    ],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'TX1-Soils-Water',
                paint: {
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                    'fill-color': 'hsl(203, 95%, 43%)',
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                type: 'line',
                source: 'composite',
                id: 'TX1-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'dcdd479d9bf9fe76c9fe8e8274a98ce0',
                },
                type: 'symbol',
                source: 'composite',
                id: 'TX1-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'TX_Soil_1_20200505',
            },
        ],
        created: '2019-06-18T13:49:13.035Z',
        modified: '2020-05-18T16:34:48.142Z',
        id: 'cjx1v62qk0jj91cnvj1paji8w',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    soils_2: {
        version: 8,
        name: 'Soils Group 2 061819',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.54.0',
                android: '6.7.0',
                ios: '4.7.0',
            },
            'mapbox:groups': {
                '90bdb24762d784c90066ec05f2696107': {
                    name: 'OH2 Group',
                    collapsed: true,
                },
                fd49efc75ea7112dc4f6e85b55c9c5c0: {
                    name: 'IN1 Group',
                    collapsed: true,
                },
                '75d1348ca4692661256e94ebc3e21a36': {
                    name: 'IL1 Group',
                    collapsed: true,
                },
                '2b34f785cca4f7c853c91c13d7fd8174': {
                    name: 'MN3 Group',
                    collapsed: true,
                },
                '546fddb780415b1dd8a0c5de7c9c6e1d': {
                    name: 'IN2 Group',
                    collapsed: true,
                },
                '911d6304e01dcfcf9fa96e5b0eeab70a': {
                    name: 'WI1 Group',
                    collapsed: true,
                },
                '67a25f4c801cb448c86ce09296d3ee29': {
                    name: 'MN2 Group',
                    collapsed: true,
                },
                '101c9dbdee0b6afe62766367bd626ec0': {
                    name: 'TX2 Group',
                    collapsed: true,
                },
                '72faca7cf8f526bd48a4b7bcf7950f91': {
                    name: 'IL2 Group',
                    collapsed: true,
                },
                '1f3eb7bea29ed034387e954e78c8ed0a': {
                    name: 'IA2 Group',
                    collapsed: true,
                },
                '0dfaedf5c494efb3a8b4fd158ad77eef': {
                    name: 'WI2 Group',
                    collapsed: true,
                },
                b9eac63bc07e4792d03c0ce46dbb1c7a: {
                    name: 'IL3 Group',
                    collapsed: true,
                },
                '58535f32a9754718a83cf461c4d5ee2e': {
                    name: 'MN1 Group',
                    collapsed: true,
                },
                '7f206634539ca70ad3b0df7883c66f33': {
                    name: 'IA1 Group',
                    collapsed: true,
                },
                '0493949b0b071a61b9938846ab5d1e01': {
                    name: 'IA3 Group',
                    collapsed: true,
                },
                '961951e174a9f8106bed3a48c698fbdd': {
                    name: 'OH1 Group',
                    collapsed: true,
                },
            },
        },
        center: [-93.23425241360849, 43.152468094413194],
        zoom: 14.23032397346668,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.7y0vtzhv,mapright2.dkrt4xpa,mapright2.dwoku2ui,mapright2.6nvdl0xt,mapright2.43qjykie,mapright2.4sn6wwv7,mapright2.019v14q9,mapright2.bsjfxqk1,mapright2.9xg4vhg7,mapright2.0vrvf4pg,mapright2.0pswsafd,mapright2.29eqkyiy,mapright2.dq9j78c8,mapright2.c7mnjxvk,mapright2.27hjai81',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjxeoxijp0vc81cqcceje16z2/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH1-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH1-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH1-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OH1-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH1-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH1-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH1-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OH1-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OH1-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OH1-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                type: 'line',
                source: 'composite',
                id: 'OH1-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '961951e174a9f8106bed3a48c698fbdd',
                },
                type: 'symbol',
                source: 'composite',
                id: 'OH1-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'OH1_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH2-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH2-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH2-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OH2-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH2-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH2-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OH2-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OH2-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'OH2-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OH2-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                type: 'line',
                source: 'composite',
                id: 'OH2-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '90bdb24762d784c90066ec05f2696107',
                },
                type: 'symbol',
                source: 'composite',
                id: 'OH2-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'OH2_Soils_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8w', '8s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN1-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN1-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN1-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN1-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN1-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN1-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2c', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN1-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MN1-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'MN1-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MN1-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                type: 'line',
                source: 'composite',
                id: 'MN1-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '58535f32a9754718a83cf461c4d5ee2e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'MN1-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'MN_Soil_1_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN2-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN2-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN2-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MN2-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN2-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN2-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2c', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN2-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MN2-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'MN2-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MN2-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                type: 'line',
                source: 'composite',
                id: 'MN2-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '67a25f4c801cb448c86ce09296d3ee29',
                },
                type: 'symbol',
                source: 'composite',
                id: 'MN2-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'MN_Soil_2_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN3-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN3-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN3-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MN3-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4s', '4e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN3-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN3-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2c', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN3-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MN3-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'MN3-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water, miscellaneous', 'Water', 'Water, intermittent'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MN3-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                type: 'line',
                source: 'composite',
                id: 'MN3-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '2b34f785cca4f7c853c91c13d7fd8174',
                },
                type: 'symbol',
                source: 'composite',
                id: 'MN3-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'MN_Soil_3_20190312',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL1-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL1-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL1-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL1-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL1-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL1-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL1-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IL1-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'IL1-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IL1-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                type: 'line',
                source: 'composite',
                id: 'IL1-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '75d1348ca4692661256e94ebc3e21a36',
                },
                type: 'symbol',
                source: 'composite',
                id: 'IL1-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'IL_Soil1_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL2-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL2-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL2-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IL2-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL2-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL2-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL2-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IL2-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'IL2-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IL2-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                type: 'line',
                source: 'composite',
                id: 'IL2-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '72faca7cf8f526bd48a4b7bcf7950f91',
                },
                type: 'symbol',
                source: 'composite',
                id: 'IL2-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'IL_Soil2_20190228',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL3-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL3-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL3-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL3-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL3-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL3-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL3-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IL3-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'IL3-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Miscellaneous water', 'Water'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IL3-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                type: 'line',
                source: 'composite',
                id: 'IL3-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'b9eac63bc07e4792d03c0ce46dbb1c7a',
                },
                type: 'symbol',
                source: 'composite',
                id: 'IL3-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'IL_Soil3_20190301',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8w', '8s', '8'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN1-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN1-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN1-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IN1-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN1-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN1-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN1-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IN1-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['', 'e', 's', 'w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN1-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IN1-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                type: 'line',
                source: 'composite',
                id: 'IN1-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'fd49efc75ea7112dc4f6e85b55c9c5c0',
                },
                type: 'symbol',
                source: 'composite',
                id: 'IN1-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'IN_Soils1_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8w', '8s', '8'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN2-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN2-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN2-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IN2-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN2-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN2-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN2-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IN2-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['', 'e', 'w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IN2-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IN2-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                type: 'line',
                source: 'composite',
                id: 'IN2-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '546fddb780415b1dd8a0c5de7c9c6e1d',
                },
                type: 'symbol',
                source: 'composite',
                id: 'IN2-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'IN_Soils2_20190227',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI1-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI1-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI1-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5e', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI1-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI1-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI1-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI1-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'WI1-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'WI1-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Miscellaneous water', 'Water'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI1-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                type: 'line',
                source: 'composite',
                id: 'WI1-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '911d6304e01dcfcf9fa96e5b0eeab70a',
                },
                type: 'symbol',
                source: 'composite',
                id: 'WI1-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'WI_Soil_1_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI2-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI2-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI2-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI2-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI2-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI2-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WI2-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'WI2-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'WI2-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'WI2-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                type: 'line',
                source: 'composite',
                id: 'WI2-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '0dfaedf5c494efb3a8b4fd158ad77eef',
                },
                type: 'symbol',
                source: 'composite',
                id: 'WI2-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'WI_Soil_2_20190412',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA1-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA1-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA1-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA1-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA1-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA1-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA1-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IA1-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IA1-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Water, rivers and streams'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA1-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                type: 'line',
                source: 'composite',
                id: 'IA1-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '7f206634539ca70ad3b0df7883c66f33',
                },
                type: 'symbol',
                source: 'composite',
                id: 'IA1-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'IA_Soils_1_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8w', '8s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA2-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA2-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA2-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA2-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA2-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA2-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA2-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IA2-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IA2-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    [
                        'Water',
                        'Water, rivers and streams',
                        'Water, waste lagoon',
                    ],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA2-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                type: 'line',
                source: 'composite',
                id: 'IA2-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '1f3eb7bea29ed034387e954e78c8ed0a',
                },
                type: 'symbol',
                source: 'composite',
                id: 'IA2-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'IA_Soils_2_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA3-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA3-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA3-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IA3-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA3-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA3-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA3-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IA3-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'IA3-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Water, rivers and streams'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'IA3-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                type: 'line',
                source: 'composite',
                id: 'IA3-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '0493949b0b071a61b9938846ab5d1e01',
                },
                type: 'symbol',
                source: 'composite',
                id: 'IA3-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'IA_Soils_3_20190303',
            },
        ],
        created: '2019-06-27T13:15:36.244Z',
        modified: '2019-07-01T13:55:11.227Z',
        id: 'cjxeoxijp0vc81cqcceje16z2',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    soils_3: {
        version: 8,
        name: 'Soils Group 3 061819',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.54.0',
                android: '6.7.0',
                ios: '4.7.0',
            },
            'mapbox:groups': {
                '6b3b4b6c25eafb9eb17e47f9b3f21f76': {
                    name: 'NE Group',
                    collapsed: true,
                },
                fd81ff6df9a572026e7c332340a79b47: {
                    name: 'WA Group',
                    collapsed: true,
                },
                '353d2fd3ad793d87e78b551abf4030a6': {
                    name: 'KS Group',
                    collapsed: true,
                },
                '989aedf970327c0de9230f5d61f5ec94': {
                    name: 'ND1 Group',
                    collapsed: true,
                },
                '17db5a58c3755873602f983edcab0b32': {
                    name: 'OR Group',
                    collapsed: true,
                },
                cc30ed04a7f2be742efdd95bfb5c8530: {
                    name: 'AZ Group',
                    collapsed: true,
                },
                '39b7df04099cd5b9ebea7dd51fccd137': {
                    name: 'NM Group',
                    collapsed: true,
                },
                cc44c70bb77b72dcf10ab87f47b91ef1: {
                    name: 'ND2 Group',
                    collapsed: true,
                },
                '537790392be702cc86d944833a75a8d0': {
                    name: 'NV-UT Group',
                    collapsed: true,
                },
                '64c733c13690e3a99599a919fc59a4e2': {
                    name: 'CO Group',
                    collapsed: true,
                },
                e12e783b220f65eccdfb725fc0f41ebf: {
                    name: 'SD Group',
                    collapsed: true,
                },
                '76e1a404862314d8006d5fd3d3ab1aa9': {
                    name: 'CA Group',
                    collapsed: true,
                },
                ab92f75607c8dfec77d826da4f4a7aa9: {
                    name: 'MT1 Group',
                    collapsed: true,
                },
                d41fa3c47dac77b96462938a810c8afe: {
                    name: 'ID-WY Group',
                    collapsed: true,
                },
                eda30b3ce9a3ecb47545898cd40e1997: {
                    name: 'MT2 Group',
                    collapsed: true,
                },
            },
        },
        center: [-103.4177116233954, 47.05658955096649],
        zoom: 14.305347230018292,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.b73887ab,mapright2.1dd1daba,mapright2.cbaeeb95,mapright2.b13383e7,mapright2.9hud2ac6,mapright2.8fodybey,mapright2.4xlrford,mapright2.2ep960yj,mapright2.bob4it8v,mapright2.8e467fb4,mapright2.47ecst3e,mapright2.c4dde7c6,mapright2.60t7op60,mapright2.2xmhelhi,mapright2.b5672b23',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjxghql4l2jko1cpurdqoa1wb/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AZ-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AZ_Soils_20190220',
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AZ-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AZ_Soils_20190220',
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AZ-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AZ_Soils_20190220',
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AZ-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AZ_Soils_20190220',
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AZ-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AZ_Soils_20190220',
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AZ-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AZ_Soils_20190220',
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AZ-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AZ_Soils_20190220',
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AZ-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AZ_Soils_20190220',
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
            },
            {
                layout: {},
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'AZ-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AZ_Soils_20190220',
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
            },
            {
                layout: {},
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'AZ-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'AZ_Soils_20190220',
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
                type: 'line',
                source: 'composite',
                id: 'AZ-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'AZ_Soils_20190220',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'cc30ed04a7f2be742efdd95bfb5c8530',
                },
                type: 'symbol',
                source: 'composite',
                id: 'AZ-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'AZ_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8w', '8s', '8'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CA-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7c', '7e', '7', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CA-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6c', '6e', '6s', '6'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CA-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5e', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CA-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4c', '4e', '4', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CA-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3c', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CA-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e', '2c'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CA-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'CA-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'CA-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'CA-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                type: 'line',
                source: 'composite',
                id: 'CA-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '76e1a404862314d8006d5fd3d3ab1aa9',
                },
                type: 'symbol',
                source: 'composite',
                id: 'CA-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'CA_Soils_20190220',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT1-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT1-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6c', '6e', '6', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT1-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MT1-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT1-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3w', '3c', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT1-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['2e'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MT1-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MT1-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MT1-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Miscellaneous water', 'Water'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT1-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                type: 'line',
                source: 'composite',
                id: 'MT1-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'ab92f75607c8dfec77d826da4f4a7aa9',
                },
                type: 'symbol',
                source: 'composite',
                id: 'MT1-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'MT_Soil1_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8w', '8s', '8'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT2-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT2-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6c', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT2-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MT2-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT2-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3c', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT2-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2c', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT2-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MT2-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['', 's'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT2-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    [
                        'Wet land',
                        'Lakes and streams',
                        'Water-Riverwash association',
                        'Water',
                    ],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MT2-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                type: 'line',
                source: 'composite',
                id: 'MT2-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'eda30b3ce9a3ecb47545898cd40e1997',
                },
                type: 'symbol',
                source: 'composite',
                id: 'MT2-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'MT_Soil2_20190226',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8w', '8s', '8'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OR-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7c', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OR-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7c', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OR-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OR-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4c', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OR-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3c', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OR-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2c', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'OR-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OR-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OR-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'OR-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                type: 'line',
                source: 'composite',
                id: 'OR-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '17db5a58c3755873602f983edcab0b32',
                },
                type: 'symbol',
                source: 'composite',
                id: 'OR-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'OR_Soil_20190328',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8w', '8s', '8'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NE-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NE-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NE-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'NE-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NE-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3c', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NE-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e', '2c'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NE-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'NE-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'NE-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'NE-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                type: 'line',
                source: 'composite',
                id: 'NE-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '6b3b4b6c25eafb9eb17e47f9b3f21f76',
                },
                type: 'symbol',
                source: 'composite',
                id: 'NE-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'NE_Soil_20190313',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8s', '8e', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WA-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7c', '7e', '7s', '7w', '7'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WA-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6', '6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WA-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5', '5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WA-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4', '4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WA-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3', '3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WA-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2', '2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WA-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WA-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'WA-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    [
                        'Water',
                        'Water, saline',
                        'Water, fresh',
                        'Water, misc',
                        'Miscellaneous Water',
                    ],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'WA-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                type: 'line',
                source: 'composite',
                id: 'WA-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'fd81ff6df9a572026e7c332340a79b47',
                },
                type: 'symbol',
                source: 'composite',
                id: 'WA-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'WA_Soil_20190411',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8w', '8s', '8'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ID-WY-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7c', '7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ID-WY-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6c', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ID-WY-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5s', '5e', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ID-WY-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4c', '4s', '4e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ID-WY-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3c', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ID-WY-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2c', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ID-WY-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ID-WY-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ID-WY-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ID-WY-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                type: 'line',
                source: 'composite',
                id: 'ID-WY-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'd41fa3c47dac77b96462938a810c8afe',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ID-WY-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'ID_WY_Soils_042319',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NV-UT-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7c', '7e', '7s', '7'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NV-UT-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6c', '6s', '6', '6e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NV-UT-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5c', '5e', '5s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NV-UT-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4c', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NV-UT-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e', '3c'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NV-UT-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2c', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NV-UT-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'NV-UT-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                type: 'fill',
                source: 'composite',
                id: 'NV-UT-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'NV-UT-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-antialias': false,
                    'fill-opacity': 0.62,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                type: 'line',
                source: 'composite',
                id: 'NV-UT-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '537790392be702cc86d944833a75a8d0',
                },
                type: 'symbol',
                source: 'composite',
                id: 'NV-UT-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'NV_UT_Soils_050219',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CO-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7', '7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CO-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6', '6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CO-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5', '5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CO-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4', '4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CO-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3', '3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CO-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2', '2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CO-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CO-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'CO-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Intermittent Water'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'CO-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                type: 'line',
                source: 'composite',
                id: 'CO-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '64c733c13690e3a99599a919fc59a4e2',
                },
                type: 'symbol',
                source: 'composite',
                id: 'CO-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'CO_Soil_20190221',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SD-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7', '7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SD-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6', '6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SD-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5', '5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SD-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4', '4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SD-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3', '3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SD-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2', '2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SD-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'SD-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'SD-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'SD-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                type: 'line',
                source: 'composite',
                id: 'SD-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'e12e783b220f65eccdfb725fc0f41ebf',
                },
                type: 'symbol',
                source: 'composite',
                id: 'SD-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'SD_Soils_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KS-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7', '7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KS-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6', '6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KS-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5', '5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KS-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4', '4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KS-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3', '3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KS-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2', '2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KS-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KS-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'KS-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Miscellaneous water'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KS-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                type: 'line',
                source: 'composite',
                id: 'KS-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '353d2fd3ad793d87e78b551abf4030a6',
                },
                type: 'symbol',
                source: 'composite',
                id: 'KS-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'KS_Soil_20190225',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NM-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7', '7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NM-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6', '6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NM-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5', '5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NM-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4', '4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NM-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3', '3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NM-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2', '2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NM-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'NM-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'NM-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'NM-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                type: 'line',
                source: 'composite',
                id: 'NM-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '39b7df04099cd5b9ebea7dd51fccd137',
                },
                type: 'symbol',
                source: 'composite',
                id: 'NM-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'NM_Soil_20190327',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND1-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7e', '7s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND1-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND1-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ND1-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND1-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND1-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2c', '2e'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND1-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ND1-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'ND1-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Water, intermittent'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND1-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                type: 'line',
                source: 'composite',
                id: 'ND1-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': '989aedf970327c0de9230f5d61f5ec94',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ND1-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'ND_Soils1_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND2-Soils-8',
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7', '7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND2-Soils-7',
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6', '6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND2-Soils-6',
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5', '5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND2-Soils-5',
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4', '4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND2-Soils-4',
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3', '3c', '3s', '3e', '3w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND2-Soils-3',
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2', '2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND2-Soils-2',
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND2-Soils-1',
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                type: 'fill',
                source: 'composite',
                id: 'ND2-Soils-0',
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Water, miscellaneous', 'Water, intermittent'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ND2-Soils-Water',
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                type: 'line',
                source: 'composite',
                id: 'ND2-Soils-Lines',
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
                'source-layer': 'ND_Soils2_20190302',
            },
            {
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                metadata: {
                    'mapbox:group': 'cc44c70bb77b72dcf10ab87f47b91ef1',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ND2-Soils-Labels',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'ND_Soils2_20190302',
            },
        ],
        created: '2019-06-28T19:29:48.086Z',
        modified: '2019-07-02T18:17:22.808Z',
        id: 'cjxghql4l2jko1cpurdqoa1wb',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    wetlands: {
        version: 8,
        name: 'Wetlands Test 20231005',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {
                b102c91b08e7140edd401864ba863a10: {
                    name: 'All Wetlands',
                    collapsed: false,
                },
            },
        },
        center: [-100.98590133748746, 36.65570312667518],
        zoom: 14.500299577187855,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.Wetlands_HI_20231027_1698423129,mapright2.Wetlands_AK3_20231030_1698732312,mapright2.Wetlands_3_20231030_1698776568,mapright2.Wetlands_AK1_20231030_1698702921,mapright2.Wetlands_AK2_20231030_1698730908,mapright2.Wetlands_1_20231107_1699493429,mapright2.Wetlands_2_20231107_1699449165,mapright2.Wetlands_Rip_20231113_1699903114',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/clndsy4g300bd01psb2nn9cxo/bhw8y9ssza0hsqd0t8gmva1mv',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                minzoom: 12,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'wetlands_rip_poly',
                paint: {
                    'fill-pattern': 'riparian5',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        15,
                        0.78,
                        16,
                        0.33,
                    ],
                    'fill-color': 'hsl(62, 18%, 6%)',
                },
                'source-layer': 'Wetlands_Rip_20231113_1699902696.01962',
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
            },
            {
                minzoom: 12,
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
                type: 'line',
                source: 'composite',
                id: 'wetlands_rip_line',
                paint: {
                    'line-color': 'hsl(218, 85%, 18%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        15,
                        1.25,
                        16,
                        1.85,
                        18,
                        2.3,
                    ],
                },
                'source-layer': 'Wetlands_Rip_20231113_1699902696.01962',
            },
            {
                minzoom: 12,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'wetlands_HI_poly',
                paint: {
                    'fill-pattern': 'wetlands27',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        15,
                        0.78,
                        16,
                        0.33,
                    ],
                    'fill-color': 'hsl(62, 18%, 6%)',
                },
                'source-layer': 'Wetlands_HI_20231027_1698423063.5217037',
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
            },
            {
                minzoom: 12,
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
                type: 'line',
                source: 'composite',
                id: 'wetlands_HI_line',
                paint: {
                    'line-color': 'hsl(218, 85%, 18%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        15,
                        1.25,
                        16,
                        1.85,
                        18,
                        2.3,
                    ],
                },
                'source-layer': 'Wetlands_HI_20231027_1698423063.5217037',
            },
            {
                minzoom: 12,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'wetlands_AK3_poly',
                paint: {
                    'fill-pattern': 'wetlands27',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        15,
                        0.78,
                        16,
                        0.33,
                    ],
                    'fill-color': 'hsl(62, 18%, 6%)',
                },
                'source-layer': 'Wetlands_AK3_20231030_1698731010.392679',
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
            },
            {
                minzoom: 12,
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
                type: 'line',
                source: 'composite',
                id: 'wetlands_AK3_line',
                paint: {
                    'line-color': 'hsl(218, 85%, 18%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        15,
                        1.25,
                        16,
                        1.85,
                        18,
                        2.3,
                    ],
                },
                'source-layer': 'Wetlands_AK3_20231030_1698731010.392679',
            },
            {
                minzoom: 12,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'wetlands_AK2_poly',
                paint: {
                    'fill-pattern': 'wetlands27',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        15,
                        0.78,
                        16,
                        0.33,
                    ],
                    'fill-color': 'hsl(62, 18%, 6%)',
                },
                'source-layer': 'Wetlands_AK2_20231030_1698730585.397208',
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
            },
            {
                minzoom: 12,
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
                type: 'line',
                source: 'composite',
                id: 'wetlands_AK2_line',
                paint: {
                    'line-color': 'hsl(218, 85%, 18%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        15,
                        1.25,
                        16,
                        1.85,
                        18,
                        2.3,
                    ],
                },
                'source-layer': 'Wetlands_AK2_20231030_1698730585.397208',
            },
            {
                minzoom: 12,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'wetlands_AK1_poly',
                paint: {
                    'fill-pattern': 'wetlands27',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        15,
                        0.78,
                        16,
                        0.33,
                    ],
                    'fill-color': 'hsl(62, 18%, 6%)',
                },
                'source-layer': 'Wetlands_AK1_20231030_1698701662.3827837',
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
            },
            {
                minzoom: 12,
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
                type: 'line',
                source: 'composite',
                id: 'wetlands_AK1_line',
                paint: {
                    'line-color': 'hsl(218, 85%, 18%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        15,
                        1.25,
                        16,
                        1.85,
                        18,
                        2.3,
                    ],
                },
                'source-layer': 'Wetlands_AK1_20231030_1698701662.3827837',
            },
            {
                minzoom: 12,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'wetlands_3_poly',
                paint: {
                    'fill-pattern': 'wetlands27',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        15,
                        0.78,
                        16,
                        0.33,
                    ],
                    'fill-color': 'hsl(62, 18%, 6%)',
                },
                'source-layer': 'Wetlands_3_20231030_1698768698.5538049',
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
            },
            {
                minzoom: 12,
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
                type: 'line',
                source: 'composite',
                id: 'wetlands_3_line',
                paint: {
                    'line-color': 'hsl(218, 85%, 18%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        15,
                        1.25,
                        16,
                        1.85,
                        18,
                        2.3,
                    ],
                },
                'source-layer': 'Wetlands_3_20231030_1698768698.5538049',
            },
            {
                minzoom: 12,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'wetlands_2_poly',
                paint: {
                    'fill-pattern': 'wetlands27',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        15,
                        0.78,
                        16,
                        0.33,
                    ],
                    'fill-color': 'hsl(62, 18%, 6%)',
                },
                'source-layer': 'Wetlands_2_20231107_1699427262.9916897',
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
            },
            {
                minzoom: 12,
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
                type: 'line',
                source: 'composite',
                id: 'wetlands_2_line',
                paint: {
                    'line-color': 'hsl(218, 85%, 18%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        15,
                        1.25,
                        16,
                        1.85,
                        18,
                        2.3,
                    ],
                },
                'source-layer': 'Wetlands_2_20231107_1699427262.9916897',
            },
            {
                minzoom: 12,
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'wetlands_1_poly',
                paint: {
                    'fill-pattern': 'wetlands27',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        15,
                        0.78,
                        16,
                        0.33,
                    ],
                },
                'source-layer': 'Wetlands_1_20231107_1699472081.0077796',
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
            },
            {
                minzoom: 12,
                metadata: {
                    'mapbox:group': 'b102c91b08e7140edd401864ba863a10',
                },
                type: 'line',
                source: 'composite',
                id: 'wetlands_1_line',
                paint: {
                    'line-color': 'hsl(218, 85%, 18%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        15,
                        1.25,
                        16,
                        1.85,
                        18,
                        2.3,
                    ],
                },
                'source-layer': 'Wetlands_1_20231107_1699472081.0077796',
            },
        ],
        created: '2023-10-05T23:22:01.347Z',
        modified: '2023-11-13T19:51:53.486Z',
        id: 'clndsy4g300bd01psb2nn9cxo',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    railroads: {
        version: 8,
        name: 'RailRoads REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {},
        },
        center: [-93.03387855520653, 31.97112983562546],
        zoom: 9.288482282464287,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.2f592f2a,mapright2.2b0074a1,mapright2.407c0328,mapright2.29e44f5d',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjgz5i5cg00032soesiqbv2vo/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: 'railroads-east-091521',
                type: 'line',
                paint: {
                    'line-color': 'hsl(46, 98%, 60%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.4,
                        11,
                        2.5,
                        22,
                        4,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'railroads_east_091521',
            },
            {
                id: 'railroads-east-091521 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(59, 98%, 95%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        7,
                        11,
                        12,
                        22,
                        14,
                    ],
                    'line-dasharray': [
                        'step',
                        ['zoom'],
                        ['literal', [0.25, 2]],
                        11,
                        ['literal', [0.2, 2]],
                        22,
                        ['literal', [0.25, 2]],
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'railroads_east_091521',
            },
            {
                id: 'railroads-east-091521 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(52, 100%, 100%)',
                    'text-halo-color': 'hsl(0, 0%, 2%)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1.5,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'FULLNAME']],
                    'text-font': ['Asap Bold', 'Arial Unicode MS Regular'],
                    'text-transform': 'uppercase',
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        12,
                        22,
                        16,
                    ],
                    'text-letter-spacing': 0.1,
                    'symbol-placement': 'line',
                    'text-max-angle': 180,
                },
                source: 'composite',
                'source-layer': 'railroads_east_091521',
            },
            {
                id: 'railroads-west-091521',
                type: 'line',
                paint: {
                    'line-color': 'hsl(46, 98%, 60%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.4,
                        11,
                        2.5,
                        22,
                        4,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'railroads_west_091521',
            },
            {
                id: 'railroads-west-091521 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(59, 98%, 95%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        7,
                        11,
                        12,
                        22,
                        14,
                    ],
                    'line-dasharray': [
                        'step',
                        ['zoom'],
                        ['literal', [0.25, 2]],
                        11,
                        ['literal', [0.2, 2]],
                        22,
                        ['literal', [0.25, 2]],
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'railroads_west_091521',
            },
            {
                id: 'railroads-west-091521 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(52, 100%, 100%)',
                    'text-halo-color': 'hsl(0, 0%, 2%)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1.5,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'FULLNAME']],
                    'text-font': ['Asap Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        12,
                        22,
                        16,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 180,
                },
                source: 'composite',
                'source-layer': 'railroads_west_091521',
            },
            {
                id: 'railroads-hi-091521',
                type: 'line',
                paint: {
                    'line-color': 'hsl(46, 98%, 60%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.4,
                        11,
                        2.5,
                        22,
                        4,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'railroads_HI_091521',
            },
            {
                id: 'railroads-hi-091521 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(59, 98%, 95%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        7,
                        11,
                        12,
                        22,
                        14,
                    ],
                    'line-dasharray': [
                        'step',
                        ['zoom'],
                        ['literal', [0.25, 2]],
                        11,
                        ['literal', [0.2, 2]],
                        22,
                        ['literal', [0.25, 2]],
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'railroads_HI_091521',
            },
            {
                id: 'railroads-hi-091521 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(52, 100%, 100%)',
                    'text-halo-color': 'hsl(0, 0%, 2%)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1.5,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'FULLNAME']],
                    'text-font': ['Asap Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        12,
                        22,
                        16,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 180,
                },
                source: 'composite',
                'source-layer': 'railroads_HI_091521',
            },
            {
                id: 'railroads-ak-091521',
                type: 'line',
                paint: {
                    'line-color': 'hsl(46, 98%, 60%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.4,
                        11,
                        2.5,
                        22,
                        4,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'railroads_AK_091521',
            },
            {
                id: 'railroads-ak-091521 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(59, 98%, 95%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        7,
                        11,
                        12,
                        22,
                        14,
                    ],
                    'line-dasharray': [
                        'step',
                        ['zoom'],
                        ['literal', [0.25, 2]],
                        11,
                        ['literal', [0.2, 2]],
                        22,
                        ['literal', [0.25, 2]],
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'railroads_AK_091521',
            },
            {
                id: 'railroads-ak-091521 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(52, 100%, 100%)',
                    'text-halo-color': 'hsl(0, 0%, 2%)',
                    'text-halo-width': 2,
                    'text-halo-blur': 1.5,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'FULLNAME']],
                    'text-font': ['Asap Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        12,
                        22,
                        16,
                    ],
                    'text-letter-spacing': 0.1,
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 180,
                },
                source: 'composite',
                'source-layer': 'railroads_AK_091521',
            },
        ],
        created: '2018-05-09T13:39:56.552Z',
        modified: '2021-09-21T18:57:18.924Z',
        id: 'cjgz5i5cg00032soesiqbv2vo',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_6: {
        version: 8,
        name: 'AZ-NV Contours REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {
                '61f5b2ef13dbf466a6e3250911ba78ad': {
                    name: 'NV Group',
                    collapsed: true,
                },
                '6df36609eb37f5c89c02009b1110a51f': {
                    name: 'AZ Group',
                    collapsed: true,
                },
            },
        },
        center: [-115.72327485509555, 36.36229883719294],
        zoom: 13.246751994842928,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.8dsb6f9g,mapright2.dpx8qtjy,mapright2.4yby0oo6,mapright2.96e1uifz,mapright2.82n34rfh,mapright2.8sc3nvup,mapright2.1oy2yk9t,mapright2.2z5tytpu,mapright2.9f2cmj3r,mapright2.drb0bzxx,mapright2.97ulsp61,mapright2.2mo498dd',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cji4ogz602h5z2smq0cmxlazv',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-1-black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-1-yellow',
                paint: {
                    'line-color': '#f7b708',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'AZ_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'az-contours-1-label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'AZ_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'az-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'AZ_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'az-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'AZ_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'az-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'AZ_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'az-contours-5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_5_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-6-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_6_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'line',
                source: 'composite',
                id: 'az-contours-6-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'AZ_Contours_6_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '6df36609eb37f5c89c02009b1110a51f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'az-contours-6-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AZ_Contours_6_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NV_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NV_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'symbol',
                source: 'composite',
                id: 'nv-contours1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NV_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NV_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NV_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'symbol',
                source: 'composite',
                id: 'nv-contours2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NV_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NV_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NV_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'symbol',
                source: 'composite',
                id: 'nv-contours3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NV_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NV_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NV_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'symbol',
                source: 'composite',
                id: 'nv-contours4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NV_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NV_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NV_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'symbol',
                source: 'composite',
                id: 'nv-contours5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NV_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours6-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NV_Contours6_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'line',
                source: 'composite',
                id: 'nv-contours6-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NV_Contours6_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '61f5b2ef13dbf466a6e3250911ba78ad',
                },
                type: 'symbol',
                source: 'composite',
                id: 'nv-contours6-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NV_Contours6_webaux',
            },
        ],
        created: '2018-06-07T15:09:27.815Z',
        modified: '2018-06-08T20:47:21.668Z',
        id: 'cji4ogz602h5z2smq0cmxlazv',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_7: {
        version: 8,
        name: 'MT-WY Contours REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:groups': {
                '5c9859fa2b0175b54d48c41dd7cba55b': {
                    name: 'WY Contours',
                    collapsed: true,
                },
                '9f53bf9c4437c338302b5062655eb2f6': {
                    name: 'MT Group',
                    collapsed: true,
                },
            },
            'mapbox:sdk-support': {
                js: '0.40.0',
                android: '5.2.0',
                ios: '3.7.0',
            },
        },
        center: [-105.26148313049873, 41.05998398800406],
        zoom: 14.095339806774783,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.620f733a,mapright2.0c4d13f6,mapright2.1a842a44,mapright2.c2e243bc,mapright2.b5796468,mapright2.f850c94f,mapright2.f96b4e29,mapright2.c6ba3f90,mapright2.0929dbe0,mapright2.70aacd7e,mapright2.2b1589d5,mapright2.45c2b504,mapright2.2ba73a46,mapright2.df9179fd,mapright2.6c8cc273',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cj2c8101s002s2so187lyhk5j',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'wy-contours1-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'WY_Contours1_webaux',
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                type: 'line',
                source: 'composite',
                id: 'wy-contours2-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'WY_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                type: 'line',
                source: 'composite',
                id: 'wy-contours3-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'WY_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                type: 'line',
                source: 'composite',
                id: 'wy-contours4-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'WY_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                type: 'line',
                source: 'composite',
                id: 'wy-contours5-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'WY_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                type: 'line',
                source: 'composite',
                id: 'wy-contours6-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'WY_Contours6_webaux',
            },
            {
                id: 'wy-contours1-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'WY_Contours1_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                id: 'wy-contours2-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'WY_Contours2_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                id: 'wy-contours3-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'WY_Contours3_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                id: 'wy-contours4-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'WY_Contours4_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                id: 'wy-contours5-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'WY_Contours5_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                id: 'wy-contours6-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'WY_Contours6_webaux',
                minzoom: 13,
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                type: 'symbol',
                source: 'composite',
                id: 'wy-contours1-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WY_Contours1_webaux',
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wy-contours2-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WY_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wy-contours3-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WY_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wy-contours4-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WY_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wy-contours5-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WY_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': '5c9859fa2b0175b54d48c41dd7cba55b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wy-contours6-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WY_Contours6_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-e-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MT_E_Contours_1_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-e-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'MT_E_Contours_1_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'mt-e-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MT_E_Contours_1_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-e-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MT_E_Contours_2_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-e-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'MT_E_Contours_2_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'mt-e-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MT_E_Contours_2_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-e-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MT_E_Contours_3_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-e-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'MT_E_Contours_3_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'mt-e-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MT_E_Contours_3_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-e-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MT_E_Contours_4_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-e-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'MT_E_Contours_4_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'mt-e-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MT_E_Contours_4_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-e-w-contours-5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MT_E_W_Contours_5_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-e-w-contours-5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'MT_E_W_Contours_5_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'mt-e-w-contours-5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MT_E_W_Contours_5_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-w-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MT_W_Contours_1_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-w-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'MT_W_Contours_1_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'mt-w-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MT_W_Contours_1_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-w-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MT_W_Contours_2_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-w-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'MT_W_Contours_2_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'mt-w-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MT_W_Contours_2_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-w-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MT_W_Contours_3_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-w-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'MT_W_Contours_3_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'mt-w-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MT_W_Contours_3_projected',
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-w-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MT_W_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
                type: 'line',
                source: 'composite',
                id: 'mt-w-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'MT_W_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '9f53bf9c4437c338302b5062655eb2f6',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mt-w-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MT_W_Contours_4_projected',
            },
        ],
        created: '2017-05-05T19:16:00.614Z',
        modified: '2018-06-26T15:33:49.237Z',
        id: 'cj2c8101s002s2so187lyhk5j',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_8: {
        version: 8,
        name: 'AL-FL-GA-MS-SC_Contours_REAL_WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {
                '2eb85a313967196b158ef9f58e9c17f1': {
                    name: 'GA Group',
                    collapsed: true,
                },
                ac64eb720b6bef6aca7f71402b7ba2cd: {
                    name: 'MS Group',
                    collapsed: true,
                },
                '607f62dfab7031f5884a6d9629b1780e': {
                    name: 'AL Group',
                    collapsed: true,
                },
                '8621d3d245698af3e489880b37fc45ac': {
                    name: 'SC Group',
                    collapsed: true,
                },
                '8aa3ae27661b3de00959ad6759248bd2': {
                    name: 'FL Group',
                    collapsed: false,
                },
            },
        },
        center: [-84.81235967416916, 30.66004330554334],
        zoom: 12.049199750961495,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.11dz53f0,mapright2.7bbax3vs,mapright2.5ejbolal,mapright2.ec4a26dd,mapright2.f61c0041,mapright2.8ca318ea,mapright2.15c4ed31,mapright2.c9612d50,mapright2.5d322acc,mapright2.58d8c63f,mapright2.8455dfd2,mapright2.993cb86f,mapright2.2dc0a7ae,mapright2.FL_Countours_20231025_1698215848',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjh96r90g03pa2rld3anjq8mc/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ga-contours-1 black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'GA_Contours_1',
                metadata: {
                    'mapbox:group': '2eb85a313967196b158ef9f58e9c17f1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ga-contours-1 yellow',
                paint: {
                    'line-color': '#f7b708',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'GA_Contours_1',
                metadata: {
                    'mapbox:group': '2eb85a313967196b158ef9f58e9c17f1',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'symbol-placement': 'line',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'ga-contours-1 label',
                paint: {
                    'text-color': '#fce959',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'GA_Contours_1',
                metadata: {
                    'mapbox:group': '2eb85a313967196b158ef9f58e9c17f1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ga-contours-2 black',
                paint: {
                    'line-blur': 1.5,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-color': '#1c1b17',
                },
                'source-layer': 'GA_Contours_2',
                metadata: {
                    'mapbox:group': '2eb85a313967196b158ef9f58e9c17f1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ga-contours-2 yellow',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-color': 'hsl(44, 94%, 50%)',
                },
                'source-layer': 'GA_Contours_2',
                metadata: {
                    'mapbox:group': '2eb85a313967196b158ef9f58e9c17f1',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'symbol-placement': 'line',
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'ga-contours-2 label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'GA_Contours_2',
                metadata: {
                    'mapbox:group': '2eb85a313967196b158ef9f58e9c17f1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ga-contours-3 black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'GA_Contours_3',
                metadata: {
                    'mapbox:group': '2eb85a313967196b158ef9f58e9c17f1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'ga-contours-3 yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'GA_Contours_3',
                metadata: {
                    'mapbox:group': '2eb85a313967196b158ef9f58e9c17f1',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'symbol-placement': 'line',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'ga-contours-3 label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'GA_Contours_3',
                metadata: {
                    'mapbox:group': '2eb85a313967196b158ef9f58e9c17f1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'line',
                source: 'composite',
                id: 'ms-southcontours-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MS_SouthContours_webaux',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'line',
                source: 'composite',
                id: 'ms-southcontours-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'MS_SouthContours_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'CONTOURELE']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ms-southcontours-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MS_SouthContours_webaux',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'line',
                source: 'composite',
                id: 'ms-northcontours-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MS_NorthContours_webaux',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'line',
                source: 'composite',
                id: 'ms-northcontours-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'MS_NorthContours_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'CONTOURELE']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ms-northcontours-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MS_NorthContours_webaux',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'line',
                source: 'composite',
                id: 'ms-middlesouthcontours-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MS_MiddleSouthContours_webaux',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'line',
                source: 'composite',
                id: 'ms-middlesouthcontours-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'MS_MiddleSouthContours_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'CONTOURELE']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ms-middlesouthcontours-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MS_MiddleSouthContours_webaux',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'line',
                source: 'composite',
                id: 'ms-middlenorthcontours-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MS_MiddleNorthContours',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'line',
                source: 'composite',
                id: 'ms-middlenorthcontours-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'MS_MiddleNorthContours',
            },
            {
                minzoom: 13,
                layout: {
                    'symbol-placement': 'line',
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'CONTOURELE']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ac64eb720b6bef6aca7f71402b7ba2cd',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ms-middlenorthcontours-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MS_MiddleNorthContours',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'line',
                source: 'composite',
                id: 'al-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'AL_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'line',
                source: 'composite',
                id: 'al-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'AL_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'al-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AL_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'line',
                source: 'composite',
                id: 'al-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'AL_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'line',
                source: 'composite',
                id: 'al-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'AL_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'al-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AL_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'line',
                source: 'composite',
                id: 'al-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'AL_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'line',
                source: 'composite',
                id: 'al-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'AL_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'al-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AL_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'line',
                source: 'composite',
                id: 'al-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'AL_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'line',
                source: 'composite',
                id: 'al-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'AL_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '607f62dfab7031f5884a6d9629b1780e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'al-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'AL_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '8621d3d245698af3e489880b37fc45ac',
                },
                type: 'line',
                source: 'composite',
                id: 'sc-contours-north-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'SC_Contours_North_webaux',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '8621d3d245698af3e489880b37fc45ac',
                },
                type: 'line',
                source: 'composite',
                id: 'sc-contours-north-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'SC_Contours_North_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '8621d3d245698af3e489880b37fc45ac',
                },
                type: 'symbol',
                source: 'composite',
                id: 'sc-contours-north-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'SC_Contours_North_webaux',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '8621d3d245698af3e489880b37fc45ac',
                },
                type: 'line',
                source: 'composite',
                id: 'sc-contours-south-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'SC_Contours_South_webaux',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '8621d3d245698af3e489880b37fc45ac',
                },
                type: 'line',
                source: 'composite',
                id: 'sc-contours-south-yelllow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'SC_Contours_South_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'symbol-placement': 'line',
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '8621d3d245698af3e489880b37fc45ac',
                },
                type: 'symbol',
                source: 'composite',
                id: 'sc-contours-south-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'SC_Contours_South_webaux',
            },
            {
                minzoom: 12,
                layout: {},
                metadata: {
                    'mapbox:group': '8aa3ae27661b3de00959ad6759248bd2',
                },
                type: 'line',
                source: 'composite',
                id: '5ftcontours-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'FL_Countours_20231025_1698215380.5169017',
            },
            {
                minzoom: 12,
                layout: {},
                metadata: {
                    'mapbox:group': '8aa3ae27661b3de00959ad6759248bd2',
                },
                type: 'line',
                source: 'composite',
                id: '5ftcontours-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'FL_Countours_20231025_1698215380.5169017',
            },
            {
                minzoom: 12,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'CONTOUR_LA']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '8aa3ae27661b3de00959ad6759248bd2',
                },
                type: 'symbol',
                source: 'composite',
                id: '5ftcontours-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'FL_Countours_20231025_1698215380.5169017',
            },
        ],
        created: '2018-05-16T14:12:42.586Z',
        modified: '2023-10-25T15:43:07.013Z',
        id: 'cjh96r90g03pa2rld3anjq8mc',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_9: {
        version: 8,
        name: 'CO-NM Contours WEB REAL',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {
                d389d155001f4ca2e126bfc88c4244b1: {
                    name: 'CO Group',
                    collapsed: true,
                },
                '480ebced628f6494b15726153b211b1f': {
                    name: 'NM Group',
                    collapsed: true,
                },
            },
        },
        center: [-106.93136423305981, 39.437544838599536],
        zoom: 14.588428655027906,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.9815472e,mapright2.006f0d36,mapright2.0dc1056c,mapright2.1cd25cdb,mapright2.c4a915bc,mapright2.e0082548,mapright2.c2262153,mapright2.135d8366,mapright2.60565abb,mapright2.c60c6412,mapright2.3f7143c0',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjhwd6iux1bs12smvn3wlxikv',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'nm-contours-1-black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-opacity': 1,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NM_Contours_1_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'nm-contours-1-yellow',
                paint: {
                    'line-color': '#f7b708',
                    'line-opacity': 1,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NM_Contours_1_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'nm-contours-1-label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NM_Contours_1_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'nm-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NM_Contours_2_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'nm-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NM_Contours_2_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'symbol-placement': 'line',
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'nm-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NM_Contours_2_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'nm-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NM_Contours_3_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'nm-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NM_Contours_3_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'nm-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NM_Contours_3_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'nm-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NM_Contours_4_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'nm-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NM_Contours_4_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'nm-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NM_Contours_4_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'nm-contours-5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NM_Contours_5_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'nm-contours-5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NM_Contours_5_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'nm-contours-5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NM_Contours_5_projected',
                metadata: {
                    'mapbox:group': '480ebced628f6494b15726153b211b1f',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CO_Contours_1_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CO_Contours_1_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'co-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CO_Contours_1_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CO_Contours_2_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CO_Contours_2_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'co-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CO_Contours_2_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CO_Contours_3_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CO_Contours_3_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'co-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CO_Contours_3_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CO_Contours_4_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CO_Contours_4_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'co-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CO_Contours_4_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-contours-5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CO_Contours_5_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-contours-5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CO_Contours_5_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'co-contours-5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CO_Contours_5_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-nm-contours-6-black',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CO_NM_Contours_6_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'co-nm-contours-6-yellow',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                    'line-color': 'hsl(44, 94%, 50%)',
                },
                'source-layer': 'CO_NM_Contours_6_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'co-nm-contours-6-label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CO_NM_Contours_6_projected',
                metadata: {
                    'mapbox:group': 'd389d155001f4ca2e126bfc88c4244b1',
                },
            },
        ],
        created: '2018-06-01T19:31:14.972Z',
        modified: '2018-06-08T20:54:11.771Z',
        id: 'cjhwd6iux1bs12smvn3wlxikv',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_10: {
        version: 8,
        name: 'IL-MO-NE Contours REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:groups': {
                f8f8d390455fe84786b1c69e4313b920: {
                    name: 'MO Contours',
                    collapsed: true,
                },
                '7993f1ed91c4b78b283446dfdcdefcf9': {
                    name: 'IL Group',
                    collapsed: true,
                },
                e0f7b73bcb9c88e399447d93171025c7: {
                    name: 'NE Group',
                    collapsed: false,
                },
            },
            'mapbox:sdk-support': {
                js: '0.40.0',
                android: '5.2.0',
                ios: '3.7.0',
            },
        },
        center: [-88.2806094267325, 40.36645667726714],
        zoom: 13.173672728815841,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.84e7f6c4,mapright2.6304d312,mapright2.e0c24988,mapright2.6d6d1754,mapright2.7e2b7519,mapright2.0abfd1ac,mapright2.90e950b4,mapright2.110e6b61,mapright2.02d14ea3,mapright2.8ee9e4fa,mapright2.8f887185,mapright2.9a84e3ab,mapright2.4f7251b2,mapright2.5b3cae2b',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cji50if8s2s8p2so12dsp3q9d',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'line',
                source: 'composite',
                id: 'mo-contours1-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MO_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'line',
                source: 'composite',
                id: 'mo-contours2-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MO_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'line',
                source: 'composite',
                id: 'mo-contours3-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MO_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'line',
                source: 'composite',
                id: 'mo-contours4-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MO_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'line',
                source: 'composite',
                id: 'mo-contours5-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MO_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'line',
                source: 'composite',
                id: 'mo-contours6-black',
                paint: {
                    'line-color': 'rgba(28,27,23,1)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'MO_Contours6_webaux',
            },
            {
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                id: 'mo-contours1-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'MO_Contours1_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                id: 'mo-contours2-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'MO_Contours2_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                id: 'mo-contours3-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'MO_Contours3_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                id: 'mo-contours4-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'MO_Contours4_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                id: 'mo-contours5-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'MO_Contours5_webaux',
                minzoom: 13,
            },
            {
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                id: 'mo-contours6-yellow',
                paint: {
                    'line-color': 'rgb(247, 183, 8)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                },
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                'source-layer': 'MO_Contours6_webaux',
                minzoom: 13,
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mo-contours1-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MO_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mo-contours2-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MO_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mo-contours3-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MO_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mo-contours4-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MO_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mo-contours5-labels',
                paint: {
                    'text-color': 'rgba(252,233,89,1)',
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MO_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                    'text-rotation-alignment': 'map',
                    'text-padding': 1,
                },
                metadata: {
                    'mapbox:group': 'f8f8d390455fe84786b1c69e4313b920',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mo-contours6-labels',
                paint: {
                    'text-halo-color': 'rgba(8,8,8,1)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                    'text-color': 'rgba(252,233,89,1)',
                },
                'source-layer': 'MO_Contours6_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'il-contours-south-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'IL_Contours_South_webaux',
                metadata: {
                    'mapbox:group': '7993f1ed91c4b78b283446dfdcdefcf9',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'il-contours-south-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'IL_Contours_South_webaux',
                metadata: {
                    'mapbox:group': '7993f1ed91c4b78b283446dfdcdefcf9',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'il-contours-south-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'IL_Contours_South_webaux',
                metadata: {
                    'mapbox:group': '7993f1ed91c4b78b283446dfdcdefcf9',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'il-contours-middle-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'IL_Contours_Middle_webaux',
                metadata: {
                    'mapbox:group': '7993f1ed91c4b78b283446dfdcdefcf9',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'il-contours-middle-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'IL_Contours_Middle_webaux',
                metadata: {
                    'mapbox:group': '7993f1ed91c4b78b283446dfdcdefcf9',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'il-contours-middle-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'IL_Contours_Middle_webaux',
                metadata: {
                    'mapbox:group': '7993f1ed91c4b78b283446dfdcdefcf9',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'il-contours-north-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'IL_Contours_North_webaux',
                metadata: {
                    'mapbox:group': '7993f1ed91c4b78b283446dfdcdefcf9',
                },
            },
            {
                minzoom: 13,
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'il-contours-north-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'IL_Contours_North_webaux',
                metadata: {
                    'mapbox:group': '7993f1ed91c4b78b283446dfdcdefcf9',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'il-contours-north-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'IL_Contours_North_webaux',
                metadata: {
                    'mapbox:group': '7993f1ed91c4b78b283446dfdcdefcf9',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-contours1-black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'NE_Contours1_webaux',
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-contours1-yellow',
                paint: {
                    'line-color': '#f7b708',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'NE_Contours1_webaux',
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'ne-contours1-label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NE_Contours1_webaux',
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-contours2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'NE_Contours2_webaux',
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-contours2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'NE_Contours2_webaux',
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                type: 'symbol',
                source: 'composite',
                id: 'ne-contours2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NE_Contours2_webaux',
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-contours3-black',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'NE_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-contours3-yellow',
                paint: {
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                    'line-color': 'hsl(44, 94%, 50%)',
                },
                'source-layer': 'NE_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ne-contours3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NE_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-contours4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'NE_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-contours4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'NE_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ne-contours4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NE_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-contours5-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 1.5],
                            [14, 2.5],
                            [17, 4],
                        ],
                    },
                    'line-blur': 1.5,
                },
                'source-layer': 'NE_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
                type: 'line',
                source: 'composite',
                id: 'ne-contours5-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': {
                        base: 1,
                        stops: [
                            [13, 0.5],
                            [14, 0.6],
                            [17, 1],
                        ],
                    },
                    'line-blur': 0,
                },
                'source-layer': 'NE_Contours5_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': '{ContourEle} FT',
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': {
                        base: 1,
                        stops: [
                            [13, 9],
                            [14, 10],
                            [16, 11],
                        ],
                    },
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'e0f7b73bcb9c88e399447d93171025c7',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ne-contours5-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NE_Contours5_webaux',
            },
        ],
        created: '2018-06-07T20:46:30.692Z',
        modified: '2018-06-11T18:00:45.555Z',
        id: 'cji50if8s2s8p2so12dsp3q9d',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_11: {
        version: 8,
        name: 'CT-DE-MA-MD-ME-NH-NJ-NY-OH-PA-RI-VT Contours REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {
                ffc9d8f41ec104b453aff7094ea90aa9: {
                    name: 'OH Group',
                    collapsed: true,
                },
                '74f34658475cb136b0bf718d822f8c52': {
                    name: 'ME Group',
                    collapsed: true,
                },
                '295d62126dcaa8bba311bef455640d0f': {
                    name: 'MD Group',
                    collapsed: true,
                },
                '4b9da25859037b5c6be7c99875bad2ee': {
                    name: 'NY Group',
                    collapsed: true,
                },
                '3845f710e77a3fd7bdf49db8407c63cd': {
                    name: 'CT-NH-RI-VT Group',
                    collapsed: true,
                },
                '5c4f59f93abae8a75ff83e4c14f74783': {
                    name: 'DE-NJ-MA Group',
                    collapsed: true,
                },
                c0af98821841f992e792621ce0254406: {
                    name: 'PA Group',
                    collapsed: true,
                },
            },
        },
        center: [-76.64311545820829, 40.29640670332901],
        zoom: 15.035454490297697,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.bcefeb42,mapright2.e90a40e5,mapright2.85d8f16b,mapright2.46fb10ee,mapright2.aa638c73,mapright2.7ac0d659,mapright2.51bd3496,mapright2.df54c69b,mapright2.547030da,mapright2.2567b2e5,mapright2.e719cafc,mapright2.73736e9f,mapright2.b3a6d3d6,mapright2.d0ce9a45,mapright2.72285ed3',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cji68yisj12612sqh4tonzwse',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ffc9d8f41ec104b453aff7094ea90aa9',
                },
                type: 'line',
                source: 'composite',
                id: 'oh-contours-1-black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'OH_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ffc9d8f41ec104b453aff7094ea90aa9',
                },
                type: 'line',
                source: 'composite',
                id: 'oh-contours-1-yellow',
                paint: {
                    'line-color': '#f7b708',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'OH_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ffc9d8f41ec104b453aff7094ea90aa9',
                },
                type: 'symbol',
                source: 'composite',
                id: 'oh-contours-1-label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OH_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ffc9d8f41ec104b453aff7094ea90aa9',
                },
                type: 'line',
                source: 'composite',
                id: 'oh-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'OH_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ffc9d8f41ec104b453aff7094ea90aa9',
                },
                type: 'line',
                source: 'composite',
                id: 'oh-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'OH_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ffc9d8f41ec104b453aff7094ea90aa9',
                },
                type: 'symbol',
                source: 'composite',
                id: 'oh-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OH_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ffc9d8f41ec104b453aff7094ea90aa9',
                },
                type: 'line',
                source: 'composite',
                id: 'oh-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'OH_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ffc9d8f41ec104b453aff7094ea90aa9',
                },
                type: 'line',
                source: 'composite',
                id: 'oh-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'OH_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ffc9d8f41ec104b453aff7094ea90aa9',
                },
                type: 'symbol',
                source: 'composite',
                id: 'oh-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'OH_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '295d62126dcaa8bba311bef455640d0f',
                },
                type: 'line',
                source: 'composite',
                id: 'md-contours-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MD_Contours_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '295d62126dcaa8bba311bef455640d0f',
                },
                type: 'line',
                source: 'composite',
                id: 'md-contours-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'MD_Contours_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '295d62126dcaa8bba311bef455640d0f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'md-contours-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MD_Contours_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '74f34658475cb136b0bf718d822f8c52',
                },
                type: 'line',
                source: 'composite',
                id: 'me-contours-north-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ME_Contours_North_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '74f34658475cb136b0bf718d822f8c52',
                },
                type: 'line',
                source: 'composite',
                id: 'me-contours-north-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ME_Contours_North_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '74f34658475cb136b0bf718d822f8c52',
                },
                type: 'symbol',
                source: 'composite',
                id: 'me-contours-north-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ME_Contours_North_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '74f34658475cb136b0bf718d822f8c52',
                },
                type: 'line',
                source: 'composite',
                id: 'me-contours-south-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ME_Contours_South_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '74f34658475cb136b0bf718d822f8c52',
                },
                type: 'line',
                source: 'composite',
                id: 'me-contours-south-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ME_Contours_South_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'symbol-placement': 'line',
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '74f34658475cb136b0bf718d822f8c52',
                },
                type: 'symbol',
                source: 'composite',
                id: 'me-contours-south-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ME_Contours_South_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4b9da25859037b5c6be7c99875bad2ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ny-contours1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NY_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4b9da25859037b5c6be7c99875bad2ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ny-contours1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NY_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '4b9da25859037b5c6be7c99875bad2ee',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ny-contours1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NY_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4b9da25859037b5c6be7c99875bad2ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ny-contours2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NY_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4b9da25859037b5c6be7c99875bad2ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ny-contours2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NY_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '4b9da25859037b5c6be7c99875bad2ee',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ny-contours2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NY_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4b9da25859037b5c6be7c99875bad2ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ny-contours3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'NY_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4b9da25859037b5c6be7c99875bad2ee',
                },
                type: 'line',
                source: 'composite',
                id: 'ny-contours3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'NY_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '4b9da25859037b5c6be7c99875bad2ee',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ny-contours3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'NY_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '3845f710e77a3fd7bdf49db8407c63cd',
                },
                type: 'line',
                source: 'composite',
                id: 'ct-nh-ri-vt-contours black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CT_NH_RI_VT_Contours',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '3845f710e77a3fd7bdf49db8407c63cd',
                },
                type: 'line',
                source: 'composite',
                id: 'ct-nh-ri-vt-contours yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CT_NH_RI_VT_Contours',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '3845f710e77a3fd7bdf49db8407c63cd',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ct-nh-ri-vt-contours label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CT_NH_RI_VT_Contours',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '5c4f59f93abae8a75ff83e4c14f74783',
                },
                type: 'line',
                source: 'composite',
                id: 'de-nj-ma-contours black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'DE_NJ_MA_Contours',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '5c4f59f93abae8a75ff83e4c14f74783',
                },
                type: 'line',
                source: 'composite',
                id: 'de-nj-ma-contours yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'DE_NJ_MA_Contours',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '5c4f59f93abae8a75ff83e4c14f74783',
                },
                type: 'symbol',
                source: 'composite',
                id: 'de-nj-ma-contours label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'DE_NJ_MA_Contours',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'line',
                source: 'composite',
                id: 'pa-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'PA_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'line',
                source: 'composite',
                id: 'pa-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'PA_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'symbol-placement': 'line',
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'symbol',
                source: 'composite',
                id: 'pa-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PA_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'line',
                source: 'composite',
                id: 'pa-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'PA_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'line',
                source: 'composite',
                id: 'pa-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'PA_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'symbol',
                source: 'composite',
                id: 'pa-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PA_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'line',
                source: 'composite',
                id: 'pa-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'PA_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'line',
                source: 'composite',
                id: 'pa-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'PA_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'symbol',
                source: 'composite',
                id: 'pa-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PA_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'line',
                source: 'composite',
                id: 'pa-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'PA_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'line',
                source: 'composite',
                id: 'pa-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'PA_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'c0af98821841f992e792621ce0254406',
                },
                type: 'symbol',
                source: 'composite',
                id: 'pa-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'PA_Contours_4_projected',
            },
        ],
        created: '2018-06-08T17:30:44.889Z',
        modified: '2018-06-19T13:26:37.025Z',
        id: 'cji68yisj12612sqh4tonzwse',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_12: {
        version: 8,
        name: 'IA-MN-ND-SD Contour REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {
                '9dfc2090b606bfbb929eb76642764e8e': {
                    name: 'SD Group',
                    collapsed: true,
                },
                a89e8336f28843d706f1256d813ed21d: {
                    name: 'IA Group',
                    collapsed: true,
                },
                ce0f8a8b714f6b783d376b2290e34220: {
                    name: 'MN Group',
                    collapsed: true,
                },
                dc408e93e5c6d8619eb44453e075e3dc: {
                    name: 'ND Group',
                    collapsed: true,
                },
            },
        },
        center: [-100.784827, 46.79773399999999],
        zoom: 16,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.7w0bqp4s,mapright2.a8fg1hyt,mapright2.dkoolveo,mapright2.5h0ytz87,mapright2.c5c70b76,mapright2.c0a5551c,mapright2.55b157f7,mapright2.8f1158e7,mapright2.4bd7100d,mapright2.10231893,mapright2.3cdc69bf,mapright2.1dd3f0cb,mapright2.93c3e42b',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cji6brcn93s7r2rmz4zc3kq4h',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'line',
                source: 'composite',
                id: 'sd-contours1-black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'SD_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'line',
                source: 'composite',
                id: 'sd-contours1-yellow',
                paint: {
                    'line-color': '#f7b708',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'SD_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'sd-contours1-label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'SD_Contours1_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'line',
                source: 'composite',
                id: 'sd-contours2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'SD_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'line',
                source: 'composite',
                id: 'sd-contours2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'SD_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'sd-contours2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'SD_Contours2_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'line',
                source: 'composite',
                id: 'sd-contours3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'SD_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'line',
                source: 'composite',
                id: 'sd-contours3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'SD_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'sd-contours3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'SD_Contours3_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'line',
                source: 'composite',
                id: 'sd-contours4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'SD_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'line',
                source: 'composite',
                id: 'sd-contours4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'SD_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '9dfc2090b606bfbb929eb76642764e8e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'sd-contours4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'SD_Contours4_webaux',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'a89e8336f28843d706f1256d813ed21d',
                },
                type: 'line',
                source: 'composite',
                id: 'ia-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'IA_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'a89e8336f28843d706f1256d813ed21d',
                },
                type: 'line',
                source: 'composite',
                id: 'ia-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'IA_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'a89e8336f28843d706f1256d813ed21d',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ia-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'IA_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'a89e8336f28843d706f1256d813ed21d',
                },
                type: 'line',
                source: 'composite',
                id: 'ia-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'IA_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'a89e8336f28843d706f1256d813ed21d',
                },
                type: 'line',
                source: 'composite',
                id: 'ia-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'IA_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'a89e8336f28843d706f1256d813ed21d',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ia-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'IA_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'a89e8336f28843d706f1256d813ed21d',
                },
                type: 'line',
                source: 'composite',
                id: 'ia-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'IA_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'a89e8336f28843d706f1256d813ed21d',
                },
                type: 'line',
                source: 'composite',
                id: 'ia-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'IA_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'a89e8336f28843d706f1256d813ed21d',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ia-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'IA_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce0f8a8b714f6b783d376b2290e34220',
                },
                type: 'line',
                source: 'composite',
                id: 'mn-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MN_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce0f8a8b714f6b783d376b2290e34220',
                },
                type: 'line',
                source: 'composite',
                id: 'mn-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'MN_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ce0f8a8b714f6b783d376b2290e34220',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mn-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MN_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce0f8a8b714f6b783d376b2290e34220',
                },
                type: 'line',
                source: 'composite',
                id: 'mn-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MN_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce0f8a8b714f6b783d376b2290e34220',
                },
                type: 'line',
                source: 'composite',
                id: 'mn-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'MN_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ce0f8a8b714f6b783d376b2290e34220',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mn-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MN_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce0f8a8b714f6b783d376b2290e34220',
                },
                type: 'line',
                source: 'composite',
                id: 'mn-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MN_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'ce0f8a8b714f6b783d376b2290e34220',
                },
                type: 'line',
                source: 'composite',
                id: 'mn-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'MN_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'ce0f8a8b714f6b783d376b2290e34220',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mn-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MN_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'dc408e93e5c6d8619eb44453e075e3dc',
                },
                type: 'line',
                source: 'composite',
                id: 'nd-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ND_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'dc408e93e5c6d8619eb44453e075e3dc',
                },
                type: 'line',
                source: 'composite',
                id: 'nd-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ND_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'dc408e93e5c6d8619eb44453e075e3dc',
                },
                type: 'symbol',
                source: 'composite',
                id: 'nd-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ND_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'dc408e93e5c6d8619eb44453e075e3dc',
                },
                type: 'line',
                source: 'composite',
                id: 'nd-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ND_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'dc408e93e5c6d8619eb44453e075e3dc',
                },
                type: 'line',
                source: 'composite',
                id: 'nd-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ND_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'dc408e93e5c6d8619eb44453e075e3dc',
                },
                type: 'symbol',
                source: 'composite',
                id: 'nd-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ND_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'dc408e93e5c6d8619eb44453e075e3dc',
                },
                type: 'line',
                source: 'composite',
                id: 'nd-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'ND_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': 'dc408e93e5c6d8619eb44453e075e3dc',
                },
                type: 'line',
                source: 'composite',
                id: 'nd-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'ND_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'dc408e93e5c6d8619eb44453e075e3dc',
                },
                type: 'symbol',
                source: 'composite',
                id: 'nd-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'ND_Contours_3_projected',
            },
        ],
        created: '2018-06-08T18:49:09.179Z',
        modified: '2018-06-26T14:39:09.623Z',
        id: 'cji6brcn93s7r2rmz4zc3kq4h',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_13: {
        version: 8,
        name: 'MI-VA-WI-WV Contours REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {
                bfec1eb27462b78e329009e096a0034e: {
                    name: 'VA Group',
                    collapsed: true,
                },
                '7be4ea0dabac856f295dd29d4b7e822a': {
                    name: 'WI Group',
                    collapsed: true,
                },
                f18d874d6d3f3976146efd39f896c484: {
                    name: 'MI Group',
                    collapsed: true,
                },
                '6172f6fe088ab4101aca6cc69453bbe7': {
                    name: 'WV Group',
                    collapsed: false,
                },
            },
        },
        center: [-80.31663962545667, 39.0021197301256],
        zoom: 13.912375564719671,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.0zv5qva3,mapright2.5nnhra4u,mapright2.7w23248v,mapright2.0np247za,mapright2.669ad12e,mapright2.fc09dec0,mapright2.e2cdfd10,mapright2.be16c688,mapright2.793cef34,mapright2.6b2cf67a,mapright2.2dc1d8cd,mapright2.efed0520,mapright2.b0b1493a,mapright2.974fb091,mapright2.34798661',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cji6do1gd16ig2sqhsy7r1c6b/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'line',
                source: 'composite',
                id: 'va-contours-1-black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'VA_Contours_1_Projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'line',
                source: 'composite',
                id: 'va-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'VA_Contours_1_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'va-contours-1-label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'VA_Contours_1_Projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'line',
                source: 'composite',
                id: 'va-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'VA_Contours_2_Projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'line',
                source: 'composite',
                id: 'va-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'VA_Contours_2_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'va-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'VA_Contours_2_Projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'line',
                source: 'composite',
                id: 'va-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'VA_Contours_3_Projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'line',
                source: 'composite',
                id: 'va-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'VA_Contours_3_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'va-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'VA_Contours_3_Projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'line',
                source: 'composite',
                id: 'va-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'VA_Contours_4_Projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'line',
                source: 'composite',
                id: 'va-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'VA_Contours_4_Projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'bfec1eb27462b78e329009e096a0034e',
                },
                type: 'symbol',
                source: 'composite',
                id: 'va-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'VA_Contours_4_Projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'line',
                source: 'composite',
                id: 'wi-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WI_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'line',
                source: 'composite',
                id: 'wi-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'WI_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wi-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WI_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'line',
                source: 'composite',
                id: 'wi-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WI_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'line',
                source: 'composite',
                id: 'wi-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'WI_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wi-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WI_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'line',
                source: 'composite',
                id: 'wi-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WI_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'line',
                source: 'composite',
                id: 'wi-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'WI_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wi-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WI_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'line',
                source: 'composite',
                id: 'wi-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WI_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'line',
                source: 'composite',
                id: 'wi-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'WI_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '7be4ea0dabac856f295dd29d4b7e822a',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wi-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WI_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'f18d874d6d3f3976146efd39f896c484',
                },
                type: 'line',
                source: 'composite',
                id: 'mi-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MI_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'f18d874d6d3f3976146efd39f896c484',
                },
                type: 'line',
                source: 'composite',
                id: 'mi-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'MI_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'f18d874d6d3f3976146efd39f896c484',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mi-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MI_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'f18d874d6d3f3976146efd39f896c484',
                },
                type: 'line',
                source: 'composite',
                id: 'mi-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MI_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'f18d874d6d3f3976146efd39f896c484',
                },
                type: 'line',
                source: 'composite',
                id: 'mi-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'MI_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'f18d874d6d3f3976146efd39f896c484',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mi-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MI_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'f18d874d6d3f3976146efd39f896c484',
                },
                type: 'line',
                source: 'composite',
                id: 'mi-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'MI_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': 'f18d874d6d3f3976146efd39f896c484',
                },
                type: 'line',
                source: 'composite',
                id: 'mi-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'MI_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': 'f18d874d6d3f3976146efd39f896c484',
                },
                type: 'symbol',
                source: 'composite',
                id: 'mi-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'MI_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'line',
                source: 'composite',
                id: 'wv-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WV_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'line',
                source: 'composite',
                id: 'wv-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'WV_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wv-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WV_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'line',
                source: 'composite',
                id: 'wv-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WV_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'line',
                source: 'composite',
                id: 'wv-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'WV_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wv-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WV_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'line',
                source: 'composite',
                id: 'wv-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'WV_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'line',
                source: 'composite',
                id: 'wv-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'WV_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wv-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WV_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'line',
                source: 'composite',
                id: 'wv-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-blur': 1.5,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                },
                'source-layer': 'WV_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {},
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'line',
                source: 'composite',
                id: 'wv-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                },
                'source-layer': 'WV_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '6172f6fe088ab4101aca6cc69453bbe7',
                },
                type: 'symbol',
                source: 'composite',
                id: 'wv-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'WV_Contours_4_projected',
            },
        ],
        created: '2018-06-08T19:42:33.962Z',
        modified: '2023-03-28T18:52:51.201Z',
        id: 'cji6do1gd16ig2sqhsy7r1c6b',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    contour_lines_14: {
        version: 8,
        name: 'CA Contours REAL WEB',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.45.0',
                android: '6.0.0',
                ios: '4.0.0',
            },
            'mapbox:groups': {
                '4abd8e00b57b14078258b77885e5591f': {
                    name: 'CA South Group',
                    collapsed: true,
                },
                '99df3f12c9021301381b5f14463fe801': {
                    name: 'CA Central Group',
                    collapsed: true,
                },
                '2b4b5dc3c9a30fc230606eea92abc60b': {
                    name: 'CA North Group',
                    collapsed: true,
                },
            },
        },
        center: [-122.99530213825346, 40.768184129691264],
        zoom: 14.884921897133562,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.7t6lhx90,mapright2.btmtwlms,mapright2.5mnp7kk3,mapright2.483djhyc,mapright2.3b4mamwy,mapright2.ajvxilz7,mapright2.0239bjya,mapright2.bpa49w8c,mapright2.cwnjcrw7,mapright2.dvabyubb,mapright2.bw75t7f6,mapright2.141ozfo1',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cji6hw34y4ee22rphfdwyhldm',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-s-contours-1-black',
                paint: {
                    'line-color': '#1c1b17',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_S_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-s-contours-1-yellow',
                paint: {
                    'line-color': '#f7b708',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_S_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-s-contours-1-label',
                paint: {
                    'text-color': '#fce95a',
                    'text-halo-color': '#080808',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_S_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-s-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_S_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-s-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_S_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-s-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_S_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-s-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_S_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-s-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_S_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-s-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                },
                'source-layer': 'CA_S_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-s-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_S_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-s-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_S_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'text-transform': 'uppercase',
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '4abd8e00b57b14078258b77885e5591f',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-s-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_S_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-c-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_C_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-c-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_C_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-c-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_C_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-c-contours-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_C_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-c-contours-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_C_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-c-contours-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_C_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-c-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_C_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-c-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_C_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-c-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_C_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-c-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_C_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-c-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_C_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '99df3f12c9021301381b5f14463fe801',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-c-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_C_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-n-contours-1-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_N_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-n-contours-1-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_N_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-n-contours-1-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_N_Contours_1_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-n-contours-2-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_N_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-n-contours-2-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_N_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-n-contours-2-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_N_Contours_2_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-n-contours-3-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_N_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-n-contours-3-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_N_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-n-contours-3-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_N_Contours_3_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-n-contours-4-black',
                paint: {
                    'line-color': 'hsl(48, 10%, 10%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1.5,
                        14,
                        2.5,
                        17,
                        4,
                    ],
                    'line-blur': 1.5,
                },
                'source-layer': 'CA_N_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    visibility: 'visible',
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'line',
                source: 'composite',
                id: 'ca-n-contours-4-yellow',
                paint: {
                    'line-color': 'hsl(44, 94%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.5,
                        14,
                        0.6,
                        17,
                        1,
                    ],
                    'line-blur': 0,
                },
                'source-layer': 'CA_N_Contours_4_projected',
            },
            {
                minzoom: 13,
                layout: {
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'ContourEle']],
                        '  FT',
                    ],
                    'text-font': ['Lato Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        9,
                        14,
                        10,
                        16,
                        11,
                    ],
                    'symbol-placement': 'line',
                    'text-max-angle': 30,
                },
                metadata: {
                    'mapbox:group': '2b4b5dc3c9a30fc230606eea92abc60b',
                },
                type: 'symbol',
                source: 'composite',
                id: 'ca-n-contours-4-label',
                paint: {
                    'text-color': 'hsl(53, 96%, 67%)',
                    'text-halo-color': 'hsl(0, 0%, 3%)',
                    'text-halo-width': 1.5,
                    'text-halo-blur': 1.5,
                },
                'source-layer': 'CA_N_Contours_4_projected',
            },
        ],
        created: '2018-06-08T21:40:47.820Z',
        modified: '2018-06-26T15:05:04.513Z',
        id: 'cji6hw34y4ee22rphfdwyhldm',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    opportunity_zones: {
        version: 8,
        name: 'Opportunity Zones',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.50.0',
                android: '6.7.0',
                ios: '4.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-100.34216461007274, 28.616528662498766],
        zoom: 13.330676671500973,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.604fe996',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjqi7asgthx6c2rqjzrpqm1si/4q6zufg2roavvvtcdci85xk0n',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                layout: {},
                type: 'fill',
                source: 'composite',
                id: 'opportunity-zones',
                paint: {
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.5,
                        12,
                        0.4,
                    ],
                    'fill-color': 'hsl(171, 59%, 21%)',
                    'fill-pattern': 'Op_Zns_13',
                },
                'source-layer': 'Opportunity_Zones',
            },
            {
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'opportunity-zones copy',
                paint: {
                    'line-color': 'hsl(56, 100%, 80%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1,
                        12,
                        1.85,
                        15.106,
                        3.6,
                    ],
                },
                'source-layer': 'Opportunity_Zones',
            },
        ],
        created: '2019-01-04T15:35:10.842Z',
        modified: '2021-02-01T17:32:50.995Z',
        id: 'cjqi7asgthx6c2rqjzrpqm1si',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    floodplain_2: {
        version: 8,
        name: 'Flood Group 2 REAL WEB 031820',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.50.0',
                android: '6.7.0',
                ios: '4.6.0',
            },
            'mapbox:groups': {
                '824ae1b6a2f2058fa76e30bfe7d54533': {
                    name: 'MO Group',
                    collapsed: true,
                },
                b2669b36e245e07b7d17be657f3a5c23: {
                    name: 'KS NE Group',
                    collapsed: true,
                },
                '2a1b41755cd16181a949db1df6712243': {
                    name: 'MN ND SD Group',
                    collapsed: true,
                },
                '00605feebf8465451db1bd220c9270c2': {
                    name: 'KS-NE Group',
                    collapsed: true,
                },
                afbe37a977d66119f15b0c16ea6401bb: {
                    name: 'IL IN OH Group',
                    collapsed: true,
                },
                '1ab19474bdff0dcbe16b8c7036295a28': {
                    name: 'ID-MT-OR-WA Group',
                    collapsed: true,
                },
                '753530ba58ebc5b6bef400d8f167187a': {
                    name: 'IL-IN-OH Group',
                    collapsed: true,
                },
                f7f1d782f11696d8ae73e9e71169f535: {
                    name: 'IA MO Group',
                    collapsed: true,
                },
                a4966b1955a69b1eee43733db068c5ab: {
                    name: 'CT MA ME NH RI VT Group',
                    collapsed: true,
                },
                aca4f626bbff9f5bbdb145b92229bbf8: {
                    name: 'HI Group',
                    collapsed: true,
                },
                '40b874d37c82770eae3ed1c97540ac82': {
                    name: 'AK1 Group',
                    collapsed: true,
                },
                '2bf96602b7df32104178e20fde526f11': {
                    name: 'PA2 Group',
                    collapsed: true,
                },
                db895145a66169c644d91028e3d44cab: {
                    name: 'MI WI Group',
                    collapsed: true,
                },
                '03a5c0eb28cd0b746131cb3613ffdd90': {
                    name: 'PA Group',
                    collapsed: true,
                },
                '1772ee4c057679253583517ff92ba41f': {
                    name: 'IA2-MN Group',
                    collapsed: true,
                },
                '0529e03b215b17a5b7c0e24f5e24479f': {
                    name: 'CT-MA-ME-NH-RI-VT Group',
                    collapsed: true,
                },
                b98d5af7f95948bd3b31f3f80d1f8005: {
                    name: 'NY1 Group',
                    collapsed: true,
                },
                fb4894a8f35cf26a9d23f85c6678f20e: {
                    name: 'MI-WI Group',
                    collapsed: true,
                },
                '57a9a638f7856e5237d85af83d4f12e1': {
                    name: 'AZ CA NV Group',
                    collapsed: true,
                },
                '664bc1e24415ab3e6399f8107cd99e85': {
                    name: 'PA1 Group',
                    collapsed: true,
                },
                caaebd6b8842e7b1b5302ac21f2059af: {
                    name: 'CO-NM-UT-WY Group',
                    collapsed: true,
                },
                bb7f339fb905d0020345dfb68cbbaff1: {
                    name: 'NY Group',
                    collapsed: true,
                },
                '563cc50bb9b35c1311b3e3b9b5def050': {
                    name: 'NY2 Group',
                    collapsed: true,
                },
                c1a9d796fadcbdf23584ba3329e3f255: {
                    name: 'HI Group',
                    collapsed: true,
                },
                b2e23116dcba24e9a1b7ecfcac83cdf0: {
                    name: 'IA1-ND-SD Group',
                    collapsed: true,
                },
                '4f16d98414fcf32777357261cf57ef05': {
                    name: 'CO NM UT WY Group',
                    collapsed: true,
                },
                '117e20b4c5c3648b251773a615388b8a': {
                    name: 'ID MT OR WA Group',
                    collapsed: true,
                },
                '6ea45a6f5abbe8f2a7d45a2d0298ab3b': {
                    name: 'az-ca-nv-Group',
                    collapsed: true,
                },
            },
        },
        center: [-112.83998183060979, 34.78456131582226],
        zoom: 12.630887188638068,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.KS_NE_FP_120822,mapright2.1adc5c47,mapright2.7v8h9kcl,mapright2.05322943,mapright2.d7e2dad6,mapright2.986a28ee,mapright2.bd5a1f4a,mapright2.1tqnrct4,mapright2.112b1255,mapright2.0w4c16zr,mapright2.MI_WI_FP,mapright2.101b17db,mapright2.IL_IN_OH_FP_120822,mapright2.da9353df,mapright2.c9fcde3a',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjrgk2uy184nk2so10nrowsm8/0u9rrgtrlzcm4u78sirdugjcb',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '40b874d37c82770eae3ed1c97540ac82',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ak1-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AK_FP_Final-4b0usu',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '40b874d37c82770eae3ed1c97540ac82',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ak1-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AK_FP_Final-4b0usu',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '40b874d37c82770eae3ed1c97540ac82',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ak1-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'AK_FP_Final-4b0usu',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '40b874d37c82770eae3ed1c97540ac82',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'ak1-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'AK_FP_Final-4b0usu',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '40b874d37c82770eae3ed1c97540ac82',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ak1-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AK_FP_Final-4b0usu',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '40b874d37c82770eae3ed1c97540ac82',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ak1-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'AK_FP_Final-4b0usu',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '40b874d37c82770eae3ed1c97540ac82',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ak1-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AK_FP_Final-4b0usu',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '40b874d37c82770eae3ed1c97540ac82',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ak1-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'AK_FP_Final-4b0usu',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c1a9d796fadcbdf23584ba3329e3f255',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'hi-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'HI_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c1a9d796fadcbdf23584ba3329e3f255',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'hi-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'HI_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c1a9d796fadcbdf23584ba3329e3f255',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'hi-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'HI_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c1a9d796fadcbdf23584ba3329e3f255',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'hi-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'HI_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c1a9d796fadcbdf23584ba3329e3f255',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'hi-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'HI_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c1a9d796fadcbdf23584ba3329e3f255',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'hi-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'HI_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c1a9d796fadcbdf23584ba3329e3f255',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'hi-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'HI_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'c1a9d796fadcbdf23584ba3329e3f255',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'hi-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'HI_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0529e03b215b17a5b7c0e24f5e24479f',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ct-ma-me-nh-ri-vt-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'CT_MA_ME_NH_RI_VT_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0529e03b215b17a5b7c0e24f5e24479f',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ct-ma-me-nh-ri-vt-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'CT_MA_ME_NH_RI_VT_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0529e03b215b17a5b7c0e24f5e24479f',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ct-ma-me-nh-ri-vt-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'CT_MA_ME_NH_RI_VT_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0529e03b215b17a5b7c0e24f5e24479f',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'ct-ma-me-nh-ri-vt-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'CT_MA_ME_NH_RI_VT_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0529e03b215b17a5b7c0e24f5e24479f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ct-ma-me-nh-ri-vt-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'CT_MA_ME_NH_RI_VT_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0529e03b215b17a5b7c0e24f5e24479f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ct-ma-me-nh-ri-vt-special copy',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'CT_MA_ME_NH_RI_VT_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0529e03b215b17a5b7c0e24f5e24479f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ct-ma-me-nh-ri-vt-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'CT_MA_ME_NH_RI_VT_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '0529e03b215b17a5b7c0e24f5e24479f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ct-ma-me-nh-ri-vt-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'CT_MA_ME_NH_RI_VT_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '03a5c0eb28cd0b746131cb3613ffdd90',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'pa-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'PA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '03a5c0eb28cd0b746131cb3613ffdd90',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'pa-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'PA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '03a5c0eb28cd0b746131cb3613ffdd90',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'pa-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'PA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '03a5c0eb28cd0b746131cb3613ffdd90',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'pa-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'PA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '03a5c0eb28cd0b746131cb3613ffdd90',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'pa-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'PA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '03a5c0eb28cd0b746131cb3613ffdd90',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'pa-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'PA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '03a5c0eb28cd0b746131cb3613ffdd90',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'pa-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'PA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '03a5c0eb28cd0b746131cb3613ffdd90',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'pa-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'PA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'bb7f339fb905d0020345dfb68cbbaff1',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ny-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'NY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'bb7f339fb905d0020345dfb68cbbaff1',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ny-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'NY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'bb7f339fb905d0020345dfb68cbbaff1',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ny-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'NY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'bb7f339fb905d0020345dfb68cbbaff1',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'ny-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'NY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'bb7f339fb905d0020345dfb68cbbaff1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ny-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'NY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'bb7f339fb905d0020345dfb68cbbaff1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ny-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'NY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'bb7f339fb905d0020345dfb68cbbaff1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ny-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'NY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'bb7f339fb905d0020345dfb68cbbaff1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ny-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'NY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1ab19474bdff0dcbe16b8c7036295a28',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'id-mt-or-wa-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'ID_MT_OR_WA_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1ab19474bdff0dcbe16b8c7036295a28',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'id-mt-or-wa-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'ID_MT_OR_WA_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1ab19474bdff0dcbe16b8c7036295a28',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'id-mt-or-wa-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'ID_MT_OR_WA_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1ab19474bdff0dcbe16b8c7036295a28',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'id-mt-or-wa-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'ID_MT_OR_WA_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1ab19474bdff0dcbe16b8c7036295a28',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'id-mt-or-wa-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'ID_MT_OR_WA_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1ab19474bdff0dcbe16b8c7036295a28',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'id-mt-or-wa-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'ID_MT_OR_WA_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1ab19474bdff0dcbe16b8c7036295a28',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'id-mt-or-wa-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'ID_MT_OR_WA_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1ab19474bdff0dcbe16b8c7036295a28',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'id-mt-or-wa-not included copy',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'ID_MT_OR_WA_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fb4894a8f35cf26a9d23f85c6678f20e',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'mi-wi-flood-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MI_WI_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fb4894a8f35cf26a9d23f85c6678f20e',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'mi-wi-flood-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MI_WI_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fb4894a8f35cf26a9d23f85c6678f20e',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'mi-wi-flood-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'MI_WI_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fb4894a8f35cf26a9d23f85c6678f20e',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'mi-wi-flood-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'MI_WI_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fb4894a8f35cf26a9d23f85c6678f20e',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'mi-wi-flood-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MI_WI_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fb4894a8f35cf26a9d23f85c6678f20e',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'mi-wi-flood-special copy',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'MI_WI_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fb4894a8f35cf26a9d23f85c6678f20e',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'mi-wi-flood-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MI_WI_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'fb4894a8f35cf26a9d23f85c6678f20e',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'mi-wi-flood-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'MI_WI_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'caaebd6b8842e7b1b5302ac21f2059af',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'co-nm-ut-wy-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'CO_NM_UT_WY_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'caaebd6b8842e7b1b5302ac21f2059af',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'co-nm-ut-wy-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'CO_NM_UT_WY_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'caaebd6b8842e7b1b5302ac21f2059af',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'co-nm-ut-wy-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'CO_NM_UT_WY_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'caaebd6b8842e7b1b5302ac21f2059af',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'co-nm-ut-wy-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'CO_NM_UT_WY_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'caaebd6b8842e7b1b5302ac21f2059af',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'co-nm-ut-wy-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'CO_NM_UT_WY_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'caaebd6b8842e7b1b5302ac21f2059af',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'co-nm-ut-wy-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'CO_NM_UT_WY_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'caaebd6b8842e7b1b5302ac21f2059af',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'co-nm-ut-wy-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'CO_NM_UT_WY_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'caaebd6b8842e7b1b5302ac21f2059af',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'co-nm-ut-wy-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'CO_NM_UT_WY_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '753530ba58ebc5b6bef400d8f167187a',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'il-in-oh-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IL_IN_OH_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '753530ba58ebc5b6bef400d8f167187a',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'il-in-oh-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IL_IN_OH_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '753530ba58ebc5b6bef400d8f167187a',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'il-in-oh-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'IL_IN_OH_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '753530ba58ebc5b6bef400d8f167187a',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'il-in-oh-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'IL_IN_OH_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '753530ba58ebc5b6bef400d8f167187a',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'il-in-oh-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IL_IN_OH_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '753530ba58ebc5b6bef400d8f167187a',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'il-in-oh-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'IL_IN_OH_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '753530ba58ebc5b6bef400d8f167187a',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'il-in-oh-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IL_IN_OH_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '753530ba58ebc5b6bef400d8f167187a',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'il-in-oh-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'IL_IN_OH_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '00605feebf8465451db1bd220c9270c2',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ks-ne-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'KS_NE_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '00605feebf8465451db1bd220c9270c2',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ks-ne-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'KS_NE_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '00605feebf8465451db1bd220c9270c2',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ks-ne-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'KS_NE_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '00605feebf8465451db1bd220c9270c2',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'ks-ne-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'KS_NE_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '00605feebf8465451db1bd220c9270c2',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ks-ne-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'KS_NE_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '00605feebf8465451db1bd220c9270c2',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ks-ne-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'KS_NE_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '00605feebf8465451db1bd220c9270c2',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ks-ne-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'KS_NE_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '00605feebf8465451db1bd220c9270c2',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ks-ne-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'KS_NE_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '824ae1b6a2f2058fa76e30bfe7d54533',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'mo-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MO_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '824ae1b6a2f2058fa76e30bfe7d54533',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'mo-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MO_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '824ae1b6a2f2058fa76e30bfe7d54533',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'mo-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'MO_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '824ae1b6a2f2058fa76e30bfe7d54533',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'mo-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'MO_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '824ae1b6a2f2058fa76e30bfe7d54533',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'mo-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MO_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '824ae1b6a2f2058fa76e30bfe7d54533',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'mo-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'MO_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '824ae1b6a2f2058fa76e30bfe7d54533',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'mo-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MO_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '824ae1b6a2f2058fa76e30bfe7d54533',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'mo-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'MO_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b2e23116dcba24e9a1b7ecfcac83cdf0',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ia1-nd-sd-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IA1_ND_SD_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b2e23116dcba24e9a1b7ecfcac83cdf0',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ia1-nd-sd-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IA1_ND_SD_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b2e23116dcba24e9a1b7ecfcac83cdf0',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ia1-nd-sd-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'IA1_ND_SD_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b2e23116dcba24e9a1b7ecfcac83cdf0',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'ia1-nd-sd-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'IA1_ND_SD_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b2e23116dcba24e9a1b7ecfcac83cdf0',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ia1-nd-sd-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IA1_ND_SD_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b2e23116dcba24e9a1b7ecfcac83cdf0',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ia1-nd-sd-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'IA1_ND_SD_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b2e23116dcba24e9a1b7ecfcac83cdf0',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ia1-nd-sd-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IA1_ND_SD_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b2e23116dcba24e9a1b7ecfcac83cdf0',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ia1-nd-sd-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'IA1_ND_SD_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1772ee4c057679253583517ff92ba41f',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ia2-mn-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IA2_MN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1772ee4c057679253583517ff92ba41f',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ia2-mn-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IA2_MN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1772ee4c057679253583517ff92ba41f',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ia2-mn-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'IA2_MN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1772ee4c057679253583517ff92ba41f',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'ia2-mn-floodway line',
                paint: {
                    'line-opacity': 0.64,
                    'line-color': 'hsl(190, 86%, 14%)',
                },
                'source-layer': 'IA2_MN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1772ee4c057679253583517ff92ba41f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ia2-mn-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IA2_MN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1772ee4c057679253583517ff92ba41f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ia2-mn-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'IA2_MN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1772ee4c057679253583517ff92ba41f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ia2-mn-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'IA2_MN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1772ee4c057679253583517ff92ba41f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ia2-mn-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'IA2_MN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6ea45a6f5abbe8f2a7d45a2d0298ab3b',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'az-ca-nv-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AZ_CA_NV_FP_050123',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6ea45a6f5abbe8f2a7d45a2d0298ab3b',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'az-ca-nv-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AZ_CA_NV_FP_050123',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6ea45a6f5abbe8f2a7d45a2d0298ab3b',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'az-ca-nv-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'AZ_CA_NV_FP_050123',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6ea45a6f5abbe8f2a7d45a2d0298ab3b',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'az-ca-nv-floodway line',
                paint: {
                    'line-opacity': 0.64,
                    'line-color': 'hsl(190, 86%, 14%)',
                },
                'source-layer': 'AZ_CA_NV_FP_050123',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6ea45a6f5abbe8f2a7d45a2d0298ab3b',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'az-ca-nv-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AZ_CA_NV_FP_050123',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6ea45a6f5abbe8f2a7d45a2d0298ab3b',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'az-ca-nv-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'AZ_CA_NV_FP_050123',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6ea45a6f5abbe8f2a7d45a2d0298ab3b',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'az-ca-nv-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AZ_CA_NV_FP_050123',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6ea45a6f5abbe8f2a7d45a2d0298ab3b',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'az-ca-nv-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'AZ_CA_NV_FP_050123',
            },
        ],
        created: '2019-01-28T16:37:05.533Z',
        modified: '2023-05-05T14:30:26.365Z',
        id: 'cjrgk2uy184nk2so10nrowsm8',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    floodplain_1: {
        version: 8,
        name: 'Flood Group 1 REAL WEB 030920',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.50.0',
                android: '6.7.0',
                ios: '4.6.0',
            },
            'mapbox:groups': {
                '40b01a89a8403a3f7ddb2ebd5ca8ee4a': {
                    name: 'MS Group',
                    collapsed: true,
                },
                bf8c6ae8bac51a2e1bdc14d8ceb32756: {
                    name: 'OK Group',
                    collapsed: true,
                },
                aa545ad2461780df74c9badde0d44b8c: {
                    name: 'TX2 Group',
                    collapsed: true,
                },
                b466f22eb4646bbbcfc144ab98ffc7d4: {
                    name: 'GA Group',
                    collapsed: true,
                },
                fcdffba2388a442f25bcfb741b8a4584: {
                    name: 'DC WV VA Group',
                    collapsed: true,
                },
                '8e7401a5bde8590eeb648df10fbe787a': {
                    name: 'DE MD NJ Group',
                    collapsed: true,
                },
                '052a3fd5ad69c5457dab6dbbc9ef46dc': {
                    name: 'DE-MD-NJ Group',
                    collapsed: true,
                },
                '6aa182ff0b266295ff9764e7bca8a1e1': {
                    name: 'KY Group',
                    collapsed: true,
                },
                '83536c779e5e71fca2a6c0afbbb24439': {
                    name: 'GA Group',
                    collapsed: true,
                },
                '1191df5e1c4a0707f730e030d0415a49': {
                    name: 'FL2 Group',
                    collapsed: true,
                },
                a4f56103553451e52623b2af1cee8eac: {
                    name: 'AL1 Group',
                    collapsed: true,
                },
                '7303e2df40dbf721d8531153ddd5eee5': {
                    name: 'FL2 Group',
                    collapsed: true,
                },
                c028e4d3f5b3ef6dcc2192f8d88242e8: {
                    name: 'TX 2 Group',
                    collapsed: true,
                },
                f0ce18ed29296d4fa69579edc03c6b8f: {
                    name: 'NC Group',
                    collapsed: true,
                },
                '29aef7e1f2a16a0761d5b1f77cad8bf6': {
                    name: 'AL Group',
                    collapsed: true,
                },
                '15839e8e23bb0108b728c738b4df1ba1': {
                    name: 'LA Group',
                    collapsed: true,
                },
                aa20a760eaf9f4c206f55c79c3a3824e: {
                    name: 'AR-OK Group',
                    collapsed: true,
                },
                '5292d378f53f29bda6573491313fe35b': {
                    name: 'SC TN Group',
                    collapsed: true,
                },
                '39e2e4e3fc642285651896444d4bad24': {
                    name: 'SC TN Group 021022',
                    collapsed: true,
                },
                '00df0f8e678e9c2987f7356ef1357d2b': {
                    name: 'NC Group',
                    collapsed: true,
                },
                '8a564a8502e94d1fedfd982df1bbd052': {
                    name: 'TX1 Group',
                    collapsed: true,
                },
                '3703bc5b835b11c90609a9e0637439e1': {
                    name: 'FL1 Group',
                    collapsed: true,
                },
                '708ad521f08a310448119eb08f7f336f': {
                    name: 'AL2 Group',
                    collapsed: true,
                },
                '5ab5ee871735a7c2de5ae667405012f9': {
                    name: 'TX 1 Group',
                    collapsed: true,
                },
                '2a8d2013a3be100d975ee24045a0cd6a': {
                    name: 'NC Group',
                    collapsed: false,
                },
                '302701a552bd5885fadd22d8b13dd369': {
                    name: 'FL3 Group',
                    collapsed: true,
                },
                '6e6e113d326e764b87dccfecabf3f6b3': {
                    name: 'AR LA Group',
                    collapsed: true,
                },
                '128799eaaf1b574017f67dc2773cf1ea': {
                    name: 'DC VA WV Group',
                    collapsed: true,
                },
                d28926491e41b54981ad714c84ca16b7: {
                    name: 'FL1 Group',
                    collapsed: true,
                },
                '83983cc42fc3b8b43f1591b1fd723870': {
                    name: 'MS Group',
                    collapsed: true,
                },
                e432de0b49780a494b4b3b8c88372626: {
                    name: 'KY Group',
                    collapsed: true,
                },
            },
        },
        center: [-97.12632366514481, 29.97957564843702],
        zoom: 13.610542265335244,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.d29865d1,mapright2.18a21774,mapright2.538a36e6,mapright2.49bfd898,mapright2.d0829462,mapright2.edd8e187,mapright2.16903557,mapright2.64238dc9,mapright2.30f6472c,mapright2.4e5cd683,mapright2.508fps89,mapright2.317ba8b0,mapright2.1d7ae849,mapright2.b5d989c0,mapright2.22a89339',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjr6l7awy2e422slmgbg4l8ty/0u9rrgtrlzcm4u78sirdugjcb',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6aa182ff0b266295ff9764e7bca8a1e1',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'KY-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'KY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6aa182ff0b266295ff9764e7bca8a1e1',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'KY-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'KY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6aa182ff0b266295ff9764e7bca8a1e1',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'KY-Floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'KY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6aa182ff0b266295ff9764e7bca8a1e1',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'KY-Floodway Line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'KY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6aa182ff0b266295ff9764e7bca8a1e1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'KY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6aa182ff0b266295ff9764e7bca8a1e1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'KY-Special Line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'KY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6aa182ff0b266295ff9764e7bca8a1e1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Not Included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'KY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '6aa182ff0b266295ff9764e7bca8a1e1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'KY-Not Included Line',
                paint: {},
                'source-layer': 'KY_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '83983cc42fc3b8b43f1591b1fd723870',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MS-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MS_FP_12202022',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '83983cc42fc3b8b43f1591b1fd723870',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MS-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MS_FP_12202022',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '83983cc42fc3b8b43f1591b1fd723870',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'MS-Floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'MS_FP_12202022',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '83983cc42fc3b8b43f1591b1fd723870',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'MS-Floodway Lines',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'MS_FP_12202022',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '83983cc42fc3b8b43f1591b1fd723870',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MS-Special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MS_FP_12202022',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '83983cc42fc3b8b43f1591b1fd723870',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'MS-Special Line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'MS_FP_12202022',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '83983cc42fc3b8b43f1591b1fd723870',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'MS-Not Included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'MS_FP_12202022',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '83983cc42fc3b8b43f1591b1fd723870',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'MS-Not Included Line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'MS_FP_12202022',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'aa20a760eaf9f4c206f55c79c3a3824e',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'AR-OK-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AR_OK_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'aa20a760eaf9f4c206f55c79c3a3824e',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'AR-OK-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AR_OK_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'aa20a760eaf9f4c206f55c79c3a3824e',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'AR-OK-Floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'AR_OK_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'aa20a760eaf9f4c206f55c79c3a3824e',
                },
                type: 'line',
                source: 'composite',
                id: 'AR-OK-Floodway Line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'AR_OK_FP',
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'aa20a760eaf9f4c206f55c79c3a3824e',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AR-OK-Special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AR_OK_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'aa20a760eaf9f4c206f55c79c3a3824e',
                },
                type: 'line',
                source: 'composite',
                id: 'AR-OK-Special Line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'AR_OK_FP',
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'aa20a760eaf9f4c206f55c79c3a3824e',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'AR-OK-Not Included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AR_OK_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'aa20a760eaf9f4c206f55c79c3a3824e',
                },
                type: 'line',
                source: 'composite',
                id: 'AR-OK-Not Included Line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'AR_OK_FP',
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '15839e8e23bb0108b728c738b4df1ba1',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'LA-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'LA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '15839e8e23bb0108b728c738b4df1ba1',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'LA-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'LA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '15839e8e23bb0108b728c738b4df1ba1',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'LA-Floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'LA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '15839e8e23bb0108b728c738b4df1ba1',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'LA-Floodway Line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'LA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '15839e8e23bb0108b728c738b4df1ba1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'LA-Special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'LA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '15839e8e23bb0108b728c738b4df1ba1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'LA-Special Line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'LA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '15839e8e23bb0108b728c738b4df1ba1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'LA-Not Included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'LA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '15839e8e23bb0108b728c738b4df1ba1',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'LA-Not Included Line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'LA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '128799eaaf1b574017f67dc2773cf1ea',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'dc-va-wv-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'DC_VA_WV_FP1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '128799eaaf1b574017f67dc2773cf1ea',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'dc-va-wv-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'DC_VA_WV_FP1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '128799eaaf1b574017f67dc2773cf1ea',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'dc-va-wv-Floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'DC_VA_WV_FP1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '128799eaaf1b574017f67dc2773cf1ea',
                },
                type: 'line',
                source: 'composite',
                id: 'dc-va-wv-Floodway Line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'DC_VA_WV_FP1',
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '128799eaaf1b574017f67dc2773cf1ea',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL, REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'dc-va-wv-Special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'DC_VA_WV_FP1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '128799eaaf1b574017f67dc2773cf1ea',
                },
                type: 'line',
                source: 'composite',
                id: 'dc-va-wv-Special Line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'DC_VA_WV_FP1',
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '128799eaaf1b574017f67dc2773cf1ea',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'dc-va-wv-Not Included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'DC_VA_WV_FP1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '128799eaaf1b574017f67dc2773cf1ea',
                },
                type: 'line',
                source: 'composite',
                id: 'dc-va-wv-Not Included Line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'DC_VA_WV_FP1',
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '052a3fd5ad69c5457dab6dbbc9ef46dc',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'de-md-nj-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'DE_MD_NJ_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '052a3fd5ad69c5457dab6dbbc9ef46dc',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'de-md-nj-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'DE_MD_NJ_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '052a3fd5ad69c5457dab6dbbc9ef46dc',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'de-md-nj-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'DE_MD_NJ_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '052a3fd5ad69c5457dab6dbbc9ef46dc',
                },
                type: 'line',
                source: 'composite',
                id: 'de-md-nj-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'DE_MD_NJ_FP',
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '052a3fd5ad69c5457dab6dbbc9ef46dc',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'de-md-nj-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'DE_MD_NJ_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '052a3fd5ad69c5457dab6dbbc9ef46dc',
                },
                type: 'line',
                source: 'composite',
                id: 'de-md-nj-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'DE_MD_NJ_FP',
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '052a3fd5ad69c5457dab6dbbc9ef46dc',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'de-md-nj-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'DE_MD_NJ_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '052a3fd5ad69c5457dab6dbbc9ef46dc',
                },
                type: 'line',
                source: 'composite',
                id: 'de-md-nj-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'DE_MD_NJ_FP',
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f0ce18ed29296d4fa69579edc03c6b8f',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'nc-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'NC_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f0ce18ed29296d4fa69579edc03c6b8f',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'nc-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'NC_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f0ce18ed29296d4fa69579edc03c6b8f',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'nc-floodway',
                paint: {
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                    'fill-pattern': 'Floodway7',
                },
                'source-layer': 'NC_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f0ce18ed29296d4fa69579edc03c6b8f',
                },
                type: 'line',
                source: 'composite',
                id: 'nc-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'NC_FP_Final',
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f0ce18ed29296d4fa69579edc03c6b8f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'nc-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'NC_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f0ce18ed29296d4fa69579edc03c6b8f',
                },
                type: 'line',
                source: 'composite',
                id: 'nc-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'NC_FP_Final',
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f0ce18ed29296d4fa69579edc03c6b8f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'nc-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'NC_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'f0ce18ed29296d4fa69579edc03c6b8f',
                },
                type: 'line',
                source: 'composite',
                id: 'nc-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'NC_FP_Final',
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
            },
            {
                metadata: {
                    'mapbox:group': '8a564a8502e94d1fedfd982df1bbd052',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'tx1-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'TX_FP1',
            },
            {
                metadata: {
                    'mapbox:group': '8a564a8502e94d1fedfd982df1bbd052',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'tx1-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'TX_FP1',
            },
            {
                metadata: {
                    'mapbox:group': '8a564a8502e94d1fedfd982df1bbd052',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'tx1-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'TX_FP1',
            },
            {
                metadata: {
                    'mapbox:group': '8a564a8502e94d1fedfd982df1bbd052',
                },
                type: 'line',
                source: 'composite',
                id: 'tx1-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'TX_FP1',
                layout: {},
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
            },
            {
                metadata: {
                    'mapbox:group': '8a564a8502e94d1fedfd982df1bbd052',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'tx1-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'TX_FP1',
            },
            {
                metadata: {
                    'mapbox:group': '8a564a8502e94d1fedfd982df1bbd052',
                },
                type: 'line',
                source: 'composite',
                id: 'tx1-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                    'line-opacity': 0.64,
                },
                'source-layer': 'TX_FP1',
                layout: {},
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
            },
            {
                metadata: {
                    'mapbox:group': '8a564a8502e94d1fedfd982df1bbd052',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'tx1-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'TX_FP1',
            },
            {
                metadata: {
                    'mapbox:group': '8a564a8502e94d1fedfd982df1bbd052',
                },
                type: 'line',
                source: 'composite',
                id: 'tx1-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'TX_FP1',
                layout: {},
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
            },
            {
                metadata: {
                    'mapbox:group': 'aa545ad2461780df74c9badde0d44b8c',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'tx2-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'TX_FP2',
            },
            {
                metadata: {
                    'mapbox:group': 'aa545ad2461780df74c9badde0d44b8c',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'tx2-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'TX_FP2',
            },
            {
                metadata: {
                    'mapbox:group': 'aa545ad2461780df74c9badde0d44b8c',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'tx2-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'TX_FP2',
            },
            {
                metadata: {
                    'mapbox:group': 'aa545ad2461780df74c9badde0d44b8c',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'tx2-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'TX_FP2',
            },
            {
                metadata: {
                    'mapbox:group': 'aa545ad2461780df74c9badde0d44b8c',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'tx2-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'TX_FP2',
            },
            {
                metadata: {
                    'mapbox:group': 'aa545ad2461780df74c9badde0d44b8c',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'tx2-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'TX_FP2',
            },
            {
                metadata: {
                    'mapbox:group': 'aa545ad2461780df74c9badde0d44b8c',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'tx2-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'TX_FP2',
            },
            {
                metadata: {
                    'mapbox:group': 'aa545ad2461780df74c9badde0d44b8c',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'tx2-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'TX_FP2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39e2e4e3fc642285651896444d4bad24',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'tn-sc-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'SC_TN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39e2e4e3fc642285651896444d4bad24',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'tn-sc-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'SC_TN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39e2e4e3fc642285651896444d4bad24',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'tn-sc-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'SC_TN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39e2e4e3fc642285651896444d4bad24',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'tn-sc-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'SC_TN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39e2e4e3fc642285651896444d4bad24',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'tn-sc-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'SC_TN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39e2e4e3fc642285651896444d4bad24',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'tn-sc-special copy',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'SC_TN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39e2e4e3fc642285651896444d4bad24',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'tn-sc-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'SC_TN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '39e2e4e3fc642285651896444d4bad24',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'tn-sc-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'SC_TN_FP',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd28926491e41b54981ad714c84ca16b7',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'fl1-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'FL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd28926491e41b54981ad714c84ca16b7',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'fl1-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'FL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd28926491e41b54981ad714c84ca16b7',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'fl1-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'FL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd28926491e41b54981ad714c84ca16b7',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'fl1-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'FL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd28926491e41b54981ad714c84ca16b7',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'fl1-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'FL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd28926491e41b54981ad714c84ca16b7',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'fl1-special copy',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'FL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd28926491e41b54981ad714c84ca16b7',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'fl1-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'FL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'd28926491e41b54981ad714c84ca16b7',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'fl1-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'FL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1191df5e1c4a0707f730e030d0415a49',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'fl2-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'FL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1191df5e1c4a0707f730e030d0415a49',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'fl2-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'FL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1191df5e1c4a0707f730e030d0415a49',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'fl2-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'FL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1191df5e1c4a0707f730e030d0415a49',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'fl2-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'FL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1191df5e1c4a0707f730e030d0415a49',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'fl2-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'FL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1191df5e1c4a0707f730e030d0415a49',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'fl2-special copy',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'FL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1191df5e1c4a0707f730e030d0415a49',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'fl2-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'FL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '1191df5e1c4a0707f730e030d0415a49',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'fl2-not included copy',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'FL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'a4f56103553451e52623b2af1cee8eac',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'al1-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'a4f56103553451e52623b2af1cee8eac',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'al1-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'a4f56103553451e52623b2af1cee8eac',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'al1-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'AL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'a4f56103553451e52623b2af1cee8eac',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'al1-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'AL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'a4f56103553451e52623b2af1cee8eac',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'al1-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'a4f56103553451e52623b2af1cee8eac',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'al1-special line',
                paint: {
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                    'line-color': 'hsl(337, 98%, 34%)',
                },
                'source-layer': 'AL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'a4f56103553451e52623b2af1cee8eac',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'al1-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'a4f56103553451e52623b2af1cee8eac',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'al1-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'AL_FP_Final1',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '708ad521f08a310448119eb08f7f336f',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'al2-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '708ad521f08a310448119eb08f7f336f',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'al2-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '708ad521f08a310448119eb08f7f336f',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'al2-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'AL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '708ad521f08a310448119eb08f7f336f',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'al2-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'AL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '708ad521f08a310448119eb08f7f336f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'al2-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '708ad521f08a310448119eb08f7f336f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'al2-special line',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'AL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '708ad521f08a310448119eb08f7f336f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'al2-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'AL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '708ad521f08a310448119eb08f7f336f',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'al2-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'AL_FP_Final2',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b466f22eb4646bbbcfc144ab98ffc7d4',
                },
                filter: ['match', ['get', 'SYM'], ['500'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ga-500',
                paint: {
                    'fill-pattern': '500Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'GA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b466f22eb4646bbbcfc144ab98ffc7d4',
                },
                filter: ['match', ['get', 'SYM'], ['100'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ga-100',
                paint: {
                    'fill-pattern': '100Flood',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'GA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b466f22eb4646bbbcfc144ab98ffc7d4',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'ga-floodway',
                paint: {
                    'fill-pattern': 'Floodway7',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.83,
                        13,
                        0.78,
                        15,
                        0.74,
                    ],
                },
                'source-layer': 'GA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b466f22eb4646bbbcfc144ab98ffc7d4',
                },
                filter: ['match', ['get', 'SYM'], ['FLOODWAY'], true, false],
                type: 'line',
                source: 'composite',
                id: 'ga-floodway line',
                paint: {
                    'line-color': 'hsl(190, 86%, 14%)',
                    'line-opacity': 0.64,
                },
                'source-layer': 'GA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b466f22eb4646bbbcfc144ab98ffc7d4',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ga-special',
                paint: {
                    'fill-pattern': 'NotIncluded',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'GA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b466f22eb4646bbbcfc144ab98ffc7d4',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['SPECIAL', 'REDUCED (LEVEE)'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ga-special copy',
                paint: {
                    'line-color': 'hsl(337, 98%, 34%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'GA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b466f22eb4646bbbcfc144ab98ffc7d4',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'ga-not included',
                paint: {
                    'fill-pattern': 'Unmapped',
                    'fill-opacity': 0.55,
                },
                'source-layer': 'GA_FP_Final',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': 'b466f22eb4646bbbcfc144ab98ffc7d4',
                },
                filter: [
                    'match',
                    ['get', 'SYM'],
                    ['NOT INCLUDED'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'ga-not included line',
                paint: {
                    'line-color': 'hsl(57, 100%, 81%)',
                    'line-opacity': 0.64,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        14,
                        1,
                    ],
                },
                'source-layer': 'GA_FP_Final',
            },
        ],
        created: '2019-01-21T17:10:51.010Z',
        modified: '2023-01-26T15:48:42.240Z',
        id: 'cjr6l7awy2e422slmgbg4l8ty',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    pipelines: {
        version: 8,
        name: 'TX Pipelines REAL',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.50.0',
                android: '6.7.0',
                ios: '4.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-96.6425078850881, 30.906586919903333],
        zoom: 14.622645945153915,
        bearing: -0.011496756350993564,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.TX_Pipelines_20231109_1699509682',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjtyl8jdn3uxs1flvj5yuwd38/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                minzoom: 11,
                layout: {
                    'line-join': 'round',
                },
                filter: [
                    'match',
                    ['get', 'cmdty_desc'],
                    ['CRUDE FWS', 'CRUDE OIL', 'CRUDE OIL OFFSHORE'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'Crude Oil Group2',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        1.25,
                        13,
                        2.25,
                        16.049,
                        2.75,
                        18,
                        3.75,
                    ],
                    'line-offset': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        -1,
                        13,
                        -1,
                        16,
                        -2,
                    ],
                    'line-blur': 0.5,
                },
                'source-layer': 'TX_Pipelines_20231109_1699509535.9040897',
            },
            {
                layout: {
                    'line-join': 'round',
                },
                filter: [
                    'match',
                    ['get', 'cmdty_desc'],
                    ['CRUDE FWS', 'CRUDE OIL', 'CRUDE OIL OFFSHORE'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'Crude Oil Group2 copy',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        1.25,
                        13,
                        2.25,
                        16.049,
                        2.75,
                        18,
                        3.75,
                    ],
                    'line-color': 'hsl(111, 100%, 57%)',
                },
                'source-layer': 'TX_Pipelines_20231109_1699509535.9040897',
            },
            {
                layout: {
                    'line-join': 'round',
                },
                filter: [
                    'match',
                    ['get', 'cmdty_desc'],
                    ['CRUDE FWS', 'CRUDE OIL', 'CRUDE OIL OFFSHORE'],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'Crude Oil Group2 copy 1',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        1.25,
                        13,
                        2.25,
                        16.049,
                        2.75,
                        18,
                        3.75,
                    ],
                    'line-color': 'hsl(118, 91%, 16%)',
                    'line-dasharray': [
                        'step',
                        ['zoom'],
                        ['literal', [1, 1]],
                        11,
                        ['literal', [1, 1]],
                        13,
                        ['literal', [2, 2]],
                    ],
                },
                'source-layer': 'TX_Pipelines_20231109_1699509535.9040897',
            },
            {
                layout: {
                    'line-join': 'round',
                },
                filter: [
                    'match',
                    ['get', 'cmdty_desc'],
                    [
                        'NATURAL GAS FWS',
                        'NATURAL GAS-DISTRIBUTION',
                        'NATURAL GAS',
                        'NATURAL GAS OFFSHORE',
                    ],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'Natural Gas Group2 copy',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        1.25,
                        13,
                        2.25,
                        16.049,
                        2.75,
                        18,
                        3.75,
                    ],
                    'line-color': 'hsl(62, 18%, 6%)',
                    'line-blur': 0.5,
                    'line-offset': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        -1,
                        13,
                        -1,
                        16,
                        -2,
                    ],
                },
                'source-layer': 'TX_Pipelines_20231109_1699509535.9040897',
            },
            {
                layout: {
                    'line-join': 'round',
                },
                filter: [
                    'match',
                    ['get', 'cmdty_desc'],
                    [
                        'NATURAL GAS',
                        'NATURAL GAS FWS',
                        'NATURAL GAS-DISTRIBUTION',
                        'NATURAL GAS OFFSHORE',
                    ],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'Natural Gas Group2 copy 1',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        1.25,
                        13,
                        2.25,
                        16.049,
                        2.75,
                        18,
                        3.75,
                    ],
                    'line-color': 'hsl(0, 100%, 62%)',
                },
                'source-layer': 'TX_Pipelines_20231109_1699509535.9040897',
            },
            {
                layout: {
                    'line-join': 'round',
                },
                filter: [
                    'match',
                    ['get', 'cmdty_desc'],
                    [
                        'NATURAL GAS',
                        'NATURAL GAS FWS',
                        'NATURAL GAS-DISTRIBUTION',
                        'NATURAL GAS OFFSHORE',
                    ],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'Natural Gas Group2',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        1.25,
                        13,
                        2.25,
                        16.049,
                        2.75,
                        18,
                        3.75,
                    ],
                    'line-color': 'hsl(0, 93%, 17%)',
                    'line-dasharray': [
                        'step',
                        ['zoom'],
                        ['literal', [1, 1]],
                        11,
                        ['literal', [1, 1]],
                        13,
                        ['literal', [2, 2]],
                    ],
                },
                'source-layer': 'TX_Pipelines_20231109_1699509535.9040897',
            },
            {
                layout: {
                    'line-join': 'round',
                },
                filter: [
                    'match',
                    ['get', 'cmdty_desc'],
                    [
                        'OTHER GAS',
                        'REFINED LIQUID PRODUCT',
                        'ANHYDROUS AMMONIA',
                        'BUTANE',
                        'BUTANE/BUTYLENE/ISOBUTANE',
                        'CARBON DIOXIDE',
                        'ETHYLENE',
                        'HEXANE',
                        'HIGHLY VOLATILE LIQUID (HVL)',
                        'HIGHLY VOLATILE LIQUID',
                        'HYDROGEN',
                        'PRODUCED WATER',
                    ],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'Other Group2',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        1.25,
                        13,
                        2.25,
                        16.049,
                        2.75,
                        18,
                        3.75,
                    ],
                    'line-color': 'hsl(314, 2%, 0%)',
                    'line-blur': 0.5,
                    'line-offset': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        -1,
                        13,
                        -1,
                        16,
                        -2,
                    ],
                },
                'source-layer': 'TX_Pipelines_20231109_1699509535.9040897',
            },
            {
                layout: {
                    'line-join': 'round',
                },
                filter: [
                    'match',
                    ['get', 'cmdty_desc'],
                    [
                        'OTHER GAS',
                        'REFINED LIQUID PRODUCT',
                        'ANHYDROUS AMMONIA',
                        'BUTANE',
                        'BUTANE/BUTYLENE/ISOBUTANE',
                        'CARBON DIOXIDE',
                        'ETHYLENE',
                        'HEXANE',
                        'HIGHLY VOLATILE LIQUID (HVL)',
                        'HYDROGEN',
                        'HIGHLY VOLATILE LIQUID',
                        'PRODUCED WATER',
                    ],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'Other Group2 copy',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        1.25,
                        13,
                        2.25,
                        16.049,
                        2.75,
                        18,
                        3.75,
                    ],
                    'line-color': 'hsl(314, 0%, 1%)',
                },
                'source-layer': 'TX_Pipelines_20231109_1699509535.9040897',
            },
            {
                layout: {
                    'line-join': 'round',
                },
                filter: [
                    'match',
                    ['get', 'cmdty_desc'],
                    [
                        'OTHER GAS',
                        'REFINED LIQUID PRODUCT',
                        'ANHYDROUS AMMONIA',
                        'BUTANE',
                        'BUTANE/BUTYLENE/ISOBUTANE',
                        'CARBON DIOXIDE',
                        'ETHYLENE',
                        'HEXANE',
                        'HIGHLY VOLATILE LIQUID (HVL)',
                        'HIGHLY VOLATILE LIQUID',
                        'HYDROGEN',
                        'PRODUCED WATER',
                    ],
                    true,
                    false,
                ],
                type: 'line',
                source: 'composite',
                id: 'Other Group2 copy 1',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        1.25,
                        13,
                        2.25,
                        16.049,
                        2.75,
                        18,
                        3.75,
                    ],
                    'line-color': 'hsl(304, 2%, 87%)',
                    'line-dasharray': [
                        'step',
                        ['zoom'],
                        ['literal', [1, 1]],
                        11,
                        ['literal', [1, 1]],
                        13,
                        ['literal', [2, 2]],
                    ],
                },
                'source-layer': 'TX_Pipelines_20231109_1699509535.9040897',
            },
        ],
        created: '2019-04-01T16:48:46.184Z',
        modified: '2023-11-09T06:09:58.438Z',
        id: 'cjtyl8jdn3uxs1flvj5yuwd38',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    water_wells: {
        version: 8,
        name: 'Water Wells',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.54.0',
                android: '7.4.0',
                ios: '4.11.0',
            },
            'mapbox:groups': {},
        },
        center: [-87.61330124247678, 36.260358539981425],
        zoom: 12.24044754483423,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.2e28a809,mapright2.f973e267,mapright2.15d6b591,mapright2.fe1fb596,mapright2.7e16bcc3,mapright2.527a8df8,mapright2.c4108042,mapright2.8cda0043,mapright2.fb0c8215,mapright2.3c580199,mapright2.b7e2571b,mapright2.3627e334,mapright2.1e1ff5aa',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjzy7xv7q2fhs1clbdef2ovrm/2te9hfx1gsfl111i4hryv3x0y',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: '2020tww-final',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [2, 2],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    visibility: 'none',
                },
                source: 'composite',
                'source-layer': '2020TWW_Final',
            },
            {
                id: '2020tww-final copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-allow-overlap': true,
                    'icon-padding': 0,
                    'icon-ignore-placement': true,
                    visibility: 'none',
                },
                source: 'composite',
                'source-layer': '2020TWW_Final',
            },
            {
                id: 'nv-2020-ww-final',
                type: 'symbol',
                paint: {
                    'icon-translate': [0, 0],
                },
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'icon-offset': [2, 2],
                },
                source: 'composite',
                'source-layer': 'NV_2020_WW_Final',
            },
            {
                id: 'nv-2020-ww-final copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                },
                source: 'composite',
                'source-layer': 'NV_2020_WW_Final',
            },
            {
                id: 'aZ-2020-ww-final',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [2, 2],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                },
                source: 'composite',
                'source-layer': 'AR_2020_WW_Final',
            },
            {
                id: 'aZ-2020-ww-final copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [0, 0],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                },
                source: 'composite',
                'source-layer': 'AR_2020_WW_Final',
            },
            {
                id: 'co-ww-2020-final',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [2, 2],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                },
                source: 'composite',
                'source-layer': 'CO_WW_2020_Final',
            },
            {
                id: 'co-ww-2020-final copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [0, 0],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                },
                source: 'composite',
                'source-layer': 'CO_WW_2020_Final',
            },
            {
                id: 'MT final',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'icon-offset': [2, 2],
                },
                source: 'composite',
                'source-layer': 'Final',
            },
            {
                id: 'MT final copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                },
                source: 'composite',
                'source-layer': 'Final',
            },
            {
                id: 'ca-ww-2020-1',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'icon-offset': [2, 2],
                },
                source: 'composite',
                'source-layer': 'CA_WW_2020_1',
            },
            {
                id: 'ca-ww-2020-1 copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                },
                source: 'composite',
                'source-layer': 'CA_WW_2020_1',
            },
            {
                id: 'ca-ww-2020-2',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'icon-offset': [2, 2],
                },
                source: 'composite',
                'source-layer': 'CA_WW_2020_2',
            },
            {
                id: 'ca-ww-2020-2 copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                },
                source: 'composite',
                'source-layer': 'CA_WW_2020_2',
            },
            {
                id: 'ks-2020-ww',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-offset': [2, 2],
                },
                source: 'composite',
                'source-layer': 'KS_2020_WW',
            },
            {
                id: 'ks-2020-ww copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': 'KS_2020_WW',
            },
            {
                id: 'ID 2020-ww',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [2, 2],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': '2020_WW',
            },
            {
                id: 'ID 2020-ww copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [0, 0],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': '2020_WW',
            },
            {
                id: 'ww-ky-2020',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [2, 2],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': 'WW_KY_2020',
            },
            {
                id: 'ww-ky-2020 copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [0, 0],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': 'WW_KY_2020',
            },
            {
                id: 'mo-ww',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [2, 2],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': 'MO_WW',
            },
            {
                id: 'mo-ww copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [0, 0],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': 'MO_WW',
            },
            {
                id: 'ny-ww-2020',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [2, 2],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': 'NY_WW_2020',
            },
            {
                id: 'ny-ww-2020 copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [0, 0],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': 'NY_WW_2020',
            },
            {
                id: 'tn-ww-2022',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [2, 2],
                    'icon-allow-overlap': true,
                    'icon-padding': 0,
                },
                source: 'composite',
                'source-layer': 'TN_WW_2022',
            },
            {
                id: 'tn-ww-2022 copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                },
                source: 'composite',
                'source-layer': 'TN_WW_2022',
            },
        ],
        created: '2019-08-30T14:34:47.369Z',
        modified: '2022-08-15T19:15:11.259Z',
        id: 'cjzy7xv7q2fhs1clbdef2ovrm',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    substations: {
        version: 8,
        name: 'Substations',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:groups': {},
        },
        center: [-97.73630781156648, 30.45466837924259],
        zoom: 10.712883812436385,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.1ea53dbf,mapright2.0c5c4635,mapright2.e05487bf,mapright2.bbfaf7ce',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckobhffr7148c17o4fj1aovi8/1x5k4ipv3jfpubj05w1bwwd5a',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'substations-west-032321 copy',
                type: 'circle',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        6,
                        12,
                        7.5,
                        14,
                        11,
                        16,
                        12,
                    ],
                    'circle-translate': [2, 2],
                },
                source: 'composite',
                'source-layer': 'Substations_West_032321',
                layout: {},
            },
            {
                id: 'substations-west-032321',
                type: 'symbol',
                paint: {},
                source: 'composite',
                'source-layer': 'Substations_West_032321',
                layout: {
                    'icon-image': 'substation5',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        0.5,
                        11,
                        0.5,
                        12,
                        0.65,
                        13,
                        0.75,
                        16,
                        0.95,
                        18,
                        1,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
            },
            {
                id: 'substations-east-032321 copy',
                type: 'circle',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        6,
                        12,
                        7.5,
                        14,
                        11,
                        16,
                        12,
                    ],
                    'circle-translate': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Substations_East_032321',
            },
            {
                id: 'substations-east-032321',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'substation5',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        0.5,
                        11,
                        0.5,
                        12,
                        0.65,
                        13,
                        0.75,
                        16,
                        0.95,
                        18,
                        1,
                    ],
                    'icon-allow-overlap': true,
                    'icon-padding': 0,
                },
                source: 'composite',
                'source-layer': 'Substations_East_032321',
            },
            {
                id: 'substations-hawaii-032321 copy',
                type: 'circle',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        6,
                        12,
                        7.5,
                        14,
                        11,
                        16,
                        12,
                    ],
                    'circle-translate': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Substations_Hawaii_032321',
            },
            {
                id: 'substations-hawaii-032321',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'substation5',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        0.5,
                        11,
                        0.5,
                        12,
                        0.65,
                        13,
                        0.75,
                        16,
                        0.95,
                        18,
                        1,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': 'Substations_Hawaii_032321',
            },
            {
                id: 'substations-alaska-032321 copy',
                type: 'circle',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        6,
                        12,
                        7.5,
                        14,
                        11,
                        16,
                        12,
                    ],
                    'circle-translate': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Substations_Alaska_032321',
            },
            {
                id: 'substations-alaska-032321',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'substation5',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        0.5,
                        11,
                        0.5,
                        12,
                        0.65,
                        13,
                        0.75,
                        16,
                        0.95,
                        18,
                        1,
                    ],
                    'icon-allow-overlap': true,
                    'icon-padding': 0,
                },
                source: 'composite',
                'source-layer': 'Substations_Alaska_032321',
            },
        ],
        created: '2021-05-05T13:14:19.677Z',
        modified: '2021-05-05T13:25:33.025Z',
        id: 'ckobhffr7148c17o4fj1aovi8',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    water_districts: {
        version: 8,
        name: 'CA Water Districts',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.54.0',
                android: '7.4.0',
                ios: '4.11.0',
            },
            'mapbox:groups': {},
        },
        center: [-119.49094879469851, 36.572378000697256],
        zoom: 15.697175623863695,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.1xkszzad',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjztxy1cr1bda1cqeuaassdb6/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: 'ca-water-districts',
                type: 'fill',
                source: 'composite',
                'source-layer': 'CA_Water_Districts',
                layout: {},
                paint: {
                    'fill-color': 'hsl(222, 66%, 37%)',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.69,
                        10,
                        0.5,
                        12,
                        0.44,
                        14,
                        0.4,
                    ],
                    'fill-antialias': false,
                },
            },
            {
                id: 'ca-water-districts copy',
                type: 'line',
                source: 'composite',
                'source-layer': 'CA_Water_Districts',
                layout: {},
                paint: {
                    'line-color': 'hsl(252, 98%, 17%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.5,
                        10,
                        0.8,
                        12,
                        1,
                        14,
                        1.4,
                    ],
                },
            },
            {
                id: 'ca-water-districts labels',
                type: 'symbol',
                source: 'composite',
                'source-layer': 'CA_Water_Districts',
                layout: {
                    'text-field': ['to-string', ['get', 'AGENCYNAME']],
                    'text-font': [
                        'Asap Bold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        12,
                        12.5,
                        14,
                        13.5,
                    ],
                    'text-max-width': 5,
                },
                paint: {
                    'text-color': 'hsl(193, 97%, 79%)',
                    'text-halo-color': 'hsl(226, 100%, 26%)',
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                },
            },
        ],
        created: '2019-08-27T14:43:54.478Z',
        modified: '2019-10-10T16:18:09.631Z',
        id: 'cjztxy1cr1bda1cqeuaassdb6',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    soils_4: {
        version: 8,
        name: 'Soils Group 4 061819',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '0.54.0',
                android: '6.7.0',
                ios: '4.7.0',
            },
            'mapbox:groups': {
                '347e1f3019e7ce9615e561d34feb3b81': {
                    name: 'MD-PA Group',
                    collapsed: true,
                },
                da542ad128b4932b1a3bf067355f0603: {
                    name: 'CT-MA-ME-NH-RI-VT Group',
                    collapsed: true,
                },
                '5b11aecc75ef51f47c7114e06b5ec742': {
                    name: 'DE-NJ-NY Group',
                    collapsed: true,
                },
                '9dbc58ea04a9369a284a672a08596a07': {
                    name: 'MI1 Group',
                    collapsed: true,
                },
                d2e183ff95e8b0d4521ad2ea68747bea: {
                    name: 'MI2 Group',
                    collapsed: true,
                },
                '9cbfb38caa8bc2ca31904e74d2185542': {
                    name: 'DC Group',
                    collapsed: true,
                },
                '96bef690cc34070dc1091e4cbcdeda9a': {
                    name: 'HI Group',
                    collapsed: true,
                },
                '00b24ebc0aa8dc5b42aaa4f35464b72f': {
                    name: 'AK Group',
                    collapsed: true,
                },
            },
        },
        center: [-154.0250668664558, 65.97588523421283],
        zoom: 14.180644358100478,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.e31c06a4,mapright2.13pgw139,mapright2.2a3b74ff,mapright2.5qkg0v65,mapright2.aoq0ocjq,mapright2.61m3r7m1,mapright2.1cvfej49,mapright2.bk3hae3a',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cjxkl3vkg01vb1coo9kqfo29b/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: 'MD-PA-Soils-8',
                type: 'fill',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8e', '8', '8s', '8w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MD-PA-Soils-7',
                type: 'fill',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MD-PA-Soils-6',
                type: 'fill',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MD-PA-Soils-5',
                type: 'fill',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MD-PA-Soils-4',
                type: 'fill',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MD-PA-Soils-3',
                type: 'fill',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MD-PA-Soils-2',
                type: 'fill',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MD-PA-Soils-1',
                type: 'fill',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MD-PA-Soils-0',
                type: 'fill',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['', 's'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MD-PA-Soils-Water',
                type: 'fill',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Census water'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MD-PA-Soils-Lines',
                type: 'line',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
            },
            {
                id: 'MD-PA-Soils-Labels',
                type: 'symbol',
                metadata: {
                    'mapbox:group': '347e1f3019e7ce9615e561d34feb3b81',
                },
                source: 'composite',
                'source-layer': 'MD_PA_Soils_041619',
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-8',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8c', '8e', '8s', '8', '8w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-7',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-6',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6c', '6s', '6e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-5',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-4',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-3',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3c', '3e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-2',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2c', '2e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-1',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-0',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['', 'w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-Water',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water bodies', 'Water'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-Lines',
                type: 'line',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
            },
            {
                id: 'ct-ma-me-nh-ri-vt-soils-Labels',
                type: 'symbol',
                metadata: {
                    'mapbox:group': 'da542ad128b4932b1a3bf067355f0603',
                },
                source: 'composite',
                'source-layer': 'CT_MA_ME_NH_RI_VT_Soils_042319',
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-8',
                type: 'fill',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8w', '8s', '8'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-7',
                type: 'fill',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-6',
                type: 'fill',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-5',
                type: 'fill',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-4',
                type: 'fill',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-3',
                type: 'fill',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-2',
                type: 'fill',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-1',
                type: 'fill',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-0',
                type: 'fill',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-Water',
                type: 'fill',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Dam', 'Water'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DE-NJ-NY-Soils-Lines',
                type: 'line',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
            },
            {
                id: 'DE-NJ-NY-Soils-Labels',
                type: 'symbol',
                metadata: {
                    'mapbox:group': '5b11aecc75ef51f47c7114e06b5ec742',
                },
                source: 'composite',
                'source-layer': 'DE_NJ_NY_Soils_022719',
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
            },
            {
                id: 'MI1-Soils-8',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8s', '8w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI1-Soils-7',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI1-Soils-6',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI1-Soils-5',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                filter: ['match', ['get', 'CAPABILITY'], ['5w'], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI1-Soils-4',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI1-Soils-3',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI1-Soils-2',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI1-Soils-1',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI1-Soils-0',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI1-Soils-Water',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water (Lake Superior)', 'Water', 'Water (Inland)'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI1-Soils-Lines',
                type: 'line',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
            },
            {
                id: 'MI1-Soils-Labels',
                type: 'symbol',
                metadata: {
                    'mapbox:group': '9dbc58ea04a9369a284a672a08596a07',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_20190311',
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
            },
            {
                id: 'MI2-Soils-8',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8s', '8w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI2-Soils-7',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7e', '7s', '7'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI2-Soils-6',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6e', '6s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI2-Soils-5',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI2-Soils-4',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4e', '4s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI2-Soils-3',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI2-Soils-2',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2s', '2w', '2c', '2e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI2-Soils-1',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI2-Soils-0',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI2-Soils-Water',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'MI2-Soils-Lines',
                type: 'line',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
            },
            {
                id: 'MI2-Soils-Labels',
                type: 'symbol',
                metadata: {
                    'mapbox:group': 'd2e183ff95e8b0d4521ad2ea68747bea',
                },
                source: 'composite',
                'source-layer': 'MI_Soil_2_20190311',
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
            },
            {
                id: 'DC-Soils-8',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DC-Soils-7',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7', '7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DC-Soils-6',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6', '6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DC-Soils-5',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5', '5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DC-Soils-4',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4', '4c', '4e', '4s', '4w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DC-Soils-3',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3', '3c', '3e', '3s', '3w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DC-Soils-2',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2', '2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DC-Soils-1',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DC-Soils-0',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DC-Soils-Water',
                type: 'fill',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                filter: ['match', ['get', 'name'], ['Water'], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'DC-Soils-Lines',
                type: 'line',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
            },
            {
                id: 'DC-Soils-Labels',
                type: 'symbol',
                metadata: {
                    'mapbox:group': '9cbfb38caa8bc2ca31904e74d2185542',
                },
                source: 'composite',
                'source-layer': 'DC_Soil_20190414',
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
            },
            {
                id: 'HI-Soils-8',
                type: 'fill',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8', '8c', '8e', '8s', '8w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'HI-Soils-7',
                type: 'fill',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7', '7c', '7e', '7s', '7w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'HI-Soils-6',
                type: 'fill',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6', '6c', '6e', '6s', '6w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'HI-Soils-5',
                type: 'fill',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5', '5c', '5e', '5s', '5w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'HI-Soils-4',
                type: 'fill',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4', '4s', '4e', '4c', '4w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'HI-Soils-3',
                type: 'fill',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3', '3s', '3c', '3e', '3w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'HI-Soils-2',
                type: 'fill',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['2', '2c', '2e', '2s', '2w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'HI-Soils-1',
                type: 'fill',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['1', '1c', '1e', '1s', '1w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'HI-Soils-0',
                type: 'fill',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                filter: ['match', ['get', 'CAPABILITY'], '', true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'HI-Soils-Water',
                type: 'fill',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Water > 40 acres'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'HI-Soils-Lines',
                type: 'line',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
            },
            {
                id: 'HI-Soils-Labels',
                type: 'symbol',
                metadata: {
                    'mapbox:group': '96bef690cc34070dc1091e4cbcdeda9a',
                },
                source: 'composite',
                'source-layer': 'HI_Soil_20190304',
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
            },
            {
                id: 'AK-Soils-8',
                type: 'fill',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['8c', '8e', '8s', '8', '8w'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(8, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'AK-Soils-7',
                type: 'fill',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['7w', '7c', '7e', '7s'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(26, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'AK-Soils-6',
                type: 'fill',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['6w', '6c', '6s', '6e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(39, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'AK-Soils-5',
                type: 'fill',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['5w', '5c'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(54, 100%, 50%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'AK-Soils-4',
                type: 'fill',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['4w', '4s', '4e', '4c'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(64, 100%, 45%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'AK-Soils-3',
                type: 'fill',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                filter: [
                    'match',
                    ['get', 'CAPABILITY'],
                    ['3s', '3w', '3c', '3e'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(74, 100%, 35%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'AK-Soils-2',
                type: 'fill',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                filter: ['match', ['get', 'CAPABILITY'], ['2c'], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsl(88, 100%, 27%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'AK-Soils-1',
                type: 'fill',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                filter: ['match', ['get', 'CAPABILITY'], ['1'], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsl(120, 100%, 19%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'AK-Soils-0',
                type: 'fill',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                filter: ['match', ['get', 'CAPABILITY'], [''], true, false],
                layout: {},
                paint: {
                    'fill-color': 'hsla(0, 0%, 0%, 0)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'AK-Soils-Water',
                type: 'fill',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                filter: [
                    'match',
                    ['get', 'name'],
                    ['Water', 'Water, fresh', 'Water-Riverwash complex'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(203, 95%, 43%)',
                    'fill-opacity': 0.62,
                    'fill-antialias': false,
                },
            },
            {
                id: 'AK-Soils-Lines',
                type: 'line',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 10%, 94%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        1.25,
                    ],
                },
            },
            {
                id: 'AK-Soils-Labels',
                type: 'symbol',
                metadata: {
                    'mapbox:group': '00b24ebc0aa8dc5b42aaa4f35464b72f',
                },
                source: 'composite',
                'source-layer': 'AK_Soils_20190218',
                layout: {
                    'text-field': '{MUSYM}',
                    'text-font': ['Alegreya SC Black', 'Arial Unicode MS Bold'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        15,
                        16,
                        19,
                        18,
                        28,
                    ],
                },
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 1%, 0%)',
                    'text-halo-width': 1,
                },
            },
        ],
        created: '2019-07-01T16:15:11.606Z',
        modified: '2019-11-06T17:03:54.834Z',
        id: 'cjxkl3vkg01vb1coo9kqfo29b',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    parcels_2: {
        version: 8,
        name: '20240325_corelogic_parcel_boundaries_group_2',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.9.0',
                android: '8.6.0',
                ios: '5.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-75.20363, 39.952847],
        zoom: 13.17,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_polygons_ct,mapright2.20240325_polygons_dc,mapright2.20240325_polygons_de,mapright2.20240325_polygons_in,mapright2.20240325_polygons_ky,mapright2.20240325_polygons_md,mapright2.20240325_polygons_mi,mapright2.20240325_polygons_nj,mapright2.20240325_polygons_ny,mapright2.20240325_polygons_oh,mapright2.20240325_polygons_pa,mapright2.20240325_polygons_ri,mapright2.20240325_polygons_va,mapright2.20240325_polygons_wi,mapright2.20240325_polygons_wv',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rgb200h501p28n3m6xwi/35suyk9pqrdqn9t9t1o08r6b5',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: '20240325_polygons_ct',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_dc',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_de',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_in',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ky',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_md',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_mi',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_nj',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ny',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_oh',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_pa',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ri',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_va',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_wi',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_wv',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
        ],
        created: '2024-04-03T16:53:20.078Z',
        modified: '2024-04-03T16:53:20.078Z',
        id: 'cluk1rgb200h501p28n3m6xwi',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    parcels_3: {
        version: 8,
        name: '20240325_corelogic_parcel_boundaries_group_3',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.9.0',
                android: '8.6.0',
                ios: '5.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-109.35009, 46.97589],
        zoom: 13.17,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_polygons_az,mapright2.20240325_polygons_ca,mapright2.20240325_polygons_co,mapright2.20240325_polygons_id,mapright2.20240325_polygons_mt,mapright2.20240325_polygons_mn,mapright2.20240325_polygons_nd,mapright2.20240325_polygons_ne,mapright2.20240325_polygons_nm,mapright2.20240325_polygons_nv,mapright2.20240325_polygons_or,mapright2.20240325_polygons_sd,mapright2.20240325_polygons_ut,mapright2.20240325_polygons_wa,mapright2.20240325_polygons_wy',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rgh303y701qqboxt6a9w/35suyk9pqrdqn9t9t1o08r6b5',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: '20240325_polygons_az',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ca',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_co',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_id',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_mt',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_mn',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_nd',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ne',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_nm',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_nv',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_or',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_sd',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ut',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_wa',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_wy',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
        ],
        created: '2024-04-03T16:53:20.295Z',
        modified: '2024-04-03T16:53:20.295Z',
        id: 'cluk1rgh303y701qqboxt6a9w',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    pu_pounce: {
        version: 8,
        name: 'McCollum PU Pounce',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '1.10.0',
            },
            'mapbox:groups': {},
        },
        center: [-88.0173610327355, 35.52956833466435],
        zoom: 14.59003936136185,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.41296bd3',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckdsurux21b0819o7l6sw7yi6/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'pu-pounce-041222',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 97%, 53%)',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        10,
                        0.92,
                        12,
                        0.56,
                        14,
                        0.2,
                        16,
                        0,
                    ],
                    'fill-translate': [0, 0],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'pu_pounce_041222',
            },
            {
                id: 'pu-pounce-041222 copy',
                type: 'line',
                paint: {
                    'line-color': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        'hsl(0, 77%, 12%)',
                        15,
                        'hsl(0, 97%, 51%)',
                    ],
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        10,
                        0.2,
                        11,
                        0.5,
                        12,
                        0.7,
                        14,
                        1.2,
                        16,
                        1.8,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'pu_pounce_041222',
            },
            {
                id: 'pu-pounce-041222 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 0%, 98%)',
                    'text-halo-color': 'hsl(0, 26%, 3%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1.5,
                        15,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
                layout: {
                    'text-field': [
                        'concat',
                        ['get', 'TRACT_NUM'],
                        ' ',
                        ['get', 'GROSS_GIS'],
                    ],
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        9,
                        14,
                        10,
                        16,
                        12,
                    ],
                    'text-max-width': 5,
                    'text-transform': 'uppercase',
                },
                source: 'composite',
                'source-layer': 'pu_pounce_041222',
            },
        ],
        created: '2020-08-13T13:43:13.850Z',
        modified: '2022-04-12T21:20:55.633Z',
        id: 'ckdsurux21b0819o7l6sw7yi6',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    ysc: {
        sources: {
            ysc: {
                tileSize: 256,
                scheme: 'tms',
                type: 'raster',
                tiles: [
                    'https://mapright-orthos.s3.amazonaws.com/yellowstone-club/{z}/{x}/{y}.png',
                ],
            },
        },
        layers: [
            {
                type: 'raster',
                id: 'ysc',
                source: 'ysc',
            },
        ],
    },
    zoning: {
        version: 8,
        name: 'Elevate Land Zoning Data',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:groups': {},
        },
        center: [-93.48239100776459, 45.12441340195011],
        zoom: 13.079560294909738,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.a73627cc',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckm5a19xj3p2w17p83cstu0vl/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'ZONING'],
                    ['B', 'B-PUD'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'B',
                paint: {
                    'fill-color': 'hsl(0, 100%, 44%)',
                    'fill-opacity': 0.5,
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {},
                filter: ['match', ['get', 'ZONING'], ['FF'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'FF',
                paint: {
                    'fill-color': 'hsl(33, 98%, 54%)',
                    'fill-opacity': 0.5,
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'ZONING'],
                    ['I', 'I PUD'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'I',
                paint: {
                    'fill-color': 'hsl(111, 100%, 44%)',
                    'fill-opacity': 0.5,
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {},
                filter: ['match', ['get', 'ZONING'], ['PUD'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'PUD',
                paint: {
                    'fill-color': 'hsl(183, 100%, 66%)',
                    'fill-opacity': 0.5,
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'ZONING'],
                    ['R-1', 'R-1 PUD'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'R-1',
                paint: {
                    'fill-color': 'hsl(216, 98%, 54%)',
                    'fill-opacity': 0.5,
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'ZONING'],
                    ['R-2', 'R-2 PUD', 'R-2B'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'R-2',
                paint: {
                    'fill-color': 'hsl(291, 97%, 41%)',
                    'fill-opacity': 0.5,
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'ZONING'],
                    ['R-3', 'R-3 PUD'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'R-3',
                paint: {
                    'fill-color': 'hsl(317, 100%, 72%)',
                    'fill-opacity': 0.5,
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'ZONING'],
                    ['R-4', 'R-4 PUD'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'R-4',
                paint: {
                    'fill-color': 'hsl(0, 2%, 99%)',
                    'fill-opacity': 0.5,
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {},
                filter: [
                    'match',
                    ['get', 'ZONING'],
                    ['R-5', 'R-5 PUD'],
                    true,
                    false,
                ],
                type: 'fill',
                source: 'composite',
                id: 'R-5',
                paint: {
                    'fill-opacity': 0.5,
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {},
                filter: ['match', ['get', 'ZONING'], ['R-A'], true, false],
                type: 'fill',
                source: 'composite',
                id: 'R-A',
                paint: {
                    'fill-color': 'hsl(62, 97%, 57%)',
                    'fill-opacity': 0.5,
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {},
                type: 'line',
                source: 'composite',
                id: 'B copy',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1,
                        16,
                        2,
                    ],
                },
                'source-layer': 'Zoning_elevateteam',
            },
            {
                layout: {
                    'text-field': ['to-string', ['get', 'ZONING']],
                    'text-font': ['Ubuntu Bold', 'Arial Unicode MS Regular'],
                    'symbol-avoid-edges': true,
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        10.5,
                        14,
                        12,
                        16,
                        14,
                        18,
                        17,
                    ],
                    'text-transform': 'uppercase',
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        3,
                        22,
                        3,
                    ],
                },
                type: 'symbol',
                source: 'composite',
                id: 'R-A copy',
                paint: {
                    'text-color': 'hsl(0, 2%, 100%)',
                    'text-halo-color': '#000000',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        1,
                        15,
                        1.5,
                    ],
                },
                'source-layer': 'Zoning_elevateteam',
            },
        ],
        created: '2021-03-11T19:41:20.315Z',
        modified: '2021-03-11T20:19:02.076Z',
        id: 'ckm5a19xj3p2w17p83cstu0vl',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    grid: {
        version: 8,
        name: 'Treely Grid',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:groups': {},
        },
        center: [-81.13987229107238, 33.16551770697278],
        zoom: 19.457360113166587,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.b9efd3f5',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckolpyzhs24qv18qpdo6ekssw/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'grid',
                minzoom: 16,
                type: 'line',
                paint: {
                    'line-color': 'hsl(295, 0%, 100%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        16,
                        0.5,
                        22,
                        1,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'grid',
            },
        ],
        created: '2021-05-12T17:11:10.407Z',
        modified: '2021-05-13T15:33:32.114Z',
        id: 'ckolpyzhs24qv18qpdo6ekssw',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    zip_codes: {
        version: 8,
        name: 'Zip Codes',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:groups': {},
        },
        center: [-88.27172812847444, 35.37093833385191],
        zoom: 10.113598690331882,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.2140ca22,mapright2.26e041c3,mapright2.bd911941,mapright2.88b652b2,mapright2.6cbf00d5,mapright2.700ea427,mapright2.734a2044',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckou5soh608f417pfejbsza03/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'zipcodes-east-pt1-051221 copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(206, 100%, 81%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.5,
                        10,
                        3,
                        14,
                        4,
                        16.167,
                        5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_east_pt1_051221',
            },
            {
                id: 'zipcodes-east-pt1-051221',
                type: 'line',
                paint: {
                    'line-color': 'hsl(236, 91%, 23%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.2,
                        11.438,
                        0.5,
                        14,
                        1,
                        16.167,
                        1.5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_east_pt1_051221',
            },
            {
                id: 'zipcodes-east-pt1-051221 copy',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(216, 98%, 81%)',
                    'text-halo-color': 'hsl(239, 98%, 32%)',
                    'text-halo-blur': 0.5,
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.7,
                        11,
                        1.4,
                        16.122,
                        1.6,
                    ],
                },
                source: 'composite',
                'source-layer': 'zipcodes_east_pt1_051221',
                layout: {
                    'text-field': ['get', 'GEOID10'],
                    'text-font': [
                        'Rubik Bold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-letter-spacing': 0.1,
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        10,
                        13.5,
                        14.103,
                        15,
                        16.122,
                        17,
                    ],
                    'symbol-avoid-edges': true,
                    'text-padding': 5,
                },
            },
            {
                id: 'zipcodes-east-pt2-051221',
                type: 'line',
                paint: {
                    'line-color': 'hsl(206, 100%, 81%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.5,
                        10,
                        3,
                        14,
                        4,
                        16.167,
                        5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_east_pt2_051221',
            },
            {
                id: 'zipcodes-east-pt2-051221 copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(236, 91%, 23%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.2,
                        11.438,
                        0.5,
                        14,
                        1,
                        16.167,
                        1.5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_east_pt2_051221',
            },
            {
                id: 'zipcodes-east-pt2-051221 copy',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(216, 98%, 81%)',
                    'text-halo-color': 'hsl(239, 98%, 32%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.7,
                        11,
                        1.4,
                        16.122,
                        1.6,
                    ],
                    'text-halo-blur': 0.5,
                },
                source: 'composite',
                'source-layer': 'zipcodes_east_pt2_051221',
                layout: {
                    'text-field': ['get', 'GEOID10'],
                    'text-font': [
                        'Rubik Bold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        10,
                        13.5,
                        14.103,
                        15,
                        16.122,
                        17,
                    ],
                    'text-letter-spacing': 0.1,
                    'symbol-avoid-edges': true,
                    'text-padding': 5,
                },
            },
            {
                id: 'zipcodes-west-pt1-051221',
                type: 'line',
                paint: {
                    'line-color': 'hsl(206, 100%, 81%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.5,
                        10,
                        3,
                        14,
                        4,
                        16.167,
                        5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_west_pt1_051221',
            },
            {
                id: 'zipcodes-west-pt1-051221 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(236, 91%, 23%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.2,
                        11.438,
                        0.5,
                        14,
                        1,
                        16.167,
                        1.5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_west_pt1_051221',
            },
            {
                id: 'zipcodes-west-pt1-051221 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(216, 98%, 81%)',
                    'text-halo-color': 'hsl(239, 98%, 32%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.7,
                        11,
                        1.4,
                        16.122,
                        1.6,
                    ],
                    'text-halo-blur': 0.5,
                },
                layout: {
                    'text-field': ['get', 'GEOID10'],
                    'text-font': [
                        'Rubik Bold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        10,
                        13.5,
                        14.103,
                        15,
                        16.122,
                        17,
                    ],
                    'text-letter-spacing': 0.1,
                    'symbol-avoid-edges': true,
                    'text-padding': 5,
                },
                source: 'composite',
                'source-layer': 'zipcodes_west_pt1_051221',
            },
            {
                id: 'zipcodes-west-pt2-051221',
                type: 'line',
                paint: {
                    'line-color': 'hsl(206, 100%, 81%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.5,
                        10,
                        3,
                        14,
                        4,
                        16.167,
                        5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_west_pt2_051221',
            },
            {
                id: 'zipcodes-west-pt2-051221 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(236, 91%, 23%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.2,
                        11.438,
                        0.5,
                        14,
                        1,
                        16.167,
                        1.5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_west_pt2_051221',
            },
            {
                id: 'zipcodes-west-pt2-051221 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(216, 98%, 81%)',
                    'text-halo-color': 'hsl(239, 98%, 32%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.7,
                        11,
                        1.4,
                        16.122,
                        1.6,
                    ],
                    'text-halo-blur': 0.5,
                },
                layout: {
                    'text-field': ['get', 'GEOID10'],
                    'text-font': [
                        'Rubik Bold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        10,
                        13.5,
                        14.103,
                        15,
                        16.122,
                        17,
                    ],
                    'text-letter-spacing': 0.1,
                    'symbol-avoid-edges': true,
                    'text-padding': 5,
                },
                source: 'composite',
                'source-layer': 'zipcodes_west_pt2_051221',
            },
            {
                id: 'zipcodes-hi-051221',
                type: 'line',
                paint: {
                    'line-color': 'hsl(206, 100%, 81%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.5,
                        10,
                        3,
                        14,
                        4,
                        16.167,
                        5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_hi_051221',
            },
            {
                id: 'zipcodes-hi-051221 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(236, 91%, 23%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.2,
                        11.438,
                        0.5,
                        14,
                        1,
                        16.167,
                        1.5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_hi_051221',
            },
            {
                id: 'zipcodes-hi-051221 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(216, 98%, 81%)',
                    'text-halo-color': 'hsl(239, 98%, 32%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.7,
                        11,
                        1.4,
                        16.122,
                        1.6,
                    ],
                    'text-halo-blur': 0.5,
                },
                layout: {
                    'text-field': ['get', 'GEOID10'],
                    'text-font': [
                        'Rubik Bold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        10,
                        13.5,
                        14.103,
                        15,
                        16.122,
                        17,
                    ],
                    'text-letter-spacing': 0.1,
                    'symbol-avoid-edges': true,
                    'text-padding': 5,
                },
                source: 'composite',
                'source-layer': 'zipcodes_hi_051221',
            },
            {
                id: 'zipcodes-ak-051221',
                type: 'line',
                paint: {
                    'line-color': 'hsl(206, 100%, 81%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.5,
                        10,
                        3,
                        14,
                        4,
                        16.167,
                        5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_ak_051221',
            },
            {
                id: 'zipcodes-ak-051221 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(236, 91%, 23%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.2,
                        11.438,
                        0.5,
                        14,
                        1,
                        16.167,
                        1.5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_ak_051221',
            },
            {
                id: 'zipcodes-ak-051221 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(216, 98%, 81%)',
                    'text-halo-color': 'hsl(239, 98%, 32%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.7,
                        11,
                        1.4,
                        16.122,
                        1.6,
                    ],
                    'text-halo-blur': 0.5,
                },
                layout: {
                    'text-field': ['get', 'GEOID10'],
                    'text-font': [
                        'Rubik Bold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        10,
                        13.5,
                        14.103,
                        15,
                        16.122,
                        17,
                    ],
                    'text-letter-spacing': 0.1,
                    'symbol-avoid-edges': true,
                    'text-padding': 5,
                },
                source: 'composite',
                'source-layer': 'zipcodes_ak_051221',
            },
            {
                id: 'zipcodes-pr-051221',
                type: 'line',
                paint: {
                    'line-color': 'hsl(206, 100%, 81%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1.5,
                        10,
                        3,
                        14,
                        4,
                        16.167,
                        5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_pr_051221',
            },
            {
                id: 'zipcodes-pr-051221 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(236, 91%, 23%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.2,
                        11.438,
                        0.5,
                        14,
                        1,
                        16.167,
                        1.5,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'zipcodes_pr_051221',
            },
            {
                id: 'zipcodes-pr-051221 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(216, 98%, 81%)',
                    'text-halo-color': 'hsl(239, 98%, 32%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.7,
                        11,
                        1.4,
                        16.122,
                        1.6,
                    ],
                    'text-halo-blur': 0.5,
                },
                layout: {
                    'text-field': ['get', 'GEOID10'],
                    'text-font': [
                        'Rubik Bold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        10,
                        13.5,
                        14.103,
                        15,
                        16.122,
                        17,
                    ],
                    'text-letter-spacing': 0.1,
                    'symbol-avoid-edges': true,
                    'text-padding': 5,
                },
                source: 'composite',
                'source-layer': 'zipcodes_pr_051221',
            },
        ],
        created: '2021-05-18T14:56:19.453Z',
        modified: '2021-05-20T20:12:46.248Z',
        id: 'ckou5soh608f417pfejbsza03',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    wind_turbines: {
        version: 8,
        name: 'Wind Turbines',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:groups': {},
        },
        center: [-162.5681716624889, 66.83366083712218],
        zoom: 13.225380863811788,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.Wind_PR_20231127_1701283265,mapright2.Wind_HI_20231127_1701283460,mapright2.Wind_AK_20231127_1701283416,mapright2.Wind_48_20231127_1701283365',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckoblpskv18fr17pcazb2izub/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'wind-48-20231127-1701283345-0756998',
                type: 'circle',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        2,
                        10,
                        3.3,
                        12,
                        5,
                        14,
                        6,
                        16.345,
                        8,
                    ],
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1,
                        12,
                        1.5,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(0, 0%, 100%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Wind_48_20231127_1701283345.0756998',
            },
            {
                id: 'wind-ak-20231127-1701283414-3236566',
                type: 'circle',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1,
                        12,
                        1.5,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(0, 0%, 100%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        2,
                        10,
                        3.3,
                        12,
                        5,
                        14,
                        6,
                        16.345,
                        8,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Wind_AK_20231127_1701283414.3236566',
            },
            {
                id: 'wind-hi-20231127-1701283458-6949134',
                type: 'circle',
                paint: {
                    'circle-color': 'hsl(0, 0%, 100%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        2,
                        10,
                        3.3,
                        12,
                        5,
                        14,
                        6,
                        16.345,
                        8,
                    ],
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1,
                        12,
                        1.5,
                        16,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Wind_HI_20231127_1701283458.6949134',
            },
            {
                id: 'wind-pr-20231127-1701283264-3089921',
                type: 'circle',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        2,
                        10,
                        3.3,
                        12,
                        5,
                        14,
                        6,
                        16.345,
                        8,
                    ],
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1,
                        12,
                        1.5,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(0, 0%, 100%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Wind_PR_20231127_1701283264.3089921',
            },
        ],
        created: '2021-05-05T15:14:21.318Z',
        modified: '2023-11-29T18:49:22.581Z',
        id: 'ckoblpskv18fr17pcazb2izub',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    transmission_lines: {
        version: 8,
        name: 'Transmission Lines',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:groups': {
                '0af78e0b538e9af5ea82d5adf84c1673': {
                    name: '48 Group',
                    collapsed: false,
                },
                '949c4bafd1be7589b27348663ea2fce5': {
                    name: 'Hawaii Group',
                    collapsed: false,
                },
                ba8b92c743aeccb16508f791d2182e60: {
                    name: 'Alaska Group',
                    collapsed: false,
                },
            },
        },
        center: [-117.72703934693004, 35.53274007146133],
        zoom: 9.63389251363571,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.2y92630p,mapright2.43alitpy,mapright2.0ifbj2r1',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckmgrjmya105g17ozuzbq38gd/aye2hyaeodok7cecf8slq6lsl',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'translines-48-735-3',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['DC', '735 AND ABOVE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-48-735-2',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-color': 'rgb(0, 0, 0)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['DC', '735 AND ABOVE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-48-735',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'rgb(199, 1, 239)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['DC', '735 AND ABOVE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-not-available',
                type: 'line',
                paint: {
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['NOT AVAILABLE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-not-available copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-pattern': '14',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['NOT AVAILABLE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-not-available copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(36, 6%, 19%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['NOT AVAILABLE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-under-100',
                type: 'line',
                paint: {
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['UNDER 100'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-under-100 copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(39, 100%, 20%)',
                    'line-pattern': '18',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['UNDER 100'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-under-100 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(39, 100%, 20%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['UNDER 100'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-100-161',
                type: 'line',
                paint: {
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['100-161'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-100-161 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(190, 98%, 13%)',
                    'line-pattern': '15',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['100-161'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-100-161 copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(190, 98%, 13%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['100-161'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-220-287',
                type: 'line',
                paint: {
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['220-287'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-220-287 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(118, 100%, 19%)',
                    'line-pattern': '1',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                },
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['220-287'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-220-287 copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(118, 100%, 19%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-dasharray': [2, 2],
                },
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['220-287'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-345',
                type: 'line',
                paint: {
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: ['match', ['get', 'VOLT_CLASS'], ['345'], true, false],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-345 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(324, 100%, 25%)',
                    'line-pattern': '16',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: ['match', ['get', 'VOLT_CLASS'], ['345'], true, false],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-345 copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(324, 100%, 25%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: ['match', ['get', 'VOLT_CLASS'], ['345'], true, false],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-500',
                type: 'line',
                paint: {
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: ['match', ['get', 'VOLT_CLASS'], ['500'], true, false],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-500 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 99%, 18%)',
                    'line-pattern': '13',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: ['match', ['get', 'VOLT_CLASS'], ['500'], true, false],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-west-500 copy 1',
                type: 'line',
                paint: {
                    'line-color': [
                        'interpolate',
                        ['linear'],
                        ['get', 'VOLT_CLASS'],
                        0,
                        'hsl(0, 99%, 18%)',
                        1,
                        'hsl(0, 99%, 18%)',
                    ],
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_48_20231115-c247zt',
                filter: ['match', ['get', 'VOLT_CLASS'], ['500'], true, false],
                metadata: {
                    'mapbox:group': '0af78e0b538e9af5ea82d5adf84c1673',
                },
            },
            {
                id: 'translines-hawaii-not-available',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['NOT AVAILABLE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-not-available copy',
                type: 'line',
                paint: {
                    'line-pattern': '14',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['NOT AVAILABLE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-not-available copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(36, 6%, 19%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['NOT AVAILABLE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-under-100',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['UNDER 100'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-under-100 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-pattern': '18',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['UNDER 100'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-under-100 copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(39, 100%, 20%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['UNDER 100'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-100-161',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['100-161'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-100-161 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-pattern': '15',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['100-161'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-100-161 copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(190, 98%, 13%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['100-161'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-220-287',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['220-287'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-220-287 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-pattern': '1',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['220-287'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-220-287 copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(118, 100%, 19%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['220-287'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-345',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: ['match', ['get', 'VOLT_CLASS'], ['345'], true, false],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-345 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-pattern': '16',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: ['match', ['get', 'VOLT_CLASS'], ['345'], true, false],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-345 copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(324, 100%, 25%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: ['match', ['get', 'VOLT_CLASS'], ['345'], true, false],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-500',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: ['match', ['get', 'VOLT_CLASS'], ['500'], true, false],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-500 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-color': 'hsl(36, 0%, 6%)',
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                    'line-pattern': '13',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: ['match', ['get', 'VOLT_CLASS'], ['500'], true, false],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-hawaii-500 copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(0, 99%, 18%)',
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_HI_20231115-58ipff',
                filter: ['match', ['get', 'VOLT_CLASS'], ['500'], true, false],
                metadata: {
                    'mapbox:group': '949c4bafd1be7589b27348663ea2fce5',
                },
            },
            {
                id: 'translines-alaska-not-available',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['NOT AVAILABLE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-not-available copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-pattern': '14',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['NOT AVAILABLE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-not-available copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(190, 98%, 13%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['NOT AVAILABLE'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-under-100',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['UNDER 100'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-under-100-copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-pattern': '18',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['UNDER 100'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-under-100-copy copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(39, 100%, 20%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['UNDER 100'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-100-161',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['100-161'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-100-161 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-pattern': '15',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['100-161'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-100-161 copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(190, 98%, 13%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['100-161'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-220-287',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['220-287'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-220-287 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-pattern': '1',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['220-287'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-220-287 copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(118, 100%, 19%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: [
                    'match',
                    ['get', 'VOLT_CLASS'],
                    ['220-287'],
                    true,
                    false,
                ],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-345',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: ['match', ['get', 'VOLT_CLASS'], ['345'], true, false],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-345 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-pattern': '16',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: ['match', ['get', 'VOLT_CLASS'], ['345'], true, false],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-345 copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(324, 100%, 25%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: ['match', ['get', 'VOLT_CLASS'], ['345'], true, false],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-500',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        3,
                        11,
                        5,
                        14,
                        9,
                    ],
                    'line-blur': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0,
                        11,
                        1,
                        13,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: ['match', ['get', 'VOLT_CLASS'], ['500'], true, false],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-500 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        2,
                        11,
                        4,
                        16,
                        6,
                    ],
                    'line-pattern': '13',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: ['match', ['get', 'VOLT_CLASS'], ['500'], true, false],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
            {
                id: 'translines-alaska-500 copy 1',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        11,
                        2,
                        16,
                        3,
                    ],
                    'line-color': 'hsl(0, 99%, 18%)',
                    'line-dasharray': [2, 2],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Transmission_AK_20231115-8hxv3e',
                filter: ['match', ['get', 'VOLT_CLASS'], ['500'], true, false],
                metadata: {
                    'mapbox:group': 'ba8b92c743aeccb16508f791d2182e60',
                },
            },
        ],
        created: '2021-03-19T20:36:58.366Z',
        modified: '2023-12-20T18:09:22.031Z',
        id: 'ckmgrjmya105g17ozuzbq38gd',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    airports: {
        version: 8,
        name: 'Airports',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:groups': {
                '957efd301beff6b1f5e22bf2072c6934': {
                    name: 'PR Group',
                    collapsed: true,
                },
                '8efcd3e3150eaefb39396daa2acd5f41': {
                    name: 'HI Group',
                    collapsed: true,
                },
                '339e0c36101c8b3d51b11ee831495283': {
                    name: 'AK Group',
                    collapsed: true,
                },
                f07dc2fb63f50670063c077220191f63: {
                    name: 'West Group',
                    collapsed: true,
                },
                a8465a2e93e6b63e5d5f2e581a34d359: {
                    name: 'East Group',
                    collapsed: false,
                },
            },
        },
        center: [-77.36930974130878, 24.97911630440734],
        zoom: 10.46375760594037,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.0ee33528,mapright2.3289710f,mapright2.c051ed4a,mapright2.fe45f212,mapright2.4f4a29e7',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckp5otohl80yj17phs3mk2z9p/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                layout: {},
                metadata: {
                    'mapbox:group': '957efd301beff6b1f5e22bf2072c6934',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['AD'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-pr-ad copy',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(285, 95%, 53%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_pr',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '957efd301beff6b1f5e22bf2072c6934',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['HP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-pr-hp copy',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(128, 96%, 53%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_pr',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '957efd301beff6b1f5e22bf2072c6934',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['SP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-pr-sp copy',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(20, 93%, 61%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_pr',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '957efd301beff6b1f5e22bf2072c6934',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['GL'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-pr-gl copy',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(180, 89%, 50%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_pr',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '957efd301beff6b1f5e22bf2072c6934',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['UL'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-pr-ul copy',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(0, 88%, 45%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_pr',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '957efd301beff6b1f5e22bf2072c6934',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['BP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-pr-bp copy 1',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(72, 100%, 53%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_pr',
            },
            {
                minzoom: 11,
                layout: {
                    'text-field': ['to-string', ['get', 'NAME']],
                    'text-font': [
                        'Open Sans ExtraBold',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        14,
                        14,
                        18,
                    ],
                    'text-transform': 'uppercase',
                    'text-anchor': 'bottom',
                    'text-offset': [0, -0.7],
                },
                metadata: {
                    'mapbox:group': '957efd301beff6b1f5e22bf2072c6934',
                },
                type: 'symbol',
                source: 'composite',
                id: 'airports-pr-bp copy 2',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': '#000000',
                    'text-halo-width': 1.5,
                },
                'source-layer': 'airports_pr',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8efcd3e3150eaefb39396daa2acd5f41',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['AD'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-hi-ad',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(285, 95%, 53%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_hi',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8efcd3e3150eaefb39396daa2acd5f41',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['HP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-hi-hp',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(128, 96%, 53%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_hi',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8efcd3e3150eaefb39396daa2acd5f41',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['SP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-hi-sp',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(20, 93%, 61%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_hi',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8efcd3e3150eaefb39396daa2acd5f41',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['GL'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-hi-gl',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(180, 89%, 50%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_hi',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8efcd3e3150eaefb39396daa2acd5f41',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['UL'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-hi-ul',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(0, 88%, 45%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_hi',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '8efcd3e3150eaefb39396daa2acd5f41',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['BP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-hi-bp',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(72, 100%, 53%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_hi',
            },
            {
                minzoom: 11,
                layout: {
                    'text-field': ['to-string', ['get', 'NAME']],
                    'text-font': [
                        'Open Sans ExtraBold',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        14,
                        14,
                        18,
                    ],
                    'text-transform': 'uppercase',
                    'text-anchor': 'bottom',
                    'text-offset': [0, -0.7],
                },
                metadata: {
                    'mapbox:group': '8efcd3e3150eaefb39396daa2acd5f41',
                },
                type: 'symbol',
                source: 'composite',
                id: 'airports-hi-bp copy',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': '#000000',
                    'text-halo-width': 1.5,
                },
                'source-layer': 'airports_hi',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '339e0c36101c8b3d51b11ee831495283',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['AD'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-ak-ad',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(285, 95%, 53%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_ak',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '339e0c36101c8b3d51b11ee831495283',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['HP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-ak-hp',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(128, 96%, 53%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_ak',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '339e0c36101c8b3d51b11ee831495283',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['SP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-ak-sp',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(20, 93%, 61%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_ak',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '339e0c36101c8b3d51b11ee831495283',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['GL'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-ak-gl',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(180, 89%, 50%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_ak',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '339e0c36101c8b3d51b11ee831495283',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['UL'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-ak-ul',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(0, 88%, 45%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_ak',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '339e0c36101c8b3d51b11ee831495283',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['BP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-ak-bp',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(72, 100%, 53%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_ak',
            },
            {
                minzoom: 11,
                layout: {
                    'text-field': ['to-string', ['get', 'NAME']],
                    'text-font': [
                        'Open Sans ExtraBold',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        14,
                        14,
                        18,
                    ],
                    'text-transform': 'uppercase',
                    'text-anchor': 'bottom',
                    'text-offset': [0, -0.7],
                },
                metadata: {
                    'mapbox:group': '339e0c36101c8b3d51b11ee831495283',
                },
                type: 'symbol',
                source: 'composite',
                id: 'airports-ak-bp copy',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': '#000000',
                    'text-halo-width': 1.5,
                },
                'source-layer': 'airports_ak',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'f07dc2fb63f50670063c077220191f63',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['AD'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-west-ad',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(285, 95%, 53%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_continent_west',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'f07dc2fb63f50670063c077220191f63',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['HP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-west-hp',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(128, 96%, 53%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_continent_west',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'f07dc2fb63f50670063c077220191f63',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['SP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-west-sp',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(20, 93%, 61%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_continent_west',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'f07dc2fb63f50670063c077220191f63',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['GL'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-west-gl',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(180, 89%, 50%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_continent_west',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'f07dc2fb63f50670063c077220191f63',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['UL'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-west-ul',
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                    'circle-color': 'hsl(0, 88%, 45%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                },
                'source-layer': 'airports_continent_west',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'f07dc2fb63f50670063c077220191f63',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['BP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-west-bp',
                paint: {
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-color': 'hsl(72, 100%, 53%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_continent_west',
            },
            {
                minzoom: 11,
                layout: {
                    'text-field': ['to-string', ['get', 'NAME']],
                    'text-anchor': 'bottom',
                    'text-offset': [0, -0.7],
                    'text-font': [
                        'Open Sans ExtraBold',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        14,
                        14,
                        18,
                    ],
                    'text-transform': 'uppercase',
                },
                metadata: {
                    'mapbox:group': 'f07dc2fb63f50670063c077220191f63',
                },
                type: 'symbol',
                source: 'composite',
                id: 'airports-west-bp copy',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': '#000000',
                    'text-halo-width': 1.5,
                },
                'source-layer': 'airports_continent_west',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'a8465a2e93e6b63e5d5f2e581a34d359',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['AD'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-east-ad',
                paint: {
                    'circle-color': 'hsl(285, 95%, 53%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_continent_east',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'a8465a2e93e6b63e5d5f2e581a34d359',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['HP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-east-hp',
                paint: {
                    'circle-color': 'hsl(128, 96%, 53%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_continent_east',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'a8465a2e93e6b63e5d5f2e581a34d359',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['SP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-east-sp',
                paint: {
                    'circle-color': 'hsl(20, 93%, 61%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_continent_east',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'a8465a2e93e6b63e5d5f2e581a34d359',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['GL'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-east-gl',
                paint: {
                    'circle-color': 'hsl(180, 89%, 50%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_continent_east',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'a8465a2e93e6b63e5d5f2e581a34d359',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['UL'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-east-ul',
                paint: {
                    'circle-color': 'hsl(0, 88%, 45%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_continent_east',
            },
            {
                minzoom: 5,
                layout: {},
                metadata: {
                    'mapbox:group': 'a8465a2e93e6b63e5d5f2e581a34d359',
                },
                filter: ['match', ['get', 'TYPE_CODE'], ['BP'], true, false],
                type: 'circle',
                source: 'composite',
                id: 'airports-east-bp',
                paint: {
                    'circle-color': 'hsl(72, 100%, 53%)',
                    'circle-stroke-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        1.4,
                        10,
                        1.6,
                        14,
                        1.8,
                        16,
                        2,
                    ],
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5,
                        3,
                        10,
                        7,
                        12,
                        7,
                        14,
                        10,
                        16,
                        16,
                    ],
                },
                'source-layer': 'airports_continent_east',
            },
            {
                minzoom: 11,
                layout: {
                    'text-field': ['to-string', ['get', 'NAME']],
                    'text-font': [
                        'Open Sans ExtraBold',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        14,
                        14,
                        18,
                    ],
                    'text-transform': 'uppercase',
                    'text-anchor': 'bottom',
                    'text-offset': [0, -0.7],
                },
                metadata: {
                    'mapbox:group': 'a8465a2e93e6b63e5d5f2e581a34d359',
                },
                type: 'symbol',
                source: 'composite',
                id: 'airports-east-bp copy',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': '#000000',
                    'text-halo-width': 1.5,
                },
                'source-layer': 'airports_continent_east',
            },
        ],
        created: '2021-05-26T16:34:26.768Z',
        modified: '2021-06-09T13:59:47.584Z',
        id: 'ckp5otohl80yj17phs3mk2z9p',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    land_use_1: {
        version: 8,
        name: '20240325_landuse_group_1',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.9.0',
                android: '8.6.0',
                ios: '5.6.0',
            },
            'mapbox:groups': {
                ar_group: {
                    name: 'AR Group',
                    collapsed: true,
                },
                fl_group: {
                    name: 'FL Group',
                    collapsed: true,
                },
                ok_group: {
                    name: 'OK Group',
                    collapsed: true,
                },
                ks_group: {
                    name: 'KS Group',
                    collapsed: true,
                },
                ia_group: {
                    name: 'IA Group',
                    collapsed: true,
                },
                il_group: {
                    name: 'IL Group',
                    collapsed: true,
                },
                ms_group: {
                    name: 'MS Group',
                    collapsed: true,
                },
                ga_group: {
                    name: 'GA Group',
                    collapsed: true,
                },
                sc_group: {
                    name: 'SC Group',
                    collapsed: true,
                },
                nc_group: {
                    name: 'NC Group',
                    collapsed: true,
                },
                al_group: {
                    name: 'AL Group',
                    collapsed: true,
                },
                tn_group: {
                    name: 'TN Group',
                    collapsed: false,
                },
                tx_group: {
                    name: 'tx Group',
                    collapsed: true,
                },
                mo_group: {
                    name: 'MO Group',
                    collapsed: true,
                },
                la_group: {
                    name: 'LA Group',
                    collapsed: true,
                },
            },
        },
        center: [-86.83812036830447, 33.552587399694815],
        zoom: 13.771814565321742,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_polygons_al,mapright2.20240325_polygons_ar,mapright2.20240325_polygons_fl,mapright2.20240325_polygons_ga,mapright2.20240325_polygons_ia,mapright2.20240325_polygons_il,mapright2.20240325_polygons_ks,mapright2.20240325_polygons_la,mapright2.20240325_polygons_mo,mapright2.20240325_polygons_ms,mapright2.20240325_polygons_nc,mapright2.20240325_polygons_ok,mapright2.20240325_polygons_sc,mapright2.20240325_polygons_tn,mapright2.20240325_polygons_tx',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rgy303nz01nwgezp6ivy/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: '20240325_tx_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_tx_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tx_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_tx_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tx_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_tx_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tx_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_tx_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tx_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_tx_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tx_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_tx_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tx_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tx',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ar_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ar_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ar_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ar_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ar_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ar_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ar_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ar_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ar_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ar_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ar_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ar_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ar_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ar',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_fl_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_fl_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_fl_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_fl_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_fl_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_fl_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_fl_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_fl_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_fl_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_fl_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_fl_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_fl_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'fl_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_fl',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ga_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ga_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ga_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ga_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ga_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ga_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ga_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ga_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ga_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ga_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ga_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ga_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ga_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ga',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ia_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ia_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ia_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ia_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ia_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ia_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ia_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ia_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ia_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ia_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ia_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ia_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ia_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ia',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                    'line-color': 'hsl(0, 0%, 6%)',
                },
            },
            {
                id: '20240325_il_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_il_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_il_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_il_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_il_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_il_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_il_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_il_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_il_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_il_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_il_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_il_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'il_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_il',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ks_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ks_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ks_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ks_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ks_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ks_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ks_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ks_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                },
            },
            {
                id: '20240325_ks_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ks_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ks_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ks_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ks_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ks',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_la_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_la_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_la_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_la_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_la_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_la_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_la_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_la_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_la_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_la_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_la_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_la_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'la_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_la',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ms_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ms_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ms_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ms_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ms_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ms_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ms_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ms_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ms_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ms_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ms_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 6%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ms_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ms_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ms',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mo_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mo_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mo_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mo_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mo_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mo_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mo_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mo_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mo_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mo_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mo_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 6%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_mo_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mo_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mo',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                },
            },
            {
                id: '20240325_nc_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nc_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nc_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nc_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nc_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nc_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nc_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nc_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nc_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nc_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nc_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 6%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_nc_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                },
            },
            {
                id: '20240325_ok_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ok_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ok_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ok_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ok_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ok_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ok_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ok_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ok_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ok_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ok_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 6%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ok_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ok_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ok',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sc_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_sc_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sc_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_sc_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sc_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_sc_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sc_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_sc_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sc_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_sc_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sc_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_sc_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                    'line-color': 'hsl(0, 0%, 6%)',
                },
            },
            {
                id: '20240325_al_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_al_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_al_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_al_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_al_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_al_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_al_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_al_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_al_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_al_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_al_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_al_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'al_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_al',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tn_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_tn_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tn_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_tn_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tn_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_tn_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tn_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_tn_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    [
                        '70',
                        '71',
                        '72',
                        '73',
                        '74',
                        '75',
                        '76',
                        '77',
                        '78',
                        '79',
                    ],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tn_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(207, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_tn_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(219, 11%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_tn_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                paint: {
                    'fill-color': 'hsl(29, 1%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_tn_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'tn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_tn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
        ],
        created: '2024-04-03T16:53:20.907Z',
        modified: '2024-04-03T16:53:20.907Z',
        id: 'cluk1rgy303nz01nwgezp6ivy',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    land_use_2: {
        version: 8,
        name: '20240325_landuse_group_2',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.9.0',
                android: '8.6.0',
                ios: '5.6.0',
            },
            'mapbox:groups': {
                ct_group: {
                    name: 'CT Group',
                    collapsed: true,
                },
                ri_group: {
                    name: 'RI Group',
                    collapsed: true,
                },
                wi_group: {
                    name: 'WI Group',
                    collapsed: true,
                },
                va_group: {
                    name: 'VA Group',
                    collapsed: true,
                },
                ky_group: {
                    name: 'KY Group',
                    collapsed: true,
                },
                oh_group: {
                    name: 'OH Group',
                    collapsed: true,
                },
                de_group: {
                    name: 'DE Group',
                    collapsed: true,
                },
                md_group: {
                    name: 'MD Group',
                    collapsed: true,
                },
                dc_group: {
                    name: 'DC Group',
                    collapsed: true,
                },
                wv_group: {
                    name: 'WV Group',
                    collapsed: true,
                },
                ny_group: {
                    name: 'NY Group',
                    collapsed: true,
                },
                nj_group: {
                    name: 'NJ Group',
                    collapsed: true,
                },
                pa_group: {
                    name: 'PA Group',
                    collapsed: true,
                },
                mi_group: {
                    name: 'MI Group',
                    collapsed: true,
                },
                in_group: {
                    name: 'IN Group',
                    collapsed: true,
                },
            },
        },
        center: [-77.03343074744285, 38.90102372582757],
        zoom: 13.109374324942346,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_polygons_ct,mapright2.20240325_polygons_dc,mapright2.20240325_polygons_de,mapright2.20240325_polygons_in,mapright2.20240325_polygons_ky,mapright2.20240325_polygons_md,mapright2.20240325_polygons_mi,mapright2.20240325_polygons_nj,mapright2.20240325_polygons_ny,mapright2.20240325_polygons_oh,mapright2.20240325_polygons_pa,mapright2.20240325_polygons_ri,mapright2.20240325_polygons_va,mapright2.20240325_polygons_wi,mapright2.20240325_polygons_wv',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rh6502aa01qfbm5ff8gq/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: '20240325_in_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_in_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_in_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_in_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_in_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_in_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_in_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_in_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_in_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_in_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_in_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_in_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'in_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_in',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ky_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ky_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ky_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ky_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ky_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ky_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ky_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ky_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ky_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ky_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ky_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ky_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ky_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ky',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mi_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mi_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mi_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mi_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mi_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mi_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mi_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mi_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mi_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mi_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mi_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_mi_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nj_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nj_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nj_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nj_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nj_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nj_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nj_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nj_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nj_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nj_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nj_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_nj_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nj_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nj',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ny_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ny_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ny_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ny_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ny_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ny_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ny_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ny_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ny_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ny_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ny_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ny_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ny_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ny',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_oh_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_oh_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_oh_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_oh_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_oh_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_oh_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_oh_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_oh_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_oh_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_oh_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                },
            },
            {
                id: '20240325_oh_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_oh_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'oh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_oh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_pa_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_pa_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_pa_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_pa_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_pa_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_pa_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_pa_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_pa_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_pa_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_pa_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_pa_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_pa_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'pa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_pa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_va_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_va_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                    'line-color': 'hsl(65, 98%, 62%)',
                },
            },
            {
                id: '20240325_va_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_va_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_va_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_va_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_va_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_va_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_va_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_va_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_va_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_va_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'va_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_va',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wv_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wv_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wv_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wv_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wv_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wv_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wv_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wv_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wv_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wv_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wv_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_wv_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wi_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wi_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wi_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wi_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wi_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wi_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wi_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wi_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wi_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wi_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wi_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_wi_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_de_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_de_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_de_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_de_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_de_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_de_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_de_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_de_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_de_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_de_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_de_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_de_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'de_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_de',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_md_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_md_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_md_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_md_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_md_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_md_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_md_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_md_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_md_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_md_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_md_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_md_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'md_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_md',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_dc_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_dc_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_dc_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_dc_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_dc_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_dc_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_dc_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_dc_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_dc_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_dc_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_dc_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_dc_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'dc_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_dc',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ct_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ct_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ct_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ct_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ct_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ct_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ct_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ct_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ct_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ct_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ct_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ct_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ct_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ct',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ri_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ri_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ri_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ri_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ri_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ri_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ri_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ri_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ri_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ri_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ri_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ri_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ri_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ri',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
        ],
        created: '2024-04-03T16:53:21.198Z',
        modified: '2024-04-03T16:53:21.198Z',
        id: 'cluk1rh6502aa01qfbm5ff8gq',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    land_use_3: {
        version: 8,
        name: '20240325_landuse_group_3',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.9.0',
                android: '8.6.0',
                ios: '5.6.0',
            },
            'mapbox:groups': {
                mn_group: {
                    name: 'MN Group',
                    collapsed: true,
                },
                nv_group: {
                    name: 'NV Group',
                    collapsed: true,
                },
                nd_group: {
                    name: 'ND Group',
                    collapsed: true,
                },
                sd_group: {
                    name: 'SD Group',
                    collapsed: true,
                },
                nm_group: {
                    name: 'NM Group',
                    collapsed: true,
                },
                ca_group: {
                    name: 'CA Group',
                    collapsed: true,
                },
                az_group: {
                    name: 'AZ Group',
                    collapsed: true,
                },
                mt_group: {
                    name: 'MT Group',
                    collapsed: true,
                },
                or_group: {
                    name: 'OR Group',
                    collapsed: true,
                },
                ut_group: {
                    name: 'UT Group',
                    collapsed: true,
                },
                ne_group: {
                    name: 'NE Group',
                    collapsed: true,
                },
                co_group: {
                    name: 'CO Group',
                    collapsed: true,
                },
                wa_group: {
                    name: 'WA Group',
                    collapsed: true,
                },
                wy_group: {
                    name: 'WY Group',
                    collapsed: false,
                },
                id_group: {
                    name: 'ID Group',
                    collapsed: true,
                },
            },
        },
        center: [-118.41172906661303, 34.039595019093724],
        zoom: 13.03823199813272,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_polygons_az,mapright2.20240325_polygons_ca,mapright2.20240325_polygons_co,mapright2.20240325_polygons_id,mapright2.20240325_polygons_mt,mapright2.20240325_polygons_mn,mapright2.20240325_polygons_nd,mapright2.20240325_polygons_ne,mapright2.20240325_polygons_nm,mapright2.20240325_polygons_nv,mapright2.20240325_polygons_or,mapright2.20240325_polygons_sd,mapright2.20240325_polygons_ut,mapright2.20240325_polygons_wa,mapright2.20240325_polygons_wy',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rhfk03o001nwat7b7mdw/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: '20240325_az_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_az_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                    'line-color': 'hsl(65, 98%, 62%)',
                },
            },
            {
                id: '20240325_az_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_az_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_az_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_az_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_az_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_az_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_az_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'rgb(154, 167, 177)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_az_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_az_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_az_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'az_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_az',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ca_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ca_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                    'line-color': 'hsl(65, 98%, 62%)',
                },
            },
            {
                id: '20240325_ca_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ca_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ca_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ca_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ca_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ca_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ca_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ca_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ca_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ca_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ca_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ca',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_co_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_co_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_co_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_co_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_co_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_co_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_co_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_co_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_co_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_co_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_co_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_co_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'co_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_co',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_id_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_id_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                    'line-color': 'hsl(65, 98%, 62%)',
                },
            },
            {
                id: '20240325_id_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_id_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_id_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_id_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_id_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_id_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_id_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_id_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_id_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_id_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'id_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_id',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mt_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mt_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mt_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mt_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mt_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mt_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mt_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mt_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mt_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mt_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mt_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_mt_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mn_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mn_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mn_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mn_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mn_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mn_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mn_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mn_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mn_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_mn_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_mn_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_mn_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'mn_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_mn',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ne_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ne_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ne_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ne_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ne_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ne_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ne_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ne_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ne_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ne_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ne_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ne_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ne_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ne',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nv_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nv_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nv_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nv_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nv_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nv_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nv_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nv_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nv_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nv_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nv_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_nv_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nv_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nv',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                    'line-color': 'hsl(0, 0%, 3%)',
                },
            },
            {
                id: '20240325_nm_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nm_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nm_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nm_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nm_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nm_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nm_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nm_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nm_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nm_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nm_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_nm_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nm_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nm',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nd_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nd_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nd_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nd_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nd_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nd_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nd_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nd_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nd_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nd_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nd_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_nd_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_or_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_or_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_or_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_or_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_or_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_or_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_or_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_or_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_or_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_or_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_or_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_or_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'or_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_or',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sd_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_sd_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sd_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_sd_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sd_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_sd_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sd_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_sd_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sd_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_sd_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_sd_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_sd_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'sd_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_sd',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ut_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ut_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ut_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ut_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ut_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ut_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ut_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ut_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ut_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ut_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ut_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ut_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ut_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ut',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wa_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wa_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wa_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wa_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wa_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wa_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wa_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wa_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wa_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wa_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wa_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_wa_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wa_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wa',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wy_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wy_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wy_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wy_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wy_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wy_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wy_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wy_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wy_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_wy_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_wy_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_wy_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'wy_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_wy',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 3%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
        ],
        created: '2024-04-03T16:53:21.536Z',
        modified: '2024-04-03T16:53:21.536Z',
        id: 'cluk1rhfk03o001nwat7b7mdw',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    wind_farms: {
        version: 8,
        name: 'WindFarms',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.0.0',
            },
            'mapbox:groups': {},
        },
        center: [-99.72807272147516, 33.90965886647017],
        zoom: 11.924876826382603,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.e646ec2a,mapright2.a14af037,mapright2.c81a3aff,mapright2.e9af6044',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckobjlsp616e517pc2qc47ybd/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'windfarms-continental-050521',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 72%, 25%)',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.9,
                        12,
                        0.5,
                        14.128,
                        0.4,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'WindFarms_Continental_050521',
            },
            {
                id: 'windfarms-continental-050521 copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 94%, 84%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        12,
                        2,
                        14,
                        2.5,
                        16.253,
                        3,
                    ],
                },
                source: 'composite',
                'source-layer': 'WindFarms_Continental_050521',
            },
            {
                id: 'windfarms-alaska-050521',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 72%, 25%)',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.9,
                        12,
                        0.5,
                        14.128,
                        0.4,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'WindFarms_Alaska_050521',
            },
            {
                id: 'windfarms-alaska-050521 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 94%, 84%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        12,
                        2,
                        14,
                        2.5,
                        16.253,
                        3,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'WindFarms_Alaska_050521',
            },
            {
                id: 'windfarms-hawaii-050521',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 72%, 25%)',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.9,
                        12,
                        0.5,
                        14.128,
                        0.4,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'WindFarms_Hawaii_050521',
            },
            {
                id: 'windfarms-hawaii-050521 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 94%, 84%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        12,
                        2,
                        14,
                        2.5,
                        16.253,
                        3,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'WindFarms_Hawaii_050521',
            },
            {
                id: 'windfarms-puertorico-050521',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 72%, 25%)',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.9,
                        12,
                        0.5,
                        14.128,
                        0.4,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'WindFarms_PuertoRico_050521',
            },
            {
                id: 'windfarms-puertorico-050521 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(0, 94%, 84%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        12,
                        2,
                        14,
                        2.5,
                        16.253,
                        3,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'WindFarms_PuertoRico_050521',
            },
        ],
        created: '2021-05-05T14:15:15.613Z',
        modified: '2021-05-20T16:30:58.105Z',
        id: 'ckobjlsp616e517pc2qc47ybd',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    bexar_grid: {
        version: 8,
        name: 'DopVip Grids',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '9.3.0',
                ios: '5.10.0',
                js: '2.3.0',
            },
            'mapbox:groups': {},
        },
        center: [-100.56285237298688, 31.42093539853397],
        zoom: 10.599780030480769,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.db98940a,mapright2.b6b323e0',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckrb25eph04qw17o1mopjt1rv/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'bexar-grid-projected-072021',
                type: 'line',
                paint: {
                    'line-color': 'hsl(180, 93%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1.5,
                        12,
                        2,
                        15,
                        3,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Bexar_Grid_projected_072021',
            },
            {
                id: 'bexar-grid-projected-072021 copy',
                type: 'symbol',
                layout: {
                    'text-field': ['to-string', ['get', 'Label']],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        15,
                        12,
                        26,
                        14.386,
                        34,
                    ],
                    'text-font': ['Ubuntu Bold', 'Arial Unicode MS Regular'],
                    'symbol-avoid-edges': true,
                    'text-padding': 5,
                },
                source: 'composite',
                'source-layer': 'Bexar_Grid_projected_072021',
                paint: {
                    'text-halo-color': 'hsl(187, 93%, 50%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1,
                        12,
                        1.7,
                        14.327,
                        2,
                    ],
                },
            },
            {
                id: '5-square-full-tom-green',
                type: 'line',
                paint: {
                    'line-color': 'hsl(180, 93%, 50%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1.5,
                        12,
                        2,
                        15,
                        3,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': '5_Square_Full_Tom_Green',
            },
            {
                id: '5-square-full-tom-green copy',
                type: 'symbol',
                paint: {
                    'text-halo-color': 'hsl(187, 93%, 50%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1,
                        12,
                        1.7,
                        14.327,
                        2,
                    ],
                },
                layout: {
                    'text-font': ['Ubuntu Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        15,
                        12,
                        26,
                        14.386,
                        34,
                    ],
                    'text-padding': 5,
                    'text-field': ['get', 'Id'],
                },
                source: 'composite',
                'source-layer': '5_Square_Full_Tom_Green',
            },
        ],
        created: '2021-07-19T20:05:44.520Z',
        modified: '2021-08-25T13:50:27.269Z',
        id: 'ckrb25eph04qw17o1mopjt1rv',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    ccns: {
        version: 8,
        name: 'CCN TX',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.0.0',
                ios: '10.0.0',
                js: '2.3.0',
            },
            'mapbox:groups': {},
        },
        center: [-97.51737722989043, 30.779277241991196],
        zoom: 9.493140336612996,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.3c7c4b84,mapright2.c4bcc3e0',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cktufxluf0rvb17p82xhkp6cc/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'ccn-water-texas2021',
                type: 'fill',
                paint: {
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        0.5,
                        12,
                        0.35,
                    ],
                    'fill-color': 'hsl(288, 93%, 44%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CCN_Water_Texas2021',
            },
            {
                id: 'ccn-water-texas2021 copy',
                type: 'line',
                paint: {
                    'line-color': '#2c0f2f',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1,
                        12,
                        1.7,
                        15.461,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CCN_Water_Texas2021',
            },
            {
                id: 'ccnfac-water-texas2021',
                type: 'line',
                paint: {
                    'line-color': '#2c0f2f',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1,
                        12,
                        1.7,
                        15.461,
                        2,
                    ],
                },
                source: 'composite',
                'source-layer': 'CCNFAC_Water_Texas2021',
            },
            {
                id: 'ccnfac-water-texas2021 copy',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(295, 66%, 43%)',
                    'text-halo-color': 'hsl(295, 48%, 96%)',
                    'text-halo-width': 1.5,
                },
                source: 'composite',
                'source-layer': 'CCNFAC_Water_Texas2021',
                layout: {
                    'text-field': ['to-string', ['get', 'CCN_NO']],
                    'text-font': ['Oswald Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        15,
                        12,
                        17,
                        15.094,
                        19,
                    ],
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        30,
                        12,
                        15,
                    ],
                },
            },
            {
                id: 'ccn-water-texas2021 copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(295, 66%, 43%)',
                    'text-halo-color': 'hsl(295, 48%, 96%)',
                    'text-halo-width': 1.5,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'CCN_NO']],
                    'text-font': ['Oswald Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        15,
                        12,
                        17,
                        15.094,
                        19,
                    ],
                    'symbol-avoid-edges': true,
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        30,
                        12,
                        15,
                    ],
                },
                source: 'composite',
                'source-layer': 'CCN_Water_Texas2021',
            },
        ],
        created: '2021-09-21T18:58:37.173Z',
        modified: '2021-09-21T19:45:45.379Z',
        id: 'cktufxluf0rvb17p82xhkp6cc',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    water_wells_1: {
        version: 8,
        name: 'Water Wells Texas 092221-copy',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.0.0',
                ios: '10.0.0',
                js: '2.3.0',
            },
            'mapbox:groups': {},
        },
        center: [-99.01715796346619, 29.75510888549006],
        zoom: 12.629701997378225,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.e6019fe2',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/ckv6s2ikk7koa15qf0mkyyiok/5sbzxxk9gt5nemvpylr0tvpqd',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'tx-ww-final2021',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4back',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [5, 5],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                },
                source: 'composite',
                'source-layer': 'TX_WW_Final2021',
            },
            {
                id: 'tx-ww-final2021 copy',
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'well4',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        11,
                        0.4,
                        12,
                        0.55,
                        13,
                        0.65,
                        16,
                        0.85,
                        18,
                        0.9,
                    ],
                    'icon-offset': [2, 2],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                },
                source: 'composite',
                'source-layer': 'TX_WW_Final2021',
            },
        ],
        created: '2021-10-25T14:51:18.040Z',
        modified: '2021-10-25T14:51:18.040Z',
        id: 'ckv6s2ikk7koa15qf0mkyyiok',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    water_districts_1: {
        version: 8,
        name: 'Water Districts TX',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.0.0',
                ios: '10.0.0',
                js: '2.3.0',
            },
            'mapbox:groups': {},
        },
        center: [-97.5655911535488, 32.48292083515355],
        zoom: 9.075002728848528,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.df81ca4e',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cktuhpuq512j919s6p5j1vnt0/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'tx-wd2021',
                type: 'fill',
                paint: {
                    'fill-color': '#20459d',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.69,
                        10,
                        0.5,
                        12,
                        0.44,
                        14,
                        0.4,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'TX_WD2021',
            },
            {
                id: 'tx-wd2021 copy',
                type: 'line',
                paint: {
                    'line-color': '#120156',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.5,
                        10,
                        0.8,
                        12,
                        1,
                        14,
                        1.4,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'TX_WD2021',
            },
            {
                id: 'tx-wd2021 copy 1',
                type: 'symbol',
                paint: {
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                    'text-color': '#96e7fd',
                    'text-halo-color': '#001f85',
                },
                layout: {
                    'text-field': ['to-string', ['get', 'NAME']],
                    'text-font': ['Asap Bold', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        12,
                        12.5,
                        14,
                        13.5,
                    ],
                    'symbol-avoid-edges': true,
                },
                source: 'composite',
                'source-layer': 'TX_WD2021',
            },
        ],
        created: '2021-09-21T19:48:34.643Z',
        modified: '2021-09-22T13:48:21.890Z',
        id: 'cktuhpuq512j919s6p5j1vnt0',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    dallas_co_zoning: {
        version: 8,
        name: 'Dallas City Zoning',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.0.0',
                ios: '10.0.0',
                js: '2.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-96.8057969468033, 32.85186007446232],
        zoom: 11.90207263947012,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.78f99840',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl02p2is7001t15nkgjvdsvq6/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'dallas-zoning2022-final',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(281, 98%, 61%)',
                    'fill-antialias': false,
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.44,
                        14,
                        0.29,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Dallas_Zoning2022_Final',
                filter: [
                    'match',
                    ['get', 'LONG_ZONE_'],
                    [
                        'MU-3(SAH)',
                        'MU-3',
                        'MU-2(SAH)',
                        'MU-2',
                        'MU-1(SAH)',
                        'MU-1',
                        'MF-4(A)',
                        'MF-3(A)',
                        'MF-2(A)(SAH)',
                        'MF-2(A)',
                        'MF-2 Chap 51',
                        'MF-1(A)(SAH)',
                        'MF-1(A)',
                    ],
                    false,
                    true,
                ],
            },
            {
                id: 'dallas-zoning2022-final copy 3',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(56, 98%, 55%)',
                    'fill-antialias': false,
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.44,
                        14,
                        0.29,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Dallas_Zoning2022_Final',
                filter: [
                    'match',
                    ['get', 'LONG_ZONE_'],
                    [
                        'MU-1',
                        'MU-1(SAH)',
                        'MU-2',
                        'MU-2(SAH)',
                        'MU-3',
                        'MU-3(SAH)',
                    ],
                    true,
                    false,
                ],
            },
            {
                id: 'dallas-zoning2022-final copy 4',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(115, 98%, 61%)',
                    'fill-antialias': false,
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.44,
                        14,
                        0.29,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Dallas_Zoning2022_Final',
                filter: [
                    'match',
                    ['get', 'LONG_ZONE_'],
                    [
                        'MF-1(A)',
                        'MF-1(A)(SAH)',
                        'MF-2 Chap 51',
                        'MF-2(A)',
                        'MF-2(A)(SAH)',
                        'MF-3(A)',
                        'MF-4(A)',
                    ],
                    true,
                    false,
                ],
            },
            {
                id: 'dallas-zoning2022-final copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(298, 4%, 100%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.7,
                        12,
                        1,
                        14,
                        1.8,
                        16,
                        3,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Dallas_Zoning2022_Final',
            },
            {
                id: 'dallas-zoning2022-final copy 1',
                minzoom: 13,
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 1%, 100%)',
                    'text-halo-color': 'hsl(0, 53%, 6%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        1,
                        16,
                        1.5,
                    ],
                },
                layout: {
                    'text-field': ['get', 'LONG_ZONE_'],
                    'text-transform': 'uppercase',
                    'text-font': [
                        'Open Sans ExtraBold',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        12,
                        14,
                        13,
                        16,
                        14,
                        18,
                        18,
                    ],
                    'text-max-width': 6,
                },
                source: 'composite',
                'source-layer': 'Dallas_Zoning2022_Final',
            },
            {
                id: 'dallas-zoning2022-final copy 2',
                minzoom: 16,
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 1%, 100%)',
                    'text-halo-color': 'hsl(0, 53%, 6%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        1,
                        16,
                        1.5,
                    ],
                },
                layout: {
                    'text-transform': 'uppercase',
                    'text-font': [
                        'Open Sans ExtraBold',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        12,
                        14,
                        13,
                        16,
                        14,
                        18,
                        18,
                    ],
                    'text-max-width': 6,
                    'text-field': ['to-string', ['get', 'COMMON_NAM']],
                },
                source: 'composite',
                'source-layer': 'Dallas_Zoning2022_Final',
            },
        ],
        created: '2022-02-25T17:34:46.427Z',
        modified: '2022-04-14T15:12:55.125Z',
        id: 'cl02p2is7001t15nkgjvdsvq6',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    '2022_drone_nc': {
        sources: {
            '2022_drone_nc': {
                tileSize: 256,
                scheme: 'tms',
                type: 'raster',
                tiles: [
                    'https://mapright-orthos.s3.amazonaws.com/user/71104/1/{z}/{x}/{y}.png',
                ],
            },
        },
        layers: [
            {
                type: 'raster',
                id: '2022_drone_nc',
                source: '2022_drone_nc',
            },
        ],
    },
    principal_aquifers: {
        version: 8,
        name: 'Principal Aquifers',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.0.0',
                ios: '10.0.0',
                js: '2.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-111.6498715711007, 42.56087765174968],
        zoom: 7.51897580988846,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.dc5a59f3',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl3ai9kgz001a15nx7ta7tkdg/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'us-aquifers-editsmlm',
                type: 'fill',
                paint: {
                    'fill-opacity': 0.3,
                    'fill-color': 'hsl(243, 90%, 49%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'us_aquifers_editsmlm',
            },
            {
                id: 'us-aquifers-editsmlm copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(231, 98%, 62%)',
                    'line-width': 5,
                    'line-blur': 5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'us_aquifers_editsmlm',
            },
            {
                id: 'us-aquifers-editsmlm copy 2',
                type: 'line',
                paint: {
                    'line-color': 'hsl(231, 100%, 77%)',
                    'line-blur': 3,
                    'line-width': 3,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'us_aquifers_editsmlm',
            },
            {
                id: 'us-aquifers-editsmlm copy 3',
                type: 'line',
                paint: {
                    'line-color': 'hsl(231, 99%, 94%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'us_aquifers_editsmlm',
            },
            {
                id: 'us-aquifers-editsmlm copy 1',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(243, 97%, 100%)',
                    'text-halo-color': 'hsl(243, 98%, 15%)',
                    'text-halo-width': 2,
                    'text-halo-blur': 2,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'AQ_NAME']],
                    'text-transform': 'uppercase',
                    'symbol-avoid-edges': true,
                    'text-font': [
                        'Rubik Black Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        22,
                        16,
                    ],
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        10,
                        22,
                        10,
                    ],
                    'text-padding': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        20,
                        12,
                        200,
                    ],
                },
                source: 'composite',
                'source-layer': 'us_aquifers_editsmlm',
                minzoom: 9,
            },
        ],
        created: '2022-05-17T18:45:34.326Z',
        modified: '2022-05-17T20:58:15.254Z',
        id: 'cl3ai9kgz001a15nx7ta7tkdg',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    townships: {
        version: 8,
        name: 'Townships',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.0.0',
                ios: '10.0.0',
                js: '2.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-77.02785946568608, 40.0758042925207],
        zoom: 15.381623310532815,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.13b8cd58,mapright2.c3d4c3e9',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl2qhoo8c000j14p76ua8y2in/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'Glow 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(278, 100%, 45%)',
                    'line-blur': 5,
                    'line-opacity': 0.4,
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        8,
                        16,
                        12,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'All_Townships_Proj',
            },
            {
                id: 'Glow 3',
                type: 'line',
                paint: {
                    'line-color': 'hsl(275, 98%, 78%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        5,
                        16,
                        8,
                    ],
                    'line-blur': 3,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'All_Townships_Proj',
            },
            {
                id: 'Glow 2',
                type: 'line',
                paint: {
                    'line-color': 'hsl(285, 98%, 88%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        14,
                        1,
                        16,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'All_Townships_Proj',
            },
            {
                id: 'townships-labels',
                minzoom: 10,
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(304, 100%, 100%)',
                    'text-halo-color': 'hsl(288, 95%, 6%)',
                    'text-halo-width': 2,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'NAME']],
                    'text-font': [
                        'Open Sans ExtraBold Italic',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        12.7,
                        13,
                        16,
                        15.906,
                        20,
                    ],
                },
                source: 'composite',
                'source-layer': 'Townships_Labels',
            },
        ],
        created: '2022-05-03T18:33:55.912Z',
        modified: '2022-05-23T20:58:19.625Z',
        id: 'cl2qhoo8c000j14p76ua8y2in',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    comparable_sales: {
        version: 8,
        name: 'Republic Comps',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.0.0',
                ios: '10.0.0',
                js: '2.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-98.95001479333338, 28.850807952761798],
        zoom: 12.264114434994395,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.37d440c1,mapright2.89b53aee',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl3j96xdy001p15l73nc40zzp/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'b5158a70-b346-4b82-922a-c25385dcb2b0-polygon',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(6, 38%, 45%)',
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        1,
                        10,
                        0.78,
                        12,
                        0.47,
                        13,
                        0.22,
                        15,
                        0,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'b5158a70-b346-4b82-922a-c25385dcb2b0_POLYGON',
            },
            {
                id: 'b5158a70-b346-4b82-922a-c25385dcb2b0-polygon copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(215, 61%, 26%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        6,
                        10,
                        8,
                        12.503,
                        10,
                        14.116,
                        12,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'b5158a70-b346-4b82-922a-c25385dcb2b0_POLYGON',
            },
            {
                id: 'b5158a70-b346-4b82-922a-c25385dcb2b0-polygon copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(215, 68%, 48%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        4,
                        10,
                        5.5,
                        12.503,
                        7,
                        14.116,
                        9,
                    ],
                    'line-blur': 3,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'b5158a70-b346-4b82-922a-c25385dcb2b0_POLYGON',
            },
            {
                id: 'b5158a70-b346-4b82-922a-c25385dcb2b0-polygon copy 2',
                type: 'line',
                paint: {
                    'line-color': 'hsl(215, 63%, 79%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1,
                        12.5,
                        1.5,
                        14.116,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'b5158a70-b346-4b82-922a-c25385dcb2b0_POLYGON',
            },
            {
                id: 'centerpoints',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 1%, 100%)',
                    'text-halo-color': '#000000',
                    'text-halo-width': 1.5,
                },
                layout: {
                    'text-field': [
                        'concat',
                        'County: ',
                        ['get', 'County'],
                        ' \nAC: ',
                        ['get', 'Acreage'],
                        '\nSales Price: ',
                        ['get', 'Sales_Pric'],
                        '\nPrice Per AC: ',
                        ['get', 'Price_per'],
                        '\nYear Sold: ',
                        ['get', 'Year_Sold'],
                        '\nMonth Sold: ',
                        ['get', 'Month_Sold'],
                        '\nBrokerage: ',
                        ['get', 'Brokerage'],
                        '\nHouse: ',
                        ['get', 'House'],
                        '\nProperty ID: ',
                        ['get', 'Property_I'],
                    ],
                    'text-transform': 'uppercase',
                    'text-max-width': 15,
                    'text-line-height': 1.8,
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        14,
                        14,
                        16,
                    ],
                    'text-font': ['Overpass Black', 'Arial Unicode MS Regular'],
                },
                source: 'composite',
                'source-layer': 'Centerpoints',
            },
        ],
        created: '2022-05-23T21:41:30.134Z',
        modified: '2022-07-26T18:37:45.893Z',
        id: 'cl3j96xdy001p15l73nc40zzp',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    summits_gaps: {
        version: 8,
        name: 'Summits and Gaps',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.0.0',
                ios: '10.0.0',
                js: '2.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-109.4971866395507, 38.5489862104896],
        zoom: 9.27140029016316,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.dc0f0fcc',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl48r55d3004715mps539s3ur/7b9ab4l5gnzqbxi791g3lwrka',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                minzoom: 9,
                layout: {
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        9.5,
                        11,
                        12,
                        14,
                        14,
                    ],
                    'icon-image': 'gap7',
                    'text-transform': 'uppercase',
                    'text-font': ['Orbitron Black', 'Arial Unicode MS Regular'],
                    'text-offset': [0, -0.4],
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1,
                        12,
                        1.2,
                        14,
                        1.4,
                    ],
                    'text-anchor': 'bottom',
                    'text-field': [
                        'concat',
                        ['to-string', ['get', 'FEATURE_NA']],
                        '\n',
                        ['get', 'FEATURE_CL'],
                    ],
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        5,
                        11,
                        10,
                    ],
                },
                filter: ['match', ['get', 'FEATURE_CL'], ['Gap'], true, false],
                type: 'symbol',
                source: 'composite',
                id: 'us-summits-gaps-20220602',
                paint: {
                    'text-color': 'hsl(337, 0%, 100%)',
                    'text-halo-color': 'hsl(334, 94%, 48%)',
                    'text-halo-width': 1,
                },
                'source-layer': 'US_Summits_Gaps_20220602',
            },
            {
                minzoom: 9,
                layout: {
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        9.5,
                        11,
                        12,
                        14,
                        14,
                    ],
                    'icon-image': 'mountain4',
                    'text-transform': 'uppercase',
                    'text-font': ['Orbitron Black', 'Arial Unicode MS Regular'],
                    'text-offset': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        ['literal', [0, -0.5]],
                        10,
                        ['literal', [0, -0.7]],
                        11,
                        ['literal', [0, -1]],
                    ],
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        0.6,
                        10,
                        0.7,
                        12,
                        1,
                    ],
                    'text-anchor': 'bottom',
                    'text-field': [
                        'step',
                        ['zoom'],
                        ['to-string', ['get', 'FEATURE_NA']],
                        11,
                        [
                            'concat',
                            ['to-string', ['get', 'FEATURE_NA']],
                            '\n',
                            ['get', 'ELEV_IN_FT'],
                            ' FT',
                        ],
                    ],
                    'text-letter-spacing': 0.1,
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        5,
                        11,
                        10,
                    ],
                },
                filter: [
                    'match',
                    ['get', 'FEATURE_CL'],
                    ['Summit'],
                    true,
                    false,
                ],
                type: 'symbol',
                source: 'composite',
                id: 'us-summits-gaps-20220602 copy 1',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(46, 73%, 8%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        1.6,
                        11,
                        2,
                    ],
                    'text-halo-blur': 1,
                },
                'source-layer': 'US_Summits_Gaps_20220602',
            },
        ],
        created: '2022-06-10T17:58:14.648Z',
        modified: '2022-06-13T21:32:58.260Z',
        id: 'cl48r55d3004715mps539s3ur',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    wtp_comps: {
        version: 8,
        name: 'WTP Comps',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.6.0',
                ios: '10.6.0',
                js: '2.9.0',
            },
            'mapbox:groups': {},
        },
        center: [-93.55140085250679, 40.550030711380145],
        zoom: 13.218955136589846,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.93096952,mapright2.1f3d7fc5',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl4q0cmux000014une4cshahm/3tle7uyyafoikozd9rkh4p1c5',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'whitetailpoly',
                type: 'fill',
                paint: {
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.86,
                        14,
                        0.44,
                        15,
                        0.14,
                        16,
                        0,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'whitetailpoly',
                minzoom: 12,
            },
            {
                id: 'whitetailpoly copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(52, 100%, 44%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        5,
                        15,
                        8,
                    ],
                    'line-blur': 4,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'whitetailpoly',
                minzoom: 12,
            },
            {
                id: 'whitetailpoly copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(52, 100%, 60%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        3,
                        15,
                        5,
                    ],
                    'line-blur': 2,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'whitetailpoly',
                minzoom: 12,
            },
            {
                id: 'whitetailpoly copy 2',
                type: 'line',
                paint: {
                    'line-color': 'hsl(56, 100%, 68%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1,
                        15,
                        2,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'whitetailpoly',
                minzoom: 12,
            },
            {
                id: 'whitetails-centerpoints',
                minzoom: 6,
                maxzoom: 12,
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-image': 'whitetail8',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        0.6,
                        10,
                        1,
                        12,
                        1.2,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                source: 'composite',
                'source-layer': 'whitetails_centerpoints',
            },
            {
                id: 'whitetails-centerpoints copy',
                minzoom: 12,
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(49, 100%, 59%)',
                    'text-halo-color': 'hsl(0, 3%, 4%)',
                    'text-halo-width': 3,
                    'text-halo-blur': 0.5,
                },
                layout: {
                    'text-font': [
                        'Knewave Regular',
                        'Arial Unicode MS Regular',
                    ],
                    'text-transform': 'uppercase',
                    'text-field': [
                        'concat',
                        'Sales Date: ',
                        ['get', 'sale_date'],
                        '\nSales Price: $',
                        ['get', 'sale_price'],
                        '\nSold Price: $',
                        ['get', 'sold_price'],
                        '\nAcres: ',
                        ['get', 'acres'],
                    ],
                    'text-max-width': 11,
                    'text-anchor': 'bottom',
                    'text-offset': [0, -2],
                    'text-letter-spacing': 0.1,
                    'text-line-height': 1.5,
                },
                source: 'composite',
                'source-layer': 'whitetails_centerpoints',
            },
        ],
        created: '2022-06-22T19:48:05.466Z',
        modified: '2022-06-27T16:47:03.885Z',
        id: 'cl4q0cmux000014une4cshahm',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    selected_properties: {
        version: 8,
        name: 'Liberation Ranches Parcels',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.6.0',
                ios: '10.6.0',
                js: '2.9.0',
            },
            'mapbox:groups': {},
        },
        center: [-97.59413822146075, 30.964583738759586],
        zoom: 13.181099296450968,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.6d8a926f,mapright2.deb7c016',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl769y43c000816k7gm88ncnf/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'fowler-parcels-webaux-20220817',
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(0, 96%, 61%)',
                    'fill-antialias': false,
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1,
                        13,
                        0.64,
                        14.306,
                        0.39,
                        16.694,
                        0.22,
                    ],
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Fowler_Parcels_WebAux_20220817',
            },
            {
                id: 'fowler-parcels-webaux-20220817 copy',
                type: 'line',
                paint: {
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        1,
                        14,
                        2,
                        16.276,
                        3,
                    ],
                    'line-color': 'hsl(0, 78%, 11%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Fowler_Parcels_WebAux_20220817',
            },
            {
                id: 'fowler-labels-webaux-20220817',
                type: 'symbol',
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-halo-color': 'hsl(0, 83%, 7%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        1,
                        14,
                        1.7,
                        16,
                        2,
                    ],
                },
                source: 'composite',
                'source-layer': 'Fowler_Labels_WebAux_20220817',
                layout: {
                    'text-field': ['concat', ['get', 'LAND_ACRES'], ' AC'],
                    'text-font': [
                        'Open Sans ExtraBold',
                        'Arial Unicode MS Regular',
                    ],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        11,
                        14,
                        15,
                    ],
                    'text-max-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        4,
                        14,
                        5,
                    ],
                },
                minzoom: 12,
            },
        ],
        created: '2022-08-23T14:20:27.595Z',
        modified: '2022-08-23T14:32:45.310Z',
        id: 'cl769y43c000816k7gm88ncnf',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    plss_subdivision: {
        version: 8,
        name: 'Utah_Quarters-Lots 20221129',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.6.0',
                ios: '10.6.0',
                js: '2.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-111.83229645266105, 38.21921879554034],
        zoom: 11.51909034052237,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.6sodecn0,mapright2.1fuen7bn,mapright2.935ljni9',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/clb2p5jhc000615s4jvh6w8yb/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'divs2-al1dzm',
                minzoom: 9,
                type: 'line',
                paint: {
                    'line-color': 'hsl(108, 87%, 52%)',
                    'line-width': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Divs2-al1dzm',
            },
            {
                id: 'divs1-cqrar6',
                type: 'line',
                paint: {
                    'line-color': 'hsl(108, 87%, 52%)',
                    'line-width': 1.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Divs1-cqrar6',
            },
            {
                id: 'ut-plss-2nd-div-labels-9kgz70',
                minzoom: 13.3,
                type: 'symbol',
                paint: {
                    'text-halo-color': 'hsl(0, 2%, 100%)',
                    'text-halo-width': 1.5,
                },
                layout: {
                    'text-field': ['to-string', ['get', 'Name']],
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'text-size': 12,
                },
                source: 'composite',
                'source-layer': 'UT_PLSS_2nd_Div_Labels-9kgz70',
            },
        ],
        created: '2022-11-29T20:53:53.009Z',
        modified: '2022-12-05T20:29:57.415Z',
        id: 'clb2p5jhc000615s4jvh6w8yb',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    new_cumberland_aerial: {
        sources: {
            new_cumberland_aerial: {
                tileSize: 256,
                scheme: 'xyz',
                type: 'raster',
                tiles: [
                    'https://a.tiles.mapbox.com/v4/mapright2.bbauer_ortho_12132022/{z}/{x}/{y}.jpg?access_token=pk.eyJ1IjoibWFwcmlnaHQyIiwiYSI6IkpMTE8tT0EifQ.BVYqz1Tp-th28TXPgg3rrQ',
                ],
            },
        },
        layers: [
            {
                type: 'raster',
                id: 'new_cumberland_aerial',
                source: 'new_cumberland_aerial',
            },
        ],
    },
    dallas_zoning: {
        version: 8,
        name: 'Dallas Zoning',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.9.0',
                ios: '10.9.0',
                js: '2.11.1',
            },
            'mapbox:groups': {},
        },
        center: [-96.90996617690409, 32.89118192545895],
        zoom: 15.000753240035605,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.45bmm0hz',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/clbxwxj7m000415n2tih45bi4/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'base-zoning2-0fs157',
                type: 'line',
                paint: {
                    'line-color': 'hsl(62, 95%, 67%)',
                    'line-width': 3,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Base_Zoning2-0fs157',
            },
            {
                id: 'base-zoning2-0fs157 copy',
                minzoom: 13,
                maxzoom: 16,
                type: 'symbol',
                paint: {
                    'text-halo-width': 1.5,
                    'text-halo-color': 'hsl(0, 96%, 97%)',
                },
                layout: {
                    'text-field': ['to-string', ['get', 'ZONE_DIST']],
                },
                source: 'composite',
                'source-layer': 'Base_Zoning2-0fs157',
            },
        ],
        created: '2022-12-21T17:12:27.800Z',
        modified: '2022-12-26T20:42:16.618Z',
        id: 'clbxwxj7m000415n2tih45bi4',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    wilderness_areas: {
        version: 8,
        name: 'WildernessAreas',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.6.0',
                ios: '10.6.0',
                js: '2.9.0',
            },
            'mapbox:groups': {},
        },
        center: [-102.6563, 42.4883],
        zoom: 9,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.b36c0129',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl8vt6upw000414lnt3jigybd/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 's-usa-wilderness-proj',
                type: 'fill',
                layout: {},
                source: 'composite',
                'source-layer': 'S_USA_Wilderness_Proj',
                minzoom: 9,
                paint: {
                    'fill-opacity': 0.55,
                    'fill-color': 'hsl(62, 95%, 85%)',
                },
            },
            {
                id: 's-usa-wilderness-proj copy',
                type: 'line',
                layout: {},
                source: 'composite',
                'source-layer': 'S_USA_Wilderness_Proj',
                minzoom: 9,
                paint: {
                    'line-width': 3,
                    'line-color': 'hsl(59, 98%, 67%)',
                },
            },
        ],
        created: '2022-10-05T15:53:04.792Z',
        modified: '2022-10-05T16:43:12.168Z',
        id: 'cl8vt6upw000414lnt3jigybd',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    points_of_interest: {
        version: 8,
        name: 'Points of Interest',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.6.0',
                ios: '10.6.0',
                js: '2.9.0',
            },
            'mapbox:groups': {
                '4fe41ba28e3b3864f337b29343be5c4b': {
                    name: 'Points of Interest',
                    collapsed: false,
                },
            },
        },
        center: [-81.67336390134437, 32.230502170294585],
        zoom: 12.447363933872582,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.f54981e2,mapright2.2ccc7fbc,mapright2.f0a746ad,mapright2.bcbf4878,mapright2.f3478478,mapright2.5477ff1c,mapright2.b0b5043e',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl891lyt1001j15qp772tequy/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4fe41ba28e3b3864f337b29343be5c4b',
                },
                type: 'circle',
                source: 'composite',
                id: 'poi-uscont-1',
                paint: {
                    'circle-color': 'hsl(124, 98%, 71%)',
                    'circle-stroke-width': 1.5,
                    'circle-stroke-color': 'hsl(0, 0%, 100%)',
                },
                'source-layer': 'POI_USCont_1',
                filter: ['has', 'FEATURE_NA'],
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4fe41ba28e3b3864f337b29343be5c4b',
                },
                type: 'circle',
                source: 'composite',
                id: 'poi-uscont-2',
                paint: {
                    'circle-color': 'hsl(124, 98%, 71%)',
                    'circle-stroke-width': 1.5,
                    'circle-stroke-color': 'hsl(0, 0%, 100%)',
                },
                'source-layer': 'POI_USCont_2',
                filter: ['has', 'FEATURE_NA'],
            },
            {
                minzoom: 9,
                layout: {},
                metadata: {
                    'mapbox:group': '4fe41ba28e3b3864f337b29343be5c4b',
                },
                filter: ['has', 'FEATURE_NA'],
                type: 'circle',
                source: 'composite',
                id: 'poi-uscont-3',
                paint: {
                    'circle-color': 'hsl(124, 98%, 71%)',
                    'circle-stroke-width': 1.5,
                    'circle-stroke-color': 'hsl(0, 0%, 100%)',
                },
                'source-layer': 'POI_USCont_3',
            },
            {
                minzoom: 9,
                layout: {},
                metadata: {
                    'mapbox:group': '4fe41ba28e3b3864f337b29343be5c4b',
                },
                filter: ['has', 'FEATURE_NA'],
                type: 'circle',
                source: 'composite',
                id: 'poi-uscont-4',
                paint: {
                    'circle-color': 'hsl(124, 98%, 71%)',
                    'circle-stroke-width': 1.5,
                    'circle-stroke-color': 'hsl(0, 0%, 100%)',
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        5,
                        22,
                        5,
                    ],
                },
                'source-layer': 'POI_USCont_4',
            },
            {
                layout: {},
                metadata: {
                    'mapbox:group': '4fe41ba28e3b3864f337b29343be5c4b',
                },
                type: 'circle',
                source: 'composite',
                id: 'points-of-interest-ak1',
                paint: {
                    'circle-color': 'hsl(124, 98%, 71%)',
                    'circle-stroke-width': 1.5,
                    'circle-stroke-color': 'hsl(0, 0%, 100%)',
                },
                'source-layer': 'Points_of_Interest_AK1',
                filter: ['has', 'FEATURE_NA'],
            },
            {
                metadata: {
                    'mapbox:group': '4fe41ba28e3b3864f337b29343be5c4b',
                },
                type: 'circle',
                source: 'composite',
                id: 'points-of-interest-ak2',
                paint: {
                    'circle-color': 'hsl(124, 98%, 71%)',
                    'circle-stroke-width': 1.5,
                    'circle-stroke-color': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        'hsl(0, 0%, 100%)',
                        22,
                        'hsl(0, 0%, 100%)',
                    ],
                },
                'source-layer': 'Points_of_Interest_AK2',
                filter: ['has', 'FEATURE_NA'],
            },
            {
                minzoom: 9,
                layout: {},
                metadata: {
                    'mapbox:group': '4fe41ba28e3b3864f337b29343be5c4b',
                },
                filter: ['has', 'FEATURE_NA'],
                type: 'circle',
                source: 'composite',
                id: 'points-of-interest-hawaii',
                paint: {
                    'circle-color': 'hsl(124, 98%, 71%)',
                    'circle-stroke-width': 1.5,
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['get', 'FEATURE_CL'],
                        0,
                        ['case', ['has', 'FEATURE_CL'], 5, 5],
                        1,
                        ['case', ['has', 'FEATURE_CL'], 5, 5],
                    ],
                    'circle-stroke-color': 'hsl(0, 3%, 97%)',
                },
                'source-layer': 'Points_of_Interest_Hawaii',
            },
        ],
        created: '2022-09-19T17:30:04.812Z',
        modified: '2022-10-05T19:48:02.917Z',
        id: 'cl891lyt1001j15qp772tequy',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    power_plants: {
        version: 8,
        name: 'Power Plants',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.6.0',
                ios: '10.6.0',
                js: '2.9.0',
            },
            'mapbox:groups': {},
        },
        center: [-151.0420131117117, 61.169123144668816],
        zoom: 11.293153106429282,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.9sfwvy8b,mapright2.4r6tpipc,mapright2.4459zs6u',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl9eeqaaj000r14qrerepwdip/9auokvbdh0mz67k5ay5175l26',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                minzoom: 8,
                layout: {
                    'icon-size': 0.12,
                    'icon-image': 'Angular_lightningbolt3',
                },
                maxzoom: 17,
                type: 'symbol',
                source: 'composite',
                id: 'power-plants-HI',
                paint: {},
                slot: '',
                'source-layer': 'Power_Plants_HI_20231122-5teg3a',
            },
            {
                minzoom: 10,
                layout: {
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'text-size': 12,
                    'icon-size': 0.12,
                    'icon-image': ['to-string', ['get', 'NAME']],
                    'text-field': [
                        'concat',
                        ['get', 'NAME'],
                        ' ',
                        ['get', 'OPER_CAP'],
                        ' MW',
                    ],
                    'text-offset': [7, 0],
                    'text-justify': 'left',
                },
                maxzoom: 17,
                type: 'symbol',
                source: 'composite',
                id: 'power-plants-Label-HI',
                paint: {
                    'text-halo-color': 'hsl(0, 0%, 100%)',
                    'text-halo-width': 1,
                },
                slot: '',
                'source-layer': 'Power_Plants_HI_20231122-5teg3a',
            },
            {
                id: 'power-plants-Label-AK',
                minzoom: 10,
                maxzoom: 17,
                type: 'symbol',
                paint: {
                    'text-halo-color': 'hsl(0, 0%, 100%)',
                    'text-halo-width': 1,
                },
                layout: {
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'text-size': 12,
                    'icon-size': 0.12,
                    'icon-image': ['to-string', ['get', 'NAME']],
                    'text-field': [
                        'concat',
                        ['get', 'NAME'],
                        ' ',
                        ['get', 'OPER_CAP'],
                        ' MW',
                    ],
                    'text-offset': [7, 0],
                    'text-justify': 'left',
                },
                source: 'composite',
                'source-layer': 'Power_Plants_AK_20231122-bpd3gr',
            },
            {
                id: 'power-plants-AK',
                minzoom: 8,
                maxzoom: 17,
                type: 'symbol',
                paint: {},
                layout: {
                    'icon-size': 0.12,
                    'icon-image': 'Angular_lightningbolt3',
                },
                source: 'composite',
                'source-layer': 'Power_Plants_AK_20231122-bpd3gr',
            },
            {
                minzoom: 8,
                layout: {
                    'icon-size': 0.12,
                    'icon-image': 'Angular_lightningbolt3',
                },
                maxzoom: 17,
                type: 'symbol',
                source: 'composite',
                id: 'power-plants-48',
                paint: {},
                slot: '',
                'source-layer': 'Power_Plants_48_20231122-0tbx6w',
            },
            {
                minzoom: 10,
                layout: {
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'text-size': 12,
                    'icon-size': 0.12,
                    'icon-image': ['to-string', ['get', 'NAME']],
                    'text-field': [
                        'concat',
                        ['get', 'NAME'],
                        ' ',
                        ['get', 'OPER_CAP'],
                        ' MW',
                    ],
                    'text-offset': [7, 0],
                    'text-justify': 'left',
                },
                maxzoom: 17,
                type: 'symbol',
                source: 'composite',
                id: 'power-plants-Label-48',
                paint: {
                    'text-halo-color': 'hsl(0, 0%, 100%)',
                    'text-halo-width': 1,
                },
                slot: '',
                'source-layer': 'Power_Plants_48_20231122-0tbx6w',
            },
        ],
        created: '2022-10-18T16:15:54.550Z',
        modified: '2023-11-22T18:56:48.384Z',
        id: 'cl9eeqaaj000r14qrerepwdip',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    solar_farms: {
        version: 8,
        name: 'Solar Plants',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.6.0',
                ios: '10.6.0',
                js: '2.9.0',
            },
            'mapbox:groups': {},
        },
        center: [-94.77627812381488, 33.11371907546133],
        zoom: 5.229193798995448,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.9x78gckq,mapright2.aq11gyar',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl8wmebo8000a14tcg7k6redj/a9o5ooybz9ux01cc9vxtja0ne',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                minzoom: 5,
                layout: {
                    'icon-image': 'sun5black',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        0.08,
                        11,
                        0.13,
                        13.419,
                        0.16,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-offset': [20, 20],
                },
                type: 'symbol',
                source: 'composite',
                id: 'solar-proj-HI-back',
                paint: {
                    'icon-opacity': 0.81,
                },
                slot: '',
                'source-layer': 'Solar_Proj_HI_20231212-5f36eo',
            },
            {
                minzoom: 5,
                layout: {
                    'icon-image': 'sun5',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        0.08,
                        11,
                        0.13,
                        13.419,
                        0.16,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                type: 'symbol',
                source: 'composite',
                id: 'solar-proj-HI',
                paint: {},
                slot: '',
                'source-layer': 'Solar_Proj_HI_20231212-5f36eo',
            },
            {
                minzoom: 5,
                layout: {
                    'icon-image': 'sun5black',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        0.08,
                        11,
                        0.13,
                        13.419,
                        0.16,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'icon-offset': [20, 20],
                },
                type: 'symbol',
                source: 'composite',
                id: 'solar-proj-48-back',
                paint: {
                    'icon-opacity': 0.81,
                },
                slot: '',
                'source-layer': 'Solar_Proj_48_20231212-0iux6m',
            },
            {
                minzoom: 5,
                layout: {
                    'icon-image': 'sun5',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        9,
                        0.08,
                        11,
                        0.13,
                        13.419,
                        0.16,
                    ],
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                },
                type: 'symbol',
                source: 'composite',
                id: 'solar-proj-48',
                paint: {},
                slot: '',
                'source-layer': 'Solar_Proj_48_20231212-0iux6m',
            },
        ],
        created: '2022-10-06T05:30:42.222Z',
        modified: '2023-12-19T18:17:25.339Z',
        id: 'cl8wmebo8000a14tcg7k6redj',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    bozeman_short_term_rentals: {
        version: 8,
        name: 'Bozeman Short Term Rentals',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.9.0',
                ios: '10.9.0',
                js: '2.11.1',
            },
            'mapbox:groups': {},
        },
        center: [-111.04795732437151, 45.68545081691323],
        zoom: 12.038208059665486,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.dgct0shq',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/clexdabpn003k01p1k02h89iu/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'rental-zones-20230224-bravza',
                minzoom: 12,
                maxzoom: 18,
                type: 'fill',
                paint: {
                    'fill-color': [
                        'match',
                        ['get', 'Rental'],
                        ['NOT ALLOWED'],
                        'hsl(0, 98%, 53%)',
                        ['Principal Use, Type 1 Only'],
                        'hsl(35, 94%, 52%)',
                        ['Principal Use, Type 1 or Type 2'],
                        'hsl(62, 95%, 64%)',
                        ['Principal Use, All Types'],
                        'hsl(93, 97%, 43%)',
                        '#000000',
                    ],
                    'fill-opacity': 0.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Rental_Zones_20230224-bravza',
            },
        ],
        created: '2023-03-06T22:01:39.279Z',
        modified: '2023-03-06T23:22:42.121Z',
        id: 'clexdabpn003k01p1k02h89iu',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    ' bozeman_historic_districts': {
        version: 8,
        name: 'Bozeman Historic Districts',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.9.0',
                ios: '10.9.0',
                js: '2.11.1',
            },
            'mapbox:groups': {},
        },
        center: [-111.03780494874252, 45.67854508677405],
        zoom: 14.144954699169043,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.dos5rxi0',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/clexctml1002601suf3k31d5h/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'bozeman-historic-districts-20-25djhd',
                minzoom: 13,
                maxzoom: 19,
                type: 'fill',
                layout: {},
                source: 'composite',
                'source-layer': 'Bozeman_Historic_Districts_20-25djhd',
                paint: {
                    'fill-color': [
                        'match',
                        ['get', 'Hist_Dist'],
                        ['Story Mill'],
                        'hsl(0, 83%, 72%)',
                        ['South Tracy Avenue'],
                        'hsl(67, 97%, 68%)',
                        ['Main Street'],
                        'hsl(291, 88%, 67%)',
                        ['Bozeman Brewery'],
                        'hsl(260, 94%, 47%)',
                        ['MSU'],
                        'hsl(178, 57%, 52%)',
                        ['North Tracy Avenue'],
                        'hsl(159, 95%, 30%)',
                        ['Cooper Park'],
                        'hsl(114, 91%, 58%)',
                        ['Lindley Place'],
                        'hsl(189, 93%, 39%)',
                        ['Bon Ton'],
                        'hsl(33, 95%, 43%)',
                        ['South Tracy/ South Black'],
                        'hsl(55, 98%, 42%)',
                        'hsl(0, 0%, 0%)',
                    ],
                    'fill-opacity': 0.46,
                },
            },
            {
                id: 'bozeman-historic-districts-20-25djhd copy',
                minzoom: 13,
                maxzoom: 19,
                type: 'symbol',
                layout: {
                    'text-field': ['to-string', ['get', 'Hist_Dist']],
                },
                source: 'composite',
                'source-layer': 'Bozeman_Historic_Districts_20-25djhd',
                paint: {
                    'text-halo-color': 'hsl(0, 0%, 100%)',
                    'text-halo-width': 1.5,
                },
            },
        ],
        created: '2023-03-06T21:48:40.243Z',
        modified: '2023-03-06T22:01:30.870Z',
        id: 'clexctml1002601suf3k31d5h',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    '8_digit_hucs': {
        version: 8,
        name: '8-Digit HUCs',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.6.0',
                ios: '10.6.0',
                js: '2.9.0',
            },
            'mapbox:groups': {},
        },
        center: [-109.85082430623359, 46.07534354257652],
        zoom: 8.806260691302265,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.ccbcd6b5,mapright2.f2443320,mapright2.c839103d,mapright2.0f23babd',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cl9egtxzj000915t819u03wep/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'countus-huc-1 copy 1',
                type: 'line',
                paint: {
                    'line-width': 5,
                    'line-color': 'hsl(239, 37%, 95%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CountUS_HUC_1',
                minzoom: 10,
            },
            {
                id: 'countus-huc-1 copy',
                type: 'line',
                paint: {
                    'line-width': 3,
                    'line-color': 'hsl(239, 96%, 61%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CountUS_HUC_1',
                minzoom: 10,
            },
            {
                id: 'countus-huc-2 copy',
                type: 'line',
                paint: {
                    'line-width': 5,
                    'line-color': 'hsl(239, 37%, 95%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CountUS_HUC_2',
                minzoom: 10,
            },
            {
                id: 'countus-huc-2 copy 1',
                type: 'line',
                paint: {
                    'line-width': 3,
                    'line-color': 'hsl(239, 96%, 61%)',
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CountUS_HUC_2',
                minzoom: 10,
            },
            {
                id: 'countus-huc-3 copy',
                type: 'line',
                paint: {
                    'line-color': 'hsl(239, 37%, 95%)',
                    'line-width': 5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CountUS_HUC_3',
                minzoom: 10,
            },
            {
                id: 'countus-huc-3 copy 1',
                type: 'line',
                paint: {
                    'line-color': 'hsl(239, 96%, 61%)',
                    'line-width': 3,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CountUS_HUC_3',
                minzoom: 10,
            },
            {
                id: 'countus-huc-4 copy',
                minzoom: 10,
                type: 'line',
                paint: {
                    'line-color': 'hsl(239, 37%, 95%)',
                    'line-width': 5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CountUS_HUC_4',
            },
            {
                id: 'countus-huc-4',
                minzoom: 10,
                type: 'line',
                paint: {
                    'line-color': 'hsl(239, 96%, 61%)',
                    'line-width': 3,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'CountUS_HUC_4',
            },
            {
                id: 'countus-huc-3',
                type: 'symbol',
                paint: {
                    'text-halo-color': 'hsl(226, 98%, 81%)',
                    'text-halo-width': 1.5,
                },
                layout: {
                    'text-field': [
                        'concat',
                        ['get', 'huc8'],
                        '\n',
                        ['get', 'name'],
                    ],
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                },
                source: 'composite',
                'source-layer': 'CountUS_HUC_3',
            },
            {
                id: 'countus-huc-4 copy 1',
                minzoom: 10,
                type: 'symbol',
                layout: {
                    'text-field': [
                        'concat',
                        ['get', 'huc8'],
                        '\n',
                        ['get', 'name'],
                    ],
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                },
                source: 'composite',
                'source-layer': 'CountUS_HUC_4',
                paint: {
                    'text-halo-color': 'hsl(226, 98%, 81%)',
                    'text-halo-width': 1.5,
                },
            },
            {
                id: 'countus-huc-2',
                type: 'symbol',
                paint: {
                    'text-halo-color': 'hsl(226, 98%, 81%)',
                    'text-halo-width': 1.5,
                },
                layout: {
                    'text-field': [
                        'concat',
                        ['get', 'huc8'],
                        '\n',
                        ['get', 'name'],
                    ],
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                },
                source: 'composite',
                'source-layer': 'CountUS_HUC_2',
            },
            {
                id: 'countus-huc-1',
                type: 'symbol',
                paint: {
                    'text-halo-color': 'hsl(226, 98%, 81%)',
                    'text-halo-width': 1.5,
                },
                layout: {
                    'text-field': [
                        'concat',
                        ['get', 'huc8'],
                        '\n',
                        ['get', 'name'],
                    ],
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
                    'symbol-avoid-edges': true,
                },
                source: 'composite',
                'source-layer': 'CountUS_HUC_1',
            },
        ],
        created: '2022-10-18T17:14:44.457Z',
        modified: '2023-03-15T18:27:55.746Z',
        id: 'cl9egtxzj000915t819u03wep',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    housing_developments: {
        version: 8,
        name: 'Builder Sites',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.9.0',
                ios: '10.9.0',
                js: '2.11.1',
            },
            'mapbox:groups': {},
        },
        center: [-157.96147282704564, 21.371334524797135],
        zoom: 9.870925395509532,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.dptmsinh,mapright2.9jzrazz3,mapright2.8t9963nx',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cld9gjo8w000b01ln9klx52r6/e23txy2p83tm1kw11jwjfuxme',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'housing-dev-HI',
                type: 'symbol',
                paint: {},
                source: 'composite',
                'source-layer': 'Housing_Dev_HI_20231027-dj2srx',
                layout: {
                    'icon-size': 0.5,
                    'icon-image':
                        'smashicons_property_development_build-house-icon-4-yellow',
                },
                minzoom: 9.3,
                maxzoom: 16.1,
            },
            {
                id: 'housing-dev-AK',
                type: 'symbol',
                paint: {},
                source: 'composite',
                'source-layer': 'Housing_Dev_AK_20231027-3lj0y5',
                layout: {
                    'icon-size': 0.5,
                    'icon-image':
                        'smashicons_property_development_build-house-icon-4-yellow',
                },
                minzoom: 9.3,
                maxzoom: 16.1,
            },
            {
                id: 'housing-dev-48',
                type: 'symbol',
                paint: {},
                source: 'composite',
                'source-layer': 'Builder_Sites_48_20230123-70kzx4',
                layout: {
                    'icon-size': 0.5,
                    'icon-image':
                        'smashicons_property_development_build-house-icon-4-yellow',
                },
                minzoom: 9.3,
                maxzoom: 16.1,
            },
        ],
        created: '2023-01-23T23:46:43.737Z',
        modified: '2023-10-31T18:28:04.916Z',
        id: 'cld9gjo8w000b01ln9klx52r6',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    independent_system_operators: {
        version: 8,
        name: 'Independent System Operators',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.9.0',
                ios: '10.9.0',
                js: '2.11.1',
            },
            'mapbox:groups': {},
        },
        center: [-101.54331675670713, 43.98697850339366],
        zoom: 6.02476892478212,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.274vy8kg',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cle4skrjw000w01pebumgjekv/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'independent-system-operators-d832i7',
                minzoom: 6,
                maxzoom: 16,
                type: 'fill',
                layout: {},
                source: 'composite',
                'source-layer': 'Independent_System_Operators_-d832i7',
                paint: {
                    'fill-color': [
                        'match',
                        ['get', 'NAME'],
                        ['SOUTHWEST POWER POOL/ERCOT'],
                        'hsl(331, 78%, 54%)',
                        ['CAISO'],
                        'hsl(242, 79%, 66%)',
                        ['ISO NEW ENGLAND'],
                        'hsl(62, 93%, 48%)',
                        ['MIDCONTINENT ISO'],
                        'hsl(144, 88%, 55%)',
                        ['PJM'],
                        'hsl(0, 93%, 50%)',
                        ['NEW YORK ISO'],
                        'hsl(36, 83%, 50%)',
                        ['MIDCONTINENT ISO/PJM'],
                        'hsl(291, 83%, 51%)',
                        ['ISO NEW ENGLAND/NEW YORK ISO'],
                        'hsl(180, 83%, 56%)',
                        ['NEW YORK ISO/PJM'],
                        'hsl(327, 44%, 83%)',
                        ['MIDCONTINENT ISO/SOUTHWEST POWER POOL'],
                        'hsl(239, 42%, 79%)',
                        ['SOUTHWEST POWER POOL/ERCOT/MIDCONTINENT ISO'],
                        'hsl(29, 93%, 81%)',
                        ['SOUTHWEST POWER POOL'],
                        'hsl(226, 37%, 46%)',
                        ['ERCOT'],
                        'hsl(0, 54%, 52%)',
                        '#000000',
                    ],
                    'fill-opacity': 0.58,
                },
            },
        ],
        created: '2023-02-14T22:04:21.521Z',
        modified: '2023-02-14T23:28:00.365Z',
        id: 'cle4skrjw000w01pebumgjekv',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    oil_wells: {
        version: 8,
        name: 'Oil and Gas Wells',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.9.0',
                ios: '10.9.0',
                js: '2.11.1',
            },
            'mapbox:groups': {},
        },
        center: [-150.8630525234928, 60.72656108149738],
        zoom: 13.515136176489792,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.OG_Wells_AK_20231018_1697745878,mapright2.OG_Wells_48_20231018_1697747727',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cleorfnh3000301qrc8x1q10w/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'og-wells-ak',
                minzoom: 13,
                maxzoom: 18,
                type: 'circle',
                paint: {
                    'circle-stroke-color': 'hsl(0, 0%, 100%)',
                    'circle-stroke-width': 1,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'OG_Wells_AK_20231018_1697745875.4803193',
            },
            {
                id: 'oil-wells-48',
                minzoom: 13,
                maxzoom: 18,
                type: 'circle',
                paint: {
                    'circle-stroke-color': 'hsl(0, 0%, 100%)',
                    'circle-stroke-width': 1,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'OG_Wells_48_20231018_1697747587.2614675',
            },
        ],
        created: '2023-02-28T21:27:46.854Z',
        modified: '2023-10-19T20:42:15.648Z',
        id: 'cleorfnh3000301qrc8x1q10w',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    parcels_4: {
        version: 8,
        name: '20240325_corelogic_parcel_boundaries_group_4',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.9.0',
                android: '8.6.0',
                ios: '5.6.0',
            },
            'mapbox:groups': {},
        },
        center: [-73.10911, 44.436096],
        zoom: 13.17,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_polygons_ak,mapright2.20240325_polygons_hi,mapright2.20240325_polygons_ma,mapright2.20240325_polygons_me,mapright2.20240325_polygons_nh,mapright2.20240325_polygons_vt',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rgn3002u01qo69qbensn/35suyk9pqrdqn9t9t1o08r6b5',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: '20240325_polygons_ak',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_hi',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_ma',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_me',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_nh',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
            {
                id: '20240325_polygons_vt',
                type: 'line',
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                layout: {},
                paint: {
                    'line-color': '#f4a734',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.7,
                        14,
                        1,
                        16,
                        1.2,
                    ],
                },
            },
        ],
        created: '2024-04-03T16:53:20.511Z',
        modified: '2024-04-03T16:53:20.511Z',
        id: 'cluk1rgn3002u01qo69qbensn',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    parcels_labels_4: {
        version: 8,
        name: '20240325_corelogic_parcel_labels_group_4',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.6.0',
                android: '8.3.0',
                ios: '5.3.0',
            },
            'mapbox:groups': {},
        },
        center: [-73.10911, 44.436096],
        zoom: 13.96,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_points_ak,mapright2.20240325_points_hi,mapright2.20240325_points_ma,mapright2.20240325_points_me,mapright2.20240325_points_nh,mapright2.20240325_points_vt',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rfxy02a901qfe49b2qyi/35suyk9pqrdqn9t9t1o08r6b5',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: '20240325_points_ak',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ak',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_hi',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_hi',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: 'n20240325_points_ma',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ma',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_me',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_me',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_nh',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_nh',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_vt',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_vt',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
        ],
        created: '2024-04-03T16:53:19.606Z',
        modified: '2024-04-03T16:53:19.606Z',
        id: 'cluk1rfxy02a901qfe49b2qyi',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    proposed_tehuacana_reservoir: {
        version: 8,
        name: 'Tehuacana_Res_20230405',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '10.9.0',
                ios: '10.9.0',
                js: '2.11.1',
            },
            'mapbox:groups': {},
        },
        center: [-96.14583976116128, 31.88640633862518],
        zoom: 10.020257710121307,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.7s7014z2',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/clg45on22000n01nsragg92zn/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'proposed-tehuacana-reservoir-4t7cjp',
                minzoom: 10,
                maxzoom: 19,
                type: 'fill',
                paint: {
                    'fill-color': 'hsl(200, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
                layout: {},
                source: 'composite',
                'source-layer': 'Proposed_Tehuacana_Reservoir-4t7cjp',
            },
        ],
        created: '2023-04-05T20:42:55.819Z',
        modified: '2023-04-05T20:47:18.352Z',
        id: 'clg45on22000n01nsragg92zn',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    coal_closure_energy_communities: {
        version: 8,
        name: 'Coal Closures',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                android: '11.0.0',
                ios: '11.0.0',
                js: '3.0.0',
            },
            'mapbox:groups': {},
        },
        center: [-80.7666396399271, 38.95939899432844],
        zoom: 8.532279230904605,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.CoalClosures20230621',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/clgvcacwh002s01r8a65lcrur/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        projection: {
            name: 'globe',
        },
        layers: [
            {
                id: 'output',
                minzoom: 8,
                maxzoom: 15,
                type: 'fill',
                layout: {},
                source: 'composite',
                'source-layer': 'output',
                paint: {
                    'fill-color': [
                        'case',
                        [
                            'match',
                            ['get', 'Symbol'],
                            [
                                '',
                                'Census tract directly adjoining a census tract with a coal closure',
                            ],
                            true,
                            false,
                        ],
                        'hsl(151, 93%, 50%)',
                        [
                            'match',
                            ['get', 'Symbol'],
                            ['', 'Census tract with a coal closure'],
                            true,
                            false,
                        ],
                        'hsl(62, 93%, 66%)',
                        '#000000',
                    ],
                    'fill-opacity': 0.5,
                },
            },
        ],
        created: '2023-04-24T21:17:33.522Z',
        modified: '2023-06-27T16:04:54.189Z',
        id: 'clgvcacwh002s01r8a65lcrur',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    ' ilesboro_road': {
        sources: {
            ' ilesboro_road': {
                tileSize: 256,
                scheme: 'xyz',
                type: 'raster',
                tiles: [
                    'https://a.tiles.mapbox.com/v4/mapright2.bbauer_ilesboro_road_2/{z}/{x}/{y}.jpg?access_token=pk.eyJ1IjoibWFwcmlnaHQyIiwiYSI6IkpMTE8tT0EifQ.BVYqz1Tp-th28TXPgg3rrQ',
                ],
            },
        },
        layers: [
            {
                type: 'raster',
                id: ' ilesboro_road',
                source: ' ilesboro_road',
            },
        ],
    },
    parcels_labels_3: {
        version: 8,
        name: '20240325_corelogic_parcel_labels_group_3',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.6.0',
                android: '8.3.0',
                ios: '5.3.0',
            },
            'mapbox:groups': {},
        },
        center: [-111.94581, 40.7551],
        zoom: 13.96,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_points_az,mapright2.20240325_points_ca,mapright2.20240325_points_co,mapright2.20240325_points_id,mapright2.20240325_points_mt,mapright2.20240325_points_mn,mapright2.20240325_points_nd,mapright2.20240325_points_ne,mapright2.20240325_points_nm,mapright2.20240325_points_nv,mapright2.20240325_points_or,mapright2.20240325_points_sd,mapright2.20240325_points_ut,mapright2.20240325_points_wa,mapright2.20240325_points_wy',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rfpb03ny01nw9oqt1nid/35suyk9pqrdqn9t9t1o08r6b5',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: '20240325_points_az',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_az',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ca',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ca',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_co',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_co',
                layout: {
                    'text-padding': 1,
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_id',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_id',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_mt',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_mt',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_mn',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_mn',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_nd',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_nd',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ne',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ne',
                layout: {
                    'text-padding': 1,
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_nm',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_nm',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: 'n20240325_points_nv',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_nv',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_or',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_or',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_sd',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_sd',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ut',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ut',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_wa',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_wa',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_wy',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_wy',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
        ],
        created: '2024-04-03T16:53:19.295Z',
        modified: '2024-04-03T16:53:19.295Z',
        id: 'cluk1rfpb03ny01nw9oqt1nid',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    parcels_labels_2: {
        version: 8,
        name: '20240325_corelogic_parcel_labels_group_2',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.6.0',
                android: '8.3.0',
                ios: '5.3.0',
            },
            'mapbox:groups': {},
        },
        center: [-75.20363, 39.952847],
        zoom: 13.96,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_points_ct,mapright2.20240325_points_dc,mapright2.20240325_points_de,mapright2.20240325_points_in,mapright2.20240325_points_ky,mapright2.20240325_points_md,mapright2.20240325_points_mi,mapright2.20240325_points_nj,mapright2.20240325_points_ny,mapright2.20240325_points_oh,mapright2.20240325_points_pa,mapright2.20240325_points_ri,mapright2.20240325_points_va,mapright2.20240325_points_wi,mapright2.20240325_points_wv',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rfir03nx01nw4pjxhhr5/35suyk9pqrdqn9t9t1o08r6b5',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: '20240325_points_ct',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ct',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_dc',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_dc',
                layout: {
                    'text-padding': 1,
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_de',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_de',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_in',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_in',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ky',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ky',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_md',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_md',
                layout: {
                    'text-padding': 1,
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_mi',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_mi',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_nj',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_nj',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ny',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ny',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_oh',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_oh',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_pa',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_pa',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ri',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ri',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_va',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_va',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_wi',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_wi',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_wv',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_wv',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
        ],
        created: '2024-04-03T16:53:19.059Z',
        modified: '2024-04-03T16:53:19.059Z',
        id: 'cluk1rfir03nx01nw4pjxhhr5',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    parcels_labels_1: {
        version: 8,
        name: '20240325_corelogic_parcel_labels_group_1',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.6.0',
                android: '8.3.0',
                ios: '5.3.0',
            },
            'mapbox:groups': {},
        },
        center: [-84.388214, 33.75888],
        zoom: 13.96,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_points_al,mapright2.20240325_points_ar,mapright2.20240325_points_fl,mapright2.20240325_points_ga,mapright2.20240325_points_ia,mapright2.20240325_points_il,mapright2.20240325_points_ks,mapright2.20240325_points_la,mapright2.20240325_points_mo,mapright2.20240325_points_ms,mapright2.20240325_points_nc,mapright2.20240325_points_ok,mapright2.20240325_points_sc,mapright2.20240325_points_tn,mapright2.20240325_points_tx',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rf9w03nw01nwedb3didn/35suyk9pqrdqn9t9t1o08r6b5',
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [
            {
                id: 'background',
                type: 'background',
                paint: {
                    'background-color': 'rgba(0,0,0,0)',
                },
            },
            {
                id: '20240325_points_al',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_al',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ar',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ar',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_fl',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_fl',
                layout: {
                    'text-padding': 1,
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ga',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ga',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ia',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ia',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_il',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_il',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ks',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ks',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_la',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_la',
                layout: {
                    'text-padding': 1,
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_mo',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_mo',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: 'n20240325_points_ms',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ms',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_nc',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_nc',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_ok',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_ok',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_sc',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_sc',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_tn',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_tn',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
            {
                id: '20240325_points_tx',
                type: 'symbol',
                source: 'composite',
                'source-layer': '20240325_points_tx',
                layout: {
                    'text-field': ['to-string', ['get', 'OWNER_PARSED']],
                    'text-font': ['Raleway Black', 'Arial Unicode MS Regular'],
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        8.5,
                        14,
                        9.5,
                        16,
                        10.5,
                    ],
                    'text-max-width': 4,
                    'text-transform': 'uppercase',
                    'text-padding': 1,
                },
                paint: {
                    'text-color': 'hsl(44, 100%, 56%)',
                    'text-halo-color': 'hsl(42, 96%, 10%)',
                    'text-halo-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        2,
                        16,
                        2.5,
                    ],
                    'text-halo-blur': 1,
                },
            },
        ],
        created: '2024-04-03T16:53:18.740Z',
        modified: '2024-04-03T16:53:18.740Z',
        id: 'cluk1rf9w03nw01nwedb3didn',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    wolfe_aerial: {
        sources: {
            wolfe_aerial: {
                tileSize: 256,
                scheme: 'xyz',
                type: 'raster',
                tiles: [
                    'https://a.tiles.mapbox.com/v4/mapright2.bbauer_wolfe_run_rd/{z}/{x}/{y}.jpg?access_token=pk.eyJ1IjoibWFwcmlnaHQyIiwiYSI6IkpMTE8tT0EifQ.BVYqz1Tp-th28TXPgg3rrQ',
                ],
            },
        },
        layers: [
            {
                type: 'raster',
                id: 'wolfe_aerial',
                source: 'wolfe_aerial',
            },
        ],
    },
    custom_aerial: {
        sources: {
            custom_aerial: {
                tileSize: 256,
                scheme: 'xyz',
                type: 'raster',
                tiles: [
                    'https://a.tiles.mapbox.com/v4/mapright2.jmittelstaedt_eagle_ranch/{z}/{x}/{y}.jpg?access_token=pk.eyJ1IjoibWFwcmlnaHQyIiwiYSI6IkpMTE8tT0EifQ.BVYqz1Tp-th28TXPgg3rrQ',
                ],
            },
        },
        layers: [
            {
                type: 'raster',
                id: 'custom_aerial',
                source: 'custom_aerial',
            },
        ],
    },
    land_use_4: {
        version: 8,
        name: '20240325_landuse_group_4',
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'template',
            'mapbox:sdk-support': {
                js: '1.9.0',
                android: '8.6.0',
                ios: '5.6.0',
            },
            'mapbox:groups': {
                ma_group: {
                    name: 'MA Group',
                    collapsed: true,
                },
                vt_group: {
                    name: 'VT Group',
                    collapsed: true,
                },
                nh_group: {
                    name: 'NH Group',
                    collapsed: true,
                },
                me_group: {
                    name: 'ME Group',
                    collapsed: true,
                },
                ak_group: {
                    name: 'AK Group',
                    collapsed: true,
                },
                hi_group: {
                    name: 'HI Group',
                    collapsed: true,
                },
            },
        },
        center: [-77.03343074744285, 38.90102372582757],
        zoom: 13.109374324942346,
        bearing: 0,
        pitch: 0,
        sources: {
            composite: {
                url: 'mapbox://mapright2.20240325_polygons_ak,mapright2.20240325_polygons_hi,mapright2.20240325_polygons_ma,mapright2.20240325_polygons_me,mapright2.20240325_polygons_nh,mapright2.20240325_polygons_vt',
                type: 'vector',
            },
        },
        sprite: 'mapbox://sprites/mapright2/cluk1rjta03o101nwd6mp3mef/ck2u8j60r58fu0sgyxrigm3cu',
        glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
        layers: [
            {
                id: '20240325_ak_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ak_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ak_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ak_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                },
            },
            {
                id: '20240325_ak_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ak_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ak_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ak_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ak_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ak_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ak_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ak_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ak_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ak',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_hi_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_hi_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_hi_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_hi_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_hi_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_hi_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_hi_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_hi_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_hi_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_hi_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_hi_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_hi_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'hi_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_hi',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_me_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_me_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_me_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_me_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_me_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_me_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_me_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_me_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_me_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_me_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_me_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_me_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'me_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_me',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ma_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ma_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ma_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ma_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ma_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ma_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ma_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ma_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ma_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_ma_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_ma_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_ma_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'ma_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_ma',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_vt_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_vt_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_vt_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_vt_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_vt_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_vt_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_vt_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_vt_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_vt_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_vt_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_vt_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_vt_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'vt_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_vt',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nh_residential_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(59, 82%, 62%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nh_residential_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['RESIDENTIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(65, 98%, 62%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nh_commercial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(4, 96%, 53%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nh_commercial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['COMMERCIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nh_industrial_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(270, 78%, 55%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nh_industrial_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['INDUSTRIAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(291, 100%, 85%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nh_agricultural_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(150, 98%, 49%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nh_agricultural_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['AGRICULTURAL'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(144, 100%, 61%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nh_vacant_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(206, 13%, 65%)',
                    'fill-opacity': 0.5,
                },
            },
            {
                id: '20240325_nh_vacant_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['VACANT'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(216, 10%, 90%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
            {
                id: '20240325_nh_miscellaneous_fill',
                type: 'fill',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'fill-color': 'hsl(0, 0%, 3%)',
                    'fill-opacity': 0.7,
                },
            },
            {
                id: '20240325_nh_miscellaneous_line',
                type: 'line',
                metadata: {
                    'mapbox:group': 'nh_group',
                },
                source: 'composite',
                'source-layer': '20240325_polygons_nh',
                filter: [
                    'match',
                    ['get', 'B_LAND_USE_DESC'],
                    ['MISCELLANEOUS'],
                    true,
                    false,
                ],
                layout: {},
                paint: {
                    'line-color': 'hsl(0, 0%, 6%)',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        13,
                        0.2,
                        16,
                        1,
                    ],
                },
            },
        ],
        created: '2024-04-03T16:53:24.622Z',
        modified: '2024-04-03T16:53:24.622Z',
        id: 'cluk1rjta03o101nwd6mp3mef',
        owner: 'mapright2',
        visibility: 'private',
        protected: false,
        draft: false,
    },
    big_ridge_aerial: {
        sources: {
            big_ridge_aerial: {
                tileSize: 256,
                scheme: 'xyz',
                type: 'raster',
                tiles: [
                    'https://a.tiles.mapbox.com/v4/mapright2.15_big_ridge_lane/{z}/{x}/{y}.jpg?access_token=pk.eyJ1IjoibWFwcmlnaHQyIiwiYSI6IkpMTE8tT0EifQ.BVYqz1Tp-th28TXPgg3rrQ',
                ],
            },
        },
        layers: [
            {
                type: 'raster',
                id: 'big_ridge_aerial',
                source: 'big_ridge_aerial',
            },
        ],
    },
    roadsVectorLayer: {
        roadsVectorLayer: {
            sprite: 'mapbox://sprites/mapright2/cj2jiug1i00012rka57atsob9?fresh=true',
            glyphs: 'mapbox://fonts/mapright2/{fontstack}/{range}.pbf',
            layers: [
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'tunnel'],
                            ['in', 'class', 'secondary', 'tertiary'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-secondary-tertiary case',
                    paint: {
                        'line-color': '#fff',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13, 0],
                                [13.5, 0.5],
                                [16, 0.75],
                                [18, 0.35],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 2],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-blur': 0,
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 12],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-street_limited case',
                    paint: {
                        'line-color': '#fff',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13, 0],
                                [13.5, 0.5],
                                [16, 0.75],
                                [18, 0.35],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 2],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-blur': 0,
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 12],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-street case',
                    paint: {
                        'line-color': 'hsl(0, 28%, 93%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13, 0],
                                [13.5, 0.5],
                                [16, 0.75],
                                [18, 0.35],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 2],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-blur': 0,
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'primary'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-primary-case',
                    paint: {
                        'line-color': '#fff',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13, 0],
                                [13.5, 0.5],
                                [16, 0.75],
                                [18, 0.35],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 2],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-blur': 0,
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 18],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'tunnel'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-trunk_link-case',
                    paint: {
                        'line-color': 'hsl(0, 28%, 93%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13, 0],
                                [13.5, 0.5],
                                [16, 0.75],
                                [18, 0.35],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 2],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-blur': 0,
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-motorway_link-case',
                    paint: {
                        'line-color': '#fff',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13, 0],
                                [13.5, 0.5],
                                [16, 0.75],
                                [18, 0.35],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [16, 2],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-blur': 0,
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 18],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-trunk-case',
                    paint: {
                        'line-color': '#fff',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.75],
                                [16, 0.5],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [16, 2],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-blur': 0,
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 18],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-motorway-case',
                    paint: {
                        'line-color': '#fff',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.75],
                                [16, 0.5],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [16, 2],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-blur': 0,
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 18],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'steps'],
                            ['==', 'class', 'path'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-path',
                    paint: {
                        'line-color': 'hsl(0, 0%, 86%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.25],
                                [15, 0.4],
                                [16, 0.75],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 1],
                                [18, 2.5],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.75, 1]],
                                [16, [1, 0.75]],
                                [17, [1, 0.5]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'tunnel'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-trunk_link',
                    paint: {
                        'line-color': 'hsl(0, 28%, 93%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.75],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-blur': 0,
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-motorway_link',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [5, 'hsl(31, 27%, 51%)'],
                                [14.5, 'hsl(31, 59%, 56%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.75],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 18],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-blur': 0,
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'pedestrian'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-pedestrian case',
                    paint: {
                        'line-color': 'hsl(26, 33%, 97%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.75],
                                [16, 0.5],
                            ],
                        },
                        'line-width': 1,
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.5, 1]],
                                [16, [1, 2]],
                            ],
                        },
                        'line-blur': 0,
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 14,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['==', 'structure', 'tunnel'],
                            ['in', 'class', 'link', 'service', 'track'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-service-link-track case',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [7, 'hsl(0, 1%, 56%)'],
                                [10, 'hsl(0, 0%, 75%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.75],
                                [16, 0.5],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 12],
                            ],
                        },
                        'line-dasharray': [1, 0],
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-street_limited',
                    paint: {
                        'line-color': 'hsl(0, 1%, 17%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [12, 0],
                                [14, 0.5],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10.5, 0.5],
                                [12, 4],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-blur': 0,
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-street',
                    paint: {
                        'line-color': 'hsl(0, 1%, 17%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [12, 0],
                                [14, 0.5],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10.5, 0.5],
                                [12, 4],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-blur': 0,
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'tunnel'],
                            ['in', 'class', 'secondary', 'tertiary'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-secondary-tertiary',
                    paint: {
                        'line-color': 'hsl(0, 1%, 17%)',
                        'line-opacity': {
                            base: 1.2,
                            stops: [
                                [11.5, 0],
                                [12.5, 0.65],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [16, 12],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-blur': 0,
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'primary'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-primary',
                    paint: {
                        'line-color': 'hsl(0, 2%, 83%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.75],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 18],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-blur': 0,
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 15,
                    layout: {
                        'icon-image': 'oneway-spaced-white-large',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'tunnel'],
                            [
                                'in',
                                'class',
                                'link',
                                'path',
                                'pedestrian',
                                'service',
                                'track',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'tunnel-oneway-arrows-blue-minor',
                    paint: {},
                    'source-layer': 'road',
                },
                {
                    minzoom: 15,
                    layout: {
                        'icon-image': 'oneway-spaced-white-large',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'tunnel'],
                            [
                                'in',
                                'class',
                                'primary',
                                'secondary',
                                'street',
                                'street_limited',
                                'tertiary',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'tunnel-oneway-arrows-blue-major',
                    paint: {},
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-trunk',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [14, 'hsl(54, 59%, 54%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.75],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 18],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-blur': 0,
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-motorway',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [5, 'hsl(31, 27%, 51%)'],
                                [14.5, 'hsl(31, 59%, 56%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.75],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 18],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-blur': 0,
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 15,
                    layout: {
                        'icon-image': 'oneway-spaced-white-large',
                        'symbol-placement': 'line',
                        'symbol-spacing': 150,
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1460127465037.9006',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            [
                                '!in',
                                'type',
                                'primary_link',
                                'secondary_link',
                                'tertiary_link',
                            ],
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'tunnel'],
                            [
                                'in',
                                'class',
                                'link',
                                'motorway',
                                'motorway_link',
                                'trunk',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'tunnel-oneway-arrows-white',
                    paint: {},
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'type', 'ferry'],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'ferry',
                    paint: {
                        'line-color': '#dbdbdb',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [7.5, 0],
                                [8, 0.15],
                                [16, 0.5],
                            ],
                        },
                        'line-width': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [18, 2.5],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [12, [3.5, 2]],
                                [14, [2, 1]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'type', 'ferry_auto'],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'ferry, auto',
                    paint: {
                        'line-color': '#dbdbdb',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [7.5, 0],
                                [8, 0.15],
                                [16, 0.5],
                            ],
                        },
                        'line-width': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [18, 2.5],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'text-field': '{name_en}',
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [16, 11],
                                [20, 13],
                            ],
                        },
                        'text-letter-spacing': 0.01,
                        'text-line-height': 1.1,
                        'text-max-width': 7,
                        'symbol-placement': 'line',
                    },
                    filter: ['==', 'class', 'ferry'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'ferry-label',
                    paint: {
                        'text-color': 'hsl(230, 50%, 98%)',
                        'text-opacity': 1,
                        'text-halo-color': 'hsl(0, 1%, 3%)',
                        'text-halo-blur': 0.5,
                        'text-halo-width': 1.75,
                    },
                    'source-layer': 'road_label',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-link case',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [7, 'hsl(0, 1%, 56%)'],
                                [10, 'hsl(0, 0%, 75%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 10,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'motorway_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-motorway_link-case',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                                [15, 'hsl(0, 1%, 25%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [16, 2],
                            ],
                        },
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    maxzoom: 15,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'primary'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-primary-case',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [11, 'hsl(0, 1%, 17%)'],
                                [14, 'hsl(54, 2%, 53%)'],
                                [18, 'hsl(0, 1%, 13%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [7, 0],
                                [10, 0.5],
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [16, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-trunk_link-case',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                                [15, 'hsl(0, 1%, 25%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [11, 0.75],
                                [12, 0.65],
                                [18, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [14, 2],
                            ],
                        },
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [5, 'round'],
                                [11, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'trunk'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-trunk-case',
                    paint: {
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                            ],
                        },
                        'line-width': {
                            base: 1,
                            stops: [
                                [5, 0.5],
                                [18, 2],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [5, 'round'],
                                [11, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'motorway'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-motorway-case',
                    paint: {
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                                [15, 'hsl(0, 1%, 25%)'],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [16, 2],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            [
                                '!in',
                                'type',
                                'crossing',
                                'piste',
                                'sidewalk',
                                'steps',
                            ],
                            ['==', 'class', 'path'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-path',
                    paint: {
                        'line-color': 'hsl(0, 0%, 86%)',
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 1],
                                [18, 2.5],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.25],
                                [15, 0.4],
                                [16, 0.75],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.75, 1]],
                                [16, [1, 0.75]],
                                [17, [1, 0.5]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-trunk_link',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [14, 'hsl(54, 48%, 49%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 10,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'motorway_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-motorway_link',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [5, 'hsl(31, 27%, 51%)'],
                                [14.5, 'hsl(31, 59%, 56%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 12,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'pedestrian'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-pedestrian',
                    paint: {
                        'line-color': 'hsl(0, 0%, 86%)',
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 1],
                                [18, 2.5],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.25],
                                [15, 0.4],
                                [16, 0.75],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.5, 1]],
                                [16, [1, 2]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'o'],
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-link',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [7, 'hsl(0, 1%, 56%)'],
                                [10, 'hsl(0, 0%, 75%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-street_limited',
                    paint: {
                        'line-color': 'hsl(0, 1%, 17%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [12, 0],
                                [14, 0.25],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10.5, 0.5],
                                [12, 4],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                        visibility: 'none',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-street',
                    paint: {
                        'line-color': 'hsl(0, 1%, 17%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [12, 0],
                                [14, 0.5],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10.5, 0.5],
                                [12, 4],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                        visibility: 'none',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['in', 'class', 'secondary', 'tertiary'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-secondary-tertiary',
                    paint: {
                        'line-color': 'hsl(0, 1%, 17%)',
                        'line-opacity': {
                            base: 1.2,
                            stops: [
                                [11.5, 0],
                                [12.5, 0.65],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [16, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['in', 'class', 'link', 'primary'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-primary',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 0%, 96%)'],
                                [10, 'hsl(0, 2%, 83%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [6, 0.25],
                                [8, 0.6],
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.5],
                                [12, 1.75],
                                [18, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 16,
                    layout: {
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-spaced-small'],
                                [17, 'oneway-spaced-large'],
                            ],
                        },
                        'symbol-placement': 'line',
                        'symbol-spacing': 200,
                        'icon-padding': 2,
                        'icon-rotation-alignment': 'map',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'oneway', 'true'],
                            [
                                'in',
                                'class',
                                'link',
                                'path',
                                'pedestrian',
                                'service',
                                'track',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-oneway-arrows-blue-minor',
                    paint: {},
                    'source-layer': 'road',
                },
                {
                    minzoom: 15,
                    layout: {
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-spaced-small'],
                                [17, 'oneway-spaced-large'],
                            ],
                        },
                        'symbol-placement': 'line',
                        'symbol-spacing': 200,
                        'icon-padding': 2,
                        'icon-rotation-alignment': 'map',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'oneway', 'true'],
                            [
                                'in',
                                'class',
                                'primary',
                                'secondary',
                                'street',
                                'street_limited',
                                'tertiary',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-oneway-arrows-blue-major',
                    paint: {},
                    'source-layer': 'road',
                },
                {
                    minzoom: 5,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'trunk'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-trunk',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [14, 'hsl(54, 59%, 54%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'motorway'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-motorway',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [5, 'hsl(31, 27%, 51%)'],
                                [14.5, 'hsl(31, 59%, 56%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 15,
                    layout: {
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-spaced-small'],
                                [17, 'oneway-spaced-large'],
                            ],
                        },
                        'symbol-placement': 'line',
                        'symbol-spacing': 200,
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1459882298057.56',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            [
                                '!in',
                                'type',
                                'primary_link',
                                'secondary_link',
                                'tertiary_link',
                            ],
                            ['==', 'oneway', 'true'],
                            [
                                'in',
                                'class',
                                'link',
                                'motorway',
                                'motorway_link',
                                'trunk',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-oneway-arrows-white',
                    paint: {},
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'primary'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-primary-case',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [11, 'hsl(0, 1%, 17%)'],
                                [14, 'hsl(54, 2%, 53%)'],
                                [18, 'hsl(0, 1%, 13%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [7, 0],
                                [10, 0.5],
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [16, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    maxzoom: 15,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk_link-case',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                                [15, 'hsl(0, 1%, 25%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [11, 0.75],
                                [12, 0.65],
                                [18, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [14, 2],
                            ],
                        },
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway_link-case',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                                [15, 'hsl(0, 1%, 25%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [16, 2],
                            ],
                        },
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [5, 'round'],
                                [11, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk-case',
                    paint: {
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                            ],
                        },
                        'line-width': {
                            base: 1,
                            stops: [
                                [5, 0.5],
                                [18, 2],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway-case',
                    paint: {
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                                [15, 'hsl(0, 1%, 25%)'],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [16, 2],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'steps'],
                            ['==', 'class', 'path'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-path',
                    paint: {
                        'line-color': 'hsl(0, 0%, 86%)',
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 1],
                                [18, 2.5],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0.25],
                                [15, 0.4],
                                [16, 0.75],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.75, 1]],
                                [16, [1, 0.75]],
                                [17, [1, 0.5]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk_link',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [14, 'hsl(54, 48%, 49%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway_link',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [5, 'hsl(31, 27%, 51%)'],
                                [14.5, 'hsl(31, 59%, 56%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-street_limited',
                    paint: {
                        'line-color': 'hsl(0, 1%, 17%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [12, 0],
                                [14, 0.25],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10.5, 0.5],
                                [12, 4],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-street',
                    paint: {
                        'line-color': 'hsl(0, 1%, 17%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [12, 0],
                                [14, 0.5],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10.5, 0.5],
                                [12, 4],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['in', 'type', 'secondary', 'tertiary'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-secondary-tertiary',
                    paint: {
                        'line-color': 'hsl(0, 1%, 17%)',
                        'line-opacity': {
                            base: 1.2,
                            stops: [
                                [11.5, 0],
                                [12.5, 0.65],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [16, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'primary'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-primary',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 0%, 96%)'],
                                [10, 'hsl(0, 2%, 83%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [6, 0.25],
                                [8, 0.6],
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.5],
                                [12, 1.75],
                                [18, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 15,
                    layout: {
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-spaced-small'],
                                [17, 'oneway-spaced-large'],
                            ],
                        },
                        'symbol-placement': 'line',
                        'symbol-spacing': 200,
                        'icon-padding': 2,
                        'icon-rotation-alignment': 'map',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'bridge'],
                            [
                                'in',
                                'class',
                                'link',
                                'path',
                                'pedestrian',
                                'service',
                                'track',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'bridge-oneway-arrows-blue-minor',
                    paint: {},
                    'source-layer': 'road',
                },
                {
                    minzoom: 15,
                    layout: {
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-spaced-small'],
                                [17, 'oneway-spaced-large'],
                            ],
                        },
                        'symbol-placement': 'line',
                        'symbol-spacing': 200,
                        'icon-padding': 2,
                        'icon-rotation-alignment': 'map',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'bridge'],
                            [
                                'in',
                                'class',
                                'primary',
                                'secondary',
                                'street',
                                'street_limited',
                                'tertiary',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'bridge-oneway-arrows-blue-major',
                    paint: {},
                    'source-layer': 'road',
                },
                {
                    minzoom: 5,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [14, 'hsl(54, 59%, 54%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [5, 'hsl(31, 27%, 51%)'],
                                [14.5, 'hsl(31, 59%, 56%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    maxzoom: 15,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'trunk_link'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk_link-2-case',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                                [15, 'hsl(0, 1%, 25%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [11, 0.75],
                                [12, 0.65],
                                [18, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [14, 2],
                            ],
                        },
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway_link-2-case',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                                [15, 'hsl(0, 1%, 25%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [16, 2],
                            ],
                        },
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    maxzoom: 15,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk-2-case',
                    paint: {
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [16, 2],
                            ],
                        },
                        'line-opacity': {
                            base: 1.2,
                            stops: [
                                [5, 0],
                                [5.5, 0.5],
                                [8, 0.85],
                                [14, 0.2],
                                [16, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    maxzoom: 15,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway-2-case',
                    paint: {
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 1%, 26%)'],
                                [12, 'hsl(0, 1%, 31%)'],
                                [15, 'hsl(0, 1%, 25%)'],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [16, 2],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'trunk_link'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk_link-2',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [14, 'hsl(54, 48%, 49%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway_link-2',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [5, 'hsl(31, 27%, 51%)'],
                                [14.5, 'hsl(31, 59%, 56%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk-2',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [14, 'hsl(54, 59%, 54%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [10, 'round'],
                                [12, 'butt'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway-2',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [5, 'hsl(31, 27%, 51%)'],
                                [14.5, 'hsl(31, 59%, 56%)'],
                                [18, 'hsl(84, 2%, 82%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 1],
                                [16, 0],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 1],
                                [12, 2],
                                [18, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 16,
                    layout: {
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-spaced-small'],
                                [17, 'oneway-spaced-large'],
                            ],
                        },
                        'symbol-placement': 'line',
                        'symbol-spacing': 200,
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1459882360129.4563',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            [
                                '!in',
                                'type',
                                'primary_link',
                                'secondary_link',
                                'tertiary_link',
                            ],
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'bridge'],
                            [
                                'in',
                                'class',
                                'link',
                                'motorway',
                                'motorway_link',
                                'trunk',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'bridge-oneway-arrows-white',
                    paint: {},
                    'source-layer': 'road',
                },
                {
                    minzoom: 12,
                    layout: {
                        'line-join': 'round',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'type', 'piste'],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'piste-grey',
                    paint: {
                        'line-color': 'hsl(0, 0%, 25%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [12.5, 0],
                                [14, 0.25],
                                [16, 0.5],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 1],
                                [18, 2.5],
                            ],
                        },
                        'line-blur': 1,
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 12,
                    layout: {
                        'line-join': 'round',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'type', 'piste'],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'piste-white',
                    paint: {
                        'line-color': '#dbdbdb',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [12, 0],
                                [12.5, 1],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 1],
                                [18, 2.5],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [0, [3, 3]],
                                [22, [6, 6]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 12,
                    layout: {
                        'line-join': 'round',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'class', 'aerialway'],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'aerialway-grey',
                    paint: {
                        'line-color': 'hsl(0, 0%, 25%)',
                        'line-opacity': {
                            base: 1.5,
                            stops: [
                                [12.5, 0],
                                [14, 0.25],
                                [16, 0.5],
                            ],
                        },
                        'line-width': {
                            base: 1,
                            stops: [
                                [14, 3],
                                [18, 3.5],
                            ],
                        },
                        'line-blur': 1,
                    },
                    'source-layer': 'road',
                },
                {
                    minzoom: 12,
                    layout: {
                        'line-join': 'round',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'class', 'aerialway'],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'aerialway-white',
                    paint: {
                        'line-color': '#dbdbdb',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [12, 0],
                                [12.5, 1],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 1],
                                [18, 1.5],
                            ],
                        },
                    },
                    'source-layer': 'road',
                },
                {
                    layout: {
                        'line-join': 'bevel',
                    },
                    metadata: {
                        'mapbox:group': '1459882330622.065',
                    },
                    filter: [
                        'all',
                        ['==', 'maritime', 0],
                        ['>=', 'admin_level', 3],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'admin-3-4-boundaries-bg',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [0, 'hsla(93, 26%, 62%, 0.77)'],
                                [6, 'hsla(93, 26%, 62%, 0.77)'],
                                [8, 'hsla(93, 63%, 24%, 0.77)'],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [4, 0.2],
                                [8, 0.35],
                            ],
                        },
                        'line-width': {
                            base: 1,
                            stops: [
                                [3, 2.5],
                                [10, 4.5],
                            ],
                        },
                        'line-blur': {
                            base: 1,
                            stops: [
                                [3, 0],
                                [8, 3],
                            ],
                        },
                        'line-translate': [0, 0],
                    },
                    'source-layer': 'admin',
                },
                {
                    minzoom: 1,
                    layout: {
                        'line-join': 'miter',
                    },
                    metadata: {
                        'mapbox:group': '1459882330622.065',
                    },
                    filter: [
                        'all',
                        ['==', 'admin_level', 2],
                        ['==', 'maritime', 0],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'admin-2-boundaries-bg',
                    paint: {
                        'line-color': 'hsla(0, 0%, 95%, 0.77)',
                        'line-width': {
                            base: 1,
                            stops: [
                                [3, 3.5],
                                [10, 10],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [4, 0.1],
                                [8, 0.35],
                            ],
                        },
                        'line-blur': {
                            base: 1,
                            stops: [
                                [3, 0],
                                [10, 2],
                            ],
                        },
                        'line-translate': [0, 0],
                    },
                    'source-layer': 'admin',
                },
                {
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882330622.065',
                    },
                    filter: [
                        'all',
                        ['==', 'maritime', 0],
                        ['>=', 'admin_level', 3],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'admin-3-4-boundaries',
                    paint: {
                        'line-color': 'hsla(0, 0%, 0%, 0.72)',
                        'line-dasharray': [5, 2.5],
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [0, 1],
                                [12, 0.5],
                            ],
                        },
                        'line-width': {
                            base: 1,
                            stops: [
                                [4, 1],
                                [9, 1.75],
                            ],
                        },
                    },
                    'source-layer': 'admin',
                },
                {
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882330622.065',
                    },
                    filter: [
                        'all',
                        ['==', 'admin_level', 2],
                        ['==', 'disputed', 0],
                        ['==', 'maritime', 0],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'admin-2-boundaries',
                    paint: {
                        'line-color': 'hsl(0, 0%, 0%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [0, 0.5],
                                [6, 0.75],
                            ],
                        },
                        'line-width': {
                            base: 1,
                            stops: [
                                [0, 0.75],
                                [4, 3],
                            ],
                        },
                    },
                    'source-layer': 'admin',
                },
                {
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882330622.065',
                    },
                    filter: [
                        'all',
                        ['==', 'admin_level', 2],
                        ['==', 'disputed', 0],
                        ['==', 'maritime', 0],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'admin-2-boundaries offset',
                    paint: {
                        'line-color': 'hsla(0, 5%, 92%, 0.72)',
                        'line-translate': [0, 0],
                        'line-width': {
                            base: 1,
                            stops: [
                                [0, 0.5],
                                [4, 0.75],
                                [9, 1.5],
                                [12, 2],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [0, 0.25],
                                [4, 0.5],
                                [8, 0.75],
                            ],
                        },
                        'line-blur': 0,
                        'line-offset': {
                            base: 1,
                            stops: [
                                [0, 1.5],
                                [4, 0.75],
                            ],
                        },
                    },
                    'source-layer': 'admin',
                },
                {
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1459882330622.065',
                    },
                    filter: [
                        'all',
                        ['==', 'admin_level', 2],
                        ['==', 'disputed', 1],
                        ['==', 'maritime', 0],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'admin-2-boundaries-dispute',
                    paint: {
                        'line-color': 'hsl(0, 0%, 0%)',
                        'line-dasharray': [4, 8],
                        'line-width': 0.75,
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [0, 1],
                                [12, 0.75],
                                [16, 0],
                            ],
                        },
                    },
                    'source-layer': 'admin',
                },
                {
                    minzoom: 12,
                    layout: {
                        'text-field': '{name_en}',
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-max-angle': 30,
                        'text-size': {
                            base: 1,
                            stops: [
                                [13, 12],
                                [18, 16],
                            ],
                        },
                    },
                    filter: ['in', 'class', 'canal', 'river', 'stream'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'waterway-label',
                    paint: {
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [12, 0.5],
                                [14, 1.5],
                            ],
                        },
                        'text-halo-color': 'hsl(0, 0%, 9%)',
                        'text-color': '#75cff0',
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'waterway_label',
                },
                {
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [14, 11],
                                [20, 14],
                            ],
                        },
                        'icon-image': {
                            base: 1,
                            stops: [
                                [12, '{maki}-11'],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-offset': [0, 0.65],
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1461616039439.258',
                    },
                    filter: [
                        'all',
                        [
                            '!in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                        ['==', 'scalerank', 4],
                        ['>=', 'localrank', 15],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-scalerank4-l15',
                    paint: {
                        'text-color': 'hsl(49, 95%, 72%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 3%, 16%)'],
                            ],
                        },
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                    },
                    'source-layer': 'poi_label',
                },
                {
                    minzoom: 15,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [14, 11],
                                [20, 14],
                            ],
                        },
                        'icon-image': {
                            base: 1,
                            stops: [
                                [12, '{maki}-11'],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-offset': [0, 0.65],
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1461616039439.258',
                    },
                    filter: [
                        'all',
                        [
                            '!in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                        ['<', 'localrank', 14],
                        ['==', 'scalerank', 4],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-scalerank4-l1',
                    paint: {
                        'text-color': 'hsl(49, 95%, 72%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 3%, 16%)'],
                            ],
                        },
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                    },
                    'source-layer': 'poi_label',
                },
                {
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [14, 11],
                                [20, 14],
                            ],
                        },
                        'icon-image': {
                            base: 1,
                            stops: [
                                [13, ''],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Medium Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1461616039439.258',
                    },
                    filter: [
                        'all',
                        ['==', 'scalerank', 4],
                        [
                            'in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-parks-scalerank4',
                    paint: {
                        'text-color': 'hsl(100, 50%, 60%)',
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 1%, 18%)'],
                            ],
                        },
                        'icon-opacity': 1,
                    },
                    'source-layer': 'poi_label',
                },
                {
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [14, 11],
                                [20, 14],
                            ],
                        },
                        'icon-image': {
                            base: 1,
                            stops: [
                                [12, '{maki}-11'],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-offset': [0, 0.65],
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1461616021709.3948',
                    },
                    filter: [
                        'all',
                        [
                            '!in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                        ['<=', 'localrank', 3],
                        ['==', 'scalerank', 3],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-scalerank3',
                    paint: {
                        'text-color': 'hsl(49, 95%, 72%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 3%, 16%)'],
                            ],
                        },
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                    },
                    'source-layer': 'poi_label',
                },
                {
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [14, 11],
                                [20, 14],
                            ],
                        },
                        'icon-image': {
                            base: 1,
                            stops: [
                                [13, ''],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Medium Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1461616021709.3948',
                    },
                    filter: [
                        'all',
                        ['==', 'scalerank', 3],
                        [
                            'in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-parks-scalerank3',
                    paint: {
                        'text-color': 'hsl(100, 50%, 60%)',
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 1%, 18%)'],
                            ],
                        },
                        'icon-opacity': 1,
                    },
                    'source-layer': 'poi_label',
                },
                {
                    minzoom: 13,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [15, 10],
                                [20, 13],
                            ],
                        },
                        'text-max-angle': 30,
                        'symbol-spacing': 250,
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    10,
                                    [
                                        'DIN Offc Pro Regular',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    14,
                                    [
                                        'DIN Offc Pro Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'symbol-placement': 'line',
                        'text-padding': 1,
                        visibility: 'none',
                        'text-rotation-alignment': 'map',
                        'text-field': '{name_en}',
                    },
                    metadata: {
                        'mapbox:group': '1459882215460.736',
                    },
                    filter: [
                        'all',
                        [
                            '!in',
                            'class',
                            'ferry',
                            'link',
                            'motorway',
                            'pedestrian',
                            'primary',
                            'secondary',
                            'street',
                            'street_limited',
                            'tertiary',
                            'trunk',
                        ],
                        ['==', '$type', 'LineString'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-label-small',
                    paint: {
                        'text-color': '#fff',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 2%, 16%)'],
                            ],
                        },
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                    },
                    'source-layer': 'road_label',
                },
                {
                    minzoom: 12,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [12, 8],
                                [14, 9.5],
                                [16, 11],
                                [18, 11.5],
                            ],
                        },
                        'text-max-angle': 30,
                        'text-transform': 'uppercase',
                        'symbol-spacing': 250,
                        'text-font': [
                            'Overpass Black Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-padding': 1,
                        'text-rotation-alignment': 'map',
                        'text-field': '{name_en}',
                    },
                    metadata: {
                        'mapbox:group': '1459882215460.736',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'in',
                            'class',
                            'link',
                            'pedestrian',
                            'street',
                            'street_limited',
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-label-medium',
                    paint: {
                        'text-color': 'hsl(0, 1%, 100%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 2%, 16%)'],
                            ],
                        },
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                    },
                    'source-layer': 'road_label',
                },
                {
                    minzoom: 12,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [11, 8],
                                [12, 9],
                                [14, 10],
                                [16, 12.5],
                                [18, 13.5],
                            ],
                        },
                        'text-max-angle': 30,
                        'text-transform': 'uppercase',
                        'symbol-spacing': 250,
                        'text-font': [
                            'Overpass Black Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-padding': 1,
                        'text-rotation-alignment': 'map',
                        'text-field': '{name_en}',
                    },
                    metadata: {
                        'mapbox:group': '1459882215460.736',
                    },
                    filter: ['in', 'class', 'secondary', 'tertiary'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-label-large',
                    paint: {
                        'text-color': 'hsl(58, 3%, 100%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 2%, 16%)'],
                            ],
                        },
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                    },
                    'source-layer': 'road_label',
                },
                {
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [11, 8.5],
                                [12, 9],
                                [14, 10.5],
                                [16, 13],
                                [18, 14],
                            ],
                        },
                        'text-max-angle': 30,
                        'text-transform': 'uppercase',
                        'symbol-spacing': 250,
                        'text-font': [
                            'Overpass Black Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-padding': 1,
                        'text-rotation-alignment': 'map',
                        'text-field': '{name_en}',
                    },
                    metadata: {
                        'mapbox:group': '1459882215460.736',
                    },
                    filter: ['in', 'class', 'motorway', 'primary', 'trunk'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-label-xlarge',
                    paint: {
                        'text-color': 'hsl(0, 0%, 100%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 2%, 16%)'],
                            ],
                        },
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                    },
                    'source-layer': 'road_label',
                },
                {
                    layout: {
                        'text-size': 9,
                        'icon-image': '{shield}-{reflen}',
                        'icon-rotation-alignment': 'viewport',
                        'text-max-angle': 38,
                        'symbol-spacing': {
                            base: 1,
                            stops: [
                                [11, 150],
                                [14, 200],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                        'symbol-placement': {
                            base: 1,
                            stops: [
                                [10, 'point'],
                                [11, 'line'],
                            ],
                        },
                        'text-padding': 2,
                        'text-rotation-alignment': 'viewport',
                        'text-field': '{ref}',
                        'text-letter-spacing': 0.05,
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1459802883310.3264',
                    },
                    filter: [
                        'all',
                        [
                            '!in',
                            'shield',
                            'at-expressway',
                            'at-motorway',
                            'at-state-b',
                            'bg-motorway',
                            'bg-national',
                            'ch-main',
                            'ch-motorway',
                            'cz-expressway',
                            'cz-motorway',
                            'cz-road',
                            'de-motorway',
                            'e-road',
                            'fi-main',
                            'gr-motorway',
                            'gr-national',
                            'hr-motorway',
                            'hr-state',
                            'hu-main',
                            'hu-motorway',
                            'nz-state',
                            'pl-expressway',
                            'pl-motorway',
                            'pl-national',
                            'ro-county',
                            'ro-motorway',
                            'ro-national',
                            'rs-motorway',
                            'rs-state-1b',
                            'se-main',
                            'si-expressway',
                            'si-motorway',
                            'sk-highway',
                            'sk-road',
                            'us-interstate',
                            'us-interstate-business',
                            'us-interstate-duplex',
                            'us-interstate-truck',
                            'za-metropolitan',
                            'za-national',
                            'za-provincial',
                            'za-regional',
                        ],
                        ['<=', 'reflen', 6],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-shields-black',
                    paint: {
                        'text-color': '#000',
                        'icon-halo-color': 'rgba(0, 0, 0, 1)',
                        'icon-halo-width': 1,
                        'text-opacity': 1,
                        'icon-color': 'white',
                        'text-halo-color': '#ffffff',
                        'text-halo-width': 0,
                    },
                    'source-layer': 'road_label',
                },
                {
                    layout: {
                        'text-size': 9,
                        'icon-image': '{shield}-{reflen}',
                        'icon-rotation-alignment': 'viewport',
                        'text-max-angle': 38,
                        'symbol-spacing': {
                            base: 1,
                            stops: [
                                [11, 150],
                                [14, 200],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                        'symbol-placement': {
                            base: 1,
                            stops: [
                                [10, 'point'],
                                [11, 'line'],
                            ],
                        },
                        'text-padding': 2,
                        'text-rotation-alignment': 'viewport',
                        'text-field': '{ref}',
                        'text-letter-spacing': 0.05,
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1459802883310.3264',
                    },
                    filter: [
                        'all',
                        ['<=', 'reflen', 6],
                        [
                            'in',
                            'shield',
                            'at-expressway',
                            'at-motorway',
                            'at-state-b',
                            'bg-motorway',
                            'bg-national',
                            'ch-main',
                            'ch-motorway',
                            'cz-expressway',
                            'cz-motorway',
                            'cz-road',
                            'de-motorway',
                            'e-road',
                            'fi-main',
                            'gr-motorway',
                            'gr-national',
                            'hr-motorway',
                            'hr-state',
                            'hu-main',
                            'hu-motorway',
                            'nz-state',
                            'pl-expressway',
                            'pl-motorway',
                            'pl-national',
                            'ro-county',
                            'ro-motorway',
                            'ro-national',
                            'rs-motorway',
                            'rs-state-1b',
                            'se-main',
                            'si-expressway',
                            'si-motorway',
                            'sk-highway',
                            'sk-road',
                            'us-interstate',
                            'us-interstate-business',
                            'us-interstate-duplex',
                            'us-interstate-truck',
                            'za-metropolitan',
                            'za-national',
                            'za-provincial',
                            'za-regional',
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-shields-white',
                    paint: {
                        'text-color': '#fff',
                        'icon-halo-color': 'rgba(0, 0, 0, 1)',
                        'icon-halo-width': 1,
                        'text-opacity': 1,
                        'icon-color': 'white',
                        'text-halo-color': '#ffffff',
                        'text-halo-width': 0,
                    },
                    'source-layer': 'road_label',
                },
                {
                    minzoom: 14,
                    layout: {
                        'text-field': '{ref}',
                        'text-size': 9,
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Regular',
                        ],
                        'icon-image': 'motorway-exit-{reflen}',
                    },
                    metadata: {
                        'mapbox:group': '1459802883310.3264',
                    },
                    filter: ['all', ['<=', 'reflen', 9], ['>', 'reflen', 0]],
                    type: 'symbol',
                    source: 'composite',
                    id: 'motorway-junction',
                    paint: {
                        'text-color': '#fff',
                        'text-translate': [0, 0],
                    },
                    'source-layer': 'motorway_junction',
                },
                {
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [14, 11],
                                [20, 14],
                            ],
                        },
                        'icon-image': {
                            base: 1,
                            stops: [
                                [12, '{maki}-11'],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-offset': [0, 0.65],
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1460058190129.9705',
                    },
                    filter: [
                        'all',
                        [
                            '!in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                        ['<=', 'localrank', 3],
                        ['==', 'scalerank', 2],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-scalerank2',
                    paint: {
                        'text-color': 'hsl(49, 95%, 72%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 3%, 16%)'],
                            ],
                        },
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                    },
                    'source-layer': 'poi_label',
                },
                {
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [14, 11],
                                [20, 14],
                            ],
                        },
                        'icon-image': {
                            base: 1,
                            stops: [
                                [13, ''],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Medium Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1460058190129.9705',
                    },
                    filter: [
                        'all',
                        ['==', 'scalerank', 2],
                        [
                            'in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-parks-scalerank2',
                    paint: {
                        'text-color': 'hsl(100, 50%, 60%)',
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 1%, 18%)'],
                            ],
                        },
                        'icon-opacity': 1,
                    },
                    'source-layer': 'poi_label',
                },
                {
                    minzoom: 12,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [16, 11],
                                [20, 13],
                            ],
                        },
                        'icon-image': '{network}',
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-offset': [0, 0.85],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': {
                            base: 1,
                            stops: [
                                [0, ''],
                                [13, '{name_en}'],
                            ],
                        },
                        'text-letter-spacing': 0.01,
                        'icon-padding': 0,
                        'text-max-width': 7,
                    },
                    filter: ['!=', 'maki', 'entrance'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'rail-label',
                    paint: {
                        'text-color': 'hsl(0, 0%, 92%)',
                        'text-halo-color': 'hsl(0, 0%, 25%)',
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [7, 0.5],
                                [16, 1.5],
                            ],
                        },
                        'icon-halo-width': 4,
                        'icon-halo-color': '#fff',
                        'text-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'rail_station_label',
                },
                {
                    minzoom: 15,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [16, 13],
                                [20, 16],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-max-width': 7,
                        'text-field': '{name_en}',
                    },
                    metadata: {
                        'mapbox:group': '1459803160909.4875',
                    },
                    filter: ['<=', 'area', 10000],
                    type: 'symbol',
                    source: 'composite',
                    id: 'water-label-sm',
                    paint: {
                        'text-color': '#75cff0',
                        'text-halo-color': 'hsl(0, 0%, 9%)',
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [13, 0.5],
                                [14, 1],
                            ],
                        },
                        'text-halo-blur': {
                            base: 1,
                            stops: [
                                [12, 0.5],
                                [15, 1],
                            ],
                        },
                    },
                    'source-layer': 'water_label',
                },
                {
                    minzoom: 5,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [13, 13],
                                [18, 18],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-max-width': 7,
                        'text-field': '{name_en}',
                    },
                    metadata: {
                        'mapbox:group': '1459803160909.4875',
                    },
                    filter: ['>', 'area', 10000],
                    type: 'symbol',
                    source: 'composite',
                    id: 'water-label',
                    paint: {
                        'text-color': '#75cff0',
                        'text-halo-color': 'hsl(0, 0%, 9%)',
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [13, 0.5],
                                [14, 1],
                            ],
                        },
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'water_label',
                },
                {
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11],
                                [18, 14],
                            ],
                        },
                        'icon-image': {
                            base: 1,
                            stops: [
                                [13, ''],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1459803084625.6987',
                    },
                    filter: [
                        'all',
                        ['<=', 'scalerank', 1],
                        [
                            'in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-parks-scalerank1',
                    paint: {
                        'text-color': 'hsl(100, 50%, 60%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 1%, 18%)'],
                            ],
                        },
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                        'icon-opacity': 1,
                    },
                    'source-layer': 'poi_label',
                },
                {
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11],
                                [18, 14],
                            ],
                        },
                        'icon-image': {
                            base: 1,
                            stops: [
                                [12, '{maki}-11'],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1459803084625.6987',
                    },
                    filter: [
                        'all',
                        [
                            '!in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                        ['<=', 'scalerank', 1],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-scalerank1',
                    paint: {
                        'text-color': 'hsl(49, 95%, 72%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(0, 1%, 10%)'],
                                [16, 'hsl(0, 3%, 16%)'],
                            ],
                        },
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [14, 1.25],
                                [15, 1.5],
                            ],
                        },
                        'text-halo-blur': 0,
                    },
                    'source-layer': 'poi_label',
                },
                {
                    minzoom: 9,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 12],
                                [18, 18],
                            ],
                        },
                        'icon-image': {
                            stops: [
                                [12, '{maki}-11'],
                                [13, '{maki}-15'],
                            ],
                        },
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.75],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': {
                            stops: [
                                [11, '{ref}'],
                                [12, '{name_en}'],
                            ],
                        },
                        'text-letter-spacing': 0.01,
                        'text-max-width': {
                            base: 1,
                            stops: [
                                [7, 7],
                                [12, 8],
                                [16, 10],
                            ],
                        },
                    },
                    filter: ['<=', 'scalerank', 2],
                    type: 'symbol',
                    source: 'composite',
                    id: 'airport-label',
                    paint: {
                        'text-color': 'hsl(0, 0%, 92%)',
                        'text-halo-color': 'hsl(0, 0%, 25%)',
                        'text-halo-width': {
                            base: 1,
                            stops: [
                                [7, 0.5],
                                [16, 1.5],
                            ],
                        },
                        'text-halo-blur': 0.5,
                        'icon-opacity': 0.8,
                    },
                    'source-layer': 'airport_label',
                },
                {
                    layout: {
                        'text-line-height': 1.2,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11],
                                [18, 16],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0],
                        'text-rotation-alignment': 'viewport',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    filter: ['==', 'type', 'islet'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-islets',
                    paint: {
                        'text-color': 'hsl(0, 100%, 100%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-halo-width': 1,
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'place_label',
                },
                {
                    layout: {
                        'text-field': '{name_en}',
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11],
                                [18, 14],
                            ],
                        },
                        'text-max-width': 7,
                        'text-padding': 2,
                    },
                    maxzoom: 18,
                    filter: [
                        'all',
                        [
                            'all',
                            ['<=', 'localrank', 10],
                            ['==', 'type', 'residential'],
                        ],
                        ['in', '$type', 'LineString', 'Point', 'Polygon'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-residential',
                    paint: {
                        'text-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                        'text-halo-color': 'hsl(0, 0%, 0%)',
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'place_label',
                },
                {
                    minzoom: 10,
                    layout: {
                        'text-line-height': 1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [12, 10],
                                [15, 15],
                                [16, 16],
                            ],
                        },
                        'text-transform': 'uppercase',
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 3,
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.1,
                        'text-max-width': 7,
                    },
                    maxzoom: 16,
                    filter: ['==', 'type', 'neighbourhood'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-neighbourhood',
                    paint: {
                        'text-halo-color': 'hsl(0, 53%, 4%)',
                        'text-halo-blur': 0.5,
                        'text-color': 'hsl(230, 71%, 86%)',
                        'text-halo-width': 1,
                    },
                    'source-layer': 'place_label',
                },
                {
                    minzoom: 10,
                    layout: {
                        'text-line-height': 1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [12, 11],
                                [15, 18],
                            ],
                        },
                        'text-transform': 'uppercase',
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 3,
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.15,
                        'text-max-width': 7,
                    },
                    maxzoom: 16,
                    filter: ['==', 'type', 'suburb'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-suburb',
                    paint: {
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-halo-blur': 0.5,
                        'text-color': 'rgb(241, 243, 237)',
                        'text-halo-width': 1,
                    },
                    'source-layer': 'place_label',
                },
                {
                    minzoom: 10,
                    layout: {
                        'text-line-height': 1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [12, 10],
                                [15, 15],
                            ],
                        },
                        'text-font': [
                            'Montserrat SemiBold',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 3,
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.1,
                        'text-max-width': 7,
                    },
                    maxzoom: 16,
                    filter: ['==', 'type', 'hamlet'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-hamlet',
                    paint: {
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-halo-blur': 0.5,
                        'text-color': 'hsl(80, 19%, 94%)',
                        'text-halo-width': 1,
                    },
                    'source-layer': 'place_label',
                },
                {
                    minzoom: 8,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11.5],
                                [16, 18],
                            ],
                        },
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    0,
                                    [
                                        'Montserrat Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    10,
                                    [
                                        'Montserrat SemiBold',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7, [0, -0.15]],
                                [8, [0, 0]],
                            ],
                        },
                        'icon-size': 1,
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'bottom'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                    },
                    maxzoom: 15,
                    filter: [
                        'all',
                        ['<=', 'localrank', 12],
                        ['==', 'type', 'village'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-village',
                    paint: {
                        'text-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(0, 1%, 100%)'],
                                [11, 'rgb(241, 243, 237)'],
                            ],
                        },
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-halo-width': 1,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 0.9],
                                [8, 0],
                            ],
                        },
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'place_label',
                },
                {
                    minzoom: 6,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [7, 9],
                                [9, 11.5],
                                [11, 14],
                            ],
                        },
                        'icon-image': 'dot-9',
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    0,
                                    [
                                        'Montserrat Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    9,
                                    [
                                        'Montserrat SemiBold',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7, [0, -0.15]],
                                [8, [0, 0]],
                            ],
                        },
                        'icon-size': 1,
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'bottom'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                    },
                    maxzoom: 15,
                    filter: [
                        'all',
                        ['<=', 'localrank', 12],
                        ['==', 'type', 'town'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-town',
                    paint: {
                        'text-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(0, 0%, 100%)'],
                                [11, 'rgb(241, 243, 237)'],
                            ],
                        },
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-halo-width': 1,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 0.9],
                                [8, 0],
                            ],
                        },
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'place_label',
                },
                {
                    layout: {
                        'text-line-height': 1.2,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11],
                                [18, 16],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'Montserrat Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0],
                        'text-rotation-alignment': 'viewport',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 7,
                    },
                    filter: ['==', 'type', 'island'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-islands',
                    paint: {
                        'text-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(0, 0%, 100%)'],
                                [11, 'rgb(241, 243, 237)'],
                            ],
                        },
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-halo-width': 1,
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'place_label',
                },
                {
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [6, 10],
                                [10, 14],
                                [14, 22],
                            ],
                        },
                        'icon-image': 'dot-9',
                        'text-font': [
                            'Montserrat SemiBold',
                            'Arial Unicode MS Regular',
                        ],
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7.99, [0, -0.2]],
                                [8, [0, 0]],
                            ],
                        },
                        'icon-size': 1,
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'bottom'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-letter-spacing': {
                            base: 1,
                            stops: [
                                [5, 0.02],
                                [6, 0.07],
                            ],
                        },
                        'text-max-width': 7,
                    },
                    metadata: {
                        'mapbox:group': '1459802912335.5327',
                    },
                    maxzoom: 14,
                    filter: [
                        'all',
                        ['!in', 'scalerank', 0, 1, 2, 3, 4, 5],
                        ['==', 'type', 'city'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-city-sm',
                    paint: {
                        'text-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(0, 0%, 100%)'],
                                [11, 'rgb(241, 243, 237)'],
                            ],
                        },
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-halo-width': 1.75,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 0.9],
                                [8, 0],
                            ],
                        },
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'place_label',
                },
                {
                    layout: {
                        'text-field': '{name_en}',
                        'icon-image': 'dot-10',
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'top'],
                                [8, 'center'],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7.99, [0, 0.1]],
                                [8, [0, 0]],
                            ],
                        },
                        'text-font': [
                            'Montserrat SemiBold',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 0.9,
                            stops: [
                                [5, 12],
                                [12, 22],
                            ],
                        },
                        'icon-size': 1,
                    },
                    metadata: {
                        'mapbox:group': '1459802912335.5327',
                    },
                    maxzoom: 14,
                    filter: [
                        'all',
                        ['==', 'type', 'city'],
                        ['in', 'ldir', 'E', 'S', 'SE', 'SW'],
                        ['in', 'scalerank', 3, 4, 5],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-city-md-s',
                    paint: {
                        'text-halo-width': 1.5,
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(0, 0%, 100%)'],
                                [11, 'rgb(241, 243, 237)'],
                            ],
                        },
                        'text-halo-blur': 0.5,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 0.9],
                                [8, 0],
                            ],
                        },
                        'text-opacity': 1,
                    },
                    'source-layer': 'place_label',
                },
                {
                    layout: {
                        'text-size': {
                            base: 0.9,
                            stops: [
                                [5, 12],
                                [12, 22],
                            ],
                        },
                        'icon-image': 'dot-10',
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    7,
                                    [
                                        'DIN Offc Pro Regular',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    8,
                                    [
                                        'DIN Offc Pro Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7.99, [0, -0.25]],
                                [8, [0, 0]],
                            ],
                        },
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'bottom'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                    },
                    metadata: {
                        'mapbox:group': '1459802912335.5327',
                    },
                    maxzoom: 14,
                    filter: [
                        'all',
                        ['==', 'type', 'city'],
                        ['in', 'ldir', 'N', 'NE', 'NW', 'W'],
                        ['in', 'scalerank', 3, 4, 5],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-city-md-n',
                    paint: {
                        'text-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(0, 0%, 100%)'],
                                [11, 'rgb(241, 243, 237)'],
                            ],
                        },
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-halo-width': 1.5,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 0.9],
                                [8, 0],
                            ],
                        },
                        'text-halo-blur': 0.5,
                        'text-opacity': 1,
                    },
                    'source-layer': 'place_label',
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-size': {
                            base: 0.9,
                            stops: [
                                [4, 12],
                                [10, 22],
                            ],
                        },
                        'icon-image': 'dot-11',
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    7,
                                    [
                                        'DIN Offc Pro Regular',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    8,
                                    [
                                        'DIN Offc Pro Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7.99, [0, 0.15]],
                                [8, [0, 0]],
                            ],
                        },
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'top'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                    },
                    metadata: {
                        'mapbox:group': '1459802912335.5327',
                    },
                    maxzoom: 14,
                    filter: [
                        'all',
                        ['<=', 'scalerank', 2],
                        ['==', 'type', 'city'],
                        ['in', 'ldir', 'E', 'S', 'SE', 'SW'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-city-lg-s',
                    paint: {
                        'text-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(0, 0%, 100%)'],
                                [11, 'rgb(241, 243, 237)'],
                            ],
                        },
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-halo-width': 1.5,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 0.9],
                                [8, 0],
                            ],
                        },
                        'text-halo-blur': 0.5,
                        'text-translate': [0, -0.75],
                        'text-opacity': 1,
                    },
                    'source-layer': 'place_label',
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-size': {
                            base: 0.9,
                            stops: [
                                [4, 12],
                                [10, 22],
                            ],
                        },
                        'icon-image': 'dot-11',
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    7,
                                    [
                                        'DIN Offc Pro Regular',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    8,
                                    [
                                        'DIN Offc Pro Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7.99, [0, -0.25]],
                                [8, [0, 0]],
                            ],
                        },
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'bottom'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                    },
                    metadata: {
                        'mapbox:group': '1459802912335.5327',
                    },
                    maxzoom: 14,
                    filter: [
                        'all',
                        ['<=', 'scalerank', 2],
                        ['==', 'type', 'city'],
                        ['in', 'ldir', 'N', 'NE', 'NW', 'W'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-city-lg-n',
                    paint: {
                        'text-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(0, 0%, 100%)'],
                                [11, 'rgb(241, 243, 237)'],
                            ],
                        },
                        'text-opacity': 1,
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [9, 'hsl(0, 0%, 18%)'],
                                [12, 'hsl(0, 2%, 14%)'],
                            ],
                        },
                        'text-halo-width': 1.5,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 1],
                                [8, 0],
                            ],
                        },
                        'text-halo-blur': 0.5,
                        'text-translate': [0, 0],
                    },
                    'source-layer': 'place_label',
                },
                {
                    minzoom: 3,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [3, 12],
                                [6, 16],
                            ],
                        },
                        'symbol-spacing': {
                            base: 1,
                            stops: [
                                [4, 100],
                                [6, 400],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.1,
                        'text-max-width': 5,
                    },
                    metadata: {
                        'mapbox:group': '1459802922547.9956',
                    },
                    maxzoom: 10,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['>=', 'labelrank', 4],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-sm-ln',
                    paint: {
                        'text-color': 'hsl(224, 29%, 40%)',
                    },
                    'source-layer': 'marine_label',
                },
                {
                    minzoom: 3,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': 5,
                        'text-letter-spacing': 0.1,
                        'text-line-height': 1.5,
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [3, 12],
                                [6, 16],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1459802922547.9956',
                    },
                    maxzoom: 10,
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['>=', 'labelrank', 4],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-sm-pt',
                    paint: {
                        'text-color': 'hsl(224, 29%, 40%)',
                    },
                    'source-layer': 'marine_label',
                },
                {
                    minzoom: 2,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1.1,
                            stops: [
                                [2, 12],
                                [5, 20],
                            ],
                        },
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.15,
                        'text-max-width': 5,
                    },
                    metadata: {
                        'mapbox:group': '1459802922547.9956',
                    },
                    maxzoom: 8,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['in', 'labelrank', 2, 3],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-md-ln',
                    paint: {
                        'text-color': 'hsl(224, 29%, 40%)',
                    },
                    'source-layer': 'marine_label',
                },
                {
                    minzoom: 2,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': 5,
                        'text-letter-spacing': 0.15,
                        'text-line-height': 1.5,
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1.1,
                            stops: [
                                [2, 14],
                                [5, 20],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1459802922547.9956',
                    },
                    maxzoom: 8,
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['in', 'labelrank', 2, 3],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-md-pt',
                    paint: {
                        'text-color': 'hsl(224, 29%, 40%)',
                    },
                    'source-layer': 'marine_label',
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': 4,
                        'text-size': {
                            base: 1,
                            stops: [
                                [1, 14],
                                [4, 30],
                            ],
                        },
                        'text-line-height': 1.1,
                        'symbol-placement': 'line',
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-letter-spacing': 0.25,
                    },
                    metadata: {
                        'mapbox:group': '1459802922547.9956',
                    },
                    maxzoom: 4,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'labelrank', 1],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-lg-ln',
                    paint: {
                        'text-color': 'hsl(224, 29%, 40%)',
                    },
                    'source-layer': 'marine_label',
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': 4,
                        'text-letter-spacing': 0.25,
                        'text-line-height': 1.5,
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [1, 14],
                                [4, 30],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1459802922547.9956',
                    },
                    maxzoom: 4,
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['==', 'labelrank', 1],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-lg-pt',
                    paint: {
                        'text-color': 'hsl(224, 29%, 40%)',
                    },
                    'source-layer': 'marine_label',
                },
                {
                    minzoom: 3,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [6, 10],
                                [9, 14],
                            ],
                        },
                        'text-transform': 'uppercase',
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                        'text-field': {
                            base: 1,
                            stops: [
                                [0, '{abbr}'],
                                [6, '{name_en}'],
                            ],
                        },
                        'text-letter-spacing': 0.15,
                        'text-max-width': 5,
                    },
                    metadata: {
                        'mapbox:group': '1459802933574.639',
                    },
                    maxzoom: 9,
                    filter: ['<', 'area', 20000],
                    type: 'symbol',
                    source: 'composite',
                    id: 'state-label-sm',
                    paint: {
                        'text-color': 'hsl(0, 0%, 100%)',
                        'text-halo-color': 'hsl(224, 2%, 18%)',
                        'text-halo-width': 1,
                        'text-opacity': 1,
                    },
                    'source-layer': 'state_label',
                },
                {
                    minzoom: 3,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [5, 10],
                                [8, 16],
                            ],
                        },
                        'text-transform': 'uppercase',
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                        'text-field': {
                            base: 1,
                            stops: [
                                [0, '{abbr}'],
                                [5, '{name_en}'],
                            ],
                        },
                        'text-letter-spacing': 0.15,
                        'text-max-width': 6,
                    },
                    metadata: {
                        'mapbox:group': '1459802933574.639',
                    },
                    maxzoom: 8,
                    filter: [
                        'all',
                        ['<', 'area', 80000],
                        ['>=', 'area', 20000],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'state-label-md',
                    paint: {
                        'text-color': 'hsl(0, 0%, 100%)',
                        'text-halo-color': 'hsl(224, 2%, 18%)',
                        'text-halo-width': 2,
                    },
                    'source-layer': 'state_label',
                },
                {
                    minzoom: 3,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [4, 10],
                                [7, 18],
                            ],
                        },
                        'text-transform': 'uppercase',
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                        'text-padding': 1,
                        'text-field': {
                            base: 1,
                            stops: [
                                [0, '{abbr}'],
                                [4, '{name_en}'],
                            ],
                        },
                        'text-letter-spacing': 0.15,
                        'text-max-width': 6,
                    },
                    metadata: {
                        'mapbox:group': '1459802933574.639',
                    },
                    maxzoom: 7,
                    filter: ['>=', 'area', 80000],
                    type: 'symbol',
                    source: 'composite',
                    id: 'state-label-lg',
                    paint: {
                        'text-color': 'hsl(0, 0%, 100%)',
                        'text-halo-color': 'hsl(224, 2%, 18%)',
                        'text-opacity': 1,
                        'text-halo-width': 2,
                    },
                    'source-layer': 'state_label',
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': 6,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 0.9,
                            stops: [
                                [5, 14],
                                [9, 22],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1459802941943.712',
                    },
                    maxzoom: 10,
                    filter: ['>=', 'scalerank', 5],
                    type: 'symbol',
                    source: 'composite',
                    id: 'country-label-sm',
                    paint: {
                        'text-color': 'hsl(0, 0%, 100%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(224, 2%, 18%)'],
                                [4, 'hsl(224, 1%, 12%)'],
                                [8, 'hsl(224, 1%, 2%)'],
                            ],
                        },
                        'text-halo-width': 1.25,
                    },
                    'source-layer': 'country_label',
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-field': {
                            base: 1,
                            stops: [
                                [0, '{code}'],
                                [2, '{name_en}'],
                            ],
                        },
                        'text-max-width': 6,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [3, 10],
                                [8, 24],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1459802941943.712',
                    },
                    maxzoom: 8,
                    filter: ['in', 'scalerank', 3, 4],
                    type: 'symbol',
                    source: 'composite',
                    id: 'country-label-md',
                    paint: {
                        'text-color': 'hsl(0, 0%, 100%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(224, 2%, 18%)'],
                                [4, 'hsl(224, 1%, 12%)'],
                                [8, 'hsl(224, 1%, 2%)'],
                            ],
                        },
                        'text-halo-width': 1.25,
                    },
                    'source-layer': 'country_label',
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': {
                            base: 1,
                            stops: [
                                [0, 5],
                                [3, 6],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [1, 10],
                                [6, 24],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1459802941943.712',
                    },
                    maxzoom: 7,
                    filter: ['in', 'scalerank', 1, 2],
                    type: 'symbol',
                    source: 'composite',
                    id: 'country-label-lg',
                    paint: {
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [0, 'hsl(224, 2%, 18%)'],
                                [4, 'hsl(224, 1%, 12%)'],
                                [8, 'hsl(224, 1%, 2%)'],
                            ],
                        },
                        'text-halo-width': 1.25,
                        'text-color': 'hsl(0, 0%, 100%)',
                    },
                    'source-layer': 'country_label',
                },
            ],
        },
    },
    streetLayerVectorial: {
        streetLayerVectorial: {
            layers: [
                {
                    id: 'background',
                    type: 'background',
                    layout: {},
                    paint: {
                        'background-color': {
                            base: 1,
                            stops: [
                                [11, 'hsl(35, 32%, 91%)'],
                                [13, 'hsl(35, 12%, 89%)'],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'landcover_snow',
                    type: 'fill',
                    metadata: {
                        'mapbox:group': '1456970288113.8113',
                    },
                    source: 'composite',
                    'source-layer': 'landcover',
                    filter: ['==', 'class', 'snow'],
                    layout: {},
                    paint: {
                        'fill-color': 'hsl(0, 0%, 100%)',
                        'fill-opacity': 0.2,
                        'fill-antialias': false,
                    },
                    interactive: true,
                },
                {
                    layout: {},
                    metadata: {
                        'mapbox:group': '1456970288113.8113',
                    },
                    maxzoom: 14,
                    filter: ['==', 'class', 'wood'],
                    type: 'fill',
                    source: 'composite',
                    id: 'landcover_wood',
                    paint: {
                        'fill-color': 'hsl(75, 62%, 81%)',
                        'fill-opacity': {
                            base: 1.5,
                            stops: [
                                [2, 0.3],
                                [7, 0],
                            ],
                        },
                        'fill-antialias': false,
                    },
                    'source-layer': 'landcover',
                    interactive: true,
                },
                {
                    layout: {},
                    metadata: {
                        'mapbox:group': '1456970288113.8113',
                    },
                    maxzoom: 14,
                    filter: ['==', 'class', 'scrub'],
                    type: 'fill',
                    source: 'composite',
                    id: 'landcover_scrub',
                    paint: {
                        'fill-color': 'hsl(75, 62%, 81%)',
                        'fill-opacity': {
                            base: 1.5,
                            stops: [
                                [2, 0.3],
                                [7, 0],
                            ],
                        },
                        'fill-antialias': false,
                    },
                    'source-layer': 'landcover',
                    interactive: true,
                },
                {
                    layout: {},
                    metadata: {
                        'mapbox:group': '1456970288113.8113',
                    },
                    maxzoom: 14,
                    filter: ['==', 'class', 'grass'],
                    type: 'fill',
                    source: 'composite',
                    id: 'landcover_grass',
                    paint: {
                        'fill-color': 'hsl(75, 62%, 81%)',
                        'fill-opacity': {
                            base: 1.5,
                            stops: [
                                [2, 0.3],
                                [7, 0],
                            ],
                        },
                        'fill-antialias': false,
                    },
                    'source-layer': 'landcover',
                    interactive: true,
                },
                {
                    layout: {},
                    metadata: {
                        'mapbox:group': '1456970288113.8113',
                    },
                    maxzoom: 14,
                    filter: ['==', 'class', 'crop'],
                    type: 'fill',
                    source: 'composite',
                    id: 'landcover_crop',
                    paint: {
                        'fill-color': 'hsl(75, 62%, 81%)',
                        'fill-opacity': {
                            base: 1.5,
                            stops: [
                                [2, 0.3],
                                [7, 0],
                            ],
                        },
                        'fill-antialias': false,
                    },
                    'source-layer': 'landcover',
                    interactive: true,
                },
                {
                    id: 'national_park',
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'landuse_overlay',
                    filter: ['==', 'class', 'national_park'],
                    layout: {},
                    paint: {
                        'fill-color': 'hsl(100, 58%, 76%)',
                        'fill-opacity': {
                            base: 1,
                            stops: [
                                [5, 0],
                                [6, 0.5],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'hospital',
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'landuse',
                    filter: ['==', 'class', 'hospital'],
                    layout: {},
                    paint: {
                        'fill-color': {
                            base: 1,
                            stops: [
                                [15.5, 'hsl(340, 37%, 87%)'],
                                [16, 'hsl(340, 63%, 89%)'],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'school',
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'landuse',
                    filter: ['==', 'class', 'school'],
                    layout: {},
                    paint: {
                        'fill-color': {
                            base: 1,
                            stops: [
                                [15.5, 'hsl(50, 47%, 81%)'],
                                [16, 'hsl(50, 63%, 84%)'],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'park',
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'landuse',
                    filter: ['==', 'class', 'park'],
                    layout: {},
                    paint: {
                        'fill-color': 'hsl(100, 58%, 76%)',
                        'fill-opacity': {
                            base: 1,
                            stops: [
                                [5, 0],
                                [6, 1],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'pitch',
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'landuse',
                    filter: ['==', 'class', 'pitch'],
                    layout: {},
                    paint: {
                        'fill-color': 'hsl(100, 57%, 72%)',
                    },
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {
                        'line-join': 'miter',
                    },
                    filter: ['==', 'class', 'pitch'],
                    type: 'line',
                    source: 'composite',
                    id: 'pitch-line',
                    paint: {
                        'line-color': 'hsl(75, 57%, 84%)',
                    },
                    'source-layer': 'landuse',
                    interactive: true,
                },
                {
                    id: 'cemetery',
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'landuse',
                    filter: ['==', 'class', 'cemetery'],
                    layout: {},
                    paint: {
                        'fill-color': 'hsl(75, 37%, 81%)',
                    },
                    interactive: true,
                },
                {
                    id: 'industrial',
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'landuse',
                    filter: ['==', 'class', 'industrial'],
                    layout: {},
                    paint: {
                        'fill-color': {
                            base: 1,
                            stops: [
                                [15.5, 'hsl(230, 15%, 86%)'],
                                [16, 'hsl(230, 29%, 89%)'],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'sand',
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'landuse',
                    filter: ['==', 'class', 'sand'],
                    layout: {},
                    paint: {
                        'fill-color': 'hsl(60, 46%, 87%)',
                    },
                    interactive: true,
                },
                {
                    layout: {},
                    metadata: {
                        'mapbox:group': '1456969573402.7817',
                    },
                    maxzoom: 16,
                    filter: ['==', 'level', 94],
                    type: 'fill',
                    source: 'composite',
                    id: 'hillshade_highlight_bright',
                    paint: {
                        'fill-color': 'hsl(0, 0%, 100%)',
                        'fill-opacity': {
                            stops: [
                                [14, 0.12],
                                [16, 0],
                            ],
                        },
                        'fill-antialias': false,
                    },
                    'source-layer': 'hillshade',
                    interactive: true,
                },
                {
                    layout: {},
                    metadata: {
                        'mapbox:group': '1456969573402.7817',
                    },
                    maxzoom: 16,
                    filter: ['==', 'level', 90],
                    type: 'fill',
                    source: 'composite',
                    id: 'hillshade_highlight_med',
                    paint: {
                        'fill-color': 'hsl(0, 0%, 100%)',
                        'fill-opacity': {
                            stops: [
                                [14, 0.12],
                                [16, 0],
                            ],
                        },
                        'fill-antialias': false,
                    },
                    'source-layer': 'hillshade',
                    interactive: true,
                },
                {
                    layout: {},
                    metadata: {
                        'mapbox:group': '1456969573402.7817',
                    },
                    maxzoom: 16,
                    filter: ['==', 'level', 89],
                    type: 'fill',
                    source: 'composite',
                    id: 'hillshade_shadow_faint',
                    paint: {
                        'fill-color': 'hsl(56, 59%, 22%)',
                        'fill-opacity': {
                            stops: [
                                [14, 0.05],
                                [16, 0],
                            ],
                        },
                        'fill-antialias': false,
                    },
                    'source-layer': 'hillshade',
                    interactive: true,
                },
                {
                    layout: {},
                    metadata: {
                        'mapbox:group': '1456969573402.7817',
                    },
                    maxzoom: 16,
                    filter: ['==', 'level', 78],
                    type: 'fill',
                    source: 'composite',
                    id: 'hillshade_shadow_med',
                    paint: {
                        'fill-color': 'hsl(56, 59%, 22%)',
                        'fill-opacity': {
                            stops: [
                                [14, 0.05],
                                [16, 0],
                            ],
                        },
                        'fill-antialias': false,
                    },
                    'source-layer': 'hillshade',
                    interactive: true,
                },
                {
                    layout: {},
                    metadata: {
                        'mapbox:group': '1456969573402.7817',
                    },
                    maxzoom: 16,
                    filter: ['==', 'level', 67],
                    type: 'fill',
                    source: 'composite',
                    id: 'hillshade_shadow_dark',
                    paint: {
                        'fill-color': 'hsl(56, 59%, 22%)',
                        'fill-opacity': {
                            stops: [
                                [14, 0.06],
                                [16, 0],
                            ],
                        },
                        'fill-antialias': false,
                    },
                    'source-layer': 'hillshade',
                    interactive: true,
                },
                {
                    layout: {},
                    metadata: {
                        'mapbox:group': '1456969573402.7817',
                    },
                    maxzoom: 16,
                    filter: ['==', 'level', 56],
                    type: 'fill',
                    source: 'composite',
                    id: 'hillshade_shadow_extreme',
                    paint: {
                        'fill-color': 'hsl(56, 59%, 22%)',
                        'fill-opacity': {
                            stops: [
                                [14, 0.06],
                                [16, 0],
                            ],
                        },
                        'fill-antialias': false,
                    },
                    'source-layer': 'hillshade',
                    interactive: true,
                },
                {
                    minzoom: 8,
                    layout: {
                        'line-cap': {
                            base: 1,
                            stops: [
                                [0, 'butt'],
                                [11, 'round'],
                            ],
                        },
                        'line-join': 'round',
                    },
                    filter: ['in', 'class', 'canal', 'river'],
                    type: 'line',
                    source: 'composite',
                    id: 'waterway-river-canal',
                    paint: {
                        'line-color': 'hsl(205, 87%, 76%)',
                        'line-width': {
                            base: 1.3,
                            stops: [
                                [8.5, 0.1],
                                [20, 8],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [8, 0],
                                [8.5, 1],
                            ],
                        },
                    },
                    'source-layer': 'waterway',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                    },
                    filter: ['!in', 'class', 'canal', 'river'],
                    type: 'line',
                    source: 'composite',
                    id: 'waterway-small',
                    paint: {
                        'line-color': 'hsl(205, 87%, 76%)',
                        'line-width': {
                            base: 1.35,
                            stops: [
                                [13.5, 0.1],
                                [20, 3],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13, 0],
                                [13.5, 1],
                            ],
                        },
                    },
                    'source-layer': 'waterway',
                    interactive: true,
                },
                {
                    id: 'water-shadow',
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'water',
                    layout: {},
                    paint: {
                        'fill-color': 'hsl(215, 84%, 69%)',
                        'fill-translate': {
                            base: 1.2,
                            stops: [
                                [7, [0, 0]],
                                [16, [-1, -1]],
                            ],
                        },
                        'fill-translate-anchor': 'viewport',
                        'fill-opacity': 1,
                    },
                    interactive: true,
                },
                {
                    id: 'water',
                    paint: {
                        'fill-color': 'hsl(196, 80%, 70%)',
                    },
                    layout: {},
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'water',
                    interactive: true,
                },
                {
                    id: 'barrier_line-land-polygon',
                    type: 'fill',
                    source: 'composite',
                    'source-layer': 'barrier_line',
                    filter: [
                        'all',
                        ['==', '$type', 'Polygon'],
                        ['==', 'class', 'land'],
                    ],
                    layout: {},
                    paint: {
                        'fill-color': 'hsl(35, 12%, 89%)',
                    },
                    interactive: true,
                },
                {
                    id: 'barrier_line-land-line',
                    type: 'line',
                    source: 'composite',
                    'source-layer': 'barrier_line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'class', 'land'],
                    ],
                    layout: {
                        'line-cap': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.99,
                            stops: [
                                [14, 0.75],
                                [20, 40],
                            ],
                        },
                        'line-color': 'hsl(35, 12%, 89%)',
                    },
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {},
                    metadata: {
                        'mapbox:group': '1444934828655.3389',
                    },
                    filter: [
                        'all',
                        ['!=', 'type', 'apron'],
                        ['==', '$type', 'Polygon'],
                    ],
                    type: 'fill',
                    source: 'composite',
                    id: 'aeroway-polygon',
                    paint: {
                        'fill-color': {
                            base: 1,
                            stops: [
                                [15, 'hsl(230, 23%, 82%)'],
                                [16, 'hsl(230, 37%, 84%)'],
                            ],
                        },
                        'fill-opacity': {
                            base: 1,
                            stops: [
                                [11, 0],
                                [11.5, 1],
                            ],
                        },
                    },
                    'source-layer': 'aeroway',
                    interactive: true,
                },
                {
                    minzoom: 9,
                    layout: {},
                    metadata: {
                        'mapbox:group': '1444934828655.3389',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'type', 'runway'],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'aeroway-runway',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [15, 'hsl(230, 23%, 82%)'],
                                [16, 'hsl(230, 37%, 84%)'],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [9, 1],
                                [18, 80],
                            ],
                        },
                    },
                    'source-layer': 'aeroway',
                    interactive: true,
                },
                {
                    minzoom: 9,
                    layout: {},
                    metadata: {
                        'mapbox:group': '1444934828655.3389',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'type', 'taxiway'],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'aeroway-taxiway',
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [15, 'hsl(230, 23%, 82%)'],
                                [16, 'hsl(230, 37%, 84%)'],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 0.5],
                                [18, 20],
                            ],
                        },
                    },
                    'source-layer': 'aeroway',
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {},
                    filter: [
                        'all',
                        ['!=', 'type', 'building:part'],
                        ['==', 'underground', 'false'],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'building-line',
                    paint: {
                        'line-color': 'hsl(35, 6%, 79%)',
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 0.75],
                                [20, 3],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [15.5, 0],
                                [16, 1],
                            ],
                        },
                    },
                    'source-layer': 'building',
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {},
                    filter: [
                        'all',
                        ['!=', 'type', 'building:part'],
                        ['==', 'underground', 'false'],
                    ],
                    type: 'fill',
                    source: 'composite',
                    id: 'building',
                    paint: {
                        'fill-color': {
                            base: 1,
                            stops: [
                                [15, 'hsl(35, 11%, 88%)'],
                                [16, 'hsl(35, 8%, 85%)'],
                            ],
                        },
                        'fill-opacity': {
                            base: 1,
                            stops: [
                                [15.5, 0],
                                [16, 1],
                            ],
                        },
                        'fill-outline-color': 'hsl(35, 6%, 79%)',
                    },
                    'source-layer': 'building',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-street-low',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': {
                            stops: [
                                [11.5, 0],
                                [12, 1],
                                [14, 1],
                                [14.01, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-street_limited-low',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': {
                            stops: [
                                [11.5, 0],
                                [12, 1],
                                [14, 1],
                                [14.01, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 14,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['==', 'structure', 'tunnel'],
                            ['in', 'class', 'link', 'service', 'track'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-service-link-track-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 19%, 75%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                        'line-dasharray': [3, 3],
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-street_limited-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 19%, 75%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [13, 0],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-street-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 19%, 75%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [13, 0],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'tunnel-secondary-tertiary-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'tunnel'],
                            ['in', 'class', 'secondary', 'tertiary'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.2,
                            stops: [
                                [10, 0.75],
                                [18, 2],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [8.5, 0.5],
                                [10, 0.75],
                                [18, 26],
                            ],
                        },
                        'line-color': 'hsl(230, 19%, 75%)',
                    },
                    interactive: true,
                },
                {
                    id: 'tunnel-primary-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'primary'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-dasharray': [3, 3],
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': 'hsl(230, 19%, 75%)',
                    },
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'tunnel'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-trunk_link-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-dasharray': [3, 3],
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-motorway_link-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-dasharray': [3, 3],
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'tunnel-trunk-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'tunnel'],
                            ['==', 'type', 'trunk'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-opacity': 1,
                        'line-dasharray': [3, 3],
                    },
                    interactive: true,
                },
                {
                    id: 'tunnel-motorway-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-opacity': 1,
                        'line-dasharray': [3, 3],
                    },
                    interactive: true,
                },
                {
                    minzoom: 14,
                    layout: {
                        'line-join': 'miter',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'construction'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-construction',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [0.4, 0.8]],
                                [15, [0.3, 0.6]],
                                [16, [0.2, 0.3]],
                                [17, [0.2, 0.25]],
                                [18, [0.15, 0.15]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'tunnel-path',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'steps'],
                            ['==', 'class', 'path'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 1],
                                [18, 4],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.75, 1]],
                                [16, [1, 0.75]],
                                [17, [1, 0.5]],
                            ],
                        },
                        'line-color': 'hsl(35, 26%, 95%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0],
                                [14.25, 1],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'tunnel-steps',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'tunnel'],
                            ['==', 'type', 'steps'],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 1],
                                [16, 1.6],
                                [18, 6],
                            ],
                        },
                        'line-color': 'hsl(35, 26%, 95%)',
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.75, 1]],
                                [16, [1, 0.75]],
                                [17, [0.3, 0.3]],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0],
                                [14.25, 1],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'tunnel'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-trunk_link',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(46, 77%, 78%)',
                        'line-opacity': 1,
                        'line-dasharray': [1, 0],
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-motorway_link',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(26, 100%, 78%)',
                        'line-opacity': 1,
                        'line-dasharray': [1, 0],
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'pedestrian'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-pedestrian',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': 1,
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.5, 0.4]],
                                [16, [1, 0.2]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 14,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['==', 'structure', 'tunnel'],
                            ['in', 'class', 'link', 'service', 'track'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-service-link-track',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-dasharray': [1, 0],
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-street_limited',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(35, 14%, 93%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'tunnel-street',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'tunnel-secondary-tertiary',
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [8.5, 0.5],
                                [10, 0.75],
                                [18, 26],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': 1,
                        'line-dasharray': [1, 0],
                        'line-blur': 0,
                    },
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    type: 'line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'tunnel'],
                            ['in', 'class', 'secondary', 'tertiary'],
                        ],
                    ],
                    source: 'composite',
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'tunnel-primary',
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': 1,
                        'line-dasharray': [1, 0],
                        'line-blur': 0,
                    },
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    type: 'line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'primary'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    source: 'composite',
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 16,
                    layout: {
                        'symbol-placement': 'line',
                        'icon-image': {
                            base: 1,
                            stops: [
                                [17, 'oneway-small'],
                                [18, 'oneway-large'],
                            ],
                        },
                        'symbol-spacing': 200,
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'tunnel'],
                            [
                                'in',
                                'class',
                                'link',
                                'path',
                                'pedestrian',
                                'service',
                                'track',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'tunnel-oneway-arrows-blue-minor',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {
                        'symbol-placement': 'line',
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-small'],
                                [17, 'oneway-large'],
                            ],
                        },
                        'symbol-spacing': 200,
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'tunnel'],
                            [
                                'in',
                                'class',
                                'primary',
                                'secondary',
                                'street',
                                'street_limited',
                                'tertiary',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'tunnel-oneway-arrows-blue-major',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'tunnel-trunk',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': 'hsl(46, 77%, 78%)',
                    },
                    interactive: true,
                },
                {
                    id: 'tunnel-motorway',
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-opacity': 1,
                        'line-color': 'hsl(26, 100%, 78%)',
                        'line-blur': 0,
                    },
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    type: 'line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'tunnel'],
                        ],
                    ],
                    source: 'composite',
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 16,
                    layout: {
                        'symbol-placement': 'line',
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-white-small'],
                                [17, 'oneway-white-large'],
                            ],
                        },
                        'symbol-spacing': 200,
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1444855769305.6016',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            [
                                '!in',
                                'type',
                                'primary_link',
                                'secondary_link',
                                'tertiary_link',
                            ],
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'tunnel'],
                            [
                                'in',
                                'class',
                                'link',
                                'motorway',
                                'motorway_link',
                                'trunk',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'tunnel-oneway-arrows-white',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'ferry',
                    type: 'line',
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'type', 'ferry'],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [15, 'hsl(205, 73%, 63%)'],
                                [17, 'hsl(230, 73%, 63%)'],
                            ],
                        },
                        'line-opacity': 1,
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [20, 1],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [12, [1, 0]],
                                [13, [12, 4]],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'ferry_auto',
                    type: 'line',
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'type', 'ferry_auto'],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [15, 'hsl(205, 73%, 63%)'],
                                [17, 'hsl(230, 73%, 63%)'],
                            ],
                        },
                        'line-opacity': 1,
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [20, 1],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'road-path-bg',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['!in', 'type', 'crossing', 'sidewalk', 'steps'],
                            ['==', 'class', 'path'],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 2],
                                [18, 7],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-color': 'hsl(230, 17%, 82%)',
                        'line-blur': 0,
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0],
                                [14.25, 0.75],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'road-steps-bg',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'type', 'steps'],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 2],
                                [17, 4.6],
                                [18, 7],
                            ],
                        },
                        'line-color': 'hsl(230, 17%, 82%)',
                        'line-dasharray': [1, 0],
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0],
                                [14.25, 0.75],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    minzoom: 16,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['in', 'type', 'crossing', 'sidewalk'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-sidewalk-bg',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 2],
                                [18, 7],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-color': 'hsl(230, 17%, 82%)',
                        'line-blur': 0,
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [16, 0],
                                [16.25, 0.75],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {
                        'icon-image': 'turning-circle-outline',
                        'icon-size': {
                            base: 1.5,
                            stops: [
                                [14, 0.122],
                                [18, 0.969],
                                [20, 1],
                            ],
                        },
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                        'icon-padding': 0,
                        'icon-rotation-alignment': 'map',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['in', 'class', 'turning_circle', 'turning_loop'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'turning-features-outline',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 12,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'pedestrian'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-pedestrian-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 2],
                                [18, 14.5],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': 0,
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-street-low',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': {
                            stops: [
                                [11, 0],
                                [11.25, 1],
                                [14, 1],
                                [14.01, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-street_limited-low',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': {
                            stops: [
                                [11, 0],
                                [11.25, 1],
                                [14, 1],
                                [14.01, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 14,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['in', 'class', 'link', 'service', 'track'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-service-link-track-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-street_limited-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [13, 0],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-street-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [13, 0],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'road-secondary-tertiary-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['in', 'class', 'secondary', 'tertiary'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.2,
                            stops: [
                                [10, 0.75],
                                [18, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [8.5, 0.5],
                                [10, 0.75],
                                [18, 26],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [9.99, 0],
                                [10, 1],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'road-primary-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'primary'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [9.99, 0],
                                [10, 1],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    minzoom: 10,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'motorway_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-motorway_link-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [10.99, 0],
                                [11, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-trunk_link-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [10.99, 0],
                                [11, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'road-trunk-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'trunk'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [6, 0],
                                [6.1, 1],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'road-motorway-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'motorway'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    minzoom: 14,
                    layout: {
                        'line-join': 'miter',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'construction'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-construction',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [0.4, 0.8]],
                                [15, [0.3, 0.6]],
                                [16, [0.2, 0.3]],
                                [17, [0.2, 0.25]],
                                [18, [0.15, 0.15]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 16,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['in', 'type', 'crossing', 'sidewalk'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-sidewalks',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 1],
                                [18, 4],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.75, 1]],
                                [16, [1, 0.75]],
                                [17, [1, 0.5]],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [16, 0],
                                [16.25, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'road-path',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 1],
                                [18, 4],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.75, 1]],
                                [16, [1, 0.75]],
                                [17, [1, 0.5]],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0],
                                [14.25, 1],
                            ],
                        },
                    },
                    layout: {
                        'line-join': 'round',
                    },
                    type: 'line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['!in', 'type', 'crossing', 'sidewalk', 'steps'],
                            ['==', 'class', 'path'],
                        ],
                    ],
                    source: 'composite',
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'road-steps',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 1],
                                [16, 1.6],
                                [18, 6],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.75, 1]],
                                [16, [1, 0.75]],
                                [17, [0.3, 0.3]],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0],
                                [14.25, 1],
                            ],
                        },
                    },
                    layout: {
                        'line-join': 'round',
                    },
                    type: 'line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'type', 'steps'],
                        ],
                    ],
                    source: 'composite',
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-trunk_link',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(46, 85%, 67%)',
                        'line-opacity': 1,
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 10,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'motorway_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-motorway_link',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(26, 100%, 68%)',
                        'line-opacity': 1,
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 12,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'pedestrian'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-pedestrian',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': 1,
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.5, 0.4]],
                                [16, [1, 0.2]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 12,
                    layout: {},
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'Polygon'],
                        [
                            'all',
                            ['==', 'structure', 'none'],
                            ['in', 'class', 'path', 'pedestrian'],
                        ],
                    ],
                    type: 'fill',
                    source: 'composite',
                    id: 'road-pedestrian-polygon-fill',
                    paint: {
                        'fill-color': {
                            base: 1,
                            stops: [
                                [16, 'hsl(230, 16%, 94%)'],
                                [16.25, 'hsl(230, 50%, 98%)'],
                            ],
                        },
                        'fill-outline-color': 'hsl(230, 26%, 88%)',
                        'fill-opacity': 1,
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 12,
                    layout: {},
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'Polygon'],
                        [
                            'all',
                            ['==', 'structure', 'none'],
                            ['in', 'class', 'path', 'pedestrian'],
                        ],
                    ],
                    type: 'fill',
                    source: 'composite',
                    id: 'road-pedestrian-polygon-pattern',
                    paint: {
                        'fill-color': 'hsl(0, 0%, 100%)',
                        'fill-outline-color': 'hsl(35, 10%, 83%)',
                        'fill-pattern': 'pedestrian-polygon',
                        'fill-opacity': {
                            base: 1,
                            stops: [
                                [16, 0],
                                [16.25, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 12,
                    layout: {},
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'Polygon'],
                        [
                            'all',
                            [
                                '!in',
                                'class',
                                'motorway',
                                'path',
                                'pedestrian',
                                'trunk',
                            ],
                            ['!in', 'structure', 'bridge', 'tunnel'],
                        ],
                    ],
                    type: 'fill',
                    source: 'composite',
                    id: 'road-polygon',
                    paint: {
                        'fill-color': 'hsl(0, 0%, 100%)',
                        'fill-outline-color': '#d6d9e6',
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 14,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['in', 'class', 'link', 'service', 'track'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-service-link-track',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-street_limited',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(35, 14%, 93%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'none'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-street',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'road-secondary-tertiary',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [8.5, 0.5],
                                [10, 0.75],
                                [18, 26],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [5, 'hsl(35, 32%, 91%)'],
                                [8, 'hsl(0, 0%, 100%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1.2,
                            stops: [
                                [5, 0],
                                [5.5, 1],
                            ],
                        },
                    },
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    type: 'line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['in', 'class', 'secondary', 'tertiary'],
                        ],
                    ],
                    source: 'composite',
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'road-primary',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [5, 'hsl(35, 32%, 91%)'],
                                [7, 'hsl(0, 0%, 100%)'],
                            ],
                        },
                        'line-opacity': 1,
                    },
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    type: 'line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'primary'],
                        ],
                    ],
                    source: 'composite',
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 16,
                    layout: {
                        'symbol-placement': 'line',
                        'icon-image': {
                            base: 1,
                            stops: [
                                [17, 'oneway-small'],
                                [18, 'oneway-large'],
                            ],
                        },
                        'icon-rotation-alignment': 'map',
                        'icon-padding': 2,
                        'symbol-spacing': 200,
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'oneway', 'true'],
                            [
                                'in',
                                'class',
                                'link',
                                'path',
                                'pedestrian',
                                'service',
                                'track',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-oneway-arrows-blue-minor',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {
                        'symbol-placement': 'line',
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-small'],
                                [17, 'oneway-large'],
                            ],
                        },
                        'icon-rotation-alignment': 'map',
                        'icon-padding': 2,
                        'symbol-spacing': 200,
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'oneway', 'true'],
                            [
                                'in',
                                'class',
                                'primary',
                                'secondary',
                                'street',
                                'street_limited',
                                'tertiary',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-oneway-arrows-blue-major',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'road-trunk',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [6, 'hsl(0, 0%, 100%)'],
                                [6.1, 'hsl(46, 80%, 60%)'],
                                [9, 'hsl(46, 85%, 67%)'],
                            ],
                        },
                    },
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    type: 'line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'trunk'],
                        ],
                    ],
                    source: 'composite',
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'road-motorway',
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(26, 87%, 62%)'],
                                [9, 'hsl(26, 100%, 68%)'],
                            ],
                        },
                    },
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    type: 'line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['==', 'class', 'motorway'],
                        ],
                    ],
                    source: 'composite',
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['in', 'class', 'major_rail', 'minor_rail'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-rail',
                    paint: {
                        'line-color': {
                            stops: [
                                [13, 'hsl(50, 17%, 82%)'],
                                [16, 'hsl(230, 10%, 74%)'],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [20, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            ['in', 'class', 'major_rail', 'minor_rail'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'road-rail-tracks',
                    paint: {
                        'line-color': {
                            stops: [
                                [13, 'hsl(50, 17%, 82%)'],
                                [16, 'hsl(230, 10%, 74%)'],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 4],
                                [20, 8],
                            ],
                        },
                        'line-dasharray': [0.1, 15],
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.75, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 16,
                    layout: {
                        'icon-size': 1,
                        'icon-image': 'level-crossing',
                        'icon-allow-overlap': true,
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['==', 'class', 'level_crossing'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'level-crossings',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 16,
                    layout: {
                        'symbol-placement': 'line',
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-white-small'],
                                [17, 'oneway-white-large'],
                            ],
                        },
                        'icon-padding': 2,
                        'symbol-spacing': 200,
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'structure', 'bridge', 'tunnel'],
                            [
                                '!in',
                                'type',
                                'primary_link',
                                'secondary_link',
                                'tertiary_link',
                            ],
                            ['==', 'oneway', 'true'],
                            [
                                'in',
                                'class',
                                'link',
                                'motorway',
                                'motorway_link',
                                'trunk',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-oneway-arrows-white',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {
                        'icon-image': 'turning-circle',
                        'icon-size': {
                            base: 1.5,
                            stops: [
                                [14, 0.095],
                                [18, 1],
                            ],
                        },
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                        'icon-padding': 0,
                        'icon-rotation-alignment': 'map',
                    },
                    metadata: {
                        'mapbox:group': '1444855786460.0557',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['in', 'class', 'turning_circle', 'turning_loop'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'turning-features',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'bridge-path-bg',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'steps'],
                            ['==', 'class', 'path'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 2],
                                [18, 7],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-color': 'hsl(230, 17%, 82%)',
                        'line-blur': 0,
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [15, 0],
                                [15.25, 1],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'bridge-steps-bg',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'steps'],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 2],
                                [17, 4.6],
                                [18, 7],
                            ],
                        },
                        'line-color': 'hsl(230, 17%, 82%)',
                        'line-dasharray': [1, 0],
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0],
                                [14.25, 0.75],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'pedestrian'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-pedestrian-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 2],
                                [18, 14.5],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': 0,
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-street-low',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': {
                            stops: [
                                [11.5, 0],
                                [12, 1],
                                [14, 1],
                                [14.01, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-street_limited-low',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': {
                            stops: [
                                [11.5, 0],
                                [12, 1],
                                [14, 1],
                                [14.01, 0],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 14,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['==', 'structure', 'bridge'],
                            ['in', 'class', 'link', 'service', 'track'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-service-link-track-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-street_limited-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [13, 0],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-street-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [13, 0],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'bridge-secondary-tertiary-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['in', 'class', 'secondary', 'tertiary'],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.2,
                            stops: [
                                [10, 0.75],
                                [18, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [8.5, 0.5],
                                [10, 0.75],
                                [18, 26],
                            ],
                        },
                        'line-translate': [0, 0],
                    },
                    interactive: true,
                },
                {
                    id: 'bridge-primary-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'primary'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-translate': [0, 0],
                    },
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk_link-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [10.99, 0],
                                [11, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway_link-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-opacity': 1,
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'bridge-trunk-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'bridge-motorway-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    minzoom: 14,
                    layout: {
                        'line-join': 'miter',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'construction'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-construction',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(230, 24%, 87%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [0.4, 0.8]],
                                [15, [0.3, 0.6]],
                                [16, [0.2, 0.3]],
                                [17, [0.2, 0.25]],
                                [18, [0.15, 0.15]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'bridge-path',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'steps'],
                            ['==', 'class', 'path'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 1],
                                [18, 4],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.75, 1]],
                                [16, [1, 0.75]],
                                [17, [1, 0.5]],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0],
                                [14.25, 1],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'bridge-steps',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [15, 1],
                                [16, 1.6],
                                [18, 6],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.75, 1]],
                                [16, [1, 0.75]],
                                [17, [0.3, 0.3]],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [14, 0],
                                [14.25, 1],
                            ],
                        },
                    },
                    layout: {
                        'line-join': 'round',
                    },
                    type: 'line',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'steps'],
                        ],
                    ],
                    source: 'composite',
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'trunk_link'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk_link',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(46, 85%, 67%)',
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway_link',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(26, 100%, 68%)',
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'pedestrian'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-pedestrian',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': 1,
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [14, [1, 0]],
                                [15, [1.5, 0.4]],
                                [16, [1, 0.2]],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 14,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!=', 'type', 'trunk_link'],
                            ['==', 'structure', 'bridge'],
                            ['in', 'class', 'link', 'service', 'track'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-service-link-track',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [18, 12],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street_limited'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-street_limited',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(35, 14%, 93%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'street'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-street',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12.5, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'bridge-secondary-tertiary',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['in', 'type', 'secondary', 'tertiary'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [8.5, 0.5],
                                [10, 0.75],
                                [18, 26],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': {
                            base: 1.2,
                            stops: [
                                [5, 0],
                                [5.5, 1],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'bridge-primary',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'primary'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-opacity': 1,
                    },
                    interactive: true,
                },
                {
                    minzoom: 16,
                    layout: {
                        'symbol-placement': 'line',
                        'icon-image': {
                            base: 1,
                            stops: [
                                [17, 'oneway-small'],
                                [18, 'oneway-large'],
                            ],
                        },
                        'symbol-spacing': 200,
                        'icon-rotation-alignment': 'map',
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'bridge'],
                            [
                                'in',
                                'class',
                                'link',
                                'path',
                                'pedestrian',
                                'service',
                                'track',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'bridge-oneway-arrows-blue-minor',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {
                        'symbol-placement': 'line',
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-small'],
                                [17, 'oneway-large'],
                            ],
                        },
                        'symbol-spacing': 200,
                        'icon-rotation-alignment': 'map',
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'bridge'],
                            [
                                'in',
                                'class',
                                'primary',
                                'secondary',
                                'street',
                                'street_limited',
                                'tertiary',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'bridge-oneway-arrows-blue-major',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'bridge-trunk',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': 'hsl(46, 85%, 67%)',
                    },
                    interactive: true,
                },
                {
                    id: 'bridge-motorway',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['!in', 'layer', 2, 3, 4, 5],
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'bridge'],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': 'hsl(26, 100%, 68%)',
                    },
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['in', 'class', 'major_rail', 'minor_rail'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-rail',
                    paint: {
                        'line-color': {
                            stops: [
                                [13, 'hsl(50, 17%, 82%)'],
                                [16, 'hsl(230, 10%, 74%)'],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [20, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['in', 'class', 'major_rail', 'minor_rail'],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-rail-tracks',
                    paint: {
                        'line-color': {
                            stops: [
                                [13, 'hsl(50, 17%, 82%)'],
                                [16, 'hsl(230, 10%, 74%)'],
                            ],
                        },
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 4],
                                [20, 8],
                            ],
                        },
                        'line-dasharray': [0.1, 15],
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [13.75, 0],
                                [20, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'trunk_link'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk_link-2-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [10.99, 0],
                                [11, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway_link-2-case',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.75],
                                [20, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-opacity': 1,
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'bridge-trunk-2-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    id: 'bridge-motorway-2-case',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    layout: {
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [10, 1],
                                [16, 2],
                            ],
                        },
                        'line-color': 'hsl(0, 0%, 100%)',
                        'line-gap-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'structure', 'bridge'],
                            ['==', 'type', 'trunk_link'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-trunk_link-2',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(46, 85%, 67%)',
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway_link'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'bridge-motorway_link-2',
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [12, 0.5],
                                [14, 2],
                                [18, 18],
                            ],
                        },
                        'line-color': 'hsl(26, 100%, 68%)',
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'bridge-trunk-2',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'trunk'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': 'hsl(46, 85%, 67%)',
                    },
                    interactive: true,
                },
                {
                    id: 'bridge-motorway-2',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    source: 'composite',
                    'source-layer': 'road',
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            ['==', 'class', 'motorway'],
                            ['==', 'structure', 'bridge'],
                            ['>=', 'layer', 2],
                        ],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [5, 0.75],
                                [18, 32],
                            ],
                        },
                        'line-color': 'hsl(26, 100%, 68%)',
                    },
                    interactive: true,
                },
                {
                    minzoom: 16,
                    layout: {
                        'symbol-placement': 'line',
                        'icon-image': {
                            base: 1,
                            stops: [
                                [16, 'oneway-white-small'],
                                [17, 'oneway-white-large'],
                            ],
                        },
                        'symbol-spacing': 200,
                        'icon-padding': 2,
                    },
                    metadata: {
                        'mapbox:group': '1444855799204.86',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'all',
                            [
                                '!in',
                                'type',
                                'primary_link',
                                'secondary_link',
                                'tertiary_link',
                            ],
                            ['==', 'oneway', 'true'],
                            ['==', 'structure', 'bridge'],
                            [
                                'in',
                                'class',
                                'link',
                                'motorway',
                                'motorway_link',
                                'trunk',
                            ],
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'bridge-oneway-arrows-white',
                    paint: {},
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    minzoom: 13,
                    layout: {
                        'line-join': 'round',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'class', 'aerialway'],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'aerialway',
                    paint: {
                        'line-color': 'hsl(230, 10%, 74%)',
                        'line-width': {
                            base: 1.5,
                            stops: [
                                [14, 0.5],
                                [20, 1],
                            ],
                        },
                    },
                    'source-layer': 'road',
                    interactive: true,
                },
                {
                    id: 'admin-3-4-boundaries-bg',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444934295202.7542',
                    },
                    source: 'composite',
                    'source-layer': 'admin',
                    filter: [
                        'all',
                        ['==', 'maritime', 0],
                        ['>=', 'admin_level', 3],
                    ],
                    layout: {
                        'line-join': 'bevel',
                    },
                    paint: {
                        'line-color': {
                            base: 1,
                            stops: [
                                [8, 'hsl(35, 12%, 89%)'],
                                [16, 'hsl(230, 49%, 90%)'],
                            ],
                        },
                        'line-width': {
                            base: 1,
                            stops: [
                                [7, 3.75],
                                [12, 5.5],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [7, 0],
                                [8, 0.75],
                            ],
                        },
                        'line-dasharray': [1, 0],
                        'line-translate': [0, 0],
                        'line-blur': {
                            base: 1,
                            stops: [
                                [3, 0],
                                [8, 3],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    minzoom: 1,
                    layout: {
                        'line-join': 'miter',
                    },
                    metadata: {
                        'mapbox:group': '1444934295202.7542',
                    },
                    filter: [
                        'all',
                        ['==', 'admin_level', 2],
                        ['==', 'maritime', 0],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'admin-2-boundaries-bg',
                    paint: {
                        'line-width': {
                            base: 1,
                            stops: [
                                [3, 3.5],
                                [10, 8],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [6, 'hsl(35, 12%, 89%)'],
                                [8, 'hsl(230, 49%, 90%)'],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [3, 0],
                                [4, 0.5],
                            ],
                        },
                        'line-translate': [0, 0],
                        'line-blur': {
                            base: 1,
                            stops: [
                                [3, 0],
                                [10, 2],
                            ],
                        },
                    },
                    'source-layer': 'admin',
                    interactive: true,
                },
                {
                    id: 'admin-3-4-boundaries',
                    type: 'line',
                    metadata: {
                        'mapbox:group': '1444934295202.7542',
                    },
                    source: 'composite',
                    'source-layer': 'admin',
                    filter: [
                        'all',
                        ['==', 'maritime', 0],
                        ['>=', 'admin_level', 3],
                    ],
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                    },
                    paint: {
                        'line-dasharray': {
                            base: 1,
                            stops: [
                                [6, [2, 0]],
                                [7, [2, 2, 6, 2]],
                            ],
                        },
                        'line-width': {
                            base: 1,
                            stops: [
                                [7, 0.75],
                                [12, 1.5],
                            ],
                        },
                        'line-opacity': {
                            base: 1,
                            stops: [
                                [2, 0],
                                [3, 1],
                            ],
                        },
                        'line-color': {
                            base: 1,
                            stops: [
                                [3, 'hsl(230, 14%, 77%)'],
                                [7, 'hsl(230, 8%, 62%)'],
                            ],
                        },
                    },
                    interactive: true,
                },
                {
                    minzoom: 1,
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444934295202.7542',
                    },
                    filter: [
                        'all',
                        ['==', 'admin_level', 2],
                        ['==', 'disputed', 0],
                        ['==', 'maritime', 0],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'admin-2-boundaries',
                    paint: {
                        'line-color': 'hsl(230, 8%, 51%)',
                        'line-width': {
                            base: 1,
                            stops: [
                                [3, 0.5],
                                [10, 2],
                            ],
                        },
                    },
                    'source-layer': 'admin',
                    interactive: true,
                },
                {
                    minzoom: 1,
                    layout: {
                        'line-join': 'round',
                    },
                    metadata: {
                        'mapbox:group': '1444934295202.7542',
                    },
                    filter: [
                        'all',
                        ['==', 'admin_level', 2],
                        ['==', 'disputed', 1],
                        ['==', 'maritime', 0],
                    ],
                    type: 'line',
                    source: 'composite',
                    id: 'admin-2-boundaries-dispute',
                    paint: {
                        'line-dasharray': [1.5, 1.5],
                        'line-color': 'hsl(230, 8%, 51%)',
                        'line-width': {
                            base: 1,
                            stops: [
                                [3, 0.5],
                                [10, 2],
                            ],
                        },
                    },
                    'source-layer': 'admin',
                    interactive: true,
                },
                {
                    id: 'housenum-label',
                    type: 'symbol',
                    source: 'composite',
                    'source-layer': 'housenum_label',
                    minzoom: 17,
                    layout: {
                        'text-field': '{house_num}',
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 4,
                        'text-max-width': 7,
                        'text-size': 9.5,
                    },
                    paint: {
                        'text-color': 'hsl(35, 2%, 69%)',
                        'text-halo-color': 'hsl(35, 8%, 85%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0,
                    },
                    interactive: true,
                },
                {
                    minzoom: 12,
                    layout: {
                        'text-field': '{name_en}',
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-pitch-alignment': 'viewport',
                        'text-max-angle': 30,
                        'text-size': {
                            base: 1,
                            stops: [
                                [13, 12],
                                [18, 16],
                            ],
                        },
                    },
                    filter: ['in', 'class', 'canal', 'river'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'waterway-label',
                    paint: {
                        'text-halo-width': 0.5,
                        'text-halo-color': 'hsl(196, 80%, 70%)',
                        'text-color': 'hsl(230, 48%, 44%)',
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'waterway_label',
                    interactive: true,
                },
                {
                    minzoom: 17,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [16, 11],
                                [20, 13],
                            ],
                        },
                        'icon-image': '{maki}-11',
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1444933456003.5437',
                    },
                    filter: [
                        'all',
                        [
                            '!in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                        ['==', 'scalerank', 4],
                        ['>=', 'localrank', 15],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-scalerank4-l15',
                    paint: {
                        'text-color': 'hsl(26, 25%, 32%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'poi_label',
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [16, 11],
                                [20, 13],
                            ],
                        },
                        'icon-image': '{maki}-11',
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 1,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1444933456003.5437',
                    },
                    filter: [
                        'all',
                        [
                            '!in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                        ['<=', 'localrank', 14],
                        ['==', 'scalerank', 4],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-scalerank4-l1',
                    paint: {
                        'text-color': 'hsl(26, 25%, 32%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'poi_label',
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [16, 11],
                                [20, 13],
                            ],
                        },
                        'icon-image': '{maki}-11',
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 1,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    metadata: {
                        'mapbox:group': '1444933456003.5437',
                    },
                    filter: [
                        'all',
                        ['==', 'scalerank', 4],
                        [
                            'in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'poi-parks_scalerank4',
                    paint: {
                        'text-color': 'hsl(100, 100%, 20%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'poi_label',
                    interactive: true,
                },
                {
                    id: 'poi-scalerank3',
                    type: 'symbol',
                    metadata: {
                        'mapbox:group': '1444933372896.5967',
                    },
                    source: 'composite',
                    'source-layer': 'poi_label',
                    filter: [
                        'all',
                        [
                            '!in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                        ['==', 'scalerank', 3],
                    ],
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [16, 11],
                                [20, 13],
                            ],
                        },
                        'icon-image': '{maki}-11',
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 1,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    paint: {
                        'text-color': 'hsl(26, 25%, 32%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0.5,
                    },
                    interactive: true,
                },
                {
                    id: 'poi-parks-scalerank3',
                    type: 'symbol',
                    metadata: {
                        'mapbox:group': '1444933372896.5967',
                    },
                    source: 'composite',
                    'source-layer': 'poi_label',
                    filter: [
                        'all',
                        ['==', 'scalerank', 3],
                        [
                            'in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                    ],
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [16, 11],
                                [20, 13],
                            ],
                        },
                        'icon-image': '{maki}-11',
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    paint: {
                        'text-color': 'hsl(100, 100%, 20%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0.5,
                    },
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [15, 10],
                                [20, 13],
                            ],
                        },
                        'text-max-angle': 30,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-padding': 1,
                        'text-rotation-alignment': 'map',
                        'text-pitch-alignment': 'viewport',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                    },
                    metadata: {
                        'mapbox:group': '1444933721429.3076',
                    },
                    filter: [
                        'all',
                        [
                            '!in',
                            'class',
                            'golf',
                            'link',
                            'motorway',
                            'pedestrian',
                            'primary',
                            'secondary',
                            'street',
                            'street_limited',
                            'tertiary',
                            'trunk',
                        ],
                        ['==', '$type', 'LineString'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-label-small',
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1.25,
                        'text-halo-blur': 1,
                    },
                    'source-layer': 'road_label',
                    interactive: true,
                },
                {
                    minzoom: 11,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [11, 10],
                                [20, 14],
                            ],
                        },
                        'text-max-angle': 30,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-padding': 1,
                        'text-rotation-alignment': 'map',
                        'text-pitch-alignment': 'viewport',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                    },
                    metadata: {
                        'mapbox:group': '1444933721429.3076',
                    },
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        [
                            'in',
                            'class',
                            'link',
                            'pedestrian',
                            'street',
                            'street_limited',
                        ],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'road-label-medium',
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                    },
                    'source-layer': 'road_label',
                    interactive: true,
                },
                {
                    id: 'road-label-large',
                    type: 'symbol',
                    metadata: {
                        'mapbox:group': '1444933721429.3076',
                    },
                    source: 'composite',
                    'source-layer': 'road_label',
                    filter: [
                        'in',
                        'class',
                        'motorway',
                        'primary',
                        'secondary',
                        'tertiary',
                        'trunk',
                    ],
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [9, 10],
                                [20, 16],
                            ],
                        },
                        'text-max-angle': 30,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-padding': 1,
                        'text-rotation-alignment': 'map',
                        'text-pitch-alignment': 'viewport',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                    },
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': 'hsla(0, 0%, 100%, 0.75)',
                        'text-halo-width': 1,
                        'text-halo-blur': 1,
                    },
                    interactive: true,
                },
                {
                    id: 'road-shields-black',
                    type: 'symbol',
                    metadata: {
                        'mapbox:group': '1444933575858.6992',
                    },
                    source: 'composite',
                    'source-layer': 'road_label',
                    filter: [
                        'all',
                        [
                            '!in',
                            'shield',
                            'at-expressway',
                            'at-motorway',
                            'at-state-b',
                            'bg-motorway',
                            'bg-national',
                            'ch-main',
                            'ch-motorway',
                            'cz-motorway',
                            'cz-road',
                            'de-motorway',
                            'e-road',
                            'fi-main',
                            'gr-motorway',
                            'gr-national',
                            'hr-motorway',
                            'hr-state',
                            'hu-main',
                            'hu-motorway',
                            'nz-state',
                            'pl-expressway',
                            'pl-motorway',
                            'pl-national',
                            'ro-county',
                            'ro-motorway',
                            'ro-national',
                            'rs-motorway',
                            'rs-state-1b',
                            'se-main',
                            'si-expressway',
                            'si-motorway',
                            'sk-highway',
                            'sk-road',
                            'us-interstate',
                            'us-interstate-business',
                            'us-interstate-duplex',
                            'us-interstate-truck',
                            'za-metropolitan',
                            'za-national',
                            'za-provincial',
                            'za-regional',
                        ],
                        ['<=', 'reflen', 6],
                    ],
                    layout: {
                        'text-size': 9,
                        'icon-image': '{shield}-{reflen}',
                        'icon-rotation-alignment': 'viewport',
                        'text-max-angle': 38,
                        'symbol-spacing': {
                            base: 1,
                            stops: [
                                [11, 150],
                                [14, 200],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                        'symbol-placement': {
                            base: 1,
                            stops: [
                                [10, 'point'],
                                [11, 'line'],
                            ],
                        },
                        'text-padding': 2,
                        'text-rotation-alignment': 'viewport',
                        'text-field': '{ref}',
                        'text-letter-spacing': 0.05,
                        'icon-padding': 2,
                    },
                    paint: {
                        'text-color': 'hsl(0, 0%, 7%)',
                        'icon-halo-color': 'rgba(0, 0, 0, 1)',
                        'icon-halo-width': 1,
                        'text-opacity': 1,
                        'icon-color': 'white',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0,
                    },
                    interactive: true,
                },
                {
                    id: 'road-shields-white',
                    type: 'symbol',
                    metadata: {
                        'mapbox:group': '1444933575858.6992',
                    },
                    source: 'composite',
                    'source-layer': 'road_label',
                    filter: [
                        'all',
                        ['<=', 'reflen', 6],
                        [
                            'in',
                            'shield',
                            'at-expressway',
                            'at-motorway',
                            'at-state-b',
                            'bg-motorway',
                            'bg-national',
                            'ch-main',
                            'ch-motorway',
                            'cz-motorway',
                            'cz-road',
                            'de-motorway',
                            'e-road',
                            'fi-main',
                            'gr-motorway',
                            'gr-national',
                            'hr-motorway',
                            'hr-state',
                            'hu-main',
                            'hu-motorway',
                            'nz-state',
                            'pl-expressway',
                            'pl-motorway',
                            'pl-national',
                            'ro-county',
                            'ro-motorway',
                            'ro-national',
                            'rs-motorway',
                            'rs-state-1b',
                            'se-main',
                            'si-expressway',
                            'si-motorway',
                            'sk-highway',
                            'sk-road',
                            'us-interstate',
                            'us-interstate-business',
                            'us-interstate-duplex',
                            'us-interstate-truck',
                            'za-metropolitan',
                            'za-national',
                            'za-provincial',
                            'za-regional',
                        ],
                    ],
                    layout: {
                        'text-size': 9,
                        'icon-image': '{shield}-{reflen}',
                        'icon-rotation-alignment': 'viewport',
                        'text-max-angle': 38,
                        'symbol-spacing': {
                            base: 1,
                            stops: [
                                [11, 150],
                                [14, 200],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                        'symbol-placement': {
                            base: 1,
                            stops: [
                                [10, 'point'],
                                [11, 'line'],
                            ],
                        },
                        'text-padding': 2,
                        'text-rotation-alignment': 'viewport',
                        'text-field': '{ref}',
                        'text-letter-spacing': 0.05,
                        'icon-padding': 2,
                    },
                    paint: {
                        'text-color': 'hsl(0, 0%, 100%)',
                        'icon-halo-color': 'rgba(0, 0, 0, 1)',
                        'icon-halo-width': 1,
                        'text-opacity': 1,
                        'icon-color': 'white',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0,
                    },
                    interactive: true,
                },
                {
                    minzoom: 14,
                    layout: {
                        'text-field': '{ref}',
                        'text-size': 9,
                        'icon-image': 'motorway-exit-{reflen}',
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                    },
                    metadata: {
                        'mapbox:group': '1444933575858.6992',
                    },
                    filter: ['all', ['<=', 'reflen', 9], ['>', 'reflen', 0]],
                    type: 'symbol',
                    source: 'composite',
                    id: 'motorway-junction',
                    paint: {
                        'text-color': 'hsl(0, 0%, 100%)',
                        'text-translate': [0, 0],
                    },
                    'source-layer': 'motorway_junction',
                    interactive: true,
                },
                {
                    id: 'poi-scalerank2',
                    type: 'symbol',
                    metadata: {
                        'mapbox:group': '1444933358918.2366',
                    },
                    source: 'composite',
                    'source-layer': 'poi_label',
                    filter: [
                        'all',
                        [
                            '!in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                        ['==', 'scalerank', 2],
                    ],
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [14, 11],
                                [20, 14],
                            ],
                        },
                        'icon-image': {
                            stops: [
                                [14, '{maki}-11'],
                                [15, '{maki}-15'],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    paint: {
                        'text-color': 'hsl(26, 25%, 32%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0.5,
                    },
                    interactive: true,
                },
                {
                    id: 'poi-parks-scalerank2',
                    type: 'symbol',
                    metadata: {
                        'mapbox:group': '1444933358918.2366',
                    },
                    source: 'composite',
                    'source-layer': 'poi_label',
                    filter: [
                        'all',
                        ['==', 'scalerank', 2],
                        [
                            'in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                    ],
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [14, 11],
                                [20, 14],
                            ],
                        },
                        'icon-image': {
                            stops: [
                                [14, '{maki}-11'],
                                [15, '{maki}-15'],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    paint: {
                        'text-color': 'hsl(100, 100%, 20%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0.5,
                    },
                    interactive: true,
                },
                {
                    minzoom: 12,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [16, 11],
                                [20, 13],
                            ],
                        },
                        'icon-image': '{network}',
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-offset': [0, 0.85],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': {
                            base: 1,
                            stops: [
                                [0, ''],
                                [13, '{name_en}'],
                            ],
                        },
                        'text-letter-spacing': 0.01,
                        'icon-padding': 0,
                        'text-max-width': 7,
                    },
                    filter: ['!=', 'maki', 'entrance'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'rail-label',
                    paint: {
                        'text-color': 'hsl(230, 48%, 44%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'icon-halo-width': 4,
                        'icon-halo-color': '#fff',
                        'text-opacity': {
                            base: 1,
                            stops: [
                                [13.99, 0],
                                [14, 1],
                            ],
                        },
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'rail_station_label',
                    interactive: true,
                },
                {
                    minzoom: 15,
                    layout: {
                        'text-field': '{name_en}',
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-max-width': 7,
                        'text-size': {
                            base: 1,
                            stops: [
                                [16, 13],
                                [20, 16],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444933808272.805',
                    },
                    filter: ['<=', 'area', 10000],
                    type: 'symbol',
                    source: 'composite',
                    id: 'water-label-sm',
                    paint: {
                        'text-color': 'hsl(230, 48%, 44%)',
                    },
                    'source-layer': 'water_label',
                    interactive: true,
                },
                {
                    minzoom: 5,
                    layout: {
                        'text-field': '{name_en}',
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-max-width': 7,
                        'text-size': {
                            base: 1,
                            stops: [
                                [13, 13],
                                [18, 18],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444933808272.805',
                    },
                    filter: ['>', 'area', 10000],
                    type: 'symbol',
                    source: 'composite',
                    id: 'water-label',
                    paint: {
                        'text-color': 'hsl(230, 48%, 44%)',
                    },
                    'source-layer': 'water_label',
                    interactive: true,
                },
                {
                    layout: {
                        'text-line-height': 1.2,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11],
                                [18, 14],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0],
                        'text-rotation-alignment': 'viewport',
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                    },
                    maxzoom: 18,
                    filter: [
                        'all',
                        [
                            'all',
                            ['<=', 'localrank', 10],
                            ['==', 'type', 'residential'],
                        ],
                        ['in', '$type', 'LineString', 'Point', 'Polygon'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-residential',
                    paint: {
                        'text-color': 'hsl(26, 25%, 32%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    id: 'poi-parks-scalerank1',
                    type: 'symbol',
                    metadata: {
                        'mapbox:group': '1444933322393.2852',
                    },
                    source: 'composite',
                    'source-layer': 'poi_label',
                    filter: [
                        'all',
                        ['<=', 'scalerank', 1],
                        [
                            'in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                    ],
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11],
                                [18, 14],
                            ],
                        },
                        'icon-image': {
                            stops: [
                                [13, '{maki}-11'],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    paint: {
                        'text-color': 'hsl(100, 100%, 20%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0.5,
                    },
                    interactive: true,
                },
                {
                    id: 'poi-scalerank1',
                    type: 'symbol',
                    metadata: {
                        'mapbox:group': '1444933322393.2852',
                    },
                    source: 'composite',
                    'source-layer': 'poi_label',
                    filter: [
                        'all',
                        [
                            '!in',
                            'maki',
                            'campsite',
                            'cemetery',
                            'dog-park',
                            'garden',
                            'golf',
                            'park',
                            'picnic-site',
                            'playground',
                            'zoo',
                        ],
                        ['<=', 'scalerank', 1],
                    ],
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11],
                                [18, 14],
                            ],
                        },
                        'icon-image': {
                            stops: [
                                [13, '{maki}-11'],
                                [14, '{maki}-15'],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.65],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    paint: {
                        'text-color': 'hsl(26, 25%, 32%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0.5,
                    },
                    interactive: true,
                },
                {
                    minzoom: 9,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 12],
                                [18, 18],
                            ],
                        },
                        'icon-image': {
                            stops: [
                                [12, '{maki}-11'],
                                [13, '{maki}-15'],
                            ],
                        },
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0.75],
                        'text-rotation-alignment': 'viewport',
                        'text-anchor': 'top',
                        'text-field': {
                            stops: [
                                [11, '{ref}'],
                                [12, '{name_en}'],
                            ],
                        },
                        'text-letter-spacing': 0.01,
                        'text-max-width': 9,
                    },
                    filter: ['<=', 'scalerank', 2],
                    type: 'symbol',
                    source: 'composite',
                    id: 'airport-label',
                    paint: {
                        'text-color': 'hsl(230, 48%, 44%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 0.5,
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'airport_label',
                    interactive: true,
                },
                {
                    layout: {
                        'text-line-height': 1.2,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11],
                                [18, 16],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0],
                        'text-rotation-alignment': 'viewport',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 8,
                    },
                    maxzoom: 16,
                    filter: [
                        'in',
                        'type',
                        'aboriginal_lands',
                        'archipelago',
                        'islet',
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-islet-archipelago-aboriginal',
                    paint: {
                        'text-color': 'hsl(230, 29%, 35%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    minzoom: 10,
                    layout: {
                        'text-field': '{name_en}',
                        'text-transform': 'uppercase',
                        'text-letter-spacing': 0.1,
                        'text-max-width': 7,
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 3,
                        'text-size': {
                            base: 1,
                            stops: [
                                [12, 11],
                                [16, 16],
                            ],
                        },
                    },
                    maxzoom: 16,
                    filter: ['==', 'type', 'neighbourhood'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-neighbourhood',
                    paint: {
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                        'text-color': 'hsl(230, 29%, 35%)',
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    minzoom: 10,
                    layout: {
                        'text-field': '{name_en}',
                        'text-transform': 'uppercase',
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'text-letter-spacing': 0.15,
                        'text-max-width': 7,
                        'text-padding': 3,
                        'text-size': {
                            base: 1,
                            stops: [
                                [11, 11],
                                [15, 18],
                            ],
                        },
                    },
                    maxzoom: 16,
                    filter: ['==', 'type', 'suburb'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-suburb',
                    paint: {
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                        'text-color': 'hsl(230, 29%, 35%)',
                        'text-halo-blur': 0.5,
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    minzoom: 10,
                    layout: {
                        'text-field': '{name_en}',
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [12, 11.5],
                                [15, 16],
                            ],
                        },
                    },
                    maxzoom: 16,
                    filter: ['==', 'type', 'hamlet'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-hamlet',
                    paint: {
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1.25,
                        'text-color': 'hsl(0, 0%, 0%)',
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    minzoom: 8,
                    layout: {
                        'text-field': '{name_en}',
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'text-max-width': 7,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11.5],
                                [16, 18],
                            ],
                        },
                    },
                    maxzoom: 15,
                    filter: ['==', 'type', 'village'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-village',
                    paint: {
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1.25,
                        'text-color': 'hsl(0, 0%, 0%)',
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    minzoom: 6,
                    layout: {
                        'icon-image': 'dot-9',
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    11,
                                    [
                                        'DIN Offc Pro Regular',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    12,
                                    [
                                        'DIN Offc Pro Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7, [0, -0.15]],
                                [8, [0, 0]],
                            ],
                        },
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'bottom'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                        'text-size': {
                            base: 1,
                            stops: [
                                [7, 11.5],
                                [15, 20],
                            ],
                        },
                    },
                    maxzoom: 15,
                    filter: ['==', 'type', 'town'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-town',
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1.25,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 1],
                                [8, 0],
                            ],
                        },
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    layout: {
                        'text-line-height': 1.2,
                        'text-size': {
                            base: 1,
                            stops: [
                                [10, 11],
                                [18, 16],
                            ],
                        },
                        'text-max-angle': 38,
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Regular',
                            'Arial Unicode MS Regular',
                        ],
                        'text-padding': 2,
                        'text-offset': [0, 0],
                        'text-rotation-alignment': 'viewport',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.01,
                        'text-max-width': 7,
                    },
                    maxzoom: 16,
                    filter: ['==', 'type', 'island'],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-island',
                    paint: {
                        'text-color': 'hsl(230, 29%, 35%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [6, 12],
                                [14, 22],
                            ],
                        },
                        'icon-image': 'dot-9',
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    7,
                                    [
                                        'DIN Offc Pro Regular',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    8,
                                    [
                                        'DIN Offc Pro Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7.99, [0, -0.2]],
                                [8, [0, 0]],
                            ],
                        },
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'bottom'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                    },
                    metadata: {
                        'mapbox:group': '1444862510685.128',
                    },
                    maxzoom: 14,
                    filter: [
                        'all',
                        ['!in', 'scalerank', 0, 1, 2, 3, 4, 5],
                        ['==', 'type', 'city'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-city-sm',
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1.25,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 1],
                                [8, 0],
                            ],
                        },
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    layout: {
                        'text-field': '{name_en}',
                        'icon-image': 'dot-10',
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'top'],
                                [8, 'center'],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7.99, [0, 0.1]],
                                [8, [0, 0]],
                            ],
                        },
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    7,
                                    [
                                        'DIN Offc Pro Regular',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    8,
                                    [
                                        'DIN Offc Pro Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-size': {
                            base: 0.9,
                            stops: [
                                [5, 12],
                                [12, 22],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444862510685.128',
                    },
                    maxzoom: 14,
                    filter: [
                        'all',
                        ['==', 'type', 'city'],
                        ['in', 'ldir', 'E', 'S', 'SE', 'SW'],
                        ['in', 'scalerank', 3, 4, 5],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-city-md-s',
                    paint: {
                        'text-halo-width': 1,
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-blur': 1,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 1],
                                [8, 0],
                            ],
                        },
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    layout: {
                        'icon-image': 'dot-10',
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    7,
                                    [
                                        'DIN Offc Pro Regular',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    8,
                                    [
                                        'DIN Offc Pro Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7.99, [0, -0.25]],
                                [8, [0, 0]],
                            ],
                        },
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'bottom'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                        'text-size': {
                            base: 0.9,
                            stops: [
                                [5, 12],
                                [12, 22],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444862510685.128',
                    },
                    maxzoom: 14,
                    filter: [
                        'all',
                        ['==', 'type', 'city'],
                        ['in', 'ldir', 'N', 'NE', 'NW', 'W'],
                        ['in', 'scalerank', 3, 4, 5],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-city-md-n',
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 1],
                                [8, 0],
                            ],
                        },
                        'text-halo-blur': 1,
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    minzoom: 1,
                    layout: {
                        'icon-image': 'dot-11',
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    7,
                                    [
                                        'DIN Offc Pro Regular',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    8,
                                    [
                                        'DIN Offc Pro Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7.99, [0, 0.15]],
                                [8, [0, 0]],
                            ],
                        },
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'top'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                        'text-size': {
                            base: 0.9,
                            stops: [
                                [4, 12],
                                [10, 22],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444862510685.128',
                    },
                    maxzoom: 14,
                    filter: [
                        'all',
                        ['<=', 'scalerank', 2],
                        ['==', 'type', 'city'],
                        ['in', 'ldir', 'E', 'S', 'SE', 'SW'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-city-lg-s',
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 1],
                                [8, 0],
                            ],
                        },
                        'text-halo-blur': 1,
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    minzoom: 1,
                    layout: {
                        'icon-image': 'dot-11',
                        'text-font': {
                            base: 1,
                            stops: [
                                [
                                    7,
                                    [
                                        'DIN Offc Pro Regular',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                                [
                                    8,
                                    [
                                        'DIN Offc Pro Medium',
                                        'Arial Unicode MS Regular',
                                    ],
                                ],
                            ],
                        },
                        'text-offset': {
                            base: 1,
                            stops: [
                                [7.99, [0, -0.25]],
                                [8, [0, 0]],
                            ],
                        },
                        'text-anchor': {
                            base: 1,
                            stops: [
                                [7, 'bottom'],
                                [8, 'center'],
                            ],
                        },
                        'text-field': '{name_en}',
                        'text-max-width': 7,
                        'text-size': {
                            base: 0.9,
                            stops: [
                                [4, 12],
                                [10, 22],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444862510685.128',
                    },
                    maxzoom: 14,
                    filter: [
                        'all',
                        ['<=', 'scalerank', 2],
                        ['==', 'type', 'city'],
                        ['in', 'ldir', 'N', 'NE', 'NW', 'W'],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'place-city-lg-n',
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-opacity': 1,
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                        'icon-opacity': {
                            base: 1,
                            stops: [
                                [7.99, 1],
                                [8, 0],
                            ],
                        },
                        'text-halo-blur': 1,
                    },
                    'source-layer': 'place_label',
                    interactive: true,
                },
                {
                    minzoom: 3,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1,
                            stops: [
                                [3, 12],
                                [6, 16],
                            ],
                        },
                        'symbol-spacing': {
                            base: 1,
                            stops: [
                                [4, 100],
                                [6, 400],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-pitch-alignment': 'viewport',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.1,
                        'text-max-width': 5,
                    },
                    metadata: {
                        'mapbox:group': '1444856087950.3635',
                    },
                    maxzoom: 10,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['>=', 'labelrank', 4],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-sm-ln',
                    paint: {
                        'text-color': 'hsl(205, 83%, 88%)',
                    },
                    'source-layer': 'marine_label',
                    interactive: true,
                },
                {
                    minzoom: 3,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': 5,
                        'text-letter-spacing': 0.1,
                        'text-line-height': 1.5,
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [3, 12],
                                [6, 16],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444856087950.3635',
                    },
                    maxzoom: 10,
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['>=', 'labelrank', 4],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-sm-pt',
                    paint: {
                        'text-color': 'hsl(205, 83%, 88%)',
                    },
                    'source-layer': 'marine_label',
                    interactive: true,
                },
                {
                    minzoom: 2,
                    layout: {
                        'text-line-height': 1.1,
                        'text-size': {
                            base: 1.1,
                            stops: [
                                [2, 12],
                                [5, 20],
                            ],
                        },
                        'symbol-spacing': 250,
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'symbol-placement': 'line',
                        'text-pitch-alignment': 'viewport',
                        'text-field': '{name_en}',
                        'text-letter-spacing': 0.15,
                        'text-max-width': 5,
                    },
                    metadata: {
                        'mapbox:group': '1444856087950.3635',
                    },
                    maxzoom: 8,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['in', 'labelrank', 2, 3],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-md-ln',
                    paint: {
                        'text-color': 'hsl(205, 83%, 88%)',
                    },
                    'source-layer': 'marine_label',
                    interactive: true,
                },
                {
                    minzoom: 2,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': 5,
                        'text-letter-spacing': 0.15,
                        'text-line-height': 1.5,
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1.1,
                            stops: [
                                [2, 14],
                                [5, 20],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444856087950.3635',
                    },
                    maxzoom: 8,
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['in', 'labelrank', 2, 3],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-md-pt',
                    paint: {
                        'text-color': 'hsl(205, 83%, 88%)',
                    },
                    'source-layer': 'marine_label',
                    interactive: true,
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': 4,
                        'text-letter-spacing': 0.25,
                        'text-line-height': 1.1,
                        'symbol-placement': 'line',
                        'text-pitch-alignment': 'viewport',
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [1, 14],
                                [4, 30],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444856087950.3635',
                    },
                    maxzoom: 4,
                    filter: [
                        'all',
                        ['==', '$type', 'LineString'],
                        ['==', 'labelrank', 1],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-lg-ln',
                    paint: {
                        'text-color': 'hsl(205, 83%, 88%)',
                    },
                    'source-layer': 'marine_label',
                    interactive: true,
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': 4,
                        'text-letter-spacing': 0.25,
                        'text-line-height': 1.5,
                        'text-font': [
                            'DIN Offc Pro Italic',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [1, 14],
                                [4, 30],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444856087950.3635',
                    },
                    maxzoom: 4,
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['==', 'labelrank', 1],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'marine-label-lg-pt',
                    paint: {
                        'text-color': 'hsl(205, 83%, 88%)',
                    },
                    'source-layer': 'marine_label',
                    interactive: true,
                },
                {
                    minzoom: 3,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [6, 10],
                                [9, 14],
                            ],
                        },
                        'text-transform': 'uppercase',
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                        'text-field': {
                            base: 1,
                            stops: [
                                [0, '{abbr}'],
                                [6, '{name_en}'],
                            ],
                        },
                        'text-letter-spacing': 0.15,
                        'text-max-width': 5,
                    },
                    metadata: {
                        'mapbox:group': '1444856151690.9143',
                    },
                    maxzoom: 9,
                    filter: ['<', 'area', 20000],
                    type: 'symbol',
                    source: 'composite',
                    id: 'state-label-sm',
                    paint: {
                        'text-opacity': 1,
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                    },
                    'source-layer': 'state_label',
                    interactive: true,
                },
                {
                    minzoom: 3,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [5, 10],
                                [8, 16],
                            ],
                        },
                        'text-transform': 'uppercase',
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                        'text-field': {
                            base: 1,
                            stops: [
                                [0, '{abbr}'],
                                [5, '{name_en}'],
                            ],
                        },
                        'text-letter-spacing': 0.15,
                        'text-max-width': 6,
                    },
                    metadata: {
                        'mapbox:group': '1444856151690.9143',
                    },
                    maxzoom: 8,
                    filter: [
                        'all',
                        ['<', 'area', 80000],
                        ['>=', 'area', 20000],
                    ],
                    type: 'symbol',
                    source: 'composite',
                    id: 'state-label-md',
                    paint: {
                        'text-opacity': 1,
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                    },
                    'source-layer': 'state_label',
                    interactive: true,
                },
                {
                    minzoom: 3,
                    layout: {
                        'text-size': {
                            base: 1,
                            stops: [
                                [4, 10],
                                [7, 18],
                            ],
                        },
                        'text-transform': 'uppercase',
                        'text-font': [
                            'DIN Offc Pro Bold',
                            'Arial Unicode MS Bold',
                        ],
                        'text-padding': 1,
                        'text-field': {
                            base: 1,
                            stops: [
                                [0, '{abbr}'],
                                [4, '{name_en}'],
                            ],
                        },
                        'text-letter-spacing': 0.15,
                        'text-max-width': 6,
                    },
                    metadata: {
                        'mapbox:group': '1444856151690.9143',
                    },
                    maxzoom: 7,
                    filter: ['>=', 'area', 80000],
                    type: 'symbol',
                    source: 'composite',
                    id: 'state-label-lg',
                    paint: {
                        'text-opacity': 1,
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': 'hsl(0, 0%, 100%)',
                        'text-halo-width': 1,
                    },
                    'source-layer': 'state_label',
                    interactive: true,
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': 6,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 0.9,
                            stops: [
                                [5, 14],
                                [9, 22],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444856144497.7825',
                    },
                    maxzoom: 10,
                    filter: ['>=', 'scalerank', 5],
                    type: 'symbol',
                    source: 'composite',
                    id: 'country-label-sm',
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [2, 'rgba(255,255,255,0.75)'],
                                [3, 'hsl(0, 0%, 100%)'],
                            ],
                        },
                        'text-halo-width': 1.25,
                    },
                    'source-layer': 'country_label',
                    interactive: true,
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-field': {
                            base: 1,
                            stops: [
                                [0, '{code}'],
                                [2, '{name_en}'],
                            ],
                        },
                        'text-max-width': 6,
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [3, 10],
                                [8, 24],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444856144497.7825',
                    },
                    maxzoom: 8,
                    filter: ['in', 'scalerank', 3, 4],
                    type: 'symbol',
                    source: 'composite',
                    id: 'country-label-md',
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [2, 'rgba(255,255,255,0.75)'],
                                [3, 'hsl(0, 0%, 100%)'],
                            ],
                        },
                        'text-halo-width': 1.25,
                    },
                    'source-layer': 'country_label',
                    interactive: true,
                },
                {
                    minzoom: 1,
                    layout: {
                        'text-field': '{name_en}',
                        'text-max-width': {
                            base: 1,
                            stops: [
                                [0, 5],
                                [3, 6],
                            ],
                        },
                        'text-font': [
                            'DIN Offc Pro Medium',
                            'Arial Unicode MS Regular',
                        ],
                        'text-size': {
                            base: 1,
                            stops: [
                                [1, 10],
                                [6, 24],
                            ],
                        },
                    },
                    metadata: {
                        'mapbox:group': '1444856144497.7825',
                    },
                    maxzoom: 7,
                    filter: ['in', 'scalerank', 1, 2],
                    type: 'symbol',
                    source: 'composite',
                    id: 'country-label-lg',
                    paint: {
                        'text-color': 'hsl(0, 0%, 0%)',
                        'text-halo-color': {
                            base: 1,
                            stops: [
                                [2, 'rgba(255,255,255,0.75)'],
                                [3, 'hsl(0, 0%, 100%)'],
                            ],
                        },
                        'text-halo-width': 1.25,
                    },
                    'source-layer': 'country_label',
                    interactive: true,
                },
            ],
        },
    },
};

export { MAPRIGHT_STYLES };
