import React from "react";
import { Link } from "react-router-dom";

export function InsufficientFunds() {
    return (
        <div className="alert alert-warning font-weight-bold text-white" role="alert">
            <strong>Uh oh!</strong> It looks like you don't have enough credits in your
            account to complete this purchase. Please check your account balance and add
            more credits on the{" "}
            <Link to="/home/settings" className="text-white text-decoration-underline">
                Settings
            </Link>{" "}
            page to proceed. If you need assistance, feel free to contact our support
            team.
        </div>
    );
}
