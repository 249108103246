import React, { PureComponent } from "react";

import { Button } from "library";

class ToggleButton extends PureComponent {
    render() {
        const { name, active, disabled, onClick } = this.props;
        return (
            <Button
                type={active ? "gradient-info" : "default"}
                onClick={() => onClick(name)}
                disabled={disabled}
            >
                {name}
            </Button>
        );
    }
}

export default class ToggleGroup extends PureComponent {
    render() {
        const { options, selectedValue, group_name, disabled, on_change } = this.props;

        const onClick = (name) => {
            if (name !== selectedValue) {
                on_change(group_name, name);
            }
        };

        let buttons = [];
        for (let name of options) {
            buttons.push(
                <ToggleButton
                    key={name}
                    name={name}
                    active={selectedValue === name}
                    onClick={onClick}
                    disabled={disabled && disabled.includes(name)}
                />,
            );
        }

        return (
            <div className="btn-group" role="group">
                {buttons}
            </div>
        );
    }
}
