import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { UserContext } from "context";
import { formatCredits, prettify_string, logout } from "functions";
import { PurchaseCreditsModal } from "components";

export default function Navbar({ current_path, current_location, toggle_sidebar_pin }) {
    const { user, balance } = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => setScrolled(window.scrollY > 0));
    }, []);

    let crumbs = [];
    let path = current_path;
    let last_step = current_location;

    let last_crumb = (
        <li
            key={last_step}
            className="breadcrumb-item text-sm text-dark font-weight-bolder active"
            aria-current="page"
        >
            {prettify_string(last_step)}
        </li>
    );

    let redirect_path = "/";
    for (let step of path) {
        if (step === "") {
            continue;
        }
        redirect_path += step + "/";
        crumbs.push(
            <li className="breadcrumb-item text-sm" key={step}>
                <Link className="opacity-5 text-dark" to={redirect_path}>
                    {prettify_string(step)}
                </Link>
            </li>,
        );
    }
    crumbs.push(last_crumb);

    let navbar_class = "shadow-none";
    if (scrolled) {
        navbar_class = "blur shadow-blur left-auto";
    }

    return (
        <nav
            className={`navbar navbar-main navbar-expand-lg position-sticky top-1 px-0 mx-4 border-radius-xl z-index-sticky ${navbar_class}`}
            id="navbarBlur"
            data-scroll="true"
        >
            <div className="container-fluid py-1 px-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                        {crumbs}
                    </ol>
                </nav>

                <div className="ms-md-auto pe-md-3 d-flex align-items-center"></div>

                <div style={{ float: "right" }}>
                    <ul className="navbar-nav  justify-content-end">
                        <li className="nav-item d-xl-none d-flex align-items-center">
                            <a
                                style={{ cursor: "pointer" }}
                                className="nav-link text-body p-0"
                                onClick={toggle_sidebar_pin}
                            >
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line"></i>
                                    <i className="sidenav-toggler-line"></i>
                                    <i className="sidenav-toggler-line"></i>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item d-flex ps-3 align-items-center">
                            {(showModal && (
                                <PurchaseCreditsModal
                                    onConfirmed={() => setShowModal(false)}
                                    onDismissed={() => setShowModal(false)}
                                />
                            )) ||
                                null}
                            <span
                                className="text-sm cursor-pointer"
                                title="Purchase Credits"
                                onClick={() => setShowModal(true)}
                            >
                                <i className="fa fa-coins me-sm-1" />
                                <span style={{ borderBottom: "1px dotted" }}>
                                    {formatCredits(balance)}
                                </span>
                            </span>
                        </li>
                        <li className="nav-item d-flex ps-3 align-items-center">
                            <span className="text-sm font-weight-bold">
                                <i className="fa fa-user me-sm-1" />
                                <span className="d-sm-inline d-none">{user.name}</span>
                            </span>
                        </li>
                        <li className="nav-item d-flex ps-3 align-items-center">
                            <button
                                className="btn btn-sm btn-outline-secondary mb-0"
                                onClick={logout}
                            >
                                Logout
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
