import clsx from "clsx";
import { useEffect, useState } from "react";
import { Typography } from "../typography/typography";

const BUTTON_SIZE = {
    sm: "15",
    md: "20",
};

// Create copy to clipboard component
interface CopyButtonProps {
    text: string;
    className?: string;
    size?: keyof typeof BUTTON_SIZE;
}

export function CopyButton({ text, className, size = "md" }: CopyButtonProps) {
    const [isClicked, setIsClicked] = useState(false);
    const buttonSize = BUTTON_SIZE[size];

    useEffect(() => {
        if (isClicked) {
            setTimeout(() => {
                setIsClicked(false);
            }, 2000);
        }
    }, [isClicked]);

    return (
        <button
            className={clsx(
                "lui-group/copy-button lui-relative",
                `lui-outline-none lui-border-0 lui-bg-gray-50 hover:lui-bg-gray-200`,
                `lui-rounded-${size}`,
                className,
            )}
            onClick={() => {
                copyToClipboard(text, {});
                setIsClicked(true);
            }}
        >
            <Typography
                variant="span"
                size="sm"
                color={isClicked ? "gray-1000" : "gray-900"}
            >
                {isClicked ? (
                    <svg
                        width={buttonSize}
                        height={buttonSize}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M22 11.0857V12.0057C21.9988 14.1621 21.3005 16.2604 20.0093 17.9875C18.7182 19.7147 16.9033 20.9782 14.8354 21.5896C12.7674 22.201 10.5573 22.1276 8.53447 21.3803C6.51168 20.633 4.78465 19.2518 3.61096 17.4428C2.43727 15.6338 1.87979 13.4938 2.02168 11.342C2.16356 9.19029 2.99721 7.14205 4.39828 5.5028C5.79935 3.86354 7.69279 2.72111 9.79619 2.24587C11.8996 1.77063 14.1003 1.98806 16.07 2.86572M22 4L12 14.01L9 11.01"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : (
                    <svg
                        width={buttonSize}
                        height={buttonSize}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.5 3H14.6C16.8402 3 17.9603 3 18.816 3.43597C19.5686 3.81947 20.1805 4.43139 20.564 5.18404C21 6.03969 21 7.15979 21 9.4V16.5M6.2 21H14.3C15.4201 21 15.9802 21 16.408 20.782C16.7843 20.5903 17.0903 20.2843 17.282 19.908C17.5 19.4802 17.5 18.9201 17.5 17.8V9.7C17.5 8.57989 17.5 8.01984 17.282 7.59202C17.0903 7.21569 16.7843 6.90973 16.408 6.71799C15.9802 6.5 15.4201 6.5 14.3 6.5H6.2C5.0799 6.5 4.51984 6.5 4.09202 6.71799C3.71569 6.90973 3.40973 7.21569 3.21799 7.59202C3 8.01984 3 8.57989 3 9.7V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
            </Typography>
            <Typography
                variant="span"
                size="sm"
                className="lui-hidden group-hover/copy-button:lui-block lui-absolute lui-bottom-[110%] lui-left-0 lui-bg-black lui-rounded-md lui-p-1"
                color="white"
            >
                {isClicked ? "Copied!" : "Copy"}
            </Typography>
        </button>
    );
}

function deselectCurrent() {
    var selection = document.getSelection();
    if (!selection.rangeCount) {
        return function () {};
    }
    var active = document.activeElement;

    var ranges: Range[] = [];
    for (var i = 0; i < selection.rangeCount; i++) {
        ranges.push(selection.getRangeAt(i));
    }

    switch (
        active.tagName.toUpperCase() // .toUpperCase handles XHTML
    ) {
        case "INPUT":
        case "TEXTAREA":
            if (active instanceof HTMLElement) {
                active.blur();
            }
            break;

        default:
            active = null;
            break;
    }

    selection.removeAllRanges();
    return function () {
        selection.type === "Caret" && selection.removeAllRanges();

        if (!selection.rangeCount) {
            ranges.forEach(function (range) {
                selection.addRange(range);
            });
        }

        if (active instanceof HTMLElement) {
            active.focus();
        }
    };
}

var clipboardToIE11Formatting = {
    "text/plain": "Text",
    "text/html": "Url",
    default: "Text",
};

var defaultMessage = "Copy to clipboard: #{key}, Enter";

function format(message: string) {
    var copyKey = (/mac os x/i.test(navigator.userAgent) ? "⌘" : "Ctrl") + "+C";
    return message.replace(/#{\s*key\s*}/g, copyKey);
}

function copyToClipboard(text: string, options: any) {
    let debug: any;
    let message: string | undefined;
    let reselectPrevious: () => void;
    let range: Range | undefined;
    let selection: Selection | undefined;
    let mark: HTMLElement | undefined;
    let success: boolean = false;

    if (!options) {
        options = {};
    }
    debug = options.debug || false;
    try {
        reselectPrevious = deselectCurrent();

        range = document.createRange();
        selection = document.getSelection();

        mark = document.createElement("span");
        mark.textContent = text;
        // avoid screen readers from reading out loud the text
        mark.ariaHidden = "true";
        // reset user styles for span element
        mark.style.all = "unset";
        // prevents scrolling to the end of the page
        mark.style.position = "fixed";
        mark.style.top = "0";
        mark.style.clip = "rect(0, 0, 0, 0)";
        // used to preserve spaces and line breaks
        mark.style.whiteSpace = "pre";
        // do not inherit user-select (it may be `none`)
        mark.style.webkitUserSelect = "text";
        // @ts-ignore
        mark.style.MozUserSelect = "text";
        // @ts-ignore
        mark.style.msUserSelect = "text";
        mark.style.userSelect = "text";
        mark.addEventListener("copy", function (e) {
            e.stopPropagation();
            if (options.format) {
                e.preventDefault();
                if (typeof e.clipboardData === "undefined") {
                    // IE 11
                    // @ts-ignore

                    debug && console.warn("unable to use e.clipboardData");
                    // @ts-ignore
                    debug && console.warn("trying IE specific stuff");
                    // @ts-ignore
                    window.clipboardData.clearData();
                    var format =
                        // @ts-ignore
                        clipboardToIE11Formatting[options.format] ||
                        clipboardToIE11Formatting["default"];
                    // @ts-ignore
                    window.clipboardData.setData(format, text);
                } else {
                    // all other browsers
                    e.clipboardData.clearData();
                    e.clipboardData.setData(options.format, text);
                }
            }
            if (options.onCopy) {
                e.preventDefault();
                options.onCopy(e.clipboardData);
            }
        });

        document.body.appendChild(mark);

        range.selectNodeContents(mark);
        selection.addRange(range);

        var successful = document.execCommand("copy");
        if (!successful) {
            throw new Error("copy command was unsuccessful");
        }
        success = true;
    } catch (err) {
        debug && console.error("unable to copy using execCommand: ", err);
        debug && console.warn("trying IE specific stuff");
        try {
            // @ts-ignore
            window.clipboardData.setData(options.format || "text", text);
            // @ts-ignore
            options.onCopy && options.onCopy(window.clipboardData);
            success = true;
        } catch (err) {
            debug && console.error("unable to copy using clipboardData: ", err);
            debug && console.error("falling back to prompt");
            message = format("message" in options ? options.message : defaultMessage);
            window.prompt(message, text);
        }
    } finally {
        if (selection) {
            if (typeof selection.removeRange == "function") {
                selection.removeRange(range);
            } else {
                selection.removeAllRanges();
            }
        }

        if (mark) {
            document.body.removeChild(mark);
        }
        reselectPrevious();
    }

    return success;
}
